import {
  ADD_INVOICE,
  DELETE_INVOICE,
  EDIT_INVOICE,
  GET_INVOICES,
  SET_INVOICE_DETAILS,
  DELETE_BULK_INVOICES,
  SET_INVOICE_CUSTOMER_DETAILS,
  GET_INVOICE_COUNTS,
} from '../../@jumbo/constants/ActionTypes';
import { INVOICE_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  invoices: [],
  invoiceCounts: { [INVOICE_KEYS.TOTAL]: '0' },
  currentInvoice: null,
  currentInvoiceCustomer: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES: {
      return {
        ...state,
        invoices: action.payload,
      };
    }
    case GET_INVOICE_COUNTS: {
      return {
        ...state,
        invoiceCounts: action.payload,
      };
    }
    case SET_INVOICE_DETAILS: {
      return {
        ...state,
        currentInvoice: action.payload,
      };
    }
    case SET_INVOICE_CUSTOMER_DETAILS: {
      return {
        ...state,
        currentInvoiceCustomer: action.payload,
      };
    }
    case ADD_INVOICE: {
      return {
        ...state,
        invoices: [action.payload, ...state.invoices],
      };
    }
    case EDIT_INVOICE: {
      return {
        ...state,
        invoices: state.invoices.map(invoice => (invoice[INVOICE_KEYS.ID] === action.payload.id ? action.payload : invoice)),
      };
    }
    case DELETE_INVOICE: {
      return {
        ...state,
        invoices: state.invoices.filter(invoice => invoice[INVOICE_KEYS.ID] !== action.payload),
      };
    }
    case DELETE_BULK_INVOICES: {
      return {
        ...state,
        invoices: state.invoices.filter(invoice => !action.payload.includes(invoice[INVOICE_KEYS.ID])),
      };
    }
    default:
      return state;
  }
};

import { ADD_CUSTOMER_NOTE, DELETE_CUSTOMER_NOTE, EDIT_CUSTOMER_NOTE, GET_CUSTOMER_NOTES, SET_CUSTOMER_NOTE_DETAILS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  customerNotes: [],
  currentCustomerNote: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_NOTES: {
      return {
        ...state,
        customerNotes: action.payload,
      };
    }
    case SET_CUSTOMER_NOTE_DETAILS: {
      return {
        ...state,
        currentCustomerNote: action.payload,
      };
    }
    case ADD_CUSTOMER_NOTE: {
      return {
        ...state,
        customerNotes: [action.payload, ...state.customerNotes],
      };
    }
    case EDIT_CUSTOMER_NOTE: {
      return {
        ...state,
        customerNotes: state.customerNotes.map(customerNote => (customerNote.id === action.payload.id ? action.payload : customerNote)),
      };
    }
    case DELETE_CUSTOMER_NOTE: {
      return {
        ...state,
        customerNotes: state.customerNotes.filter(customerNote => customerNote.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { ESTIMATE_KEYS, ESTIMATE_STATUS_KEYS, JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { addJobEstimate, deleteJobEstimate } from '../../../redux/actions/Jobs';

import NoRecordFound from '../../NoRecordFound';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  headerDivider: {
    marginTop: theme.spacing(2),
  },
  container: {
    maxHeight: 415,
    flexBasis: '100%',
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.black,
  },
  titleRoot2: {
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
  },
  actionBtn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

const headCells = [
  { id: ESTIMATE_KEYS.ESTIMATE_ID, disablePadding: false, label: 'Estimate' },
  { id: ESTIMATE_KEYS.FORMATTED_CREATED, disablePadding: false, label: 'Created' },
  { id: ESTIMATE_KEYS.STATUS, disablePadding: false, label: 'Status' },
  { id: ESTIMATE_KEYS.AMOUNT, disablePadding: false, label: 'Total' },
];

const colspan = headCells && headCells.length > 0 ? headCells.length + 1 : 1;

const JobEstimates = () => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { estimateStatuses } = useSelector(({ estimateStatusesReducer }) => estimateStatusesReducer);
  const { currentJob, jobEstimates } = useSelector(({ jobsReducer }) => jobsReducer);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const handleAddEstimate = () => {
    const jobEstimateData = {
      authcode: authUser.authcode,
      [ESTIMATE_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
      [ESTIMATE_KEYS.CUSTOMER_ID]: currentJob[JOB_KEYS.CUSTOMER_ID],
    };

    dispatch(
      addJobEstimate({ jobEstimateData: jobEstimateData }, null, messages => {
        if (_isMounted.current) setErrorMessages(messages);
      }),
    );
  };

  const handleRowClick = row => {
    console.log({ row });
  };

  const handleJobEstimateDelete = jobEstimate => {
    setSelectedEstimate(jobEstimate);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    if (selectedEstimate && selectedEstimate[ESTIMATE_KEYS.ID]) {
      const jobEstimateData = {
        authcode: authUser.authcode,
        [ESTIMATE_KEYS.ESTIMATE_ID]: selectedEstimate[ESTIMATE_KEYS.ID],
      };

      dispatch(
        deleteJobEstimate(
          { jobEstimateData: jobEstimateData },
          () => {
            if (_isMounted.current) setSelectedEstimate(null);
          },
          () => {
            if (_isMounted.current) setSelectedEstimate(null);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        setSelectedEstimate(null);
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid estimate',
        });
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelectedEstimate(null);
  };

  const estimatesAvailable = jobEstimates && jobEstimates.length > 0;

  return (
    <React.Fragment>
      <GridContainer>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="baseline" flexWrap="wrap">
            <Typography variant="h3" component="h3">
              Job Estimates
            </Typography>
            {!estimatesAvailable && (
              <Button
                type="button"
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddEstimate}>
                Add Estimate
              </Button>
            )}
          </Box>
          <Divider classes={{ root: classes.headerDivider }} />
        </Grid>
        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
          <Grid item xs={12}>
            {miscellaneousErrors.map((miscellaneousError, idx) => (
              <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                {miscellaneousError}
              </Typography>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="jobEstimatesTable"
              aria-label="Job Estimates Table">
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      align="left"
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={false}
                      style={{ width: headCell.width }}>
                      {headCell.label}
                    </TableCell>
                  ))}
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!!jobEstimates.length ? (
                  jobEstimates.map((row, index) => {
                    const statusObj = estimateStatuses.find(
                      estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID] === row[ESTIMATE_KEYS.STATUS],
                    );
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        classes={{
                          hover: classes.tableRow,
                        }}
                        onClick={() => handleRowClick(row)}>
                        <TableCell
                          component="th"
                          id={`property-${row[ESTIMATE_KEYS.ID]}`}
                          scope="row"
                          onClick={event => event.stopPropagation()}>
                          <Box display="flex" flexDirection="column">
                            <Typography
                              component={Link}
                              to={`/estimate/${row[ESTIMATE_KEYS.ID]}/`}
                              variant="h4"
                              className={classes.titleRoot}>
                              {`Estimate ${row[ESTIMATE_KEYS.ID]}`}
                            </Typography>
                            <Typography className={classes.titleRoot2} component="div" variant="h5">
                              {`Estimate ${row[ESTIMATE_KEYS.JOB_ID]} - 1`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{row[ESTIMATE_KEYS.FORMATTED_CREATED]}</TableCell>
                        <TableCell>{statusObj ? statusObj[ESTIMATE_STATUS_KEYS.NAME] : 'NA'}</TableCell>
                        <TableCell>{row[ESTIMATE_KEYS.AMOUNT]}</TableCell>
                        <TableCell align="center" onClick={event => event.stopPropagation()}>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleJobEstimateDelete(row)}
                            classes={{ root: classes.actionBtn, colorPrimary: classes.deleteBtn }}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={colspan} rowSpan={10} classes={{ root: classes.tableCell }}>
                      <NoRecordFound>No estimates have been added for this job!</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </GridContainer>

      {estimatesAvailable && selectedEstimate && (
        <ConfirmDialog
          open={openConfirmDialog}
          title="Delete Estimate?"
          content={'This action cannot be undone.'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </React.Fragment>
  );
};

export default JobEstimates;

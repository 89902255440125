import {
  ADD_JOB_JOB_TYPE,
  DELETE_JOB_JOB_TYPE,
  EDIT_JOB_JOB_TYPE,
  GET_JOB_JOB_TYPES,
  GET_JOB_JOB_TYPES_TOTALS,
  SET_JOB_JOB_TYPE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { JOB_JOB_TYPE_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  jobJobTypes: [],
  jobJobTypesTotals: null,
  currentJobJobType: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_JOB_TYPES: {
      return {
        ...state,
        jobJobTypes: action.payload,
      };
    }
    case GET_JOB_JOB_TYPES_TOTALS: {
      return {
        ...state,
        jobJobTypesTotals: action.payload,
      };
    }
    case SET_JOB_JOB_TYPE_DETAILS: {
      return {
        ...state,
        currentJobJobType: action.payload,
      };
    }
    case ADD_JOB_JOB_TYPE: {
      return {
        ...state,
        jobJobTypes: [...state.jobJobTypes, action.payload],
      };
    }
    case EDIT_JOB_JOB_TYPE: {
      return {
        ...state,
        jobJobTypes: state.jobJobTypes.map(jobJobType =>
          jobJobType[JOB_JOB_TYPE_KEYS.ID] === action.payload[JOB_JOB_TYPE_KEYS.ID] ? action.payload : jobJobType,
        ),
      };
    }
    case DELETE_JOB_JOB_TYPE: {
      return {
        ...state,
        jobJobTypes: state.jobJobTypes.filter(jobJobType => jobJobType[JOB_JOB_TYPE_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

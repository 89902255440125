import {
  ADD_INVOICE_PRODUCT,
  DELETE_INVOICE_PRODUCT,
  EDIT_INVOICE_PRODUCT,
  GET_INVOICE_PRODUCTS,
  GET_INVOICE_PRODUCTS_TOTALS,
  SET_INVOICE_PRODUCT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { INVOICE_PRODUCT_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  invoiceProducts: [],
  invoiceProductsTotals: null,
  currentInvoiceProduct: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE_PRODUCTS: {
      return {
        ...state,
        invoiceProducts: action.payload,
      };
    }
    case GET_INVOICE_PRODUCTS_TOTALS: {
      return {
        ...state,
        invoiceProductsTotals: action.payload,
      };
    }
    case SET_INVOICE_PRODUCT_DETAILS: {
      return {
        ...state,
        currentInvoiceProduct: action.payload,
      };
    }
    case ADD_INVOICE_PRODUCT: {
      return {
        ...state,
        invoiceProducts: [...state.invoiceProducts, action.payload],
      };
    }
    case EDIT_INVOICE_PRODUCT: {
      return {
        ...state,
        invoiceProducts: state.invoiceProducts.map(invoiceProduct =>
          invoiceProduct[INVOICE_PRODUCT_KEYS.ID] === action.payload[INVOICE_PRODUCT_KEYS.ID]
            ? action.payload
            : invoiceProduct,
        ),
      };
    }
    case DELETE_INVOICE_PRODUCT: {
      return {
        ...state,
        invoiceProducts: state.invoiceProducts.filter(
          invoiceProduct => invoiceProduct[INVOICE_PRODUCT_KEYS.ID] !== action.payload,
        ),
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import {
  PROPERTY_KEYS,
  COUNTRY_KEYS,
  CUSTOMER_KEYS,
  USA_STATE_KEYS,
  CUSTOMER_INFO_TAB,
  CUSTOMER_PROPERTIES_TAB,
  /* CUSTOMER_MAIN_ADDRESS,
  CUSTOMER_BILL_ADDRESS, */
} from '../../../@jumbo/constants/AppDataConstants';
import { requiredMessage, stateNotValid, countryNotValid, propertyNotValid } from '../../../@jumbo/constants/ErrorMessages';

import {
  setCurrentCustomerProperty,
  addNewCustomerProperty,
  addNewCustomerPropertyWithType,
  updateCustomerProperty,
  updateCustomerPropertyWithType,
  deleteCustomerProperty,
} from '../../../redux/actions/CustomerProperties';
import { getAddressString, getItemObjectNameFromId } from '../../../utils/Helpers';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  mapAddressInput: {
    width: '100%',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.borderColor.dark}`,
    borderRadius: 2,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    marginLeft: theme.spacing(2),
  },
  deleteAlert: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultCoordinates = { lat: 0, lng: 0 };
const CustomerAddressMap = withGoogleMap(props => (
  <GoogleMap zoom={12} center={props.center}>
    {props.markers && <Marker position={{ lat: props.markers.lat, lng: props.markers.lng }} />}
  </GoogleMap>
));
let autoComplete;

async function handlePlaceSelect(updateAddress, updateAddressComponents, updateMarkers, updateCenter) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateAddress(query);
  const components = addressObject.address_components;
  updateAddressComponents(components);
  if (addressObject.geometry && addressObject.geometry.location) {
    let lat = addressObject.geometry.location.lat();
    lat = !isNaN(lat) && !isNaN(parseFloat(lat)) ? lat : 0;
    let lng = addressObject.geometry.location.lng();
    lng = !isNaN(lng) && !isNaN(parseFloat(lng)) ? lng : 0;
    updateCenter({ lat, lng });
    updateMarkers({ lat, lng });
  } else {
    updateCenter(defaultCoordinates);
    updateMarkers(null);
  }
}

const EditAddressDialog = ({ tab, open, onClose, addressType }) => {
  const _isMounted = useRef(true);
  const autoCompleteRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentCustomer } = useSelector(({ customersReducer }) => customersReducer);
  const { customerProperties, currentCustomerProperty } = useSelector(
    ({ customerPropertiesReducer }) => customerPropertiesReducer,
  );
  const { countries } = useSelector(({ countriesReducer }) => countriesReducer);
  const { usaStates } = useSelector(({ usaStatesReducer }) => usaStatesReducer);

  const [property, setProperty] = useState('new');
  const [propertyError, setPropertyError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [addressOneError, setAddressOneError] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [addressTwoError, setAddressTwoError] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [state, setState] = useState('');
  const [stateError, setStateError] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState('');
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [mapAddress, setMapAddress] = useState('');
  const [mapAddressComponents, setMapAddressComponents] = useState(null);
  const [mapMarkers, setMapMarkers] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCoordinates);
  const [dataSet, setDataSet] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      autoCompleteRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (dataSet) {
      autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
      autoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon']);
      autoComplete.addListener('place_changed', () =>
        handlePlaceSelect(setMapAddress, setMapAddressComponents, setMapMarkers, setMapCenter),
      );
    }
  }, [dataSet]);

  useEffect(() => {
    if (!open) {
      setProperty('');
      setPropertyError('');
      setName('');
      setNameError('');
      setAddressOne('');
      setAddressOneError('');
      setAddressTwo('');
      setAddressTwoError('');
      setCity('');
      setCityError('');
      setState('');
      setStateError('');
      setZipcode('');
      setZipcodeError('');
      setCountry('');
      setCountryError('');
      setMapAddress('');
      setMapAddressComponents(null);
      setMapMarkers(null);
      setMapCenter(defaultCoordinates);
      setDataSet(false);
      setErrorMessages([]);
      setMiscellaneousErrors([]);
      setOpenConfirmDialog(false);
    }
  }, [open]);

  useEffect(() => {
    if ([CUSTOMER_INFO_TAB, CUSTOMER_PROPERTIES_TAB].includes(tab) && currentCustomerProperty) {
      setProperty(currentCustomerProperty[PROPERTY_KEYS.ID]);
      setName(currentCustomerProperty[PROPERTY_KEYS.NAME] ? currentCustomerProperty[PROPERTY_KEYS.NAME] : '');
      setAddressOne(
        currentCustomerProperty[PROPERTY_KEYS.ADDRESS_ONE] ? currentCustomerProperty[PROPERTY_KEYS.ADDRESS_ONE] : '',
      );
      setAddressTwo(
        currentCustomerProperty[PROPERTY_KEYS.ADDRESS_TWO] ? currentCustomerProperty[PROPERTY_KEYS.ADDRESS_TWO] : '',
      );
      setCity(currentCustomerProperty[PROPERTY_KEYS.CITY] ? currentCustomerProperty[PROPERTY_KEYS.CITY] : '');
      setZipcode(currentCustomerProperty[PROPERTY_KEYS.ZIP_CODE] ? currentCustomerProperty[PROPERTY_KEYS.ZIP_CODE] : '');

      const tempState = currentCustomerProperty[PROPERTY_KEYS.STATE] ? currentCustomerProperty[PROPERTY_KEYS.STATE] : '';
      if (tempState && usaStates.map(usaStateObj => usaStateObj[USA_STATE_KEYS.ID]).includes(tempState)) {
        setState(tempState);
      }

      const tempCountry = currentCustomerProperty[PROPERTY_KEYS.COUNTRY]
        ? currentCustomerProperty[PROPERTY_KEYS.COUNTRY]
        : '';
      if (tempCountry && countries.map(countryObj => countryObj[COUNTRY_KEYS.ID]).includes(tempCountry)) {
        setCountry(tempCountry);
      }

      let stateName = getItemObjectNameFromId(
        usaStates,
        currentCustomerProperty[PROPERTY_KEYS.STATE],
        USA_STATE_KEYS.ID,
        USA_STATE_KEYS.NAME,
      );

      let countryName = getItemObjectNameFromId(
        countries,
        currentCustomerProperty[PROPERTY_KEYS.COUNTRY],
        COUNTRY_KEYS.ID,
        COUNTRY_KEYS.NAME,
      );

      const tempMapAddress = getAddressString({
        addressOne: currentCustomerProperty[PROPERTY_KEYS.ADDRESS_ONE],
        addressTwo: currentCustomerProperty[PROPERTY_KEYS.ADDRESS_TWO],
        city: currentCustomerProperty[PROPERTY_KEYS.CITY],
        state: stateName,
        zipcode: currentCustomerProperty[PROPERTY_KEYS.ZIP_CODE],
        country: countryName,
      });

      setMapAddress(tempMapAddress);
    } else {
      setName('');
      setAddressOne('');
      setAddressTwo('');
      setCity('');
      setState('');
      setZipcode('');
      setCountry('');
      setMapAddress('');
      setMapAddressComponents(null);
      setMapMarkers(null);
      setMapCenter(defaultCoordinates);
    }

    if (!dataSet) {
      setDataSet(true);
    }
  }, [tab, currentCustomerProperty, dataSet, countries, usaStates]);

  useEffect(() => {
    if (mapAddressComponents) {
      let tempAddressOne = '';
      mapAddressComponents.forEach(function(mapAddressComponent) {
        const types = mapAddressComponent.types;
        const longName = mapAddressComponent.long_name;
        const shortName = mapAddressComponent.short_name;

        if (types.includes('street_number')) {
          tempAddressOne = `${longName} ${tempAddressOne}`;
          /* setName(longName);
          setNameError('');
          setAddressOne(longName);
          setAddressOneError(''); */
        } else if (types.includes('route')) {
          tempAddressOne = `${tempAddressOne} ${shortName}`;
          /* setAddressTwo(shortName);
          setAddressTwoError(''); */
        } else if (types.includes('locality') || types.includes('sublocality') || types.includes('sublocality_level_1')) {
          setCity(longName);
          setCityError('');
        } else if (types.includes('administrative_area_level_1')) {
          const mapState = usaStates.find(
            usaStateObj => usaStateObj[USA_STATE_KEYS.NAME] === longName || usaStateObj[USA_STATE_KEYS.CODE] === shortName,
          );
          if (mapState) {
            setState(mapState[USA_STATE_KEYS.ID]);
            setStateError('');
          } else {
            setState('');
          }
        } else if (types.includes('postal_code')) {
          setZipcode(longName);
          setZipcodeError('');
        } else if (types.includes('country')) {
          const mapCountry = countries.find(
            countryObj => countryObj[COUNTRY_KEYS.NAME] === longName || countryObj[COUNTRY_KEYS.CODE] === shortName,
          );
          if (mapCountry) {
            setCountry(mapCountry[COUNTRY_KEYS.ID]);
            setCountryError('');
          } else {
            setCountry('');
          }
        }
      });
      setName(tempAddressOne);
      setNameError('');
      setAddressOne(tempAddressOne);
      setAddressOneError('');
    }
  }, [mapAddressComponents, countries, usaStates]);

  useEffect(() => {
    if (errorMessages) {
      setMiscellaneousErrors([]);
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case PROPERTY_KEYS.NAME:
            setNameError(msg);
            break;

          case PROPERTY_KEYS.ADDRESS_ONE:
            setAddressOneError(msg);
            break;

          case PROPERTY_KEYS.ADDRESS_TWO:
            setAddressTwoError(msg);
            break;

          case PROPERTY_KEYS.CITY:
            setCityError(msg);
            break;

          case PROPERTY_KEYS.STATE:
            setStateError(msg);
            break;

          case PROPERTY_KEYS.ZIP_CODE:
            setZipcodeError(msg);
            break;

          case PROPERTY_KEYS.COUNTRY:
            setCountryError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const nameBlurHandler = event => {
    /* if (!event.target.value) {
      setNameError(requiredMessage);
    } */
  };

  const addressOneBlurHandler = event => {
    if (!event.target.value) {
      setAddressOneError(requiredMessage);
    }
  };

  const addressTwoBlurHandler = event => {
    /* if (!event.target.value) {
      setAddressTwoError(requiredMessage);
    } */
  };

  const cityBlurHandler = event => {
    if (!event.target.value) {
      setCityError(requiredMessage);
    }
  };

  const stateChangeHandler = event => {
    setState(event.target.value);
    setStateError('');

    if (!event.target.value) {
      setStateError(requiredMessage);
    } else if (!usaStates.map(usaState => usaState[USA_STATE_KEYS.ID]).includes(event.target.value)) {
      setStateError(stateNotValid);
    }
  };

  /* const regionBlurHandler = event => {
    if (!event.target.value) {
      setRegionError(requiredMessage);
    }
  }; */

  const countryChangeHandler = event => {
    setCountry(event.target.value);
    setCountryError('');

    if (!event.target.value) {
      setCountryError(requiredMessage);
    } else if (!countries.map(country => country[COUNTRY_KEYS.ID]).includes(event.target.value)) {
      setCountryError(countryNotValid);
    }
  };

  const zipcodeBlurHandler = event => {
    if (!event.target.value) {
      setZipcodeError(requiredMessage);
    }
  };

  let properties = [];
  if (customerProperties) {
    properties = customerProperties.map(customerProperty => {
      let stateName = getItemObjectNameFromId(
        usaStates,
        customerProperty[PROPERTY_KEYS.STATE],
        USA_STATE_KEYS.ID,
        USA_STATE_KEYS.NAME,
      );

      let countryName = getItemObjectNameFromId(
        countries,
        customerProperty[PROPERTY_KEYS.COUNTRY],
        COUNTRY_KEYS.ID,
        COUNTRY_KEYS.NAME,
      );

      const propertyLabel = getAddressString({
        addressOne: customerProperty[PROPERTY_KEYS.ADDRESS_ONE],
        addressTwo: customerProperty[PROPERTY_KEYS.ADDRESS_TWO],
        city: customerProperty[PROPERTY_KEYS.CITY],
        state: stateName,
        zipcode: customerProperty[PROPERTY_KEYS.ZIP_CODE],
        country: countryName,
      });

      return {
        slug: customerProperty[PROPERTY_KEYS.ID],
        label: propertyLabel,
      };
    });
  }
  properties.unshift({
    slug: 'new',
    label: 'Add New Property',
  });

  const propertyChangeHandler = event => {
    const propertyId = event.target.value;
    if (propertyId === 'new') {
      dispatch(setCurrentCustomerProperty(null));
      setProperty('new');
    } else {
      const selectedProperty = customerProperties.find(
        customerProperty => customerProperty[PROPERTY_KEYS.ID] === propertyId,
      );

      if (selectedProperty) {
        dispatch(setCurrentCustomerProperty(selectedProperty));
        setProperty(selectedProperty[PROPERTY_KEYS.ID]);
      } else {
        dispatch(setCurrentCustomerProperty(null));
        setProperty('new');
        setPropertyError(propertyNotValid);
      }
    }
  };

  const handleFormSubmit = event => {
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (![CUSTOMER_INFO_TAB, CUSTOMER_PROPERTIES_TAB].includes(tab)) {
      formIsValid = false;
      setMiscellaneousErrors(prevState => [...prevState, 'Invalid tab']);
    }

    /* if (!name) {
      formIsValid = false;
      setNameError(requiredMessage);
    } */

    if (!addressOne) {
      formIsValid = false;
      setAddressOneError(requiredMessage);
    }

    if (!city) {
      formIsValid = false;
      setCityError(requiredMessage);
    }

    if (!state) {
      formIsValid = false;
      setStateError(requiredMessage);
    } else if (!usaStates.map(usaState => usaState[USA_STATE_KEYS.ID]).includes(state)) {
      formIsValid = false;
      setStateError(stateNotValid);
    }

    if (!zipcode) {
      formIsValid = false;
      setZipcodeError(requiredMessage);
    }

    if (!country) {
      formIsValid = false;
      setCountryError(requiredMessage);
    } else if (!countries.map(country => country[COUNTRY_KEYS.ID]).includes(country)) {
      formIsValid = false;
      setStateError(countryNotValid);
    }

    if (formIsValid) {
      let customerPropertyData = {
        authcode: authUser.authcode,
        [PROPERTY_KEYS.CUSTOMER_ID]: currentCustomer[CUSTOMER_KEYS.ID],
        [PROPERTY_KEYS.NAME]: name,
        [PROPERTY_KEYS.ADDRESS_ONE]: addressOne,
        [PROPERTY_KEYS.ADDRESS_TWO]: addressTwo,
        [PROPERTY_KEYS.CITY]: city,
        [PROPERTY_KEYS.STATE]: state,
        [PROPERTY_KEYS.ZIP_CODE]: zipcode,
        [PROPERTY_KEYS.COUNTRY]: country,
      };

      if (tab === CUSTOMER_INFO_TAB) customerPropertyData[PROPERTY_KEYS.TYPE] = addressType;

      let action = '';
      if (property === 'new') {
        action = 'insert';
      } else if (
        property &&
        currentCustomerProperty &&
        currentCustomerProperty[PROPERTY_KEYS.ID] &&
        currentCustomerProperty[PROPERTY_KEYS.ID] === property
      ) {
        customerPropertyData[PROPERTY_KEYS.PROPERTY_ID] = property;
        action = 'update';
      } else {
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: propertyNotValid,
          customClass: { container: classes.deleteAlert },
        });
      }

      const requestData = { customerPropertyData: customerPropertyData };

      if (tab === CUSTOMER_INFO_TAB) {
        if (action === 'insert') {
          dispatch(
            addNewCustomerPropertyWithType(
              requestData,
              () => {
                if (_isMounted.current) {
                  onClose();
                }
              },
              messages => {
                if (_isMounted.current) setErrorMessages(messages);
              },
            ),
          );
        } else if (action === 'update') {
          dispatch(
            updateCustomerPropertyWithType(
              requestData,
              () => {
                if (_isMounted.current) {
                  onClose();
                }
              },
              messages => {
                if (_isMounted.current) setErrorMessages(messages);
              },
            ),
          );
        }
      } else if (tab === CUSTOMER_PROPERTIES_TAB) {
        if (action === 'insert') {
          dispatch(
            addNewCustomerProperty(
              requestData,
              newProperty => {
                if (_isMounted.current) onClose();
              },
              messages => {
                if (_isMounted.current) setErrorMessages(messages);
              },
            ),
          );
        } else if (action === 'update') {
          dispatch(
            updateCustomerProperty(
              requestData,
              updatedProperty => {
                if (_isMounted.current) onClose();
              },
              messages => {
                if (_isMounted.current) setErrorMessages(messages);
              },
            ),
          );
        }
      }
    }
  };

  const handlePropertyDelete = () => {
    if (
      !property ||
      property === 'new' ||
      !(
        currentCustomerProperty &&
        currentCustomerProperty[PROPERTY_KEYS.ID] &&
        currentCustomerProperty[PROPERTY_KEYS.ID] === property
      )
    ) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: propertyNotValid,
        customClass: { container: classes.deleteAlert },
      });
      return false;
    }

    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);

    const customerPropertyData = {
      authcode: authUser.authcode,
      [PROPERTY_KEYS.CUSTOMER_ID]: currentCustomer[CUSTOMER_KEYS.ID],
      [PROPERTY_KEYS.PROPERTY_ID]: property,
    };

    dispatch(
      deleteCustomerProperty(
        { customerPropertyData: customerPropertyData },
        () => {
          if (_isMounted.current) onClose();
        },
        null,
      ),
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const customerIsValid = currentCustomer && currentCustomer[CUSTOMER_KEYS.ID];
  const propertyIsValid = property && (property === 'new' || !!currentCustomerProperty);

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={onClose} className={classes.dialogRoot} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Property
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          {customerIsValid && propertyIsValid && (
            <form>
              <GridContainer>
                {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                  <Grid item xs={12}>
                    {miscellaneousErrors.map((miscellaneousError, idx) => (
                      <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                        {miscellaneousError}
                      </Typography>
                    ))}
                  </Grid>
                )}
                {[CUSTOMER_INFO_TAB].includes(tab) && (
                  <Grid item xs={12}>
                    <AppSelectBox
                      fullWidth
                      name="property"
                      data={properties}
                      label="Property"
                      valueKey="slug"
                      variant="outlined"
                      labelKey="label"
                      value={property}
                      onChange={propertyChangeHandler}
                      helperText={propertyError}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CustomerAddressMap
                    loadingElement={<Box height={1} />}
                    containerElement={<Box height={{ xs: 125, sm: 250 }} />}
                    mapElement={<Box height={1} />}
                    center={mapCenter}
                    markers={mapMarkers}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="text"
                    ref={autoCompleteRef}
                    onChange={event => setMapAddress(event.target.value)}
                    placeholder="Search location on map"
                    value={mapAddress}
                    className={classes.mapAddressInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    type="text"
                    name="name"
                    variant="outlined"
                    label="Name"
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                      setNameError('');
                    }}
                    onBlur={nameBlurHandler}
                    helperText={nameError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    type="text"
                    name="address"
                    variant="outlined"
                    label="Address"
                    value={addressOne}
                    onChange={e => {
                      setAddressOne(e.target.value);
                      setAddressOneError('');
                    }}
                    onBlur={addressOneBlurHandler}
                    helperText={addressOneError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    type="text"
                    name="address_two"
                    variant="outlined"
                    label="Apt/Suite/Floor"
                    value={addressTwo}
                    onChange={e => {
                      setAddressTwo(e.target.value);
                      setAddressTwoError('');
                    }}
                    onBlur={addressTwoBlurHandler}
                    helperText={addressTwoError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    fullWidth
                    type="text"
                    name="city"
                    variant="outlined"
                    label="City"
                    value={city}
                    onChange={e => {
                      setCity(e.target.value);
                      setCityError('');
                    }}
                    onBlur={cityBlurHandler}
                    helperText={cityError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppSelectBox
                    fullWidth
                    name="state"
                    data={usaStates}
                    label="State"
                    valueKey={USA_STATE_KEYS.ID}
                    variant="outlined"
                    labelKey={USA_STATE_KEYS.NAME}
                    value={state}
                    onChange={stateChangeHandler}
                    helperText={stateError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    fullWidth
                    type="text"
                    name="zipcode"
                    variant="outlined"
                    label="Zip"
                    value={zipcode}
                    onChange={e => {
                      setZipcode(e.target.value);
                      setZipcodeError('');
                    }}
                    onBlur={zipcodeBlurHandler}
                    helperText={zipcodeError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppSelectBox
                    fullWidth
                    name="country"
                    data={countries}
                    label="Country"
                    valueKey={COUNTRY_KEYS.ID}
                    variant="outlined"
                    labelKey={COUNTRY_KEYS.NAME}
                    value={country}
                    onChange={countryChangeHandler}
                    helperText={countryError}
                  />
                </Grid>
              </GridContainer>
            </form>
          )}
          {!customerIsValid && (
            <GridContainer>
              <Grid item xs={12}>
                <Alert severity="error">Invalid customer</Alert>
              </Grid>
            </GridContainer>
          )}
          {customerIsValid && !propertyIsValid && (
            <GridContainer>
              <Grid item xs={12}>
                <Alert severity="error">Address not found</Alert>
              </Grid>
            </GridContainer>
          )}
          <DialogActions className={classes.dialogActions}>
            <Button type="button" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            {customerIsValid && propertyIsValid && (
              <Box>
                <Button type="button" variant="contained" color="primary" onClick={handleFormSubmit}>
                  Save
                </Button>
                {tab && [CUSTOMER_PROPERTIES_TAB].includes(tab) && property && property !== 'new' && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.deleteBtn}
                    onClick={handlePropertyDelete}>
                    Delete
                  </Button>
                )}
              </Box>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Delete Property ${currentCustomerProperty[PROPERTY_KEYS.NAME]}?`}
          content={'This action cannot be undone.'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </React.Fragment>
  );
};

EditAddressDialog.prototype = {
  tab: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addressType: PropTypes.string,
};

export default EditAddressDialog;

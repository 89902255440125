import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
/* import { TwitterPicker } from 'react-color'; */

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import {
  FIELD_TECH_OPTIONS,
  TRACK_LOCATION_OPTIONS,
  SQUAD_USER_KEYS,
  ACTIVE_SQUAD_USER,
  INACTIVE_SQUAD_USER,
  INVITED_SQUAD_USER,
  UNINVITED_SQUAD_USER,
  ROLE_KEYS,
  ACTIVE_JOB_TYPE,
  JOB_TYPE_KEYS,
  USER_TYPE_KEYS,
  /* COLOR_KEYS, */
} from '../../../@jumbo/constants/AppDataConstants';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import {
  emailNotValid,
  requiredMessage,
  phoneNotValid,
  statusNotValid,
  userNotValid,
} from '../../../@jumbo/constants/ErrorMessages';

import { isValidPhoneNumber } from '../../../utils/FormValidation';

import { getRoles } from '../../../redux/actions/Roles';
import { getJobTypes } from '../../../redux/actions/JobTypes';
import { getUserTypes } from '../../../redux/actions/UserTypes';
/* import { getColors } from '../../../redux/actions/Colors'; */
import {
  deleteSquadUser,
  getSquadUser,
  setCurrentSquadUser,
  updateSquadUser,
  updateSquadUserStatus,
  userResendInvite,
} from '../../../redux/actions/Squad';

import ResetPasswordDialog from '../ResetPasswordDialog';
import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

import useStyles from './index.style';

const pageName = 'Edit User';
const breadcrumbs = [
  { label: 'Crew Members', link: '/squad' },
  { label: pageName, isActive: true },
];

const EditUserForm = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = params.userId;

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentSquadUser: user } = useSelector(({ squadReducer }) => squadReducer);
  const { roles } = useSelector(({ rolesReducer }) => rolesReducer);
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);
  const { userTypes } = useSelector(({ userTypesReducer }) => userTypesReducer);
  /* const { colors } = useSelector(({ colorsReducer }) => colorsReducer); */

  const [userFetched, setUserFetched] = useState(false);
  const [userIdError, setUserIdError] = useState('');
  const [userType, setUserType] = useState('');
  const [userTypeError, setUserTypeError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [fieldTech, setFieldTech] = useState('');
  const [fieldTechError, setFieldTechError] = useState('');
  const [trackLocation, setTrackLocation] = useState('');
  const [trackLocationError, setTrackLocationError] = useState('');
  const [userSkills, setUserSkills] = useState([]);
  const [userSkillsForDropdown, setUserSkillsForDropdown] = useState([]);
  const [userSkillsForDropdownError, setUserSkillsForDropdownError] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [homeAddressError, setHomeAddressError] = useState('');
  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState('');
  /* const [color, setColor] = useState('');
  const [pickerColor, setPickerColor] = useState('');
  const [colorError, setColorError] = useState(''); */
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const [inviteStatusError, setInviteStatusError] = useState('');
  const [activeJobTypes, setActiveJobTypes] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  /* const colorsList = colors.filter(color => color && color[COLOR_KEYS.NAME]).map(color => color[COLOR_KEYS.NAME]); */

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      dispatch(setCurrentSquadUser(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getUserTypes(payload)).then(() => {
          return dispatch(getRoles(payload)).then(() => {
            return dispatch(getJobTypes(payload)).then(() => {
              /* return dispatch(getColors(payload)).then(() => { */
              return dispatch(
                getSquadUser(payload, () => {
                  if (isActive) setUserFetched(true);
                }),
              );
              /* }); */
            });
          });
        });
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode, userId: userId }));
    promise.catch(error => {
      /* Setting to 'true' means API has been executed, not necessarily successfully */
      if (isActive) setUserFetched(true);
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, userId]);

  useEffect(() => {
    if (jobTypes) {
      const tempActiveJobTypes = jobTypes.filter(jobType => jobType[JOB_TYPE_KEYS.STATUS] === ACTIVE_JOB_TYPE);
      setActiveJobTypes(tempActiveJobTypes);
    }
  }, [jobTypes]);

  useEffect(() => {
    if (userFetched && user) {
      setUserType(user[SQUAD_USER_KEYS.TYPE]);
      setName(user[SQUAD_USER_KEYS.NAME]);
      setEmail(user[SQUAD_USER_KEYS.EMAIL]);
      setPhone(user[SQUAD_USER_KEYS.PHONE]);
      setRole(user[SQUAD_USER_KEYS.ROLE]);
      setFieldTech(user[SQUAD_USER_KEYS.FIELD_TECH]);
      setTrackLocation(user[SQUAD_USER_KEYS.TRACK_LOCATION]);
      setUserSkills(user[SQUAD_USER_KEYS.SKILLS]);
      setHomeAddress(user[SQUAD_USER_KEYS.HOME_ADDRESS]);
      setNotes(user[SQUAD_USER_KEYS.NOTES]);
      setStatus(user[SQUAD_USER_KEYS.STATUS]);
      setInviteStatus(user[SQUAD_USER_KEYS.INVITE_STATUS]);
      /* setColor(user[SQUAD_USER_KEYS.COLOR] ? user[SQUAD_USER_KEYS.COLOR] : ''); */

      const selectedSkills = user[SQUAD_USER_KEYS.SKILLS] ? user[SQUAD_USER_KEYS.SKILLS].split(',') : [];
      if (selectedSkills.length > 0) {
        setUserSkillsForDropdown(
          activeJobTypes.filter(activeJobType => selectedSkills.includes(activeJobType[JOB_TYPE_KEYS.ID])),
        );
      }

      /* const selectedColor = colors.find(color => color[COLOR_KEYS.ID] === user[SQUAD_USER_KEYS.COLOR]);
      setPickerColor(selectedColor ? selectedColor[COLOR_KEYS.NAME] : null); */
    }
  }, [userFetched, user, activeJobTypes /* , colors */]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case SQUAD_USER_KEYS.ID:
        case SQUAD_USER_KEYS.USER_ID:
          setUserIdError(msg);
          break;

        case SQUAD_USER_KEYS.NAME:
          setNameError(msg);
          break;

        case SQUAD_USER_KEYS.EMAIL:
          setEmailError(msg);
          break;

        case SQUAD_USER_KEYS.PHONE:
          setPhoneError(msg);
          break;

        case SQUAD_USER_KEYS.ROLE:
          setRoleError(msg);
          break;

        case SQUAD_USER_KEYS.FIELD_TECH:
          setFieldTechError(msg);
          break;

        case SQUAD_USER_KEYS.TRACK_LOCATION:
          setTrackLocationError(msg);
          break;

        /* case SQUAD_USER_KEYS.COLOR:
          setColorError(msg);
          break; */

        case SQUAD_USER_KEYS.HOME_ADDRESS:
          setHomeAddressError(msg);
          break;

        case SQUAD_USER_KEYS.NOTES:
          setNotesError(msg);
          break;

        case SQUAD_USER_KEYS.SKILLS:
          setUserSkillsForDropdownError(msg);
          break;

        case SQUAD_USER_KEYS.STATUS:
          setStatusError(msg);
          break;

        case SQUAD_USER_KEYS.INVITE_STATUS:
          setInviteStatusError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const handleOpenResetPasswordDialog = () => setOpenResetPasswordDialog(true);
  const handleCloseResetPasswordDialog = () => setOpenResetPasswordDialog(false);

  const userTypeChangeHandler = event => {
    setUserType(event.target.value);
    setUserTypeError('');

    if (!event.target.value) {
      setUserTypeError(requiredMessage);
    }
  };

  const emailBlurHandler = event => {
    if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  };

  const nameBlurHandler = event => {
    if (!event.target.value) {
      setNameError(requiredMessage);
    }
  };

  const phoneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneError(phoneNotValid);
    }
  };

  /* const handleColorChange = (selectedColor, event) => {
    setColorError('');

    if (selectedColor) {
      const selectedColorData = colors.find(colorData => colorData[COLOR_KEYS.NAME] === selectedColor.hex);
      if (selectedColorData) {
        setColor(selectedColorData[COLOR_KEYS.ID]);
        setPickerColor(selectedColor);
      } else {
        setColor('');
        setColorError(requiredMessage);
        setPickerColor(null);
      }
    } else {
      setColor('');
      setColorError(requiredMessage);
      setPickerColor(null);
    }
  }; */

  const roleChangeHandler = event => {
    setRole(event.target.value);
    setRoleError('');

    if (!event.target.value) {
      setRoleError(requiredMessage);
    }
  };

  const fieldTechChangeHandler = event => {
    setFieldTech(event.target.value);
    setFieldTechError('');

    if (!event.target.value) {
      setFieldTechError(requiredMessage);
    }
  };

  const trackLocationChangeHandler = event => {
    setTrackLocation(event.target.value);
    setTrackLocationError('');

    if (!event.target.value) {
      setTrackLocationError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formValid = user[SQUAD_USER_KEYS.ID] === userId;

    if (!userTypes.map(tempUserType => tempUserType[USER_TYPE_KEYS.ID]).includes(userType)) {
      formValid = false;
      setUserTypeError(requiredMessage);
    }

    if (!isValidEmail(email)) {
      formValid = false;
      setEmailError(emailNotValid);
    }

    if (!name) {
      formValid = false;
      setNameError(requiredMessage);
    }

    /* if (!color) {
      formValid = false;
      setColorError(requiredMessage);
    } */

    if (!isValidPhoneNumber(phone)) {
      formValid = false;
      setPhoneError(phoneNotValid);
    }

    if (!role) {
      formValid = false;
      setRoleError(requiredMessage);
    }

    if (!fieldTech) {
      formValid = false;
      setFieldTechError(requiredMessage);
    }

    if (formValid) {
      const userData = {
        authcode: authUser.authcode,
        [SQUAD_USER_KEYS.USER_ID]: userId,
        [SQUAD_USER_KEYS.NAME]: name,
        [SQUAD_USER_KEYS.EMAIL]: email,
        [SQUAD_USER_KEYS.PHONE]: phone,
        [SQUAD_USER_KEYS.TYPE]: userType,
        [SQUAD_USER_KEYS.ROLE]: role,
        [SQUAD_USER_KEYS.FIELD_TECH]: fieldTech,
        [SQUAD_USER_KEYS.TRACK_LOCATION]: trackLocation,
        [SQUAD_USER_KEYS.HOME_ADDRESS]: homeAddress,
        [SQUAD_USER_KEYS.NOTES]: notes,
        [SQUAD_USER_KEYS.COLOR]: /* color */ '',
        [SQUAD_USER_KEYS.SKILLS]: userSkills,
      };

      dispatch(
        updateSquadUser({ userData: userData }, null, messages => {
          if (_isMounted.current) setErrorMessages(messages);
        }),
      );
    }
  };

  const handleStatusUpdate = newStatus => {
    setStatusError('');

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    if (user[SQUAD_USER_KEYS.ID] !== userId) {
      setUserIdError(userNotValid);
      return false;
    }

    if (![ACTIVE_SQUAD_USER, INACTIVE_SQUAD_USER].includes(newStatus)) {
      setStatusError(statusNotValid);
      return false;
    }

    const userData = {
      authcode: authUser.authcode,
      [SQUAD_USER_KEYS.USER_ID]: userId,
      [SQUAD_USER_KEYS.STATUS]: newStatus,
    };

    dispatch(
      updateSquadUserStatus(
        { userData: userData },
        () => {
          if (_isMounted.current) setStatus(newStatus);
        },
        messages => {
          if (_isMounted.current) setErrorMessages(messages);
        },
      ),
    );
  };

  const handleResendInvitation = () => {
    setUserIdError('');

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    if (user[SQUAD_USER_KEYS.ID] !== userId) {
      setUserIdError(userNotValid);
      return false;
    }

    const userData = {
      authcode: authUser.authcode,
      [SQUAD_USER_KEYS.USER_ID]: userId,
    };

    dispatch(
      userResendInvite({ userData: userData }, null, messages => {
        if (_isMounted.current) setErrorMessages(messages);
      }),
    );
  };

  const handlePasswordReset = () => {
    handleOpenResetPasswordDialog();
  };

  const handleUserDelete = () => {
    if (!userId || !user || user[SQUAD_USER_KEYS.ID] !== userId) {
      setUserIdError(userNotValid);
      return false;
    }

    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);

    const userData = {
      authcode: authUser.authcode,
      [SQUAD_USER_KEYS.USER_ID]: userId,
    };

    dispatch(
      deleteSquadUser(
        { userData: userData },
        () => {
          if (_isMounted.current) history.push('/squad');
        },
        null,
      ),
    );
  };

  const handleCancelDelete = () => setOpenConfirmDialog(false);

  const fieldTechValidKeys = FIELD_TECH_OPTIONS.map(option => option.slug);
  const trackLocationValidKeys = TRACK_LOCATION_OPTIONS.map(option => option.slug);

  const isNotLoggedInUser = userId && userId !== authUser[SQUAD_USER_KEYS.ID];

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12} md={6}>
          <Link to="/squad" style={{ display: 'flex', alignItems: 'center' }}>
            <KeyboardBackspaceIcon />
            All Users
          </Link>
        </Grid>
        {userFetched && user && user[SQUAD_USER_KEYS.ID] === userId && (
          <Grid item xs={12} md={6} className={classes.btnContainer}>
            {inviteStatus === INVITED_SQUAD_USER && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.inviteBtn}
                onClick={handlePasswordReset}>
                Reset Password
              </Button>
            )}
            {isNotLoggedInUser && inviteStatus === UNINVITED_SQUAD_USER && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.inviteBtn}
                onClick={handleResendInvitation}>
                Resend Invitation
              </Button>
            )}
            {isNotLoggedInUser && status === ACTIVE_SQUAD_USER && (
              <Button type="button" variant="contained" onClick={() => handleStatusUpdate(INACTIVE_SQUAD_USER)}>
                Disable User
              </Button>
            )}
            {isNotLoggedInUser && status === INACTIVE_SQUAD_USER && (
              <Button type="button" variant="contained" onClick={() => handleStatusUpdate(ACTIVE_SQUAD_USER)}>
                Enable User
              </Button>
            )}
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              classes={{ containedPrimary: classes.btnDeleteUser }}
              onClick={handleUserDelete}>
              Delete User
            </Button>
            {!!statusError && (
              <Typography variant="caption" color="error" display="block">
                {statusError}
              </Typography>
            )}
            {!!inviteStatusError && (
              <Typography variant="caption" color="error" display="block">
                {inviteStatusError}
              </Typography>
            )}
          </Grid>
        )}
        {userFetched && user && user[SQUAD_USER_KEYS.ID] === userId && (
          <Grid item xs={12}>
            <form onSubmit={handleFormSubmit}>
              <GridContainer>
                <Grid item xs={12} md={6}>
                  <Card classes={{ root: classes.card }}>
                    <CardHeader
                      title="User Details"
                      classes={{
                        title: classes.cardHeaderTitle,
                      }}
                    />
                    <CardContent>
                      <GridContainer>
                        {userIdError && (
                          <Grid item xs={12}>
                            <Typography variant="caption" display="block" color="error" gutterBottom>
                              {userIdError}
                            </Typography>
                          </Grid>
                        )}
                        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                          <Grid item xs={12}>
                            {miscellaneousErrors.map((miscellaneousError, idx) => (
                              <Typography
                                variant="caption"
                                display="block"
                                color="error"
                                gutterBottom
                                key={`misc-error-${idx}`}>
                                {miscellaneousError}
                              </Typography>
                            ))}
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="user_type"
                            data={userTypes}
                            label="User Type"
                            valueKey={USER_TYPE_KEYS.ID}
                            variant="outlined"
                            labelKey={USER_TYPE_KEYS.NAME}
                            value={userType}
                            onChange={userTypeChangeHandler}
                            helperText={userTypeError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="name"
                            variant="outlined"
                            label="Name"
                            value={name}
                            onChange={e => {
                              setName(e.target.value);
                              setNameError('');
                            }}
                            onBlur={nameBlurHandler}
                            helperText={nameError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="email"
                            variant="outlined"
                            label="Email Address"
                            value={email}
                            onChange={e => {
                              setEmail(e.target.value);
                              setEmailError('');
                            }}
                            onBlur={emailBlurHandler}
                            helperText={emailError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="phone"
                            variant="outlined"
                            label="Phone Number"
                            value={phone}
                            onChange={e => {
                              setPhone(e.target.value);
                              setPhoneError('');
                            }}
                            onBlur={phoneBlurHandler}
                            helperText={phoneError}
                            InputProps={{
                              inputComponent: PhoneNumberFormat,
                              endAdornment: (
                                <InputAdornment component="a" href={`tel:${phone}`} position="end">
                                  <IconButton aria-label="call">
                                    <PhoneEnabledIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <Typography variant="caption" display="block" gutterBottom className={classes.boldCaption}>
                            Color Tag
                          </Typography>
                          {colorsList.length > 0 && (
                            <TwitterPicker
                              color={pickerColor ? pickerColor : ''}
                              colors={colorsList}
                              triangle="hide"
                              width="100"
                              onChangeComplete={handleColorChange}
                            />
                          )}
                          {colorError && (
                            <Typography variant="caption" display="block" color="error" gutterBottom>
                              {colorError}
                            </Typography>
                          )}
                        </Grid> */}
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="role"
                            data={roles}
                            label="User Role"
                            valueKey={ROLE_KEYS.ID}
                            variant="outlined"
                            labelKey={ROLE_KEYS.NAME}
                            value={role}
                            onChange={roleChangeHandler}
                            helperText={roleError}
                          />
                          <Typography variant="caption" display="block">
                            Roles set this team member restriction level.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Link to="/roles">Manage Roles</Link>
                        </Grid>
                      </GridContainer>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card classes={{ root: classes.card }}>
                    <CardHeader
                      title="User Options"
                      classes={{
                        title: classes.cardHeaderTitle,
                      }}
                    />
                    <CardContent>
                      <GridContainer>
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="field_tech"
                            data={FIELD_TECH_OPTIONS}
                            label="Field Tech"
                            valueKey="slug"
                            variant="outlined"
                            labelKey="title"
                            value={fieldTechValidKeys.includes(fieldTech) ? fieldTech : ''}
                            onChange={fieldTechChangeHandler}
                            helperText={fieldTechError}
                          />
                          <Typography variant="caption" display="block">
                            Can this person be assigned to jobs?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="track_locatiom"
                            data={TRACK_LOCATION_OPTIONS}
                            label="Track Location"
                            valueKey="slug"
                            variant="outlined"
                            labelKey="title"
                            value={trackLocationValidKeys.includes(trackLocation) ? trackLocation : ''}
                            onChange={trackLocationChangeHandler}
                            helperText={trackLocationError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h3">User Skills</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            id="user_skills"
                            value={userSkillsForDropdown}
                            options={activeJobTypes}
                            getOptionLabel={option => option[JOB_TYPE_KEYS.NAME]}
                            renderInput={params => (
                              <TextField
                                {...params}
                                name="user_skills"
                                variant="outlined"
                                margin="dense"
                                error={!!userSkillsForDropdownError}
                                helperText={!!userSkillsForDropdownError && userSkillsForDropdownError}
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip label={option[JOB_TYPE_KEYS.NAME]} {...getTagProps({ index })} />
                              ))
                            }
                            onChange={(event, values, reason) => {
                              /* setUserSkillsForDropdownError(''); */
                              if (['select-option', 'remove-option'].includes(reason)) {
                                if (values && values.length > 0) {
                                  const skillsIds = values.map(value => value[JOB_TYPE_KEYS.ID]);
                                  setUserSkills(skillsIds && skillsIds.length > 0 ? skillsIds.join(',') : '');
                                  setUserSkillsForDropdown(values);
                                } else {
                                  setUserSkills('');
                                  setUserSkillsForDropdown([]);
                                  /* setUserSkillsForDropdownError(requiredMessage); */
                                }
                              } else if (reason === 'clear') {
                                setUserSkills('');
                                setUserSkillsForDropdown([]);
                                /* setUserSkillsForDropdownError(requiredMessage); */
                              }
                            }}
                            getOptionSelected={(option, value) => option[JOB_TYPE_KEYS.ID] === value[JOB_TYPE_KEYS.ID]}
                          />
                          <Typography variant="caption" display="block">
                            Only show this user when these job types are selected.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="home_address"
                            variant="outlined"
                            label="Home Address"
                            value={homeAddress === null || homeAddress === '' ? '' : homeAddress}
                            onChange={e => {
                              setHomeAddress(e.target.value);
                              setHomeAddressError('');
                            }}
                            helperText={homeAddressError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            multiline
                            minRows={4}
                            name="notes"
                            variant="outlined"
                            label="Notes"
                            value={notes === null || notes === '' ? '' : notes}
                            onChange={e => {
                              setNotes(e.target.value);
                              setNotesError('');
                            }}
                            helperText={notesError}
                          />
                        </Grid>
                      </GridContainer>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} className={classes.btnContainer}>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </Grid>
              </GridContainer>
            </form>
          </Grid>
        )}
        {userFetched && (!user || user[SQUAD_USER_KEYS.ID] !== userId) && (
          <Grid item xs={12}>
            <Alert severity="error">User not found</Alert>
          </Grid>
        )}
      </GridContainer>
      {userFetched && user && user[SQUAD_USER_KEYS.ID] === userId && openResetPasswordDialog && (
        <ResetPasswordDialog open={openResetPasswordDialog} onClose={handleCloseResetPasswordDialog} userId={userId} />
      )}
      {userFetched && user && user[SQUAD_USER_KEYS.ID] === userId && openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Delete User?`}
          content={'This action cannot be undone.'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </PageContainer>
  );
};

export default EditUserForm;

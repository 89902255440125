import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
/* import Link from '@material-ui/core/Link'; */
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { alpha, makeStyles } from '@material-ui/core/styles';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import { AppName, CurrentAuthMethod } from '../../../constants/AppConstants';
import { COLOR_LOGO_LINK, SQUAD_USER_KEYS } from '../../../constants/AppDataConstants';

import AuthWrapper from './AuthWrapper';
import ContentLoader from '../../ContentLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  document.title = `${AppName} - New Password`;

  const _isMounted = useRef(true);
  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const resetCode = params.resetCode;

  const [codeError, setCodeError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    setCodeError(!!resetCode ? '' : 'Code is not valid!');
  }, [resetCode]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case SQUAD_USER_KEYS.PASSWORD:
          setPasswordError(msg);
          break;

        case SQUAD_USER_KEYS.CONFIRM_PASSWORD_ALT:
          setConfirmPasswordError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const passwordBlurHandler = event => {
    if (!event.target.value) {
      setPasswordError(<IntlMessages id="appModule.passwordIsInvalid" />);
    } else if (confirmPassword !== event.target.value) {
      setConfirmPasswordError(<IntlMessages id="appModule.passwordsMismatch" />);
    } else if (confirmPassword === event.target.value) {
      setConfirmPasswordError('');
    }
  };

  const confirmPasswordBlurHandler = event => {
    if (!event.target.value) {
      setConfirmPasswordError(<IntlMessages id="appModule.passwordIsInvalid" />);
    } else if (event.target.value !== password) {
      setConfirmPasswordError(<IntlMessages id="appModule.passwordsMismatch" />);
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!resetCode) {
      formIsValid = false;
      setCodeError('Code is not valid!');
    }

    if (!password) {
      formIsValid = false;
      setPasswordError(<IntlMessages id="appModule.passwordIsInvalid" />);
    }

    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPasswordError(<IntlMessages id="appModule.passwordIsInvalid" />);
    }

    if (password && confirmPassword && password !== confirmPassword) {
      formIsValid = false;
      setConfirmPasswordError(<IntlMessages id="appModule.passwordsMismatch" />);
    }

    if (formIsValid) {
      const authData = {
        [SQUAD_USER_KEYS.PASSWORD]: password,
        [SQUAD_USER_KEYS.CONFIRM_PASSWORD_ALT]: confirmPassword,
        [SQUAD_USER_KEYS.RESET_PASSWORD_CODE]: resetCode,
      };

      dispatch(
        AuhMethods[method].onUpdatePassword(
          { authData: authData },
          () => {
            if (_isMounted.current) history.push('/signin');
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'}>
          <CmtImage src={COLOR_LOGO_LINK} width={240} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="appModule.resetPassword" />
        </Typography>
        {!codeError && (
          <form onSubmit={handleFormSubmit}>
            {miscellaneousErrors && miscellaneousErrors.length > 0 && (
              <div className={'m-0'}>
                {miscellaneousErrors.map((miscellaneousError, idx) => (
                  <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                    {miscellaneousError}
                  </Typography>
                ))}
              </div>
            )}
            <div className={'mb-5'}>
              <TextField
                fullWidth
                type="password"
                value={password}
                label={<IntlMessages id="appModule.password" />}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                error={!!passwordError}
                helperText={passwordError}
                onChange={event => {
                  setPassword(event.target.value);
                  setPasswordError('');
                }}
                onBlur={passwordBlurHandler}
              />
            </div>
            <div className={'mb-5'}>
              <TextField
                fullWidth
                type="password"
                value={confirmPassword}
                label={<IntlMessages id="appModule.confirmPassword" />}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                onChange={event => {
                  setConfirmPassword(event.target.value);
                  setConfirmPasswordError('');
                }}
                onBlur={confirmPasswordBlurHandler}
              />
            </div>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              <Button type="submit" variant="contained" color="primary">
                <IntlMessages id="appModule.updatePassword" />
              </Button>
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/signin">
                  <IntlMessages id="appModule.signIn" />
                </NavLink>
              </Box>
            </Box>
          </form>
        )}
        {!!codeError && <Alert severity="error">Code is not valid!</Alert>}
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;

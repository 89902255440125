import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  /* ADD_ESTIMATE, */ /* DELETE_ESTIMATE, */
  /* EDIT_ESTIMATE, */ GET_ESTIMATES,
  SET_ESTIMATE_DETAILS,
  SET_ESTIMATE_CUSTOMER_DETAILS,
  /* GET_ESTIMATE_PRODUCTS,
  GET_ESTIMATE_PRODUCTS_TOTALS, */
  GET_JOB_JOB_TYPES,
  GET_JOB_JOB_TYPES_TOTALS,
  GET_ESTIMATE_COUNTS,
  GET_ESTIMATE_SIGNATURES,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_ESTIMATES_LINK,
  GET_ESTIMATE_LINK,
  /* ADD_ESTIMATE_LINK, UPDATE_ESTIMATE_LINK, */ UPDATE_ESTIMATE_STATUS_LINK,
  UPDATE_ESTIMATE_NOTE_LINK,
  DELETE_ESTIMATE_LINK,
  PREVIEW_ESTIMATE_LINK,
  SEND_ESTIMATE_LINK,
  DOWNLOAD_ESTIMATE_LINK,
  GET_ESTIMATE_FOR_CUSTOMER_LINK,
  PREVIEW_ESTIMATE_FOR_CUSTOMER_LINK,
  DOWNLOAD_ESTIMATE_FOR_CUSTOMER_LINK,
  UPDATE_ESTIMATE_STATUS_FOR_CUSTOMER_LINK,
} from '../../@jumbo/constants/APIConstants';
import { ESTIMATE_KEYS, EXPORT_LINK_KEY } from '../../@jumbo/constants/AppDataConstants';

export const getFilteredEstimates = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_ESTIMATES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimate) {
              const estimates = data.estimate;

              const counts = data.counts ? data.counts : null;
              let totals = null;
              if (counts) {
                totals = {
                  [ESTIMATE_KEYS.TOTAL]: counts[ESTIMATE_KEYS.TOTAL] ? counts[ESTIMATE_KEYS.TOTAL] : '0',
                };
              } else {
                totals = {
                  [ESTIMATE_KEYS.TOTAL]: '0',
                };
              }

              const exportLink = data[EXPORT_LINK_KEY] ? data[EXPORT_LINK_KEY] : null;

              dispatch(fetchSuccess());
              dispatch({ type: GET_ESTIMATES, payload: estimates });
              dispatch({ type: GET_ESTIMATE_COUNTS, payload: totals });
              if (callbackFun) callbackFun(estimates, exportLink);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: GET_ESTIMATES, payload: [] });
        dispatch({
          type: GET_ESTIMATE_COUNTS,
          payload: {
            [ESTIMATE_KEYS.TOTAL]: '0',
          },
        });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getEstimate = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (/* data.estimate */ data[ESTIMATE_KEYS.ID]) {
              /* const estimate = data.estimate; */
              const estimate = {
                [ESTIMATE_KEYS.ID]: data[ESTIMATE_KEYS.ID],
                [ESTIMATE_KEYS.JOB_ID]: data[ESTIMATE_KEYS.JOB_ID],
                [ESTIMATE_KEYS.CUSTOMER_ID]: data[ESTIMATE_KEYS.CUSTOMER_ID],
                [ESTIMATE_KEYS.DESCRIPTION]: data[ESTIMATE_KEYS.DESCRIPTION],
                [ESTIMATE_KEYS.NOTE]: data[ESTIMATE_KEYS.NOTE],
                [ESTIMATE_KEYS.STATUS]: data[ESTIMATE_KEYS.STATUS_ID],
                [ESTIMATE_KEYS.CREATED_AT]: data[ESTIMATE_KEYS.CREATED_AT],
              };

              let estimateCustomer = null;
              if (data.client) {
                const customer = data.client;
                const serviceAddress = customer[ESTIMATE_KEYS.CUSTOMER_SERVICE];
                const billAddress = customer[ESTIMATE_KEYS.CUSTOMER_BILL];

                estimateCustomer = {
                  [ESTIMATE_KEYS.CUSTOMER_BILL]: {
                    [ESTIMATE_KEYS.NAME]: customer[ESTIMATE_KEYS.NAME],
                    [ESTIMATE_KEYS.CUSTOMER_COMPANY]: customer[ESTIMATE_KEYS.CUSTOMER_COMPANY],
                    [ESTIMATE_KEYS.CUSTOMER_EMAIL]: customer[ESTIMATE_KEYS.CUSTOMER_EMAIL],
                    [ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]: customer[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]: billAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]: billAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [ESTIMATE_KEYS.CUSTOMER_CITY]: billAddress[ESTIMATE_KEYS.CUSTOMER_CITY],
                    [ESTIMATE_KEYS.CUSTOMER_STATE]: billAddress[ESTIMATE_KEYS.CUSTOMER_STATE],
                    [ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]: billAddress[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE],
                    [ESTIMATE_KEYS.CUSTOMER_COUNTRY]: billAddress[ESTIMATE_KEYS.CUSTOMER_COUNTRY],
                  },
                  [ESTIMATE_KEYS.CUSTOMER_SERVICE]: {
                    [ESTIMATE_KEYS.NAME]: serviceAddress[ESTIMATE_KEYS.NAME],
                    [ESTIMATE_KEYS.CUSTOMER_COMPANY]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_COMPANY],
                    [ESTIMATE_KEYS.CUSTOMER_EMAIL]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_EMAIL],
                    [ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [ESTIMATE_KEYS.CUSTOMER_CITY]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_CITY],
                    [ESTIMATE_KEYS.CUSTOMER_STATE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_STATE],
                    [ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE],
                    [ESTIMATE_KEYS.CUSTOMER_COUNTRY]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_COUNTRY],
                  },
                };
              }

              dispatch(fetchSuccess());
              dispatch({ type: SET_ESTIMATE_DETAILS, payload: estimate });
              dispatch({ type: SET_ESTIMATE_CUSTOMER_DETAILS, payload: estimateCustomer });
              if (data.jobitems) {
                /* dispatch({ type: GET_ESTIMATE_PRODUCTS, payload: data.jobitems }); */
                dispatch({ type: GET_JOB_JOB_TYPES, payload: data.jobitems });
              } else {
                /* dispatch({ type: GET_ESTIMATE_PRODUCTS, payload: [] }); */
                dispatch({ type: GET_JOB_JOB_TYPES, payload: [] });
              }
              if (data.total) {
                /* dispatch({ type: GET_ESTIMATE_PRODUCTS_TOTALS, payload: data.total }); */
                dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: data.total });
              } else {
                /* dispatch({ type: GET_ESTIMATE_PRODUCTS_TOTALS, payload: null }); */
                dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: null });
              }
              if (data.signature) {
                dispatch({ type: GET_ESTIMATE_SIGNATURES, payload: data.signature });
              } else {
                dispatch({ type: GET_ESTIMATE_SIGNATURES, payload: [] });
              }

              if (callbackFun) callbackFun(estimate);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: SET_ESTIMATE_DETAILS, payload: null });
        dispatch({ type: SET_ESTIMATE_CUSTOMER_DETAILS, payload: null });
        /* dispatch({ type: GET_ESTIMATE_PRODUCTS, payload: [] });
        dispatch({ type: GET_ESTIMATE_PRODUCTS_TOTALS, payload: null }); */
        dispatch({ type: GET_JOB_JOB_TYPES, payload: [] });
        dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: null });
        dispatch({ type: GET_ESTIMATE_SIGNATURES, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentEstimate = estimate => {
  return dispatch => {
    dispatch({ type: SET_ESTIMATE_DETAILS, payload: estimate });
  };
};

export const updateEstimateStatus = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_ESTIMATE_STATUS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimates && data.estimates.length > 0) {
              const estimateData = data.estimates[0];
              const estimate = {
                [ESTIMATE_KEYS.ID]: estimateData[ESTIMATE_KEYS.ID],
                [ESTIMATE_KEYS.JOB_ID]: estimateData[ESTIMATE_KEYS.JOB_ID],
                [ESTIMATE_KEYS.CUSTOMER_ID]: estimateData[ESTIMATE_KEYS.CUSTOMER_ID],
                [ESTIMATE_KEYS.DESCRIPTION]: estimateData[ESTIMATE_KEYS.DESCRIPTION],
                [ESTIMATE_KEYS.NOTE]: estimateData[ESTIMATE_KEYS.NOTE],
                [ESTIMATE_KEYS.STATUS]: estimateData[ESTIMATE_KEYS.STATUS],
              };
              dispatch(fetchSuccess('Estimate status was updated successfully.'));
              dispatch({ type: SET_ESTIMATE_DETAILS, payload: estimate });
              if (callbackFun) callbackFun(estimate);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateEstimateNote = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_ESTIMATE_NOTE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimates && data.estimates.length > 0) {
              const estimateData = data.estimates[0];
              const estimate = {
                [ESTIMATE_KEYS.ID]: estimateData[ESTIMATE_KEYS.ID],
                [ESTIMATE_KEYS.JOB_ID]: estimateData[ESTIMATE_KEYS.JOB_ID],
                [ESTIMATE_KEYS.CUSTOMER_ID]: estimateData[ESTIMATE_KEYS.CUSTOMER_ID],
                [ESTIMATE_KEYS.DESCRIPTION]: estimateData[ESTIMATE_KEYS.DESCRIPTION],
                [ESTIMATE_KEYS.NOTE]: estimateData[ESTIMATE_KEYS.NOTE],
                [ESTIMATE_KEYS.STATUS]: estimateData[ESTIMATE_KEYS.STATUS_ID],
              };
              dispatch(fetchSuccess('Note was updated successfully.'));
              dispatch({ type: SET_ESTIMATE_DETAILS, payload: estimate });
              if (callbackFun) callbackFun(estimate);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const previewEstimate = (request, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(PREVIEW_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const downloadEstimate = (request, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DOWNLOAD_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sendEstimate = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(SEND_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimates && data.estimates.length > 0) {
              dispatch(fetchSuccess('Estimate was sent successfully.'));
              if (callbackFun) callbackFun(data.estimates);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteEstimate = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected estimate was deleted successfully.'));
            /* dispatch({ type: DELETE_ESTIMATE, payload: request.estimateData[ESTIMATE_KEYS.ESTIMATE_ID] }); */
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getEstimateForCustomer = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_ESTIMATE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (/* data.estimate */ data[ESTIMATE_KEYS.ID]) {
              /* const estimate = data.estimate; */
              const estimate = {
                [ESTIMATE_KEYS.ID]: data[ESTIMATE_KEYS.ID],
                [ESTIMATE_KEYS.JOB_ID]: data[ESTIMATE_KEYS.JOB_ID],
                [ESTIMATE_KEYS.CUSTOMER_ID]: data[ESTIMATE_KEYS.CUSTOMER_ID],
                [ESTIMATE_KEYS.DESCRIPTION]: data[ESTIMATE_KEYS.DESCRIPTION],
                [ESTIMATE_KEYS.NOTE]: data[ESTIMATE_KEYS.NOTE],
                [ESTIMATE_KEYS.STATUS]: data[ESTIMATE_KEYS.STATUS_ID],
                [ESTIMATE_KEYS.CREATED_AT]: data[ESTIMATE_KEYS.CREATED_AT],
              };

              let estimateCustomer = null;
              if (data.client) {
                const customer = data.client;
                const serviceAddress = customer[ESTIMATE_KEYS.CUSTOMER_SERVICE];
                const billAddress = customer[ESTIMATE_KEYS.CUSTOMER_BILL];

                estimateCustomer = {
                  [ESTIMATE_KEYS.CUSTOMER_BILL]: {
                    [ESTIMATE_KEYS.NAME]: customer[ESTIMATE_KEYS.NAME],
                    [ESTIMATE_KEYS.CUSTOMER_COMPANY]: customer[ESTIMATE_KEYS.CUSTOMER_COMPANY],
                    [ESTIMATE_KEYS.CUSTOMER_EMAIL]: customer[ESTIMATE_KEYS.CUSTOMER_EMAIL],
                    [ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]: customer[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]: billAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]: billAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [ESTIMATE_KEYS.CUSTOMER_CITY]: billAddress[ESTIMATE_KEYS.CUSTOMER_CITY],
                    [ESTIMATE_KEYS.CUSTOMER_STATE]: billAddress[ESTIMATE_KEYS.CUSTOMER_STATE],
                    [ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]: billAddress[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE],
                    [ESTIMATE_KEYS.CUSTOMER_COUNTRY]: billAddress[ESTIMATE_KEYS.CUSTOMER_COUNTRY],
                  },
                  [ESTIMATE_KEYS.CUSTOMER_SERVICE]: {
                    [ESTIMATE_KEYS.NAME]: serviceAddress[ESTIMATE_KEYS.NAME],
                    [ESTIMATE_KEYS.CUSTOMER_COMPANY]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_COMPANY],
                    [ESTIMATE_KEYS.CUSTOMER_EMAIL]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_EMAIL],
                    [ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [ESTIMATE_KEYS.CUSTOMER_CITY]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_CITY],
                    [ESTIMATE_KEYS.CUSTOMER_STATE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_STATE],
                    [ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE],
                    [ESTIMATE_KEYS.CUSTOMER_COUNTRY]: serviceAddress[ESTIMATE_KEYS.CUSTOMER_COUNTRY],
                  },
                };
              }

              const estimateJobItems = data.jobitems ? data.jobitems : [];
              const estimateTotals = data.total ? data.total : null;
              const estimateSignatures = data.signature ? data.signature : [];

              const estimateData = {
                estimate: estimate,
                customer: estimateCustomer,
                products: estimateJobItems,
                totals: estimateTotals,
                signatures: estimateSignatures,
              };

              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(estimateData);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateEstimateStatusForCustomer = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_ESTIMATE_STATUS_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Estimate status was updated successfully.'));
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const previewEstimateForCustomer = (request, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(PREVIEW_ESTIMATE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const downloadEstimateForCustomer = (request, callbackFun) => {
  return dispatch => {
    const body = request.estimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DOWNLOAD_ESTIMATE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import { lighten, makeStyles } from '@material-ui/core/styles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { requiredMessage, percentageNotValid } from '../../../@jumbo/constants/ErrorMessages';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import {
  INVOICE_KEYS,
  INVOICE_PAYMENT_COMPLETE,
  JOB_JOB_TYPE_KEYS,
  JOB_JOB_TYPES_TOTALS_KEYS,
} from '../../../@jumbo/constants/AppDataConstants';

import { isValidPercentage } from '../../../utils/FormValidation';
import {
  getInvoice,
  setCurrentInvoice,
  previewInvoice,
  downloadInvoice,
  markInvoiceSent,
  deleteInvoice,
} from '../../../redux/actions/Invoices';
import {
  deleteJobJobType,
  setCurrentJobJobType,
  setJobJobTypes,
  setJobJobTypesTotals,
  updateJobJobTypesCalculations,
} from '../../../redux/actions/JobJobTypes';
import { getInvoiceStatusList } from '../../../redux/actions/InvoiceStatuses';
import { getJobTypes } from '../../../redux/actions/JobTypes';

import JobInvoiceHeader from '../JobInvoiceHeader';
import JobJobTypesTable from '../../Jobs/JobJobTypesTable';
import JobTypesDialog from '../../Jobs/JobTypesDialog';
import JobInvoiceNotes from '../JobInvoiceNotes';
/* import JobInvoicePayments from '../JobInvoicePayments'; */
import JobInvoiceSignature from '../JobInvoiceSignature';
/* import JobInvoiceAttachments from '../JobInvoiceAttachments'; */
import JobInvoicePreviewDialog from '../JobInvoicePreviewDialog';
import JobInvoiceSignatureDialog from '../JobInvoiceSignatureDialog';
import SendInvoiceDialog from '../SendInvoiceDialog';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  headerDivider: {
    marginTop: theme.spacing(2),
  },
  deleteAlert: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
}));

const pageName = 'Invoice';
const breadcrumbs = [
  { label: 'Invoices', link: '/invoices' },
  { label: pageName, isActive: true },
];

const JobInvoice = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const invoiceId = params.invoiceId;

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentInvoice } = useSelector(({ invoicesReducer }) => invoicesReducer);
  const { jobJobTypes, jobJobTypesTotals } = useSelector(({ jobJobTypesReducer }) => jobJobTypesReducer);

  const [dataFetching, setDataFetching] = useState(true);
  const [invoiceFetched, setInvoiceFetched] = useState(false);
  const [subTotal, setSubTotal] = useState('0.00');
  const [taxPercent, setTaxPercent] = useState('0.00');
  const [taxPercentError, setTaxPercentError] = useState('');
  const [tax, setTax] = useState('0.00');
  const [total, setTotal] = useState('0.00');
  const [calculations, setCalculations] = useState({
    [JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]: '0.00',
  });
  const [calculationsChanged, setCalculationsChanged] = useState(false);
  const [openJobTypesDialog, setOpenJobTypesDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openInvConfirmDialog, setInvOpenConfirmDialog] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [previewLink, setPreviewLink] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [openSendInvoiceDialog, setOpenSendInvoiceDialog] = useState(false);
  const [requestSignature, setRequestSignature] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [invErrorMessages, setInvErrorMessages] = useState([]);
  const [invMiscellaneousErrors, setInvMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      dispatch(setCurrentInvoice(null));
      dispatch(setJobJobTypes([]));
      dispatch(setJobJobTypesTotals(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;

    if (invoiceId && dataFetching && !invoiceFetched) {
      const fetchData = (payload, invoicePayload) => {
        return (dispatch, getState) => {
          return dispatch(getJobTypes(payload)).then(() => {
            return dispatch(getInvoiceStatusList(payload)).then(() => {
              return dispatch(getInvoice(invoicePayload));
            });
          });
        };
      };

      const payload = { authcode: authUser.authcode };
      const invoicePayload = { invoiceData: { ...payload, [INVOICE_KEYS.INVOICE_ID]: invoiceId } };

      const promise = dispatch(fetchData(payload, invoicePayload));
      promise
        .then(() => {
          if (isActive) {
            setInvoiceFetched(true);
            setDataFetching(false);
          }
        })
        .catch(error => {
          if (isActive) {
            setInvoiceFetched(false);
            setDataFetching(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, invoiceId, dataFetching, invoiceFetched]);

  useEffect(() => {
    if (jobJobTypesTotals) {
      setSubTotal(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.SUB_TOTAL]);
      setTaxPercent(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]);
      setTax(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TAX]);
      setTotal(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TOTAL]);
    }
  }, [jobJobTypesTotals]);

  useEffect(() => {
    if (calculationsChanged) {
      setMiscellaneousErrors([]);

      const jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_ID]: currentInvoice[INVOICE_KEYS.JOB_ID],
        [JOB_JOB_TYPE_KEYS.INVOICE_ID]: currentInvoice[INVOICE_KEYS.ID],
        ...calculations,
      };

      dispatch(
        updateJobJobTypesCalculations(
          { jobJobTypeData: jobJobTypeData },
          () => {
            if (_isMounted.current) setCalculationsChanged(false);
          },
          messages => {
            if (_isMounted.current) {
              setErrorMessages(messages);
              setCalculationsChanged(false);
            }
          },
        ),
      );
    }
  }, [dispatch, authUser.authcode, currentInvoice, calculations, calculationsChanged]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  useEffect(() => {
    for (const fieldName in invErrorMessages) {
      const msg = invErrorMessages[fieldName];
      switch (fieldName) {
        default:
          setInvMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [invErrorMessages]);

  const handlePreviewInvoice = () => {
    setPreviewLink('');

    const invoiceData = {
      authcode: authUser.authcode,
      [INVOICE_KEYS.INVOICE_ID]: currentInvoice[INVOICE_KEYS.ID],
    };

    dispatch(
      previewInvoice({ invoiceData: invoiceData }, link => {
        if (_isMounted.current) {
          setPreviewLink(link);
          setOpenPreviewDialog(true);
        }
      }),
    );
  };

  const handleClosePreviewDialog = () => setOpenPreviewDialog(false);

  const handleDeleteInvoice = event => setInvOpenConfirmDialog(true);

  const handleDownloadInvoice = () => {
    const invoiceData = {
      authcode: authUser.authcode,
      [INVOICE_KEYS.INVOICE_ID]: currentInvoice[INVOICE_KEYS.ID],
    };

    dispatch(
      downloadInvoice({ invoiceData: invoiceData }, link => {
        if (_isMounted.current) {
          SweetAlert({
            icon: 'success',
            title: 'Your download is ready!!',
            html: `Click <a href="${link}" target="_blank" download class="${classes.downloadLink}">here</a> to download.`,
          });
        }
      }),
    );
  };

  const handleCloseSignatureDialog = () => setOpenSignatureDialog(false);

  const handleSignatureClick = () => setOpenSignatureDialog(true);

  const handleRequestSignature = () => {
    setRequestSignature(true);
    setOpenSendInvoiceDialog(true);
  };

  const handleSignatureRequest = requested => setRequestSignature(requested);

  /* const handleAutoInvoicing = () => {
    console.log('handleAutoInvoicing');
  }; */

  const handleMarkSent = () => {
    const invoiceData = {
      authcode: authUser.authcode,
      [INVOICE_KEYS.INVOICE_ID]: currentInvoice[INVOICE_KEYS.ID],
    };

    dispatch(markInvoiceSent({ invoiceData: invoiceData }, null));
  };

  const handleSendInvoice = () => setOpenSendInvoiceDialog(true);

  const handleCloseSendInvoiceDialog = () => {
    setRequestSignature(false);
    setOpenSendInvoiceDialog(false);
  };

  const handleConfirmDeleteInvoice = () => {
    setInvOpenConfirmDialog(false);

    setInvErrorMessages([]);
    setInvMiscellaneousErrors([]);

    const invoiceData = {
      authcode: authUser.authcode,
      [INVOICE_KEYS.INVOICE_ID]: currentInvoice[INVOICE_KEYS.ID],
    };

    dispatch(
      deleteInvoice(
        { invoiceData: invoiceData },
        () => history.push('/invoices'),
        messages => {
          if (_isMounted.current) setInvErrorMessages(messages);
        },
      ),
    );
  };

  const handleCancelDeleteInvoice = () => setInvOpenConfirmDialog(false);

  const taxPercentBlurHandler = event => {
    if (!event.target.value) {
      setTaxPercentError(requiredMessage);
    } else if (!isValidPercentage(event.target.value)) {
      setTaxPercentError(percentageNotValid);
    } else {
      setCalculations(prevState => {
        return { ...prevState, [JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]: taxPercent };
      });
      setCalculationsChanged(true);
    }
  };

  const handleOpenJobTypesDialog = () => setOpenJobTypesDialog(true);

  const handleCloseJobTypesDialog = () => {
    setOpenJobTypesDialog(false);
    dispatch(setCurrentJobJobType(null));
  };

  const handleRowEdit = jobJobType => {
    dispatch(setCurrentJobJobType(jobJobType));
    setOpenJobTypesDialog(true);
  };

  const handleJobJobTypeDelete = jobJobType => {
    setSelectedJobType(jobJobType);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    if (selectedJobType && selectedJobType[JOB_JOB_TYPE_KEYS.ID]) {
      const jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_JOB_TYPE_ID]: selectedJobType[JOB_JOB_TYPE_KEYS.ID],
      };

      dispatch(
        deleteJobJobType(
          { jobJobTypeData: jobJobTypeData },
          () => {
            if (_isMounted.current) setSelectedJobType(null);
          },
          () => {
            if (_isMounted.current) setSelectedJobType(null);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        setSelectedJobType(null);
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid service',
        });
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelectedJobType(null);
  };

  const invoiceIsValid = invoiceId && currentInvoice && currentInvoice[INVOICE_KEYS.ID];

  return (
    <PageContainer heading={invoiceId ? `Invoice #${invoiceId}` : ''} breadcrumbs={breadcrumbs}>
      {!dataFetching && invoiceFetched && invoiceIsValid && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12}>
              <JobInvoiceHeader
                miscellaneousErrors={invMiscellaneousErrors}
                onPreviewInvoice={handlePreviewInvoice}
                onDownloadInvoice={handleDownloadInvoice}
                onSignatureClick={handleSignatureClick}
                onRequestSignature={handleRequestSignature}
                /* onAutoInvoicing={handleAutoInvoicing} */
                onMarkSent={handleMarkSent}
                onSendInvoice={handleSendInvoice}
                onDeleteInvoice={handleDeleteInvoice}
              />
            </Grid>
            {currentInvoice[INVOICE_KEYS.PAYMENT_COMPLETE] === INVOICE_PAYMENT_COMPLETE && (
              <Grid item xs={12}>
                <Alert severity="success">Payment is complete for this invoice.</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <GridContainer>
                  {jobJobTypes && jobJobTypesTotals && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <JobJobTypesTable
                          jobJobTypes={jobJobTypes}
                          onEdit={handleRowEdit}
                          onDelete={handleJobJobTypeDelete}
                        />
                      </Grid>
                      {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                        <Grid item xs={12}>
                          {miscellaneousErrors.map((miscellaneousError, idx) => (
                            <Typography
                              variant="caption"
                              display="block"
                              color="error"
                              gutterBottom
                              key={`misc-error-${idx}`}>
                              {miscellaneousError}
                            </Typography>
                          ))}
                        </Grid>
                      )}
                      <Grid item xs={12} md={8}>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={handleOpenJobTypesDialog}>
                          Type of Service
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <GridContainer>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="sub_total"
                              variant="outlined"
                              label="Sub Total"
                              value={subTotal}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="tax_percent"
                              variant="outlined"
                              label="Tax %"
                              value={taxPercent}
                              onChange={e => {
                                setTaxPercent(e.target.value);
                                setTaxPercentError('');
                              }}
                              onBlur={taxPercentBlurHandler}
                              helperText={taxPercentError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="tax"
                              variant="outlined"
                              label="Tax"
                              value={tax}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="total"
                              variant="outlined"
                              label="Total"
                              value={total}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </GridContainer>
                      </Grid>
                    </React.Fragment>
                  )}
                  {(!jobJobTypes || !jobJobTypesTotals) && (
                    <Grid item xs={12}>
                      <Alert severity="error">Service data not found!</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <JobInvoiceNotes />
                  </Grid>
                  {/* <Grid item xs={12} md={6}><JobInvoicePayments /></Grid> */}
                  <Grid item xs={12} md={6}>
                    <JobInvoiceSignature onSignatureClick={handleSignatureClick} />
                  </Grid>
                  {/* <Grid item xs={12} md={6}><JobInvoiceAttachments /></Grid> */}
                </GridContainer>
              </Paper>
            </Grid>
          </GridContainer>

          {openJobTypesDialog && (
            <JobTypesDialog
              currentJobId={currentInvoice[INVOICE_KEYS.JOB_ID]}
              open={openJobTypesDialog}
              onClose={handleCloseJobTypesDialog}
            />
          )}

          {selectedJobType && openConfirmDialog && (
            <ConfirmDialog
              open={openConfirmDialog}
              title="Delete Service?"
              content={'This action cannot be undone.'}
              onClose={handleCancelDelete}
              onConfirm={handleConfirmDelete}
            />
          )}

          {openInvConfirmDialog && (
            <ConfirmDialog
              open={openInvConfirmDialog}
              title="Delete Invoice?"
              content={'This action cannot be undone.'}
              onClose={handleCancelDeleteInvoice}
              onConfirm={handleConfirmDeleteInvoice}
            />
          )}

          {openPreviewDialog && (
            <JobInvoicePreviewDialog
              invoice={currentInvoice}
              link={previewLink}
              open={openPreviewDialog}
              onClose={handleClosePreviewDialog}
            />
          )}

          {openSignatureDialog && (
            <JobInvoiceSignatureDialog open={openSignatureDialog} onClose={handleCloseSignatureDialog} />
          )}

          {openSendInvoiceDialog && (
            <SendInvoiceDialog
              requestSignature={requestSignature}
              open={openSendInvoiceDialog}
              onRequestSignature={handleSignatureRequest}
              onClose={handleCloseSendInvoiceDialog}
            />
          )}
        </React.Fragment>
      )}

      {!dataFetching && (!invoiceFetched || !invoiceIsValid) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Invoice data not found!</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default JobInvoice;

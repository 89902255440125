import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import GridContainer from '../../@jumbo/components/GridContainer';
import ContentLoader from '../../@jumbo/components/ContentLoader';
import ConfirmDialog from '../../@jumbo/components/Common/ConfirmDialog';
import { AppName } from '../../@jumbo/constants/AppConstants';
import {
  JOB_KEYS,
  JOB_CANCEL_STATUS,
  /* JOB_DONE_STATUS, */
} from '../../@jumbo/constants/AppDataConstants';
import { jobNotValid } from '../../@jumbo/constants/ErrorMessages';

import { getJobForCustomer, cancelJobForCustomer } from '../../redux/actions/Jobs';

import JobForm from './JobForm';
import SweetAlert from '../UI/Alert';

import useStyles from './index.style';

const CustomerPortalJob = () => {
  document.title = `${AppName} - Customer Portal`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const jobId = params.jobId;
  const customerId = params.customerId;

  const [jobFetching, setJobFetching] = useState(true);
  const [jobFetched, setJobFetched] = useState(false);
  const [job, setJob] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    if (customerId && jobId && jobFetching && !jobFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getJobForCustomer(payload, jobData => {
              if (isActive && jobData) {
                setJob(jobData);
                setJobFetched(true);
                setJobFetching(false);
              }
            }),
          );
        };
      };

      const payload = { jobData: { [JOB_KEYS.JOB_ID]: jobId, [JOB_KEYS.CUSTOMER_ID]: customerId } };

      const promise = dispatch(fetchData(payload));
      promise.catch(error => {
        if (isActive) {
          setJobFetched(false);
          setJobFetching(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, customerId, jobId, jobFetching, jobFetched]);

  const handleCancelJob = () => setOpenConfirmDialog(true);

  const handleCancelCancellation = () => setOpenConfirmDialog(false);

  const handleConfirmCancellation = () => {
    setOpenConfirmDialog(false);

    if (job && job[JOB_KEYS.ID]) {
      const jobData = {
        /* [JOB_KEYS.CUSTOMER_ID]: job[JOB_KEYS.CUSTOMER_ID], */
        [JOB_KEYS.JOB_ID]: job[JOB_KEYS.ID],
        [JOB_KEYS.STATUS_ID]: JOB_CANCEL_STATUS,
      };

      dispatch(
        cancelJobForCustomer(
          { jobData: jobData },
          jobData => {
            if (_isMounted.current) setJob(jobData);
          },
          null,
        ),
      );
    } else {
      if (_isMounted.current) {
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: jobNotValid,
        });
      }
    }
  };

  const handleJobStatusUpdate = updatedJobData => setJob(updatedJobData);

  const jobIsValid = job && job[JOB_KEYS.ID];

  return (
    <Paper className={classes.paper}>
      {!jobFetching && jobFetched && jobIsValid && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12} md={6}>
              <Typography component="h1" variant="h1" className={classes.headingOne}>
                AAA Rousse Junk Removal
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.textAlignRight}>
              {job[JOB_KEYS.STATUS_ID] !== JOB_CANCEL_STATUS && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  startIcon={<CloseIcon />}
                  onClick={handleCancelJob}
                  classes={{ containedPrimary: classes.btnCancelEstimate }}>
                  Cancel Job
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                className={classes.headingTwo}
                color={job[JOB_KEYS.STATUS_ID] === JOB_CANCEL_STATUS ? 'error' : 'initial'}>
                {`Job # ${job[JOB_KEYS.ID]} (Status: ${job[JOB_KEYS.STATUS]})`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <JobForm job={job} onJobStatusUpdate={handleJobStatusUpdate} />
            </Grid>
          </GridContainer>

          {openConfirmDialog && (
            <ConfirmDialog
              open={openConfirmDialog}
              title={`Cancel Job?`}
              content={'This action cannot be undone.'}
              onClose={handleCancelCancellation}
              onConfirm={handleConfirmCancellation}
            />
          )}

          <ContentLoader />
        </React.Fragment>
      )}

      {!jobFetching && (!jobFetched || !jobIsValid) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Job data not found!</Alert>
          </Grid>
        </GridContainer>
      )}
    </Paper>
  );
};

export default CustomerPortalJob;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  /* ADD_CUSTOMER, */
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMERS_DUES,
  SET_CUSTOMER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_CUSTOMERS_LINK,
  GET_CUSTOMER_LINK,
  ADD_CUSTOMER_LINK,
  UPDATE_CUSTOMER_LINK,
  DELETE_CUSTOMER_LINK,
  CUSTOMERS_SEARCH_LINK,
  CUSTOMERS_EMAIL_SEARCH_LINK,
  GET_CUSTOMER_JOBS_LINK,
  GET_CUSTOMER_ESTIMATES_LINK,
  GET_CUSTOMER_INVOICES_LINK,
} from '../../@jumbo/constants/APIConstants';
import { EXPORT_LINK_KEY, CUSTOMER_KEYS, CUSTOMER_DUES_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getCustomers = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMERS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.clients) {
              const customers = data.clients;
              dispatch(fetchSuccess());
              dispatch({ type: GET_CUSTOMERS, payload: customers });
              if (callbackFun) callbackFun(customers);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getFilteredCustomers = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMERS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.clients) {
              const customers = data.clients;
              const dues = {
                [CUSTOMER_DUES_KEYS.TOTAL_CLIENTS]: data[CUSTOMER_DUES_KEYS.TOTAL_CLIENTS]
                  ? data[CUSTOMER_DUES_KEYS.TOTAL_CLIENTS]
                  : '0',
                [CUSTOMER_DUES_KEYS.DUE_CLIENTS]: data[CUSTOMER_DUES_KEYS.DUE_CLIENTS]
                  ? data[CUSTOMER_DUES_KEYS.DUE_CLIENTS]
                  : '0',
                [CUSTOMER_DUES_KEYS.DUE_CLIENTS_COUNT]: data[CUSTOMER_DUES_KEYS.DUE_CLIENTS_COUNT]
                  ? data[CUSTOMER_DUES_KEYS.DUE_CLIENTS_COUNT]
                  : '0',
                [CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS]: data[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS]
                  ? data[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS]
                  : '0',
                [CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS_COUNT]: data[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS_COUNT]
                  ? data[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS_COUNT]
                  : '0',
                [CUSTOMER_DUES_KEYS.ESTIMATE_PENDING]: data[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING]
                  ? data[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING]
                  : '0',
                [CUSTOMER_DUES_KEYS.ESTIMATE_PENDING_COUNT]: data[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING_COUNT]
                  ? data[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING_COUNT]
                  : '0',
              };
              const exportLink = data[EXPORT_LINK_KEY] ? data[EXPORT_LINK_KEY] : null;

              dispatch(fetchSuccess());
              dispatch({ type: GET_CUSTOMERS, payload: customers });
              dispatch({ type: GET_CUSTOMERS_DUES, payload: dues });
              if (callbackFun) callbackFun(customers, exportLink);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getCustomer = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.client) {
              const customer = data.client;
              dispatch(fetchSuccess());
              dispatch({ type: SET_CUSTOMER_DETAILS, payload: customer });
              if (callbackFun) callbackFun(customer);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch({ type: SET_CUSTOMER_DETAILS, payload: null });
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const addNewCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.client) {
              dispatch(fetchSuccess('Customer was added successfully.'));
              /* dispatch({ type: ADD_CUSTOMER, payload: data.client }); */
              if (callbackFun) callbackFun(data.client);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const setCurrentCustomer = customer => {
  return dispatch => {
    dispatch({ type: SET_CUSTOMER_DETAILS, payload: customer });
  };
};

export const updateCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.client) {
              dispatch(fetchSuccess('Selected customer was updated successfully.'));
              dispatch({ type: EDIT_CUSTOMER, payload: data.client });
              if (callbackFun) callbackFun(data.client);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected customer was deleted successfully.'));
            dispatch({ type: DELETE_CUSTOMER, payload: request.customerData[CUSTOMER_KEYS.CUSTOMER_ID] });
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const searchCustomers = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(CUSTOMERS_SEARCH_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.clients) {
              const customers = data.clients;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customers);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const searchCustomersEmails = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(CUSTOMERS_EMAIL_SEARCH_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.clients) {
              const customers = data.clients;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customers);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getCustomerJobs = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_JOBS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobs) {
              const customerJobs = data.jobs;
              const customerJobsCounts = data.counts;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customerJobs, customerJobsCounts);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getCustomerEstimates = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_ESTIMATES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimate) {
              const customerEstimates = data.estimate;
              const customerEstimatesCounts = data.counts ? data.counts : { total: '0' };
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customerEstimates, customerEstimatesCounts);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getCustomerInvoices = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_INVOICES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.invoices) {
              const customerInvoices = data.invoices;
              const customerInvoicesCounts = data.counts ? data.counts : { total: '0' };
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customerInvoices, customerInvoicesCounts);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { makeStyles } from '@material-ui/core/styles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppDatePicker from '../../../@jumbo/components/Common/formElements/AppDatePicker';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import {
  ACTIVE_JOB_TYPE,
  ACTIVE_SQUAD_USER,
  AD_SOURCES_KEYS,
  COUNTRY_KEYS,
  COUNTRY_USA,
  JOB_TYPE_KEYS,
  SQUAD_USER_KEYS,
  USA_STATE_KEYS,
  CUSTOMER_KEYS,
  PROPERTY_KEYS,
  JOB_KEYS,
} from '../../../@jumbo/constants/AppDataConstants';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import {
  addUnitsToDateTime,
  /* cloneDateTimeObj, */
  getDateinDesiredFormat,
  getDateObjinDesiredFormat,
  getMomentDateObj,
  getTimeList12HrFormat,
  isStartBeforeEnd,
  getCurrentDateinDesiredFormat,
} from '../../../@jumbo/utils/dateHelper';
import {
  customerNotValid,
  emailNotValid,
  requiredMessage,
  phoneNotValid,
  dateNotValid,
  stateNotValid,
  /* countryNotValid, */
  jobTypeNotValid,
  jobSourceNotValid,
  timeNotValid,
} from '../../../@jumbo/constants/ErrorMessages';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';

import { getUSAStates } from '../../../redux/actions/USAStates';
import { getCountries } from '../../../redux/actions/Countries';
import { getAdSources } from '../../../redux/actions/AdSources';
import { getJobTypes } from '../../../redux/actions/JobTypes';
import { getSquadUsers } from '../../../redux/actions/Squad';
import { searchCustomersEmails } from '../../../redux/actions/Customers';
import { getCustomerPropertiesForJob } from '../../../redux/actions/CustomerProperties';
import { addNewJob } from '../../../redux/actions/Jobs';

/* import useQuery from '../../../hooks/use-query'; */
import { isValidPhoneNumber } from '../../../utils/FormValidation';

import NewJobAddress from '../NewJobAddress';
import AddEditJobType from '../../Settings/JobTypes/AddEditJobType';
import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

/* import RecurringScheduleDrawer from '../RecurringScheduleDrawer'; */

const useStyles = makeStyles(theme => ({
  autocompleteFlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  listAutocompleteFlex: {
    flex: '1 1 90%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flex: '1 1 100%',
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  addNewBtn: {
    flex: '1 1 20%',
  },
  optionPrimaryTextBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  mdCenterAlign: {
    [theme.breakpoints.up('sm')]: { textAlign: 'center' },
  },
  mdRightAlign: {
    [theme.breakpoints.up('sm')]: { textAlign: 'right' },
  },
  mapAddressInput: {
    width: '100%',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.borderColor.dark}`,
    borderRadius: 2,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
  },
  numUsersClass: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const timeList = getTimeList12HrFormat();

const validateDate = (date, errorFunction, otherFunction) => {
  if (date) {
    if (!date.isValid()) {
      errorFunction(dateNotValid);
      if (otherFunction) otherFunction();
    }
  } else {
    errorFunction(requiredMessage);
    if (otherFunction) otherFunction();
  }
};

const isDateRangeValid = (startDate, startTime, endDate, endTime) => {
  const start = `${getDateinDesiredFormat(startDate, 'YYYY-MM-DD')} ${startTime}`;
  const end = `${getDateinDesiredFormat(endDate, 'YYYY-MM-DD')} ${endTime}`;
  if (isStartBeforeEnd(start, end)) {
    return true;
  } else {
    return false;
  }
};

let autoComplete = null;
async function handlePlaceSelect(updateAddress, updateAddressComponents) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateAddress(query);
  const components = addressObject.address_components;
  updateAddressComponents(components);
}

const pageName = 'New Job';

const breadcrumbs = [
  { label: 'Jobs', link: '/jobs' },
  { label: pageName, isActive: true },
];

const NewJob = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const autoCompleteRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  /* const query = useQuery(); */

  const { authUser } = useSelector(({ auth }) => auth);
  const { countries } = useSelector(({ countriesReducer }) => countriesReducer);
  const { usaStates } = useSelector(({ usaStatesReducer }) => usaStatesReducer);
  const { adSources } = useSelector(({ adSourcesReducer }) => adSourcesReducer);
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);
  const { squadUsers } = useSelector(({ squadReducer }) => squadReducer);

  /* const queryCustomerId = query.get('customer_id');
  const queryPropertyId = query.get('property_id'); */

  const [customer, setCustomer] = useState(null);
  const [customersList, setCustomersList] = useState([]);
  const [property, setProperty] = useState(null);
  const [propertiesList, setPropertiesList] = useState([]);
  const [customerId, setCustomerId] = useState('new');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneOneError, setPhoneOneError] = useState('');
  /* const [extensionOne, setExtensionOne] = useState('');
  const [extensionOneError, setExtensionOneError] = useState(''); */
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [propertyId, setPropertyId] = useState('new');
  const [addressOne, setAddressOne] = useState('');
  const [addressOneError, setAddressOneError] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [addressTwoError, setAddressTwoError] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  const [country, setCountry] = useState(null);
  const [countryError, setCountryError] = useState('');
  const [jobType, setJobType] = useState(null);
  const [jobTypeError, setJobTypeError] = useState('');
  const [jobSource, setJobSource] = useState(null);
  const [jobSourceError, setJobSourceError] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobDescriptionError, setJobDescriptionError] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [startTimeError, setStartTimeError] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState('');
  const [endTime, setEndTime] = useState(null);
  const [endTimeError, setEndTimeError] = useState('');
  const [dateTimeError, setDateTimeError] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersError, setTeamMembersError] = useState('');
  const [mapAddress, setMapAddress] = useState('');
  const [mapAddressComponents, setMapAddressComponents] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  /* const [openRecurringSchedule, setOpenRecurringSchedule] = useState(false); */
  const [dataFetching, setDataFetching] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  /* const [queryDataFetched, setQueryDataFetched] = useState(false); */
  const [openAddEditJobType, setOpenAddEditJobType] = useState(false);
  const [openPropertiesDialog, setOpenPropertiesDialog] = useState(false);
  /* const [openState, setOpenState] = useState(false);
  const [openCountry, setOpenCountry] = useState(false); */
  const [activeSquadUsers, setActiveSquadUsers] = useState([]);
  const [activeJobTypes, setActiveJobTypes] = useState([]);
  const [resetCustomer, setResetCustomer] = useState(false);
  const [resetProperty, setResetProperty] = useState(false);
  const [fetchProperties, setFetchProperties] = useState(false);

  let customerIsValid = false;
  if (customerId) {
    if (customer) {
      if (
        !isNaN(customerId) &&
        !isNaN(parseInt(customerId)) &&
        parseInt(customerId) > 0 &&
        customer[CUSTOMER_KEYS.ID] &&
        customerId === customer[CUSTOMER_KEYS.ID]
      ) {
        customerIsValid = true;
      }
    } else {
      customerIsValid = customerId === 'new';
    }
  }

  let propertyIsValid = false;
  if (customerIsValid && propertyId) {
    if (property) {
      if (
        !isNaN(propertyId) &&
        !isNaN(parseInt(propertyId)) &&
        parseInt(propertyId) > 0 &&
        property[PROPERTY_KEYS.ID] &&
        propertyId === property[PROPERTY_KEYS.ID]
      ) {
        propertyIsValid = true;
      }
    } else {
      propertyIsValid = propertyId === 'new';
    }
  }

  const customerIsNotNew = customerId && customerId !== 'new';
  const propertyIsNotNew = propertyId && propertyId !== 'new';

  const debouncedEmail = useDebounce(email ? email : '', 500);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      autoCompleteRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!dataFetching && dataFetched && customerIsValid && propertyIsValid && propertyId === 'new') {
      autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
      autoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon']);
      autoComplete.addListener('place_changed', () => handlePlaceSelect(setMapAddress, setMapAddressComponents));
    }
  }, [dataFetching, dataFetched, customerIsValid, propertyIsValid, propertyId]);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getAdSources(payload)).then(() => {
          return dispatch(getCountries(payload)).then(() => {
            return dispatch(getUSAStates(payload)).then(() => {
              return dispatch(getJobTypes(payload)).then(() => {
                return dispatch(getSquadUsers(payload));
              });
            });
          });
        });
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise
      .then(() => {
        if (isActive) {
          setDataFetching(false);
          setDataFetched(true);
        }
      })
      .catch(error => {
        if (isActive) {
          setDataFetching(false);
          setDataFetched(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    if (jobTypes) {
      const tempJobTypes = jobTypes.filter(jobTypeObj => jobTypeObj[JOB_TYPE_KEYS.STATUS] === ACTIVE_JOB_TYPE);
      setActiveJobTypes(tempJobTypes);
    }
  }, [jobTypes]);

  useEffect(() => {
    if (squadUsers) {
      const tempSquadUsers = squadUsers.filter(squadUserObj => squadUserObj[SQUAD_USER_KEYS.STATUS] === ACTIVE_SQUAD_USER);
      setActiveSquadUsers(tempSquadUsers);
    }
  }, [squadUsers]);

  /* useEffect(() => {
    if (!dataFetching && dataFetched && !queryDataFetched && queryCustomerId && queryPropertyId) {
      console.log('Get customer');
      setQueryDataFetched(true);
    }
  }, [dataFetching, dataFetched, queryDataFetched, authUser.authcode, queryCustomerId, queryPropertyId]); */

  useEffect(() => {
    if (resetCustomer && debouncedEmail === '') {
      setCustomer(null);
      setResetCustomer(false);
    }
  }, [resetCustomer, debouncedEmail]);

  useEffect(() => {
    if (customer) {
      return undefined;
    }

    if (debouncedEmail === '') {
      return undefined;
    }

    const customerData = {
      authcode: authUser.authcode,
      search: debouncedEmail,
    };

    dispatch(
      searchCustomersEmails({ customerData: customerData }, customers => {
        if (_isMounted.current) {
          let newList = [];

          if (customers) {
            newList = [...customers];
          }

          setCustomersList(newList);
        }
      }),
    ).catch(error => {
      setCustomersList([]);
    });
  }, [dispatch, authUser.authcode, customer, debouncedEmail]);

  useEffect(() => {
    if (customer) {
      setCustomerId(customer[CUSTOMER_KEYS.ID]);
      setFirstName(customer[CUSTOMER_KEYS.FIRST_NAME]);
      setFirstNameError('');
      setLastName(customer[CUSTOMER_KEYS.LAST_NAME]);
      setLastNameError('');
      setCompanyName(customer[CUSTOMER_KEYS.COMPANY_NAME]);
      setCompanyNameError('');
      setEmail(customer[CUSTOMER_KEYS.EMAIL]);
      setEmailError('');
      setPhoneOne(customer[CUSTOMER_KEYS.PHONE_ONE]);
      setPhoneOneError('');
      /* setExtensionOne(customer[CUSTOMER_KEYS.EXTENSION_ONE]);
      setExtensionOneError(''); */
      setFetchProperties(true);
    } else {
      setCustomerId('new');
      setFirstName('');
      setFirstNameError('');
      setLastName('');
      setLastNameError('');
      setCompanyName('');
      setCompanyNameError('');
      setEmailError('');
      setPhoneOne('');
      setPhoneOneError('');
      /* setExtensionOne('');
      setExtensionOneError(''); */
      setJobType(null);
      setJobTypeError('');
      setJobSource(null);
      setJobSourceError('');
      setJobDescription('');
      setJobDescriptionError('');
      setStartDate(null);
      setStartDateError('');
      setStartTime(null);
      setStartTimeError('');
      setEndDate(null);
      setEndDateError('');
      setEndTime(null);
      setEndTimeError('');
      setDateTimeError('');
      setTeamMembers([]);
      setTeamMembersError('');
      setErrorMessages([]);
      setMiscellaneousErrors([]);
    }
    setResetProperty(true);
  }, [customer]);

  useEffect(() => {
    let isActive = true;

    if (fetchProperties && customerId && customerId !== 'new') {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getCustomerPropertiesForJob(payload, customerProperties => {
              if (isActive) {
                setPropertiesList(customerProperties);
                setFetchProperties(false);
                setOpenPropertiesDialog(true);
              }
            }),
          );
        };
      };

      const payload = {
        customerData: {
          authcode: authUser.authcode,
          [PROPERTY_KEYS.CUSTOMER_ID]: customerId,
        },
      };

      const promise = dispatch(fetchData(payload));
      promise.catch(error => {
        if (isActive) {
          setPropertiesList([]);
          setFetchProperties(false);
          setOpenPropertiesDialog(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, customerId, fetchProperties]);

  useEffect(() => {
    if (resetProperty) {
      setProperty(null);
      setPropertyId('new');
      setPropertiesList([]);
      setMapAddress('');
      setMapAddressComponents(null);
      setAddressOne('');
      setAddressOneError('');
      setAddressTwo('');
      setAddressTwoError('');
      setCity('');
      setCityError('');
      setState(null);
      setStateError('');
      setZipcode('');
      setZipcodeError('');
      setCountry(null);
      setCountryError('');
      /* setOpenState(false);
      setOpenCountry(false); */
      setResetProperty(false);
    }
  }, [resetProperty]);

  useEffect(() => {
    if (property) {
      setPropertyId(property[PROPERTY_KEYS.ID]);
      setAddressOne(property[PROPERTY_KEYS.ADDRESS_ONE]);
      setAddressOneError('');
      setAddressTwo(property[PROPERTY_KEYS.ADDRESS_TWO] ? property[PROPERTY_KEYS.ADDRESS_TWO] : '');
      setAddressTwoError('');
      setCity(property[PROPERTY_KEYS.CITY]);
      setCityError('');
      setState(property[PROPERTY_KEYS.STATE]);
      setStateError('');
      setZipcode(property[PROPERTY_KEYS.ZIP_CODE]);
      setZipcodeError('');
      setCountry(property[PROPERTY_KEYS.COUNTRY]);
      setCountryError('');
    }
  }, [property]);

  useEffect(() => {
    if (mapAddressComponents) {
      let tempAddressOne = '';
      mapAddressComponents.forEach(function(mapAddressComponent) {
        const types = mapAddressComponent.types;
        const longName = mapAddressComponent.long_name;
        const shortName = mapAddressComponent.short_name;

        if (types.includes('street_number')) {
          tempAddressOne = `${longName} ${tempAddressOne}`;
          /* setAddressOne(longName);
          setAddressOneError(''); */
        } else if (types.includes('route')) {
          tempAddressOne = `${tempAddressOne} ${shortName}`;
          /* setAddressTwo(shortName);
          setAddressTwoError(''); */
        } else if (types.includes('locality') || types.includes('sublocality') || types.includes('sublocality_level_1')) {
          setCity(longName);
          setCityError('');
        } else if (types.includes('administrative_area_level_1')) {
          const mapState = usaStates.find(
            usaStateObj => usaStateObj[USA_STATE_KEYS.NAME] === longName || usaStateObj[USA_STATE_KEYS.CODE] === shortName,
          );
          if (mapState) {
            setState(mapState[USA_STATE_KEYS.ID]);
            setStateError('');
          } else {
            setState('');
          }
        } else if (types.includes('postal_code')) {
          setZipcode(longName);
          setZipcodeError('');
        } else if (types.includes('country')) {
          const mapCountry = countries.find(
            countryObj => countryObj[COUNTRY_KEYS.NAME] === longName || countryObj[COUNTRY_KEYS.CODE] === shortName,
          );
          if (mapCountry) {
            setCountry(mapCountry[COUNTRY_KEYS.ID]);
            setCountryError('');
          } else {
            setCountry('');
          }
        }
      });
      setAddressOne(tempAddressOne);
      setAddressOneError('');
    }
  }, [mapAddressComponents, countries, usaStates]);

  /* useEffect(() => {
    if (startDate && !startDateError) {
      if (!startTime || startTimeError) {
        setEndDate(startDate);
        setEndDateError('');
      } else {
        const startDateTime = `${getDateinDesiredFormat(startDate, 'YYYY-MM-DD')} ${startTime}`;
        const startDateTimeObj = getMomentDateObj(startDateTime);
        const endDateTimeObj = cloneDateTimeObj(startDateTimeObj);

        addUnitsToDateTime(endDateTimeObj, 2, 'hours');

        const endDate = getMomentDateObj(getDateObjinDesiredFormat(endDateTimeObj, 'YYYY-MM-DD'));
        const endTime = getDateObjinDesiredFormat(endDateTimeObj, 'HH:mm:ss');
        if (endDate && endTime) {
          setEndDate(endDate);
          setEndDateError('');
          setEndTime(endTime);
          setEndTimeError('');
        }
      }
    }
  }, [startDate, startDateError, startTime, startTimeError]); */

  useEffect(() => {
    if (startDate && !startDateError) {
      setEndDate(startDate);
      setEndDateError('');
    }
  }, [startDate, startDateError]);

  useEffect(() => {
    if (startTime && !startTimeError) {
      const currentDate = getCurrentDateinDesiredFormat('YYYY-MM-DD');
      const dateTime = `${currentDate} ${startTime}`;
      const dateTimeObj = getMomentDateObj(dateTime);

      addUnitsToDateTime(dateTimeObj, 2, 'hours');

      const endTime = getDateObjinDesiredFormat(dateTimeObj, 'HH:mm:ss');
      if (endTime) {
        setEndTime(endTime);
        setEndTimeError('');
      }
    }
  }, [startTime, startTimeError]);

  useEffect(() => {
    if (
      startDate &&
      !startDateError &&
      startTime &&
      !startTimeError &&
      endDate &&
      !endDateError &&
      endTime &&
      !endTimeError
    ) {
      if (!isDateRangeValid(startDate, startTime, endDate, endTime)) {
        setDateTimeError('Start time must be less than end time');
      } else if (dateTimeError) {
        setDateTimeError('');
      }
    } else if (dateTimeError) {
      setDateTimeError('');
    }
  }, [startDate, startDateError, startTime, startTimeError, endDate, endDateError, endTime, endTimeError, dateTimeError]);

  useEffect(() => {
    if (errorMessages) {
      setMiscellaneousErrors([]);
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case JOB_KEYS.CUSTOMER_FNAME:
            setFirstNameError(msg);
            break;

          case JOB_KEYS.CUSTOMER_LNAME:
            setLastNameError(msg);
            break;

          case JOB_KEYS.CUSTOMER_EMAIL:
            setEmailError(msg);
            break;

          case JOB_KEYS.CUSTOMER_PHONE_ONE:
            setPhoneOneError(msg);
            break;

          /* case JOB_KEYS.CUSTOMER_EXTENSION_ONE:
            setExtensionOneError(msg);
            break; */

          case JOB_KEYS.CUSTOMER_ADDRESS_ONE:
            setAddressOneError(msg);
            break;

          case JOB_KEYS.CUSTOMER_ADDRESS_TWO:
            setAddressTwoError(msg);
            break;

          case JOB_KEYS.CUSTOMER_CITY:
            setCityError(msg);
            break;

          case JOB_KEYS.CUSTOMER_STATE:
            setStateError(msg);
            break;

          case JOB_KEYS.CUSTOMER_ZIP_CODE:
            setZipcodeError(msg);
            break;

          case JOB_KEYS.CUSTOMER_COUNTRY:
            setCountryError(msg);
            break;

          case JOB_KEYS.JOB_TYPE_ID:
            setJobTypeError(msg);
            break;

          case JOB_KEYS.AD_SOURCE_ID:
            setJobSourceError(msg);
            break;

          case JOB_KEYS.JOB_DESCRIPTION:
            setJobDescriptionError(msg);
            break;

          case JOB_KEYS.START_DATE:
            setStartDateError(msg);
            break;

          case JOB_KEYS.START_TIME:
            setStartTimeError(msg);
            break;

          case JOB_KEYS.END_DATE:
            setEndDateError(msg);
            break;

          case JOB_KEYS.END_TIME:
            setEndTimeError(msg);
            break;

          case JOB_KEYS.TEAM_MEMBERS:
            setTeamMembersError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const firstNameBlurHandler = event => {
    if (!event.target.value) {
      setFirstNameError(requiredMessage);
    }
  };

  const lastNameBlurHandler = event => {
    if (!event.target.value) {
      setLastNameError(requiredMessage);
    }
  };

  const companyNameBlurHandler = event => {
    /* if (!event.target.value) {
      setCompanyNameError(requiredMessage);
    } */
  };

  const phoneOneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneOneError(phoneNotValid);
    }
  };

  /* const extensionOneBlurHandler = event => {
    if (!event.target.value) {
      setExtensionOneError(requiredMessage);
    }
  }; */

  /* const emailBlurHandler = event => {
    if (!event.target.value) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  }; */

  const addressOneBlurHandler = event => {
    if (!event.target.value) {
      setAddressOneError(requiredMessage);
    }
  };

  const addressTwoBlurHandler = event => {
    /* if (!event.target.value) {
      setAddressTwoError(requiredMessage);
    } */
  };

  const cityBlurHandler = event => {
    if (!event.target.value) {
      setCityError(requiredMessage);
    }
  };

  const zipcodeBlurHandler = event => {
    if (!event.target.value) {
      setZipcodeError(requiredMessage);
    }
  };

  const jobDescriptionBlurHandler = event => {
    if (!event.target.value) {
      setJobDescriptionError(requiredMessage);
    }
  };

  const startDateChangeHandler = date => {
    setStartDate(date);
    setStartDateError('');
    validateDate(date, setStartDateError);
  };

  const endDateChangeHandler = date => {
    setEndDate(date);
    setEndDateError('');
    validateDate(date, setEndDateError);
  };

  const handleResetCustomer = () => {
    setEmail('');
    setResetCustomer(true);
  };

  const handleSelectProperty = () => {
    setResetProperty(true);
    setFetchProperties(true);
  };

  const handlePropertySelection = (id, property) => {
    if (id === 'new') {
      setResetProperty(true);
    } else {
      setProperty(property);
    }
  };

  /* const handleOpenRecurringScheduleDrawer = () => setOpenRecurringSchedule(true); */

  /* const handleCloseRecurringScheduleDrawer = () => setOpenRecurringSchedule(false); */

  const handleOpenJobTypeDialog = () => setOpenAddEditJobType(true);

  const handleCloseJobTypeDialog = () => setOpenAddEditJobType(false);

  const handleClosePropertiesDialog = () => setOpenPropertiesDialog(false);

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!firstName) {
      formIsValid = false;
      setFirstNameError(requiredMessage);
    }

    if (!lastName) {
      formIsValid = false;
      setLastNameError(requiredMessage);
    }

    /* if (!companyName) {
      formIsValid = false;
      setCompanyNameError(requiredMessage);
    } */

    if (!isValidPhoneNumber(phoneOne)) {
      formIsValid = false;
      setPhoneOneError(phoneNotValid);
    }

    /* if (!extensionOne) {
      formIsValid = false;
      setExtensionOneError(requiredMessage);
    } */

    if (!email) {
      formIsValid = false;
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      formIsValid = false;
      setEmailError(emailNotValid);
    }

    if (!addressOne) {
      formIsValid = false;
      setAddressOneError(requiredMessage);
    }

    /* if (!addressTwo) {
      formIsValid = false;
      setAddressTwoError(requiredMessage);
    } */

    if (!city) {
      formIsValid = false;
      setCityError(requiredMessage);
    }

    if (state) {
      if (!usaStates.map(usaState => usaState[USA_STATE_KEYS.ID]).includes(state)) {
        formIsValid = false;
        setStateError(stateNotValid);
      }
    } else {
      formIsValid = false;
      setStateError(requiredMessage);
    }

    if (!zipcode) {
      formIsValid = false;
      setZipcodeError(requiredMessage);
    }

    if (country) {
      if (!countries.map(country => country[COUNTRY_KEYS.ID]).includes(country)) {
        formIsValid = false;
        setCountryError(stateNotValid);
      }
    } else {
      formIsValid = false;
      setCountryError(requiredMessage);
    }

    if (!jobDescription) {
      formIsValid = false;
      setJobDescriptionError(requiredMessage);
    }

    if (jobType) {
      if (!activeJobTypes.map(tempJobType => tempJobType[JOB_TYPE_KEYS.ID]).includes(jobType)) {
        formIsValid = false;
        setJobTypeError(jobTypeNotValid);
      }
    } else {
      formIsValid = false;
      setJobTypeError(requiredMessage);
    }

    if (jobSource) {
      if (!adSources.map(adSource => adSource[AD_SOURCES_KEYS.ID]).includes(jobSource)) {
        formIsValid = false;
        setJobSourceError(jobSourceNotValid);
      }
    }

    validateDate(startDate, setStartDateError, () => {
      formIsValid = false;
    });

    if (startTime) {
      if (!timeList.map(timeItem => timeItem.slug).includes(startTime)) {
        formIsValid = false;
        setStartTimeError(timeNotValid);
      }
    } else {
      formIsValid = false;
      setStartTimeError(requiredMessage);
    }

    validateDate(endDate, setEndDateError, () => {
      formIsValid = false;
    });

    if (endTime) {
      if (!timeList.map(timeItem => timeItem.slug).includes(endTime)) {
        formIsValid = false;
        setEndTimeError(timeNotValid);
      }
    } else {
      formIsValid = false;
      setEndTimeError(requiredMessage);
    }

    if (
      startDate &&
      !startDateError &&
      startTime &&
      !startTimeError &&
      endDate &&
      !endDateError &&
      endTime &&
      !endTimeError
    ) {
      if (!isDateRangeValid(startDate, startTime, endDate, endTime)) {
        formIsValid = false;
        setDateTimeError('Start time must be less than end time');
      }
    }

    if (formIsValid) {
      const jobData = {
        authcode: authUser.authcode,
        [JOB_KEYS.CUSTOMER_ID]: customerId,
        [JOB_KEYS.CUSTOMER_FNAME]: firstName,
        [JOB_KEYS.CUSTOMER_LNAME]: lastName,
        [JOB_KEYS.CUSTOMER_COMPANY]: companyName,
        [JOB_KEYS.CUSTOMER_EMAIL]: email,
        [JOB_KEYS.CUSTOMER_PHONE_ONE]: phoneOne,
        /* [JOB_KEYS.CUSTOMER_EXTENSION_ONE]: extensionOne, */
        [JOB_KEYS.CUSTOMER_PROPERTY_ID]: propertyId,
        [JOB_KEYS.CUSTOMER_ADDRESS_ONE]: addressOne,
        [JOB_KEYS.CUSTOMER_ADDRESS_TWO]: addressTwo,
        [JOB_KEYS.CUSTOMER_ZIP_CODE]: zipcode,
        [JOB_KEYS.CUSTOMER_CITY]: city,
        [JOB_KEYS.CUSTOMER_STATE]: state,
        [JOB_KEYS.CUSTOMER_COUNTRY]: /* country */ COUNTRY_USA,
        [JOB_KEYS.JOB_TYPE_ID]: jobType,
        [JOB_KEYS.AD_SOURCE_ID]: jobSource,
        [JOB_KEYS.JOB_DESCRIPTION]: jobDescription,
        [JOB_KEYS.START_DATE]: getDateinDesiredFormat(startDate, 'YYYY-MM-DD'),
        [JOB_KEYS.START_TIME]: startTime,
        [JOB_KEYS.END_DATE]: getDateinDesiredFormat(endDate, 'YYYY-MM-DD'),
        [JOB_KEYS.END_TIME]: endTime,
        [JOB_KEYS.TEAM_MEMBERS]: teamMembers && teamMembers.length > 0 ? teamMembers.join(',') : '',
      };

      dispatch(
        addNewJob(
          { jobData: jobData },
          () => {
            if (_isMounted.current) history.push('/jobs');
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  /* console.log({
    resetCustomer,
    email,
    debouncedEmail,
    customersList,
    customer,
    customerId,
    customerIsNotNew,
    customerIsValid,
    resetProperty,
    propertiesList,
    property,
    propertyId,
    propertyIsNotNew,
    propertyIsValid,
  }); */

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          {!dataFetching && dataFetched && (
            <form onSubmit={handleFormSubmit}>
              <GridContainer>
                {customerIsValid && (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <GridContainer>
                          <Grid item xs={12} md={9}>
                            <Typography component="h3" variant="h3">
                              Customer Details
                            </Typography>
                          </Grid>
                          {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                            <Grid item xs={12}>
                              {miscellaneousErrors.map((miscellaneousError, idx) => (
                                <Typography
                                  variant="caption"
                                  display="block"
                                  color="error"
                                  gutterBottom
                                  key={`misc-error-${idx}`}>
                                  {miscellaneousError}
                                </Typography>
                              ))}
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              freeSolo
                              disableClearable
                              clearOnBlur={false}
                              popupIcon={null}
                              id="email"
                              value={customer}
                              options={customersList}
                              getOptionLabel={option => {
                                return option && option[CUSTOMER_KEYS.ID] ? option[CUSTOMER_KEYS.EMAIL] : '';
                              }}
                              getOptionSelected={(option, value) => option[CUSTOMER_KEYS.ID] === value[CUSTOMER_KEYS.ID]}
                              onChange={(event, newValue) => {
                                setCustomer(newValue ? newValue : null);
                                setCustomersList([]);
                                /* setEmail(''); */
                              }}
                              onInputChange={(event, newInputValue) => {
                                setEmail(newInputValue ? newInputValue : '');
                              }}
                              renderInput={params => (
                                <AppTextInput
                                  {...params}
                                  fullWidth
                                  type="text"
                                  name="email"
                                  variant="outlined"
                                  label="Email Address"
                                  margin="dense"
                                  error={!!emailError}
                                  helperText={emailError}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                                  }}
                                />
                              )}
                              renderOption={option => {
                                return (
                                  <GridContainer>
                                    <Grid item xs={12}>
                                      <Typography variant="body1" color="textPrimary" gutterBottom>
                                        {`${option[CUSTOMER_KEYS.FIRST_NAME]} ${option[CUSTOMER_KEYS.LAST_NAME]}`}
                                      </Typography>

                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        gutterBottom
                                        className={classes.optionPrimaryTextBold}>
                                        {option[CUSTOMER_KEYS.EMAIL]}
                                      </Typography>

                                      <Typography variant="body2" color="textSecondary" gutterBottom>
                                        {option[CUSTOMER_KEYS.FORMATTED_ADDRESS]}
                                      </Typography>

                                      <Divider />
                                    </Grid>
                                  </GridContainer>
                                );
                              }}
                              classes={{ root: classes.listAutocomplete }}
                            />
                            {customer && (
                              <Button type="button" variant="text" color="secondary" onClick={handleResetCustomer}>
                                Reset Customer
                              </Button>
                            )}
                            {!customer && (
                              <Typography variant="caption" color="textSecondary">
                                Create a new customer or get customer suggestions based on email address.
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <AppTextInput
                              fullWidth
                              type="text"
                              name="first_name"
                              variant="outlined"
                              label="First Name"
                              value={firstName}
                              onChange={e => {
                                setFirstName(e.target.value);
                                setFirstNameError('');
                              }}
                              onBlur={firstNameBlurHandler}
                              helperText={firstNameError}
                              /* InputProps={{
                                readOnly: customerIsNotNew,
                              }} */
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <AppTextInput
                              fullWidth
                              type="text"
                              name="last_name"
                              variant="outlined"
                              label="Last Name"
                              value={lastName}
                              onChange={e => {
                                setLastName(e.target.value);
                                setLastNameError('');
                              }}
                              onBlur={lastNameBlurHandler}
                              helperText={lastNameError}
                              /* InputProps={{
                                readOnly: customerIsNotNew,
                              }} */
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              fullWidth
                              type="text"
                              name="company_name"
                              variant="outlined"
                              label="Company Name"
                              value={companyName}
                              onChange={e => {
                                setCompanyName(e.target.value);
                                setCompanyNameError('');
                              }}
                              onBlur={companyNameBlurHandler}
                              helperText={companyNameError}
                              /* InputProps={{
                                readOnly: customerIsNotNew,
                              }} */
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              fullWidth
                              type="text"
                              name="phone_one"
                              variant="outlined"
                              label="Phone Number"
                              value={phoneOne}
                              onChange={e => {
                                setPhoneOne(e.target.value);
                                setPhoneOneError('');
                              }}
                              onBlur={phoneOneBlurHandler}
                              helperText={phoneOneError}
                              InputProps={{
                                /* readOnly: customerIsNotNew, */
                                inputComponent: PhoneNumberFormat,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography component="h3" variant="h3">
                              Service Location
                            </Typography>
                          </Grid>
                          {customerIsNotNew && (
                            <Grid item xs={12} md={3} className={classes.mdRightAlign}>
                              <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<MapOutlinedIcon />}
                                onClick={handleSelectProperty}>
                                Select Property
                              </Button>
                            </Grid>
                          )}
                          <React.Fragment>
                            {!propertyIsNotNew && (
                              <Grid item xs={12}>
                                <input
                                  type="text"
                                  ref={autoCompleteRef}
                                  onChange={event => setMapAddress(event.target.value)}
                                  placeholder="Search address"
                                  value={mapAddress}
                                  className={classes.mapAddressInput}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12} md={9}>
                              <AppTextInput
                                fullWidth
                                type="text"
                                name="address"
                                variant="outlined"
                                label="Address"
                                value={addressOne}
                                onChange={e => {
                                  setAddressOne(e.target.value);
                                  setAddressOneError('');
                                }}
                                onBlur={addressOneBlurHandler}
                                helperText={addressOneError}
                                /* InputProps={{
                                    readOnly: customerIsNotNew,
                                  }} */
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <AppTextInput
                                fullWidth
                                type="text"
                                name="address_two"
                                variant="outlined"
                                label="Unit"
                                value={addressTwo}
                                onChange={e => {
                                  setAddressTwo(e.target.value);
                                  setAddressTwoError('');
                                }}
                                onBlur={addressTwoBlurHandler}
                                helperText={addressTwoError}
                                /* InputProps={{
                                    readOnly: customerIsNotNew,
                                  }} */
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <AppTextInput
                                fullWidth
                                type="text"
                                name="city"
                                variant="outlined"
                                label="City"
                                value={city}
                                onChange={e => {
                                  setCity(e.target.value);
                                  setCityError('');
                                }}
                                onBlur={cityBlurHandler}
                                helperText={cityError}
                                /* InputProps={{
                                    readOnly: customerIsNotNew,
                                  }} */
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                fullWidth
                                filterSelectedOptions
                                id="state"
                                value={state}
                                /* open={openState}
                                  onOpen={() => !propertyIsNotNew && setOpenState(true)}
                                  onClose={() => setOpenState(false)}
                                  disableClearable={propertyIsNotNew} */
                                options={usaStates.map(usaState => usaState[USA_STATE_KEYS.ID])}
                                getOptionLabel={option => {
                                  if (option) {
                                    const selectedState = usaStates.find(usaState => usaState[USA_STATE_KEYS.ID] === option);
                                    if (selectedState) {
                                      return selectedState[USA_STATE_KEYS.NAME];
                                    } else {
                                      return '';
                                    }
                                  } else {
                                    return '';
                                  }
                                }}
                                getOptionSelected={(option, value) => option === value || option === null}
                                renderInput={params => (
                                  <AppTextInput
                                    {...params}
                                    type="text"
                                    name="state"
                                    label="State"
                                    variant="outlined"
                                    margin="dense"
                                    helperText={stateError}
                                  />
                                )}
                                onChange={(e, value, reason) => {
                                  if (['select-option', 'remove-option'].includes(reason)) {
                                    const selectedState = value ? value : null;
                                    setState(selectedState);

                                    if (selectedState) {
                                      if (!usaStates.map(usaState => usaState[USA_STATE_KEYS.ID]).includes(selectedState)) {
                                        setStateError(stateNotValid);
                                      } else {
                                        setStateError('');
                                      }
                                    } else {
                                      setStateError(requiredMessage);
                                    }
                                  } else if (reason === 'clear') {
                                    setState(null);
                                    setStateError('');
                                  }
                                }}
                                classes={{ root: classes.listAutocomplete }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <AppTextInput
                                fullWidth
                                type="text"
                                name="zipcode"
                                variant="outlined"
                                label="Zip"
                                value={zipcode}
                                onChange={e => {
                                  setZipcode(e.target.value);
                                  setZipcodeError('');
                                }}
                                onBlur={zipcodeBlurHandler}
                                helperText={zipcodeError}
                                /* InputProps={{
                                    readOnly: customerIsNotNew,
                                  }} */
                              />
                            </Grid>
                          </React.Fragment>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h3">
                              Job Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box classes={{ root: classes.autocompleteFlexContainer }}>
                              <Autocomplete
                                fullWidth
                                filterSelectedOptions
                                id="job_type"
                                value={jobType}
                                options={activeJobTypes.map(tempJobType => tempJobType[JOB_TYPE_KEYS.ID])}
                                getOptionLabel={option => {
                                  if (option) {
                                    const selectedJobType = activeJobTypes.find(
                                      jobType => jobType[JOB_TYPE_KEYS.ID] === option,
                                    );
                                    if (selectedJobType) {
                                      return selectedJobType[JOB_TYPE_KEYS.NAME];
                                    } else {
                                      return '';
                                    }
                                  } else {
                                    return '';
                                  }
                                }}
                                getOptionSelected={(option, value) => option === value || option === null}
                                renderInput={params => (
                                  <AppTextInput
                                    {...params}
                                    type="text"
                                    name="job_type"
                                    label="Select Job Type"
                                    variant="outlined"
                                    margin="dense"
                                    helperText={jobTypeError}
                                  />
                                )}
                                onChange={(e, value, reason) => {
                                  if (['select-option', 'remove-option'].includes(reason)) {
                                    const selectedJobType = value ? value : null;
                                    setJobType(selectedJobType);

                                    if (selectedJobType) {
                                      if (
                                        !activeJobTypes
                                          .map(tempJobType => tempJobType[JOB_TYPE_KEYS.ID])
                                          .includes(selectedJobType)
                                      ) {
                                        setJobTypeError(jobTypeNotValid);
                                      } else {
                                        setJobTypeError('');
                                      }
                                    } else {
                                      setJobTypeError(requiredMessage);
                                    }
                                  } else if (reason === 'clear') {
                                    setJobType(null);
                                    setJobTypeError('');
                                  }
                                }}
                                classes={{ root: clsx(classes.listAutocomplete, classes.listAutocompleteFlex) }}
                              />
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleOpenJobTypeDialog}
                                classes={{ root: classes.addNewBtn }}>
                                Add Job Type
                              </Button>
                            </Box>
                            <Box>
                              {jobType && (
                                <React.Fragment>
                                  <Typography variant="body1" display="inline" className={classes.numUsersClass}>
                                    {activeSquadUsers.reduce((totalUsers, squadUserObj) => {
                                      if (squadUserObj[SQUAD_USER_KEYS.SKILLS]) {
                                        const squadUserSkills = squadUserObj[SQUAD_USER_KEYS.SKILLS];
                                        if (squadUserSkills && squadUserSkills.split(',').includes(jobType)) {
                                          return totalUsers + 1;
                                        } else {
                                          return totalUsers;
                                        }
                                      } else {
                                        return totalUsers + 1;
                                      }
                                    }, 0)}
                                  </Typography>
                                  <Typography variant="body1" display="inline">
                                    {' '}
                                    users are available for the selected job type
                                  </Typography>
                                </React.Fragment>
                              )}
                              {!jobType && (
                                <React.Fragment>
                                  <Typography variant="body1" display="inline" className={classes.numUsersClass}>
                                    {activeSquadUsers.reduce((totalUsers, squadUserObj) => {
                                      if (!squadUserObj[SQUAD_USER_KEYS.SKILLS]) {
                                        return totalUsers + 1;
                                      } else {
                                        return totalUsers;
                                      }
                                    }, 0)}
                                  </Typography>
                                  <Typography variant="body1" display="inline">
                                    {' '}
                                    users available for{' '}
                                  </Typography>
                                  <Typography variant="body1" display="inline" className={classes.numUsersClass}>
                                    any
                                  </Typography>
                                  <Typography variant="body1" display="inline">
                                    {' '}
                                    job type
                                  </Typography>
                                </React.Fragment>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              filterSelectedOptions
                              id="job_source"
                              value={jobSource}
                              options={adSources.map(adSource => adSource[AD_SOURCES_KEYS.ID])}
                              getOptionLabel={option => {
                                if (option) {
                                  const selectedJobSource = adSources.find(
                                    adSource => adSource[AD_SOURCES_KEYS.ID] === option,
                                  );
                                  if (selectedJobSource) {
                                    return selectedJobSource[AD_SOURCES_KEYS.NAME];
                                  } else {
                                    return '';
                                  }
                                } else {
                                  return '';
                                }
                              }}
                              getOptionSelected={(option, value) => option === value || option === null}
                              renderInput={params => (
                                <AppTextInput
                                  {...params}
                                  type="text"
                                  name="job_source"
                                  label="Select Job Source"
                                  variant="outlined"
                                  margin="dense"
                                  helperText={jobSourceError}
                                />
                              )}
                              onChange={(e, value, reason) => {
                                if (['select-option', 'remove-option'].includes(reason)) {
                                  const selectedJobSource = value ? value : null;

                                  setJobSource(selectedJobSource);
                                  setJobSourceError('');

                                  if (
                                    selectedJobSource &&
                                    !adSources.map(adSource => adSource[AD_SOURCES_KEYS.ID]).includes(selectedJobSource)
                                  ) {
                                    setJobSourceError(jobSourceNotValid);
                                  }
                                } else if (reason === 'clear') {
                                  setJobSource(null);
                                  setJobSourceError('');
                                }
                              }}
                              classes={{ root: classes.listAutocomplete }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              fullWidth
                              multiline
                              minRows={7}
                              name="job_description"
                              variant="outlined"
                              label="Job Description"
                              value={jobDescription}
                              onChange={e => {
                                setJobDescription(e.target.value);
                                setJobDescriptionError('');
                              }}
                              onBlur={jobDescriptionBlurHandler}
                              helperText={jobDescriptionError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h3">
                              Scheduled
                            </Typography>
                            {!!dateTimeError && (
                              <Typography variant="caption" display="block" color="error" gutterBottom>
                                {dateTimeError}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <AppDatePicker
                              fullWidth
                              disableToolbar
                              name="start_date"
                              inputVariant="outlined"
                              format="ddd MMM Do YYYY"
                              label="Starts"
                              value={startDate}
                              onChange={startDateChangeHandler}
                              helperText={startDateError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              fullWidth
                              filterSelectedOptions
                              id="start_time"
                              value={startTime}
                              options={timeList.map(timeItem => timeItem.slug)}
                              getOptionLabel={option => {
                                if (option) {
                                  const selectedTime = timeList.find(timeItem => timeItem.slug === option);
                                  if (selectedTime) {
                                    return selectedTime.title;
                                  } else {
                                    return '';
                                  }
                                } else {
                                  return '';
                                }
                              }}
                              getOptionSelected={(option, value) => option === value || option === null}
                              renderInput={params => (
                                <AppTextInput
                                  {...params}
                                  type="text"
                                  name="start_time"
                                  label="At"
                                  variant="outlined"
                                  margin="dense"
                                  helperText={startTimeError}
                                />
                              )}
                              onChange={(e, value, reason) => {
                                if (['select-option', 'remove-option'].includes(reason)) {
                                  const selectedTime = value ? value : null;
                                  setStartTime(selectedTime);

                                  if (selectedTime) {
                                    if (!timeList.map(timeItem => timeItem.slug).includes(selectedTime)) {
                                      setStartTimeError(timeNotValid);
                                    } else {
                                      setStartTimeError('');
                                    }
                                  } else {
                                    setStartTimeError(requiredMessage);
                                  }
                                } else if (reason === 'clear') {
                                  setStartTime(null);
                                  setStartTimeError(requiredMessage);
                                }
                              }}
                              classes={{ root: classes.listAutocomplete }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <AppDatePicker
                              fullWidth
                              disableToolbar
                              name="end_date"
                              inputVariant="outlined"
                              format="ddd MMM Do YYYY"
                              label="Ends"
                              value={endDate}
                              onChange={endDateChangeHandler}
                              helperText={endDateError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              fullWidth
                              filterSelectedOptions
                              id="end_time"
                              value={endTime}
                              options={timeList.map(timeItem => timeItem.slug)}
                              getOptionLabel={option => {
                                if (option) {
                                  const selectedTime = timeList.find(timeItem => timeItem.slug === option);
                                  if (selectedTime) {
                                    return selectedTime.title;
                                  } else {
                                    return '';
                                  }
                                } else {
                                  return '';
                                }
                              }}
                              getOptionSelected={(option, value) => option === value || option === null}
                              renderInput={params => (
                                <AppTextInput
                                  {...params}
                                  type="text"
                                  name="end_time"
                                  label="At"
                                  variant="outlined"
                                  margin="dense"
                                  helperText={endTimeError}
                                />
                              )}
                              onChange={(e, value, reason) => {
                                if (['select-option', 'remove-option'].includes(reason)) {
                                  const selectedTime = value ? value : null;
                                  setEndTime(selectedTime);

                                  if (selectedTime) {
                                    if (!timeList.map(timeItem => timeItem.slug).includes(selectedTime)) {
                                      setEndTimeError(timeNotValid);
                                    } else {
                                      setEndTimeError('');
                                    }
                                  } else {
                                    setEndTimeError(requiredMessage);
                                  }
                                } else if (reason === 'clear') {
                                  setEndTime(null);
                                  setEndTimeError(requiredMessage);
                                }
                              }}
                              classes={{ root: classes.listAutocomplete }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              fullWidth
                              filterSelectedOptions
                              id="team_member"
                              value={teamMembers}
                              options={activeSquadUsers.map(squadUser => squadUser[SQUAD_USER_KEYS.ID])}
                              getOptionLabel={option => {
                                if (option) {
                                  const selectedUser = activeSquadUsers.find(
                                    squadUser => squadUser[SQUAD_USER_KEYS.ID] === option,
                                  );
                                  if (selectedUser) {
                                    return selectedUser[SQUAD_USER_KEYS.NAME];
                                  } else {
                                    return '';
                                  }
                                } else {
                                  return '';
                                }
                              }}
                              getOptionSelected={(option, value) => option === value || option === null}
                              renderInput={params => (
                                <AppTextInput
                                  {...params}
                                  type="text"
                                  name="team_member"
                                  label="Team Member"
                                  variant="outlined"
                                  margin="dense"
                                  helperText={teamMembersError}
                                />
                              )}
                              onChange={(e, values, reason) => {
                                if (['select-option', 'remove-option'].includes(reason)) {
                                  setTeamMembers(values);
                                  setTeamMembersError('');
                                } else if (reason === 'clear') {
                                  setTeamMembers([]);
                                  setTeamMembersError('');
                                }
                              }}
                              classes={{ root: classes.listAutocomplete }}
                            />
                          </Grid>
                          {propertyIsValid && (
                            <Grid item xs={12}>
                              <Button type="submit" variant="contained" color="primary">
                                Save
                              </Button>
                            </Grid>
                          )}
                        </GridContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {!customerIsValid && (
                  <Grid item xs={12}>
                    <Alert severity="error">{customerNotValid}</Alert>
                  </Grid>
                )}
              </GridContainer>
            </form>
          )}

          {!dataFetching && !dataFetched && <Alert severity="error">Data not found!</Alert>}
        </Grid>
      </GridContainer>

      {/* openRecurringSchedule && (
        <RecurringScheduleDrawer open={openRecurringSchedule} onClose={handleCloseRecurringScheduleDrawer} />
      ) */}

      {!dataFetching && dataFetched && openAddEditJobType && (
        <AddEditJobType open={openAddEditJobType} onCloseDialog={handleCloseJobTypeDialog} />
      )}

      {!dataFetching && dataFetched && openPropertiesDialog && (
        <NewJobAddress
          open={openPropertiesDialog}
          propertiesList={propertiesList}
          onClose={handleClosePropertiesDialog}
          onPropertySelection={handlePropertySelection}
        />
      )}
    </PageContainer>
  );
};

export default NewJob;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import GridContainer from '../../@jumbo/components/GridContainer';
import { AppName } from '../../@jumbo/constants/AppConstants';
import {
  APPROVE_ESTIMATES_STATUS_ID,
  DECLINE_ESTIMATES_STATUS_ID,
  ESTIMATE_KEYS,
  ESTIMATE_PRODUCTS_TOTALS_KEYS,
} from '../../@jumbo/constants/AppDataConstants';

import {
  getEstimateForCustomer,
  updateEstimateStatusForCustomer,
  previewEstimateForCustomer,
  downloadEstimateForCustomer,
} from '../../redux/actions/Estimates';
import { statusNotValid } from '../../@jumbo/constants/ErrorMessages';

import Header from './Header';
import EstimateInfo from './EstimateInfo';
import EstimatePreviewDialog from '../Estimates/EstimatePreviewDialog';
import SweetAlert from '../UI/Alert';

import useStyles from './index.style';

const CustomerPortalEstimate = () => {
  document.title = `${AppName} - Customer Portal`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const estimateId = params.estimateId;

  const [estimateFetching, setEstimateFetching] = useState(true);
  const [estimateFetched, setEstimateFetched] = useState(false);
  const [estimate, setEstimate] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const [previewLink, setPreviewLink] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    if (estimateId && estimateFetching && !estimateFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getEstimateForCustomer(payload, estimateData => {
              if (isActive && estimateData) {
                setEstimate(estimateData.estimate);
                setCustomer(estimateData.customer);
                setProducts(estimateData.products);
                setTotals(estimateData.totals);
                setSignatures(estimateData.signatures);
                setEstimateFetched(true);
                setEstimateFetching(false);
              }
            }),
          );
        };
      };

      const payload = { estimateData: { [ESTIMATE_KEYS.ESTIMATE_ID]: estimateId } };

      const promise = dispatch(fetchData(payload));
      promise.catch(error => {
        if (isActive) {
          setEstimateFetched(false);
          setEstimateFetching(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, estimateId, estimateFetching, estimateFetched]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const handleApproveEstimate = () => updateEstimateStatus(APPROVE_ESTIMATES_STATUS_ID);

  const handleDeclineEstimate = () => updateEstimateStatus(DECLINE_ESTIMATES_STATUS_ID);

  const updateEstimateStatus = statusId => {
    if (!statusId) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: statusNotValid,
      });

      return false;
    }

    const estimateData = {
      [ESTIMATE_KEYS.ESTIMATE_ID]: estimate[ESTIMATE_KEYS.ID],
      [ESTIMATE_KEYS.STATUS]: statusId,
    };

    dispatch(
      updateEstimateStatusForCustomer(
        { estimateData: estimateData },
        () => {
          if (_isMounted.current) {
            setEstimate(prevState => {
              return { ...prevState, [ESTIMATE_KEYS.STATUS]: statusId };
            });
          }
        },
        messages => {
          if (_isMounted.current) setErrorMessages(messages);
        },
      ),
    );
  };

  const handlePreviewEstimate = () => {
    setPreviewLink('');

    const estimateData = {
      [ESTIMATE_KEYS.ESTIMATE_ID]: estimate[ESTIMATE_KEYS.ID],
    };

    dispatch(
      previewEstimateForCustomer({ estimateData: estimateData }, link => {
        if (_isMounted.current) {
          setPreviewLink(link);
          setOpenPreviewDialog(true);
        }
      }),
    );
  };

  const handleClosePreviewDialog = () => setOpenPreviewDialog(false);

  const handleDownloadEstimate = () => {
    const estimateData = {
      [ESTIMATE_KEYS.ESTIMATE_ID]: estimate[ESTIMATE_KEYS.ID],
    };

    dispatch(
      downloadEstimateForCustomer({ estimateData: estimateData }, link => {
        if (_isMounted.current) {
          SweetAlert({
            icon: 'success',
            title: 'Your download is ready!!',
            html: `Click <a href="${link}" target="_blank" download class="${classes.downloadLink}">here</a> to download.`,
          });
        }
      }),
    );
  };

  const estimateIsValid = estimate && estimate[ESTIMATE_KEYS.ID];
  const estimateStatus = estimateIsValid && estimate[ESTIMATE_KEYS.STATUS] ? estimate[ESTIMATE_KEYS.STATUS] : null;

  return (
    <Paper className={classes.paper}>
      {!estimateFetching && estimateFetched && estimateIsValid && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12}>
              <Header
                classes={classes}
                onPreviewEstimate={handlePreviewEstimate}
                onDownloadEstimate={handleDownloadEstimate}
              />
            </Grid>
            {miscellaneousErrors && miscellaneousErrors.length > 0 && (
              <Grid item xs={12}>
                {miscellaneousErrors.map((miscellaneousError, idx) => (
                  <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                    {miscellaneousError}
                  </Typography>
                ))}
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column">
                <Typography variant="h2" className={classes.headingTwo}>
                  Estimate #{estimate[ESTIMATE_KEYS.ID]}
                </Typography>
                <Typography variant="h3" className={classes.headingThree}>
                  Total: {totals[ESTIMATE_PRODUCTS_TOTALS_KEYS.TOTAL]}
                </Typography>
              </Box>
              {estimateStatus && ![APPROVE_ESTIMATES_STATUS_ID, DECLINE_ESTIMATES_STATUS_ID].includes(estimateStatus) && (
                <Box display="flex" mt={4}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    onClick={handleApproveEstimate}
                    classes={{ containedPrimary: classes.btnApproveEstimate }}>
                    Approve
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={handleDeclineEstimate}
                    classes={{ containedPrimary: classes.btnDeclineEstimate }}>
                    Reject
                  </Button>
                </Box>
              )}
              {estimateStatus && [APPROVE_ESTIMATES_STATUS_ID, DECLINE_ESTIMATES_STATUS_ID].includes(estimateStatus) && (
                <GridContainer style={{ marginTop: 5 }}>
                  <Grid item xs={12}>
                    <Alert severity={estimateStatus === APPROVE_ESTIMATES_STATUS_ID ? 'success' : 'error'}>
                      Estimate has been {estimateStatus === APPROVE_ESTIMATES_STATUS_ID ? 'approved' : 'rejected'}
                    </Alert>
                  </Grid>
                </GridContainer>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <EstimateInfo
                classes={classes}
                estimate={estimate}
                customer={customer}
                products={products}
                totals={totals}
                signatures={signatures}
              />
            </Grid>
          </GridContainer>

          {openPreviewDialog && (
            <EstimatePreviewDialog
              estimate={estimate}
              link={previewLink}
              open={openPreviewDialog}
              onClose={handleClosePreviewDialog}
            />
          )}
        </React.Fragment>
      )}

      {!estimateFetching && (!estimateFetched || !estimateIsValid) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Estimate data not found!</Alert>
          </Grid>
        </GridContainer>
      )}
    </Paper>
  );
};

export default CustomerPortalEstimate;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { MESSAGE_KEYS, MESSAGE_USER_TYPE_CUSTOMER } from '../../../@jumbo/constants/AppDataConstants';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';

import { sendMessageForCustomer } from '../../../redux/actions/Messages';

import useStyles from '../index.style';

const MessageForm = ({ messages }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);
  const [reply, setReply] = useState('');
  const [replyError, setReplyError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (messages && messages.length > 0) {
      setMessage(messages[0]);
    } else {
      setMessage(null);
    }
  }, [messages]);

  useEffect(() => {
    if (errorMessages) {
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case MESSAGE_KEYS.MESSAGE:
            setReplyError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const replyBlurHandler = event => {
    if (!event.target.value) {
      setReplyError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!reply) {
      formIsValid = false;
      setReplyError(requiredMessage);
    }

    if (formIsValid) {
      const messageData = {
        [MESSAGE_KEYS.JOB_ID]: message[MESSAGE_KEYS.JOB_ID],
        [MESSAGE_KEYS.SENDER_ID]: message[MESSAGE_KEYS.RECEIVER_ID_ALT],
        [MESSAGE_KEYS.SENDER_TYPE]: MESSAGE_USER_TYPE_CUSTOMER,
        [MESSAGE_KEYS.RECEIVER_ID]: message[MESSAGE_KEYS.SENDER_ID_ALT],
        [MESSAGE_KEYS.RECEIVER_TYPE]: message[MESSAGE_KEYS.SENDER_TYPE_ALT],
        [MESSAGE_KEYS.MESSAGE]: reply,
      };

      dispatch(
        sendMessageForCustomer(
          { messageData: messageData },
          () => {
            setReply('');
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  if (!message) {
    return (
      <GridContainer>
        <Grid item xs={12}>
          <Alert severity="error">Message not found!</Alert>
        </Grid>
      </GridContainer>
    );
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer>
        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
          <Grid item xs={12}>
            {miscellaneousErrors.map((miscellaneousError, idx) => (
              <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                {miscellaneousError}
              </Typography>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography component="h3" variant="h3">
            Send Reply
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            From:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>
            {message[MESSAGE_KEYS.SENDER_NAME]}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Sent At:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>
            {getFormattedDate(message[MESSAGE_KEYS.SENT_AT], 'DD MMM YYYY, h:mm:ss a')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Message:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <ContentEditable html={message[MESSAGE_KEYS.MESSAGE]} disabled={true} className={classes.contentArea} />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Your reply:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <AppTextInput
            fullWidth
            multiline
            minRows={4}
            name="reply"
            variant="outlined"
            label={null}
            value={reply}
            onChange={e => {
              setReply(e.target.value);
              setReplyError('');
            }}
            onBlur={replyBlurHandler}
            helperText={replyError}
          />
        </Grid>

        <Grid item xs={12} className={classes.textAlignRight}>
          <Button type="submit" variant="contained" color="primary" startIcon={<SendIcon />}>
            Send Reply
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

MessageForm.prototype = {
  messages: PropTypes.array.isRequired,
};

export default MessageForm;

import React from 'react';

import Box from '@material-ui/core/Box';
import useStyles from './index.style';

import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';
import { MESSAGE_KEYS, MESSAGE_TYPE_SENT } from '../../../@jumbo/constants/AppDataConstants';

import CmtList from '../../../@coremat/CmtList';

import SentMessageCell from './SentMessageCell';
import ReceivedMessageCell from './ReceivedMessageCell';

const Conversation = ({ conversation, selectedUser }) => {
  const generateHeaderList = () => {
    if (conversation.length === 0) {
      return [];
    }

    let conversationList = [];
    let lastDate = getFormattedDate(conversation[0][MESSAGE_KEYS.SENT_AT], 'DD MMM');
    conversationList = [{ msgId: 'header-0', header: true, title: lastDate }];

    conversation.map(message => {
      const currentDate = getFormattedDate(message[MESSAGE_KEYS.SENT_AT], 'DD MMM');
      if (lastDate !== currentDate) {
        conversationList = conversationList.concat({
          msgId: 'header-' + conversationList.length,
          header: true,
          title: currentDate,
        });
      }
      lastDate = currentDate;
      conversationList = conversationList.concat(message);

      return message;
    });

    return conversationList;
  };

  const classes = useStyles();
  return (
    <Box className={classes.chatMainContent}>
      <CmtList
        data={generateHeaderList()}
        renderRow={(message, index) => {
          if (message.header) {
            return (
              <Box key={index} className={classes.chatDateRoot}>
                <Box component="span">{message.title}</Box>
              </Box>
            );
          } else {
            return (
              <React.Fragment key={index}>
                {message[MESSAGE_KEYS.TYPE] === MESSAGE_TYPE_SENT ? (
                  <SentMessageCell key={index} message={message} />
                ) : (
                  <ReceivedMessageCell key={index} message={message} user={selectedUser} />
                )}
              </React.Fragment>
            );
          }
        }}
      />
    </Box>
  );
};

export default Conversation;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FilterListIcon from '@material-ui/icons/FilterList';

import { ACTIVE_JOB_TYPE, INACTIVE_JOB_TYPE } from '../../../../@jumbo/constants/AppDataConstants';
import CmtSearch from '../../../../@coremat/CmtSearch';

import useStyles from './index.style';

const filterOptionsList = [
  { label: 'Active', value: ACTIVE_JOB_TYPE },
  { label: 'Disabled', value: INACTIVE_JOB_TYPE },
];

const JobTypesTableToolbar = ({ filterOptions, setFilterOptions, searchTerm, setSearchTerm }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearchChipDelete = () => setSearchTerm('');

  const onChipDelete = option => {
    setFilterOptions(filterOptions.filter(item => item !== option.value));
  };

  const onFilterOptionClick = option => {
    setFilterOptions(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        return [...prevState, option.value];
      }
    });
  };

  return (
    <Toolbar className={classes.root}>
      <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
      <div className={classes.chipsRoot}>
        {searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}
        {filterOptionsList.map(
          (option, index) =>
            filterOptions.includes(option.value) && (
              <Chip key={index} label={option.label} onDelete={() => onChipDelete(option)} />
            ),
        )}
      </div>
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list" onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {filterOptionsList.map((option, index) => (
          <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
            <Checkbox checked={filterOptions.includes(option.value)} inputProps={{ 'aria-labelledby': option.label }} />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Toolbar>
  );
};

export default JobTypesTableToolbar;

JobTypesTableToolbar.propTypes = {
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';

import GridContainer from '../../@jumbo/components/GridContainer';
import ContentLoader from '../../@jumbo/components/ContentLoader';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { MESSAGE_KEYS } from '../../@jumbo/constants/AppDataConstants';

import { getMessageForCustomer } from '../../redux/actions/Messages';

import MessageForm from './MessageForm';

import useStyles from './index.style';

const CustomerPortalMessage = () => {
  document.title = `${AppName} - Customer Portal`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const messageId = params.messageId;
  /* const customerId = params.customerId; */

  const [messagesFetching, setMessagesFetching] = useState(true);
  const [messagesFetched, setMessagesFetched] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    if (messageId && messagesFetching && !messagesFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getMessageForCustomer(payload, messages => {
              if (isActive) {
                setMessages(messages);
                setMessagesFetched(true);
                setMessagesFetching(false);
              }
            }),
          );
        };
      };

      const payload = { messageData: { [MESSAGE_KEYS.MSG_ID]: messageId } };

      const promise = dispatch(fetchData(payload));
      promise.catch(error => {
        if (isActive) {
          setMessagesFetched(false);
          setMessagesFetching(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, messageId, messagesFetching, messagesFetched]);

  const messageIsValid = messages && messages.length > 0;

  return (
    <Paper className={classes.paper}>
      {!messagesFetching && messagesFetched && messageIsValid && (
        <GridContainer>
          <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h1" className={classes.headingOne}>
              AAA Rousse Junk Removal
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MessageForm messages={messages} />
          </Grid>
        </GridContainer>
      )}

      {!messagesFetching && (!messagesFetched || !messageIsValid) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Message data not found!</Alert>
          </Grid>
        </GridContainer>
      )}

      <ContentLoader />
    </Paper>
  );
};

export default CustomerPortalMessage;

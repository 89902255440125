import {
  ADD_AD_SOURCE,
  DELETE_AD_SOURCE,
  EDIT_AD_SOURCE,
  GET_AD_SOURCES,
  SET_AD_SOURCE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  adSources: [],
  currentAdSource: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AD_SOURCES: {
      return {
        ...state,
        adSources: action.payload,
      };
    }
    case SET_AD_SOURCE_DETAILS: {
      return {
        ...state,
        currentAdSource: action.payload,
      };
    }
    case ADD_AD_SOURCE: {
      return {
        ...state,
        adSources: [action.payload, ...state.adSources],
      };
    }
    case EDIT_AD_SOURCE: {
      return {
        ...state,
        adSources: state.adSources.map(adSource => (adSource.id === action.payload.id ? action.payload : adSource)),
      };
    }
    case DELETE_AD_SOURCE: {
      return {
        ...state,
        adSources: state.adSources.filter(adSource => adSource.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

import {
  ADD_JOB_PAYMENT,
  DELETE_JOB_PAYMENT,
  /* EDIT_JOB_PAYMENT, */
  GET_JOB_PAYMENTS,
  GET_JOB_PAYMENTS_BALANCE,
  /* SET_JOB_PAYMENT_DETAILS, */
} from '../../@jumbo/constants/ActionTypes';
import { JOB_PAYMENT_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  jobPayments: [],
  jobPaymentsBalance: '',
  currentJobPayment: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_PAYMENTS: {
      return {
        ...state,
        jobPayments: action.payload,
      };
    }
    case GET_JOB_PAYMENTS_BALANCE: {
      return {
        ...state,
        jobPaymentsBalance: action.payload,
      };
    }
    /* case SET_JOB_PAYMENT_DETAILS: {
      return {
        ...state,
        currentJobPayment: action.payload,
      };
    } */
    case ADD_JOB_PAYMENT: {
      return {
        ...state,
        jobPayments: [action.payload, ...state.jobPayments],
      };
    }
    /* case EDIT_JOB_PAYMENT: {
      return {
        ...state,
        jobPayments: state.jobPayments.map(jobPayment =>
          jobPayment[JOB_PAYMENT_KEYS.ID] === action.payload[JOB_PAYMENT_KEYS.ID] ? action.payload : jobPayment,
        ),
      };
    } */
    case DELETE_JOB_PAYMENT: {
      return {
        ...state,
        jobPayments: state.jobPayments.filter(jobPayment => jobPayment[JOB_PAYMENT_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

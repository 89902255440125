import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import CmtSearch from '../../../@coremat/CmtSearch';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const EditCustomerTableToolbar = ({
  type,
  filterOptions,
  setFilterOptions,
  searchTerm,
  onSearch,
  onSearchChipDelete,
  onOpenAddressDialog,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChipDelete = option => {
    setFilterOptions(filterOptions.filter(item => item !== option.value));
  };

  const onFilterOptionClick = option => {
    setFilterOptions(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        return [...prevState, option.value];
      }
    });
  };

  return (
    <Toolbar className={classes.root}>
      <GridContainer>
        {type === 'property' && (
          <Grid item xs={12} md={2}>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onOpenAddressDialog}>
              Add New
            </Button>
          </Grid>
        )}
        <Grid item xs={12} md={type === 'property' ? 10 : 12} className={classes.filterContainer}>
          <CmtSearch onChange={e => onSearch(e.target.value)} value={searchTerm} border={false} onlyIcon />
          <div className={classes.chipsRoot}>
            {searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}
            {filterOptions &&
              filterOptions.map(
                (option, index) =>
                  filterOptions.includes(option.value) && (
                    <Chip key={index} label={option.label} onDelete={() => onChipDelete(option)} />
                  ),
              )}
          </div>
          {filterOptions && (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={handleClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
          {filterOptions && (
            <Menu
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {filterOptions.map((option, index) => (
                <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
                  <Checkbox
                    checked={filterOptions.includes(option.value)}
                    inputProps={{ 'aria-labelledby': option.label }}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Grid>
      </GridContainer>
    </Toolbar>
  );
};

EditCustomerTableToolbar.propTypes = {
  type: PropTypes.string.isRequired,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchChipDelete: PropTypes.func,
  onOpenAddressDialog: PropTypes.func,
};

export default EditCustomerTableToolbar;

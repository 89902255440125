import {
  ADD_ESTIMATE_PRODUCT,
  DELETE_ESTIMATE_PRODUCT,
  EDIT_ESTIMATE_PRODUCT,
  GET_ESTIMATE_PRODUCTS,
  GET_ESTIMATE_PRODUCTS_TOTALS,
  SET_ESTIMATE_PRODUCT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { ESTIMATE_PRODUCT_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  estimateProducts: [],
  estimateProductsTotals: null,
  currentEstimateProduct: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTIMATE_PRODUCTS: {
      return {
        ...state,
        estimateProducts: action.payload,
      };
    }
    case GET_ESTIMATE_PRODUCTS_TOTALS: {
      return {
        ...state,
        estimateProductsTotals: action.payload,
      };
    }
    case SET_ESTIMATE_PRODUCT_DETAILS: {
      return {
        ...state,
        currentEstimateProduct: action.payload,
      };
    }
    case ADD_ESTIMATE_PRODUCT: {
      return {
        ...state,
        estimateProducts: [...state.estimateProducts, action.payload],
      };
    }
    case EDIT_ESTIMATE_PRODUCT: {
      return {
        ...state,
        estimateProducts: state.estimateProducts.map(estimateProduct =>
          estimateProduct[ESTIMATE_PRODUCT_KEYS.ID] === action.payload[ESTIMATE_PRODUCT_KEYS.ID]
            ? action.payload
            : estimateProduct,
        ),
      };
    }
    case DELETE_ESTIMATE_PRODUCT: {
      return {
        ...state,
        estimateProducts: state.estimateProducts.filter(
          estimateProduct => estimateProduct[ESTIMATE_PRODUCT_KEYS.ID] !== action.payload,
        ),
      };
    }
    default:
      return state;
  }
};

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  SET_MESSAGES_JOBS,
  SET_MESSAGES_USERS,
  SET_CURRENT_MESSAGES_USER,
  SET_MESSAGES_CONVERSATION_DATA,
  ADD_NEW_CHAT_MESSAGE,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_CUSTOMER_MESSAGE_LINK,
  GET_MESSAGES_JOBS_LINK,
  GET_MESSAGES_JOB_USERS_LINK,
  GET_USER_MESSAGES_LINK,
  SEND_CUSTOMER_MESSAGE_LINK,
  SEND_USER_MESSAGE_LINK,
} from '../../@jumbo/constants/APIConstants';

export const getMessagesJobs = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.messageData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_MESSAGES_JOBS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobs) {
              const jobs = data.jobs;
              dispatch(fetchSuccess());
              dispatch({ type: SET_MESSAGES_JOBS, payload: jobs });
              if (callbackFun) callbackFun(jobs);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: SET_MESSAGES_JOBS, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getMessagesUsers = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.messageData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_MESSAGES_JOB_USERS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.users) {
              const users = data.users;
              dispatch(fetchSuccess());
              dispatch({ type: SET_MESSAGES_USERS, payload: users });
              if (callbackFun) callbackFun(users);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: SET_MESSAGES_USERS, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setUsersList = users => {
  return dispatch => {
    dispatch({ type: SET_MESSAGES_USERS, payload: users });
  };
};

export const setCurrentUser = user => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_MESSAGES_USER, payload: user });
  };
};

export const getUserConversation = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.messageData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_USER_MESSAGES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.messages) {
              const conversation = data.messages;
              dispatch(fetchSuccess());
              dispatch({ type: SET_MESSAGES_CONVERSATION_DATA, payload: conversation });
              if (callbackFun) callbackFun(conversation);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: SET_MESSAGES_CONVERSATION_DATA, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentUserMessages = conversation => {
  return dispatch => {
    dispatch({ type: SET_MESSAGES_CONVERSATION_DATA, payload: conversation });
  };
};

export const sendMessage = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.messageData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    return axios
      .post(SEND_USER_MESSAGE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.messages && data.messages.length > 0) {
              const message = data.messages[0];
              dispatch(fetchSuccess());
              dispatch({ type: ADD_NEW_CHAT_MESSAGE, payload: message });
              if (callbackFun) callbackFun(message);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getMessageForCustomer = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.messageData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_MESSAGE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.messages) {
              const conversation = data.messages;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(conversation);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const sendMessageForCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.messageData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    return axios
      .post(SEND_CUSTOMER_MESSAGE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.messages && data.messages.length > 0) {
              const message = data.messages[0];
              dispatch(fetchSuccess('Message sent successfully!'));
              if (callbackFun) callbackFun(message);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

import moment from 'moment';
import { SQUAD_USER_KEYS, ROLE_KEYS, ADMIN_ROLE, SUBCONTRACTOR_ROLE } from '../@jumbo/constants/AppDataConstants';

const isUserAdmin = user => user[SQUAD_USER_KEYS.ROLE] === ADMIN_ROLE && user[SQUAD_USER_KEYS.ROLE_NAME] === 'Admin';

const isSubcontractorUser = user => user[SQUAD_USER_KEYS.ROLE] === SUBCONTRACTOR_ROLE;

export const permitPageAccess = (path, user) => {
  const userPermissions = user[SQUAD_USER_KEYS.PERMISSIONS];
  let grantAccessPermission = false;

  if (isUserAdmin(user)) {
    grantAccessPermission = true;
  } else if (isSubcontractorUser(user)) {
    if (['/messages', '/jobMessages/:jobId/'].includes(path)) {
      grantAccessPermission = true;
    }
  } else if (userPermissions) {
    if (
      ['/settings', '/jobs', '/job/:jobId/', '/reports', '/calendar', '/messages', '/jobMessages/:jobId/'].includes(path)
    ) {
      grantAccessPermission = true;
    } else if (['/dashboard'].includes(path)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.DASHBOARD] === '1'; */
      grantAccessPermission = true;
    } else if (['/roles', '/squad', '/newUser', '/user/:userId/'].includes(path)) {
      grantAccessPermission = userPermissions[ROLE_KEYS.USER_SETTINGS] === '1';
    } else if (
      ['/metroAreas', '/jobTypes', '/adgroups', /* '/products', '/product/:productId/', */ '/subStatus', '/taxes'].includes(
        path,
      )
    ) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.JOB_SETTINGS] === '1'; */
      grantAccessPermission = true;
    } else if (['/customers', '/newCustomer', '/customer/:customerId/'].includes(path)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.CLIENTS] === '1'; */
      grantAccessPermission = true;
    } else if (['/newJob'].includes(path)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.ADD_JOBS] === '1'; */
      grantAccessPermission = true;
    } else if (['/estimates', '/estimate/:estimateId/'].includes(path)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.ESTIMATE_REPORT] === '1'; */
      grantAccessPermission = true;
    } else if (['/invoices', '/jobInvoice/:invoiceId/'].includes(path)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.INVOICE_REPORT] === '1'; */
      grantAccessPermission = true;
    } else if (['/notifications'].includes(path)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.NOTIFICATIONS] === '1'; */
      grantAccessPermission = true;
    }
  }

  return grantAccessPermission;
};

export const permitComponentAccess = (component, user) => {
  const userPermissions = user[SQUAD_USER_KEYS.PERMISSIONS];
  let grantAccessPermission = false;

  if (isUserAdmin(user)) {
    grantAccessPermission = true;
  } else if (isSubcontractorUser(user)) {
    if (['/messages'].includes(component)) {
      grantAccessPermission = true;
    }
  } else if (userPermissions) {
    if (['/jobs', '/reports', '/calendar', '/messages'].includes(component)) {
      grantAccessPermission = true;
    } else if (['/squad', '/roles'].includes(component)) {
      grantAccessPermission = userPermissions[ROLE_KEYS.USER_SETTINGS] === '1';
    } else if (['/metroAreas', '/jobTypes', '/adgroups', /* '/products', */ '/subStatus', '/taxes'].includes(component)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.JOB_SETTINGS] === '1'; */
      grantAccessPermission = true;
    } else if (['/customers'].includes(component)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.CLIENTS] === '1'; */
      grantAccessPermission = true;
    } else if (['/newJob'].includes(component)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.ADD_JOBS] === '1'; */
      grantAccessPermission = true;
    } else if (['/estimates'].includes(component)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.ESTIMATE_REPORT] === '1'; */
      grantAccessPermission = true;
    } else if (['/invoices'].includes(component)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.INVOICE_REPORT] === '1'; */
      grantAccessPermission = true;
    } else if (['/notifications'].includes(component)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.NOTIFICATIONS] === '1'; */
      grantAccessPermission = true;
    }
  }

  return grantAccessPermission;
};

export const permitMenuAccess = (menu, user) => {
  const userPermissions = user[SQUAD_USER_KEYS.PERMISSIONS];
  let grantAccessPermission = false;

  if (isUserAdmin(user)) {
    grantAccessPermission = true;
  } else if (isSubcontractorUser(user)) {
    if (['menu_messages'].includes(menu)) {
      grantAccessPermission = true;
    }
  } else if (userPermissions) {
    if (['menu_jobs'].includes(menu)) {
      grantAccessPermission = true;
    } else if (['menu_payments'].includes(menu)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.ESTIMATE_REPORT] === '1' || userPermissions[ROLE_KEYS.INVOICE_REPORT] === '1'; */
      grantAccessPermission = true;
    }
  }

  return grantAccessPermission;
};

export const permitSettingsTabAccess = (setting, user) => {
  const userPermissions = user[SQUAD_USER_KEYS.PERMISSIONS];
  let grantAccessPermission = false;

  if (isUserAdmin(user)) {
    grantAccessPermission = true;
  } else if (userPermissions) {
    if (['userRoles'].includes(setting)) {
      grantAccessPermission = userPermissions[ROLE_KEYS.USER_SETTINGS] === '1';
    } else if (['jobSettings'].includes(setting)) {
      /* grantAccessPermission = userPermissions[ROLE_KEYS.JOB_SETTINGS] === '1'; */
      grantAccessPermission = true;
    }
  }

  return grantAccessPermission;
};

export const isLoginSessionTimeout = (expireTimestamp /* , expireDatetime */) => {
  const currentTime = new Date();
  /* const adjExpirationTimeOne = new Date(expireDatetime); */
  const adjExpirationTimeTwo = new Date(expireTimestamp * 1000);

  return moment(currentTime).isAfter(adjExpirationTimeTwo);
};

import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { INVOICE_SIGNATURE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import CmtImage from '../../../@coremat/CmtImage';

const headCells = [
  { id: INVOICE_SIGNATURE_KEYS.SIGN_LINK, label: 'Signature', width: '20%' },
  { id: INVOICE_SIGNATURE_KEYS.SIGNER_NAME, label: 'Signer Name', width: '45%' },
  { id: INVOICE_SIGNATURE_KEYS.FORMATTED_CREATED, label: 'Signed On', width: '35%' },
];

const SignaturesTable = ({ classes, signatures }) => {
  return (
    <Table className={classes.table} aria-labelledby="invoiceSignaturesTableTitle" aria-label="Invoice Signatures Table">
      <TableHead>
        <TableRow>
          {headCells.map(headCell => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding="normal"
                sortDirection={false}
                style={{ width: headCell.width }}
                className={classes.signTableHeadCell}>
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {!!signatures.length
          ? signatures.map((row, index) => (
              <TableRow key={`invoice-signature-key-${index}`} tabIndex={-1}>
                <TableCell component="th" id={`invoice-signature-${index}`} scope="row">
                  <CmtImage src={row[INVOICE_SIGNATURE_KEYS.SIGN_LINK]} alt={`Invoice signature ${index + 1}`} width="40%" />
                </TableCell>
                <TableCell>{row[INVOICE_SIGNATURE_KEYS.SIGNER_NAME]}</TableCell>
                <TableCell>{row[INVOICE_SIGNATURE_KEYS.FORMATTED_CREATED]}</TableCell>
              </TableRow>
            ))
          : null}
      </TableBody>
    </Table>
  );
};

SignaturesTable.prototype = {
  signatures: PropTypes.array.isRequired,
};

export default SignaturesTable;

import {
  ADD_CUSTOMER_PROPERTY,
  DELETE_CUSTOMER_PROPERTY,
  EDIT_CUSTOMER_PROPERTY,
  GET_CUSTOMER_PROPERTIES,
  SET_CUSTOMER_PROPERTY_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  customerProperties: [],
  currentCustomerProperty: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PROPERTIES: {
      return {
        ...state,
        customerProperties: action.payload,
      };
    }
    case SET_CUSTOMER_PROPERTY_DETAILS: {
      return {
        ...state,
        currentCustomerProperty: action.payload,
      };
    }
    case ADD_CUSTOMER_PROPERTY: {
      return {
        ...state,
        customerProperties: [action.payload, ...state.customerProperties],
      };
    }
    case EDIT_CUSTOMER_PROPERTY: {
      return {
        ...state,
        customerProperties: state.customerProperties.map(customerProperty =>
          customerProperty.id === action.payload.id ? action.payload : customerProperty,
        ),
      };
    }
    case DELETE_CUSTOMER_PROPERTY: {
      return {
        ...state,
        customerProperties: state.customerProperties.filter(customerProperty => customerProperty.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

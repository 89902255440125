import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
/* import Button from '@material-ui/core/Button'; */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
/* import DescriptionIcon from '@material-ui/icons/Description'; */

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { useDebounce } from '../../@jumbo/utils/commonHelper';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { ALL_ESTIMATES_STATUS_ID, ESTIMATE_KEYS, ESTIMATE_STATUS_KEYS } from '../../@jumbo/constants/AppDataConstants';

import CmtSearch from '../../@coremat/CmtSearch';

import { getFilteredEstimates } from '../../redux/actions/Estimates';
import { getEstimateStatusList } from '../../redux/actions/EstimateStatuses';

import EstimatesHeader from './EstimatesHeader';
import EstimatesTableHead from './EstimatesTableHead';
import EstimatesListRow from './EstimatesListRow';
/* import BulkStatusUpdateDialog from './BulkStatusUpdateDialog';
import BulkStatusDeleteDialog from './BulkStatusDeleteDialog'; */
import AddNewEstimateDialog from './AddNewEstimateDialog';
import NoRecordFound from '../NoRecordFound';
import SweetAlert from '../UI/Alert';

import useStyles from './index.style';

const pageName = 'Estimates';
const breadcrumbs = [{ label: pageName, isActive: true }];

const allHeadCells = [
  { id: ESTIMATE_KEYS.ID, label: 'Estimate', sortColumn: false, width: '12%' },
  { id: ESTIMATE_KEYS.CUSTOMER_NAME, label: 'Customer', sortColumn: false, width: '20%' },
  { id: ESTIMATE_KEYS.FORMATTED_CREATED, label: 'Created', sortColumn: false, width: '11%' },
  { id: ESTIMATE_KEYS.AMOUNT, label: 'Amount', sortColumn: false, width: '15%' },
  { id: ESTIMATE_KEYS.STATUS, label: 'Status', sortColumn: false, width: '25%' },
  { id: ESTIMATE_KEYS.JOB_ID, label: 'Job', sortColumn: false, width: '12%' },
];

let colspan = allHeadCells.length;
colspan = colspan > 0 ? colspan : 1;

const Estimates = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { estimates, estimateCounts } = useSelector(({ estimatesReducer }) => estimatesReducer);
  const { estimateStatuses } = useSelector(({ estimateStatusesReducer }) => estimateStatusesReducer);

  /* const [selected, setSelected] = useState([]); */
  const [estimateStatusFetching, setEstimateStatusFetching] = useState(true);
  const [estimateStatusFetched, setEstimateStatusFetched] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(ALL_ESTIMATES_STATUS_ID);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ orderBy: ESTIMATE_KEYS.ID, order: 'asc' });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const [estimatesFetched, setEstimatesFetched] = useState(false);
  const [estimatesExportFetched, setEstimatesExportFetched] = useState(true);
  const [exportCSV, setExportCSV] = useState('0');
  const [fetchCriteria, setFetchCriteria] = useState(null);
  const [estimatesFilterChanged, setEstimatesFilterChanged] = useState(false);
  /* const [openBulkStatusUpdateDialog, setOpenBulkStatusUpdateDialog] = useState(false);
  const [openBulkStatusDeleteDialog, setOpenBulkStatusDeleteDialog] = useState(false); */
  const [openAddEstimateDialog, setOpenAddEstimateDialog] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getEstimateStatusList(payload));
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise
      .then(() => {
        if (isActive) {
          setEstimateStatusFetched(true);
          setEstimateStatusFetching(false);
        }
      })
      .catch(error => {
        if (isActive) {
          setEstimateStatusFetched(false);
          setEstimateStatusFetching(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    setFetchCriteria({
      status: selectedStatus,
      search: debouncedSearchTerm,
      order: sort.order,
      orderBy: sort.orderBy,
      exportCSV: '0',
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
    });

    if (!estimatesFilterChanged) {
      setEstimatesFetched(false);
    } else {
      setPagination(prevState => {
        return { ...prevState, page: 0 };
      });
      setEstimatesFilterChanged(false);
    }
  }, [selectedStatus, debouncedSearchTerm, sort, pagination, estimatesFilterChanged]);

  useEffect(() => {
    if (exportCSV === '1') {
      setFetchCriteria(prevState => {
        return {
          ...prevState,
          exportCSV: '1',
        };
      });
      setEstimatesExportFetched(false);
    }
  }, [exportCSV]);

  useEffect(() => {
    let isActive = true;

    /**
     * Make sure that a status is always present in the filter
     * since state updates can take time
     * (Check the above code to set selectedStatus and filters)
     */
    if (estimateStatusFetched && (!estimatesFetched || !estimatesExportFetched) && fetchCriteria && fetchCriteria.status) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getFilteredEstimates(payload, (fetchedEstimates, exportLink) => {
              if (isActive) {
                if (payload.estimateData.export_list === '1' && exportLink) {
                  SweetAlert({
                    icon: 'success',
                    title: 'Your export is ready!!',
                    html: `Click <a href="${exportLink}" target="_blank" download class="${classes.downloadLink}">here</a> to download your export`,
                  });
                }
                setEstimatesFetched(true);
                setEstimatesExportFetched(true);
                setExportCSV('0');
              }
            }),
          );
        };
      };

      const estimateData = {
        authcode: authUser.authcode,
        filters: {
          status_id: fetchCriteria.status,
        },
        search: fetchCriteria.search,
        order: fetchCriteria.order,
        order_by: fetchCriteria.orderBy,
        export_list: fetchCriteria.exportCSV,
        page: fetchCriteria.page + 1,
        rows_per_page: fetchCriteria.rowsPerPage,
      };
      const promise = dispatch(fetchData({ estimateData: estimateData }));
      promise.catch(error => {
        /* Setting to 'true' means API has been executed, not necessarily successfully */
        if (isActive) {
          setEstimatesFetched(true);
          setEstimatesExportFetched(true);
          setExportCSV('0');
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [
    dispatch,
    authUser.authcode,
    estimateStatusFetched,
    estimatesFetched,
    estimatesExportFetched,
    fetchCriteria,
    classes.downloadLink,
  ]);

  const handleSetFilterChanged = () => setEstimatesFilterChanged(true);

  /* const handleCloseBulkStatusUpdateDialog = () => setOpenBulkStatusUpdateDialog(false); */

  /* const handleCloseBulkStatusDeleteDialog = () => setOpenBulkStatusDeleteDialog(false); */

  const handleCloseAddEstimateDialog = () => setOpenAddEstimateDialog(false);

  const onSearchChipDelete = () => {
    setSearchTerm('');
    handleSetFilterChanged();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sort.orderBy === property && sort.order === 'asc';
    setSort(prevState => {
      return { ...prevState, orderBy: property, order: isAsc ? 'desc' : 'asc' };
    });
  };

  const handlePageChange = (event, newPage) => {
    setPagination(prevState => {
      return { ...prevState, page: newPage };
    });
  };

  const handleRowsPerPageChange = event => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleRowClick = (event, id) => history.push(`/estimate/${id}/`);

  /* const handleCheckboxClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  }; */

  /* const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = estimates.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }; */

  /* const handleStatusUpdate = id => {
    console.log(`Estimate ${id} Status Update`);
  }; */

  const addNewEstimate = () => setOpenAddEstimateDialog(true);

  /* const bulkStatusUpdate = () => {
    if (selected.length === 0) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'No estimate has been selected',
        customClass: { container: classes.deleteAlert },
      });
    } else {
      setOpenBulkStatusUpdateDialog(true);
    }
  }; */

  /* const bulkStatusDelete = () => {
    if (selected.length === 0) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'No estimate has been selected',
        customClass: { container: classes.deleteAlert },
      });
    } else {
      setOpenBulkStatusDeleteDialog(true);
    }
  }; */

  /* const handleExportEstimatesList = () => setExportCSV('1'); */

  /* const isSelected = id => selected.indexOf(id) !== -1; */

  const estimateStatusList = [
    {
      [ESTIMATE_STATUS_KEYS.ID]: ALL_ESTIMATES_STATUS_ID,
      [ESTIMATE_STATUS_KEYS.NAME]: 'All Status',
    },
    ...estimateStatuses,
  ];

  const estimateCountNonZero =
    estimateCounts &&
    estimateCounts[ESTIMATE_KEYS.TOTAL] &&
    !isNaN(parseInt(estimateCounts[ESTIMATE_KEYS.TOTAL])) &&
    parseInt(estimateCounts[ESTIMATE_KEYS.TOTAL]) > 0;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      {!estimateStatusFetching && estimateStatusFetched && estimateStatuses && estimateStatuses.length > 0 && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12}>
              <EstimatesHeader
                estimateStatusList={estimateStatusList}
                selectedStatus={selectedStatus}
                /* numEstimatesSelected={selected ? selected.length : 0} */
                setSelectedStatus={setSelectedStatus}
                onAddNewEstimate={addNewEstimate}
                /* onBulkStatusUpdate={bulkStatusUpdate} */
                /* onBulkStatusDelete={bulkStatusDelete} */
                handleSetFilterChanged={handleSetFilterChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Toolbar className={classes.toolbarRoot}>
                  <CmtSearch
                    onChange={e => {
                      setSearchTerm(e.target.value);
                      handleSetFilterChanged();
                    }}
                    value={searchTerm}
                    border={false}
                    onlyIcon
                  />
                  <div className={classes.chipsRoot}>
                    {searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}
                  </div>
                  {/* <Button
                    type="button"
                    variant="outlined"
                    startIcon={<DescriptionIcon />}
                    onClick={handleExportEstimatesList}>
                    Export
                  </Button> */}
                </Toolbar>
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="estimatesTableTitle"
                    aria-label="Estimates Table">
                    <EstimatesTableHead
                      headCells={allHeadCells}
                      classes={classes}
                      order={sort.order}
                      orderBy={sort.orderBy}
                      /* numSelected={selected.length} */
                      rowCount={estimates.length}
                      onRequestSort={handleRequestSort}
                      /* onSelectAllClick={handleSelectAllClick} */
                    />
                    <TableBody>
                      {!!estimates.length ? (
                        estimates.map((row, index) => (
                          <EstimatesListRow
                            key={index}
                            row={row}
                            /* isSelected={isSelected} */
                            onRowClick={handleRowClick}
                            /* onCheckboxClick={handleCheckboxClick} */
                            /* onUpdateStatus={handleStatusUpdate} */
                          />
                        ))
                      ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={colspan} rowSpan={10}>
                            <NoRecordFound>
                              {estimatesFetched ? 'There are no records found.' : 'Loading estimates...'}
                            </NoRecordFound>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={estimateCountNonZero ? parseInt(estimateCounts[ESTIMATE_KEYS.TOTAL]) : 0}
                  rowsPerPage={pagination.rowsPerPage}
                  labelRowsPerPage=""
                  page={pagination.page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Paper>
            </Grid>
          </GridContainer>

          {/* openBulkStatusUpdateDialog && (
            <BulkStatusUpdateDialog
              open={openBulkStatusUpdateDialog}
              onClose={handleCloseBulkStatusUpdateDialog}
              selectedEstimates={selected}
            />
          ) */}

          {/* openBulkStatusDeleteDialog && (
            <BulkStatusDeleteDialog
              open={openBulkStatusDeleteDialog}
              onClose={handleCloseBulkStatusDeleteDialog}
              selectedEstimates={selected}
            />
          ) */}

          {openAddEstimateDialog && (
            <AddNewEstimateDialog open={openAddEstimateDialog} onClose={handleCloseAddEstimateDialog} />
          )}
        </React.Fragment>
      )}

      {!estimateStatusFetching && (!estimateStatusFetched || !estimateStatuses || estimateStatuses.length === 0) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Status data not found</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default Estimates;

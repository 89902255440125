import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import { PROPERTY_KEYS, CUSTOMER_PROPERTIES_TAB } from '../../../@jumbo/constants/AppDataConstants';

import { setCurrentCustomerProperty } from '../../../redux/actions/CustomerProperties';

import EditCustomerTableHead from '../EditCustomerTableHead';
import EditCustomerTableToolbar from '../EditCustomerTableToolbar';
import EditAddressDialog from '../EditAddressDialog';
import NoRecordFound from '../../NoRecordFound';
import Alert from '../../UI/Alert';

import useStyles from '../customerTable.style';

const headCells = [
  { id: PROPERTY_KEYS.NAME, disablePadding: false, label: 'Name', sortColumn: true },
  { id: PROPERTY_KEYS.ADDRESS_ONE, disablePadding: false, label: 'Address', sortColumn: true },
  { id: PROPERTY_KEYS.CITY, disablePadding: false, label: 'City', sortColumn: true },
  { id: PROPERTY_KEYS.STATE, disablePadding: false, label: 'State', sortColumn: true },
  { id: PROPERTY_KEYS.ZIP_CODE, disablePadding: false, label: 'Zip Code', sortColumn: true },
  /* { id: PROPERTY_KEYS.JOBS, disablePadding: false, label: 'Jobs', sortColumn: true },
  { id: PROPERTY_KEYS.TOTAL, disablePadding: false, label: 'Total', sortColumn: true },
  { id: PROPERTY_KEYS.DUE, disablePadding: false, label: 'Due', sortColumn: true },
  { id: PROPERTY_KEYS.PAST_DUE, disablePadding: false, label: 'Past Due', sortColumn: true }, */
];

const colspan = headCells && headCells.length > 0 ? headCells.length : 1;

const CustomerProperties = ({ expanded, handleAccordionExpand }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { customerProperties } = useSelector(({ customerPropertiesReducer }) => customerPropertiesReducer);

  const [filteredProperties, setFilteredProperties] = useState([]);
  const [orderBy, setOrderBy] = useState(PROPERTY_KEYS.NAME);
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (customerProperties) {
      let tempProperties = customerProperties;

      if (debouncedSearchTerm) {
        tempProperties = tempProperties.filter(tempProperty => {
          return (
            (tempProperty[PROPERTY_KEYS.NAME] &&
              tempProperty[PROPERTY_KEYS.NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.ADDRESS_ONE] &&
              tempProperty[PROPERTY_KEYS.ADDRESS_ONE].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.CITY] &&
              tempProperty[PROPERTY_KEYS.CITY].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.STATE_NAME] &&
              tempProperty[PROPERTY_KEYS.STATE_NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.ZIP_CODE] &&
              tempProperty[PROPERTY_KEYS.ZIP_CODE]
                .toLowerCase()
                .includes(
                  debouncedSearchTerm.toLowerCase(),
                )) /*  ||
            (tempProperty[PROPERTY_KEYS.JOBS] &&
              tempProperty[PROPERTY_KEYS.JOBS].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.TOTAL] &&
              tempProperty[PROPERTY_KEYS.TOTAL].toString().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.DUE] &&
              tempProperty[PROPERTY_KEYS.DUE].toString().includes(debouncedSearchTerm.toLowerCase())) ||
            (tempProperty[PROPERTY_KEYS.PAST_DUE] &&
              tempProperty[PROPERTY_KEYS.PAST_DUE].toString().includes(debouncedSearchTerm.toLowerCase())) */
          );
        });
      }

      setFilteredProperties(tempProperties);
      setFilterApplied(!!debouncedSearchTerm);
    }
  }, [customerProperties, debouncedSearchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = property => {
    if (property) {
      dispatch(setCurrentCustomerProperty(property));
      setOpenAddressDialog(true);
    } else {
      Alert({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid property',
      });
    }
  };

  const handleOpenAddressDialog = () => {
    dispatch(setCurrentCustomerProperty(null));
    setOpenAddressDialog(true);
  };

  const handleCloseAddressDialog = () => {
    setOpenAddressDialog(false);
  };

  const handleSearch = searchTerm => setSearchTerm(searchTerm);

  const handleSearchChipDelete = () => setSearchTerm('');

  return (
    <Accordion classes={{ root: classes.accordion }} expanded={expanded} onClick={handleAccordionExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="properties-content" id="properties-header">
        <Typography className={classes.accordionHeading}>Properties</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }} onClick={e => e.stopPropagation()}>
        <EditCustomerTableToolbar
          type="property"
          searchTerm={searchTerm}
          onSearch={handleSearch}
          onSearchChipDelete={handleSearchChipDelete}
          onOpenAddressDialog={handleOpenAddressDialog}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="customerPropertiesTableTitle"
            aria-label="Customer Properties Table">
            <EditCustomerTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />

            <TableBody>
              {!!filteredProperties.length ? (
                stableSort(filteredProperties, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        classes={{
                          hover: classes.tableRow,
                        }}
                        onClick={() => handleRowClick(row)}>
                        <TableCell component="th" id={`property-${row[PROPERTY_KEYS.ID]}`} scope="row">
                          {row[PROPERTY_KEYS.NAME]}
                        </TableCell>
                        <TableCell>{row[PROPERTY_KEYS.ADDRESS_ONE]}</TableCell>
                        <TableCell>{row[PROPERTY_KEYS.CITY]}</TableCell>
                        <TableCell>{row[PROPERTY_KEYS.STATE_NAME]}</TableCell>
                        <TableCell>{row[PROPERTY_KEYS.ZIP_CODE]}</TableCell>
                        {/* <TableCell>{row[PROPERTY_KEYS.JOBS]}</TableCell>
                        <TableCell>{row[PROPERTY_KEYS.TOTAL]}</TableCell>
                        <TableCell>{row[PROPERTY_KEYS.DUE]}</TableCell>
                        <TableCell>{row[PROPERTY_KEYS.PAST_DUE]}</TableCell> */}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={colspan} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                    ) : (
                      <NoRecordFound>There are no records found</NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={filteredProperties.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage=""
          page={page}
          classes={{ root: classes.pagination }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        {openAddressDialog && (
          <EditAddressDialog tab={CUSTOMER_PROPERTIES_TAB} open={openAddressDialog} onClose={handleCloseAddressDialog} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

CustomerProperties.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleAccordionExpand: PropTypes.func.isRequired,
};

export default CustomerProperties;

import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from 'axios';
import {
  /* ADD_SQUAD_USER, */
  /* DELETE_SQUAD_USER, */
  GET_SQUAD_USERS,
  GET_SQUAD_USERS_FILTERS,
  SET_SQUAD_USER_DETAILS,
  SET_SQUAD_USER_INVITE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_SQUAD_USERS_LINK,
  GET_SQUAD_FILTERS_LINK,
  GET_SQUAD_USER_LINK,
  ADD_SQUAD_USER_LINK,
  UPDATE_SQUAD_USER_LINK,
  UPDATE_SQUAD_USER_STATUS_LINK,
  UPDATE_SQUAD_USER_PASSWORD_LINK,
  CHECK_SQUAD_USER_INVITE_LINK,
  USER_ACCEPT_INVITE_LINK,
  USER_RESEND_INVITE_LINK,
  DELETE_SQUAD_USER_LINK,
} from '../../@jumbo/constants/APIConstants';
import { ROLE_KEYS, USER_TYPE_KEYS, /* METRO_AREA_KEYS, */ SQUAD_USER_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getSquadUsers = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_SQUAD_USERS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.users) {
              const squadUsers = data.users;
              dispatch(fetchSuccess());
              dispatch({ type: GET_SQUAD_USERS, payload: squadUsers });
              if (callbackFun) callbackFun(squadUsers);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getSquadFilters = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_SQUAD_FILTERS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.user_filter) {
              const squadFilter = data.user_filter;
              let filterGroups = [];
              if (squadFilter.status) {
                const statusFilter = squadFilter.status;
                for (const statusName in statusFilter) {
                  const statusFilterId =
                    typeof statusFilter[statusName] === 'number'
                      ? statusFilter[statusName].toString()
                      : statusFilter[statusName];
                  filterGroups.push({
                    id: statusFilterId,
                    label: statusName,
                    group: 'Status',
                    field: SQUAD_USER_KEYS.STATUS,
                  });
                }
                if (filterGroups.length > 0) {
                  filterGroups.push({
                    id: 'all',
                    label: 'All',
                    group: 'Status',
                    field: SQUAD_USER_KEYS.STATUS,
                  });
                }
              }
              if (squadFilter.roles && squadFilter.roles.length > 0) {
                const rolesFilter = squadFilter.roles;
                rolesFilter.forEach(roleFilter => {
                  filterGroups.push({
                    id: roleFilter[ROLE_KEYS.ID],
                    label: roleFilter[ROLE_KEYS.NAME],
                    group: 'Role',
                    field: SQUAD_USER_KEYS.ROLE,
                  });
                });
              }
              if (squadFilter.user_types && squadFilter.user_types.length > 0) {
                const userTypesFilter = squadFilter.user_types;
                userTypesFilter.forEach(userTypeFilter => {
                  filterGroups.push({
                    id: userTypeFilter[USER_TYPE_KEYS.ID],
                    label: userTypeFilter[USER_TYPE_KEYS.NAME],
                    group: 'User Type',
                    field: SQUAD_USER_KEYS.TYPE,
                  });
                });
              }
              /* if (squadFilter.metroareas && squadFilter.metroareas.length > 0) {
                const metroAreasFilter = squadFilter.metroareas;
                metroAreasFilter.forEach(metroAreaFilter => {
                  filterGroups.push({
                    id: metroAreaFilter[METRO_AREA_KEYS.ID],
                    label: metroAreaFilter[METRO_AREA_KEYS.AREA_NAME],
                    group: 'Metro Area',
                    field: SQUAD_USER_KEYS.AREAS,
                  });
                });
              } */
              dispatch(fetchSuccess());
              dispatch({ type: GET_SQUAD_USERS_FILTERS, payload: filterGroups });
              if (callbackFun) callbackFun(squadFilter);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch({ type: GET_SQUAD_USERS_FILTERS, payload: null });
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getSquadUser = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
      user_id: request.userId,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    return axios
      .post(GET_SQUAD_USER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.user) {
              const squadUser = data.user;
              dispatch(fetchSuccess());
              dispatch({ type: SET_SQUAD_USER_DETAILS, payload: squadUser });
              if (callbackFun) callbackFun(squadUser);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch({ type: SET_SQUAD_USER_DETAILS, payload: null });
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const addNewSquadUser = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_SQUAD_USER_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.user) {
              dispatch(fetchSuccess('User was added successfully.'));
              /* dispatch({ type: ADD_SQUAD_USER, payload: data.user }); */
              if (callbackFun) callbackFun(responseData);
            } else {
              dispatch(fetchError('Unable to get data'));
            }
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const setCurrentSquadUser = squadUser => {
  return dispatch => {
    dispatch({ type: SET_SQUAD_USER_DETAILS, payload: squadUser });
  };
};

export const updateSquadUser = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.userData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_SQUAD_USER_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('User was updated successfully.'));
            if (callbackFun) callbackFun();
          } else {
            const errorMsg = responseData.message ? responseData.message : 'There was a problem.';
            dispatch(fetchError(errorMsg));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateSquadUserStatus = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.userData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_SQUAD_USER_STATUS_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('User was updated successfully.'));
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateSquadUserPassword = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.userData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_SQUAD_USER_PASSWORD_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('User was updated successfully.'));
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getSquadInviteUser = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.inviteData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    return axios
      .post(CHECK_SQUAD_USER_INVITE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let errors = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.user) {
              const squadUser = data.user;
              dispatch(fetchSuccess());
              dispatch({ type: SET_SQUAD_USER_INVITE_DETAILS, payload: squadUser });
              if (callbackFun) callbackFun(squadUser);
            } else {
              dispatch(fetchError('Unable to get data'));
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          if (errorCallbackFun && errors) errorCallbackFun(errors);
          dispatch({ type: SET_SQUAD_USER_INVITE_DETAILS, payload: null });
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentSquadInviteUser = squadUser => {
  return dispatch => {
    dispatch({ type: SET_SQUAD_USER_INVITE_DETAILS, payload: squadUser });
  };
};

export const userAcceptInvite = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.userData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(USER_ACCEPT_INVITE_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('User was updated successfully.'));
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const userResendInvite = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.userData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(USER_RESEND_INVITE_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Invite was sent successfully.'));
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

/* export const sentMailToSquadUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to squadUser successfully'));
  };
}; */

export const deleteSquadUser = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.userData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_SQUAD_USER_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess());
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

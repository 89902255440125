import React /* , { useState } */ from 'react';
/* import { useSelector } from 'react-redux'; */
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import GridContainer from '../../../@jumbo/components/GridContainer';
/* import { CUSTOMER_DUES_KEYS } from '../../../@jumbo/constants/AppDataConstants'; */

import HeaderCard from '../../UI/HeaderCard';
/* import AddCustomerDialog from '../AddCustomerDialog'; */

const CustomersHeader = () => {
  /* const { dues } = useSelector(({ customersReducer }) => customersReducer); */

  /* const [openAddCustomerDialog, setOpenAddCustomerDialog] = useState(false);

  const handleOpenAddCustomerDialog = () => setOpenAddCustomerDialog(true);
  const handleCloseAddCustomerDialog = () => setOpenAddCustomerDialog(false); */

  const headerCardsList = [
    /* {
      title: dues[CUSTOMER_DUES_KEYS.TOTAL_CLIENTS] ? dues[CUSTOMER_DUES_KEYS.TOTAL_CLIENTS] : '',
      subtitle: 'Customers',
      isAmount: false,
      classes: 'primaryBorder',
    },
    {
      title: dues[CUSTOMER_DUES_KEYS.DUE_CLIENTS] ? dues[CUSTOMER_DUES_KEYS.DUE_CLIENTS] : '',
      subtitle: `Due from ${
        dues[CUSTOMER_DUES_KEYS.DUE_CLIENTS_COUNT] ? dues[CUSTOMER_DUES_KEYS.DUE_CLIENTS_COUNT] : ''
      } customers`,
      isAmount: true,
      classes: 'warningBorder',
    },
    {
      title: dues[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS] ? dues[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS] : '',
      subtitle: `Past Due from ${
        dues[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS_COUNT] ? dues[CUSTOMER_DUES_KEYS.PAST_DUE_CLIENTS_COUNT] : ''
      } customers`,
      isAmount: true,
      classes: 'errorBorder',
    },
    {
      title: dues[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING] ? dues[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING] : '',
      subtitle: `Estimates pending $(${
        dues[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING_COUNT] ? dues[CUSTOMER_DUES_KEYS.ESTIMATE_PENDING_COUNT] : ''
      })`,
      isAmount: false,
      classes: 'primaryBorder',
    }, */
  ];

  return (
    <Box>
      <GridContainer>
        {headerCardsList.map((headerCard, idx) => (
          <Grid item xs={12} md={3} key={`${headerCard.subtitle}-${idx}`}>
            <HeaderCard
              titleIsAmount={headerCard.isAmount}
              title={headerCard.title}
              subtitle={headerCard.subtitle}
              cardClass={headerCard.classes}
            />
          </Grid>
        ))}
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {/* <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddCustomerDialog}>
            Add New
          </Button> */}
          <Button component={Link} to="/newCustomer" variant="contained" color="primary" startIcon={<AddIcon />}>
            Add New
          </Button>
        </Grid>
      </GridContainer>

      {/* openAddCustomerDialog && <AddCustomerDialog open={openAddCustomerDialog} onClose={handleCloseAddCustomerDialog} /> */}
    </Box>
  );
};

export default CustomersHeader;

import { GET_USA_STATES } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  usaStates: [],
  /* currentUSAState: null, */
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USA_STATES: {
      return {
        ...state,
        usaStates: action.payload,
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import { ESTIMATE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '20vw',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '10vw',
    },
  },
  subHeading: {
    padding: theme.spacing(2),
  },
}));

const TextOnlyTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const CustomerInfoSection = () => {
  const classes = useStyles();

  const { currentEstimate, currentEstimateCustomer } = useSelector(({ estimatesReducer }) => estimatesReducer);

  const [bill, setBill] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (currentEstimateCustomer) {
      setBill(currentEstimateCustomer[ESTIMATE_KEYS.CUSTOMER_BILL]);
      setService(currentEstimateCustomer[ESTIMATE_KEYS.CUSTOMER_SERVICE]);
    }
  }, [currentEstimateCustomer]);

  const jobId = currentEstimate ? currentEstimate[ESTIMATE_KEYS.JOB_ID] : '';

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          <CardContent>
            <Typography component={Link} to={`/job/${jobId}/`} variant="h2" className={classes.subHeading}>
              Job #{jobId}
            </Typography>
            <Typography variant="h3" className={classes.subHeading}>
              Estimate No. {jobId} - 1
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          {bill && (
            <TextOnlyTooltip
              title={
                <React.Fragment>
                  <p>{bill[ESTIMATE_KEYS.NAME]}</p>
                  <p>{bill[ESTIMATE_KEYS.CUSTOMER_COMPANY]}</p>
                  <p>{bill[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]}</p>
                  <p>{bill[ESTIMATE_KEYS.CUSTOMER_EMAIL]}</p>
                </React.Fragment>
              }
              placement="top">
              <CardContent>
                <Typography variant="h3">Customer Details</Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[ESTIMATE_KEYS.NAME]}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[ESTIMATE_KEYS.CUSTOMER_COMPANY]}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  <NumberFormat
                    isNumericString
                    displayType="text"
                    value={bill[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]}
                    format="(###) ###-####"
                  />
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[ESTIMATE_KEYS.CUSTOMER_EMAIL]}
                </Typography>
              </CardContent>
            </TextOnlyTooltip>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          {service && (
            <TextOnlyTooltip
              title={
                <React.Fragment>
                  <p>{service[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]}</p>
                  <p>{service[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]}</p>
                  <p>{service[ESTIMATE_KEYS.CUSTOMER_CITY]}</p>
                  <p>
                    {service[ESTIMATE_KEYS.CUSTOMER_STATE]} {service[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]}
                  </p>
                </React.Fragment>
              }
              placement="top">
              <CardContent>
                <Typography variant="h3">Service Address</Typography>
                <Typography variant="body1" display="block" noWrap>
                  {service[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]}
                </Typography>
                {service[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO] && (
                  <Typography variant="body1" display="block" noWrap>
                    {service[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]}
                  </Typography>
                )}
                <Typography variant="body1" display="block" noWrap>
                  {service[ESTIMATE_KEYS.CUSTOMER_CITY]}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {service[ESTIMATE_KEYS.CUSTOMER_STATE]} {service[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]}
                </Typography>
              </CardContent>
            </TextOnlyTooltip>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card className={classes.card}>
          {bill && (
            <TextOnlyTooltip
              title={
                <React.Fragment>
                  <p>{bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]}</p>
                  <p>{bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]}</p>
                  <p>{bill[ESTIMATE_KEYS.CUSTOMER_CITY]}</p>
                  <p>
                    {bill[ESTIMATE_KEYS.CUSTOMER_STATE]} {bill[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]}
                  </p>
                </React.Fragment>
              }
              placement="top">
              <CardContent>
                <Typography variant="h3">Billing Address</Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]}
                </Typography>
                {bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO] && (
                  <Typography variant="body1" display="block" noWrap>
                    {bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]}
                  </Typography>
                )}
                <Typography variant="body1" display="block" noWrap>
                  {bill[ESTIMATE_KEYS.CUSTOMER_CITY]}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[ESTIMATE_KEYS.CUSTOMER_STATE]} {bill[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]}
                </Typography>
              </CardContent>
            </TextOnlyTooltip>
          )}
        </Card>
      </Grid>
    </GridContainer>
  );
};

export default CustomerInfoSection;

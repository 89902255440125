import {
  ADD_TAX,
  EDIT_TAX,
  GET_TAXES,
  SET_TAX_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  taxes: [],
  currentTax: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAXES: {
      return {
        ...state,
        taxes: action.payload,
      };
    }
    case SET_TAX_DETAILS: {
      return {
        ...state,
        currentTax: action.payload,
      };
    }
    case ADD_TAX: {
      return {
        ...state,
        taxes: [action.payload, ...state.taxes],
      };
    }
    case EDIT_TAX: {
      return {
        ...state,
        taxes: state.taxes.map(tax => (tax.id === action.payload.id ? action.payload : tax)),
      };
    }
    default:
      return state;
  }
};

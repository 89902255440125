import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtCard from '../../../@coremat/CmtCard';

import { ESTIMATE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  previewContainer: {
    [theme.breakpoints.up('md')]: {
      height: '80vh',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EstimatePreviewDialog = ({ open, estimate, onClose, link }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      className={classes.dialogRoot}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Estimate Preview
          </Typography>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers className={classes.previewContainer}>
        <CmtCard style={{ height: '100%' }}>
          <iframe
            src={`${link}#view=fitH`}
            title={`Estimate ${estimate[ESTIMATE_KEYS.ID]} Preview`}
            height="100%"
            width="100%"
          />
        </CmtCard>
      </DialogContent>
    </Dialog>
  );
};

EstimatePreviewDialog.prototype = {
  open: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  estimate: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EstimatePreviewDialog;

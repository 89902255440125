import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import AlertText from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import {
  AD_SOURCES_KEYS,
  CUSTOMER_KEYS,
  CUSTOMER_NOTE_KEYS,
  PROPERTY_KEYS,
  USA_STATE_KEYS,
  COUNTRY_KEYS,
  CUSTOMER_INFO_TAB,
  CUSTOMER_MAIN_ADDRESS,
  CUSTOMER_BILL_ADDRESS,
} from '../../../@jumbo/constants/AppDataConstants';
import { emailNotValid, requiredMessage, phoneNotValid } from '../../../@jumbo/constants/ErrorMessages';

import { updateCustomer } from '../../../redux/actions/Customers';
import { setCurrentCustomerNote, deleteCustomerNote } from '../../../redux/actions/CustomerNotes';
import { setCurrentCustomerProperty } from '../../../redux/actions/CustomerProperties';
import { isValidPhoneNumber } from '../../../utils/FormValidation';
import { getAddressString, getItemObjectNameFromId } from '../../../utils/Helpers';

import EditAddressDialog from '../EditAddressDialog';
import NoteDialog from '../NoteDialog';
import Alert from '../../UI/Alert';
import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

const useStyles = makeStyles(theme => ({
  accordion: {
    padding: theme.spacing(2),
  },
  accordionHeading: {
    ...theme.overrides.MuiTypography.h3,
  },
  addressBtn: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.borderColor.dark}`,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  addressBtnLabel: {
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  inline: {
    display: 'inline',
  },
  note: {
    flexWrap: 'wrap',
  },
  noteItem: {
    width: '100%',
  },
  noteAction: {
    flex: '1 1 auto',
  },
}));

const CustomerInfo = ({ expanded, handleAccordionExpand }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentCustomer } = useSelector(({ customersReducer }) => customersReducer);
  const { customerNotes } = useSelector(({ customerNotesReducer }) => customerNotesReducer);
  const { customerProperties } = useSelector(({ customerPropertiesReducer }) => customerPropertiesReducer);
  const { countries } = useSelector(({ countriesReducer }) => countriesReducer);
  const { usaStates } = useSelector(({ usaStatesReducer }) => usaStatesReducer);
  const { adSources } = useSelector(({ adSourcesReducer }) => adSourcesReducer);
  const { authUser } = useSelector(({ auth }) => auth);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneOneError, setPhoneOneError] = useState('');
  const [extensionOne, setExtensionOne] = useState('');
  const [extensionOneError, setExtensionOneError] = useState('');
  const [phoneTwo, setPhoneTwo] = useState('');
  const [phoneTwoError, setPhoneTwoError] = useState('');
  const [extensionTwo, setExtensionTwo] = useState('');
  const [extensionTwoError, setExtensionTwoError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [adSource, setAdSource] = useState('');
  const [adSourceError, setAdSourceError] = useState('');
  const [mainAddress, setMainAddress] = useState('');
  const [mainAddressText, setMainAddressText] = useState('');
  const [billAddress, setBillAddress] = useState('');
  const [billAddressText, setBillAddressText] = useState('');
  const [notes, setNotes] = useState(null);
  const [addressType, setAddressType] = useState('');
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (currentCustomer) {
      const mainAddress = currentCustomer[CUSTOMER_KEYS.MAIN_ADDRESS];
      const billAddress = currentCustomer[CUSTOMER_KEYS.BILL_ADDRESS];

      setFirstName(currentCustomer[CUSTOMER_KEYS.FIRST_NAME]);
      setLastName(currentCustomer[CUSTOMER_KEYS.LAST_NAME]);
      setCompanyName(currentCustomer[CUSTOMER_KEYS.COMPANY_NAME]);
      setEmail(currentCustomer[CUSTOMER_KEYS.EMAIL]);
      setPhoneOne(currentCustomer[CUSTOMER_KEYS.PHONE_ONE]);
      setExtensionOne(currentCustomer[CUSTOMER_KEYS.EXTENSION_ONE]);
      setPhoneTwo(currentCustomer[CUSTOMER_KEYS.PHONE_TWO]);
      setExtensionTwo(currentCustomer[CUSTOMER_KEYS.EXTENSION_TWO]);
      setAdSource(currentCustomer[CUSTOMER_KEYS.AD_SOURCE]);
      setMainAddress(mainAddress);
      setBillAddress(billAddress);

      if (customerProperties) {
        const mainAddressData = customerProperties.find(
          customerProperty => mainAddress === customerProperty[PROPERTY_KEYS.ID],
        );

        if (mainAddressData) {
          const stateName = getItemObjectNameFromId(
            usaStates,
            mainAddressData[PROPERTY_KEYS.STATE],
            USA_STATE_KEYS.ID,
            USA_STATE_KEYS.NAME,
          );

          const countryName = getItemObjectNameFromId(
            countries,
            mainAddressData[PROPERTY_KEYS.COUNTRY],
            COUNTRY_KEYS.ID,
            COUNTRY_KEYS.NAME,
          );

          const tempMainAddressText = getAddressString({
            addressOne: mainAddressData[PROPERTY_KEYS.ADDRESS_ONE],
            addressTwo: mainAddressData[PROPERTY_KEYS.ADDRESS_TWO],
            city: mainAddressData[PROPERTY_KEYS.CITY],
            state: stateName,
            zipcode: mainAddressData[PROPERTY_KEYS.ZIP_CODE],
            country: countryName,
          });

          setMainAddressText(tempMainAddressText);
        }

        const billAddressData = customerProperties.find(
          customerProperty => billAddress === customerProperty[PROPERTY_KEYS.ID],
        );
        if (billAddressData) {
          const stateName = getItemObjectNameFromId(
            usaStates,
            billAddressData[PROPERTY_KEYS.STATE],
            USA_STATE_KEYS.ID,
            USA_STATE_KEYS.NAME,
          );

          const countryName = getItemObjectNameFromId(
            countries,
            billAddressData[PROPERTY_KEYS.COUNTRY],
            COUNTRY_KEYS.ID,
            COUNTRY_KEYS.NAME,
          );

          const tempBillAddressText = getAddressString({
            addressOne: billAddressData[PROPERTY_KEYS.ADDRESS_ONE],
            addressTwo: billAddressData[PROPERTY_KEYS.ADDRESS_TWO],
            city: billAddressData[PROPERTY_KEYS.CITY],
            state: stateName,
            zipcode: billAddressData[PROPERTY_KEYS.ZIP_CODE],
            country: countryName,
          });

          setBillAddressText(tempBillAddressText);
        }
      }
    }
  }, [currentCustomer, customerProperties, usaStates, countries]);

  useEffect(() => {
    if (customerNotes) {
      setNotes(customerNotes);
    }
  }, [customerNotes]);

  useEffect(() => {
    if (errorMessages) {
      setMiscellaneousErrors([]);
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case CUSTOMER_KEYS.FIRST_NAME:
            setFirstNameError(msg);
            break;

          case CUSTOMER_KEYS.LAST_NAME:
            setLastNameError(msg);
            break;

          case CUSTOMER_KEYS.EMAIL:
            setEmailError(msg);
            break;

          case CUSTOMER_KEYS.PHONE_ONE:
            setPhoneOneError(msg);
            break;

          case CUSTOMER_KEYS.EXTENSION_ONE:
            setExtensionOneError(msg);
            break;

          case CUSTOMER_KEYS.PHONE_TWO:
            setPhoneTwoError(msg);
            break;

          case CUSTOMER_KEYS.EXTENSION_TWO:
            setExtensionTwoError(msg);
            break;

          case CUSTOMER_KEYS.AD_SOURCE:
            setAdSourceError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const firstNameBlurHandler = event => {
    if (!event.target.value) {
      setFirstNameError(requiredMessage);
    }
  };

  const lastNameBlurHandler = event => {
    if (!event.target.value) {
      setLastNameError(requiredMessage);
    }
  };

  const companyNameBlurHandler = event => {
    /* if (!event.target.value) {
      setCompanyNameError(requiredMessage);
    } */
  };

  const phoneOneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneOneError(phoneNotValid);
    }
  };

  const extensionOneBlurHandler = event => {
    if (!event.target.value) {
      setExtensionOneError(requiredMessage);
    }
  };

  const phoneTwoBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneTwoError(phoneNotValid);
    }
  };

  const extensionTwoBlurHandler = event => {
    if (!event.target.value) {
      setExtensionTwoError(requiredMessage);
    }
  };

  const emailBlurHandler = event => {
    if (!event.target.value) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  };

  const adSourceChangeHandler = event => {
    setAdSource(event.target.value);
    setAdSourceError('');

    /* if (!event.target.value) {
      setAdSourceError(requiredMessage);
    } */
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;

    if (!currentCustomer || !currentCustomer[CUSTOMER_KEYS.ID]) {
      formIsValid = false;
    }

    if (!firstName) {
      formIsValid = false;
      setFirstNameError(requiredMessage);
    }

    if (!lastName) {
      formIsValid = false;
      setLastNameError(requiredMessage);
    }

    if (!email) {
      formIsValid = false;
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      formIsValid = false;
      setEmailError(emailNotValid);
    }

    if (!isValidPhoneNumber(phoneOne)) {
      formIsValid = false;
      setPhoneOneError(phoneNotValid);
    }

    if (!extensionOne) {
      formIsValid = false;
      setExtensionOneError(requiredMessage);
    }

    if (!isValidPhoneNumber(phoneTwo)) {
      formIsValid = false;
      setPhoneTwoError(phoneNotValid);
    }

    if (!extensionTwo) {
      formIsValid = false;
      setExtensionTwoError(requiredMessage);
    }

    if (formIsValid) {
      const customerData = {
        authcode: authUser.authcode,
        [CUSTOMER_KEYS.CUSTOMER_ID]: currentCustomer[CUSTOMER_KEYS.ID],
        [CUSTOMER_KEYS.FIRST_NAME]: firstName,
        [CUSTOMER_KEYS.LAST_NAME]: lastName,
        [CUSTOMER_KEYS.COMPANY_NAME]: companyName,
        [CUSTOMER_KEYS.EMAIL]: email,
        [CUSTOMER_KEYS.PHONE_ONE]: phoneOne,
        [CUSTOMER_KEYS.EXTENSION_ONE]: extensionOne,
        [CUSTOMER_KEYS.PHONE_TWO]: phoneTwo,
        [CUSTOMER_KEYS.EXTENSION_TWO]: extensionTwo,
        [CUSTOMER_KEYS.MAIN_ADDRESS]: mainAddress,
        [CUSTOMER_KEYS.BILL_ADDRESS]: billAddress,
        [CUSTOMER_KEYS.AD_SOURCE]: adSource,
      };

      dispatch(
        updateCustomer({ customerData: customerData }, null, messages => {
          if (_isMounted.current) setErrorMessages(messages);
        }),
      );
    }
  };

  const handleOpenAddressDialog = (type, propertyId) => {
    if (propertyId) {
      const property = customerProperties.find(customerProperty => customerProperty[PROPERTY_KEYS.ID] === propertyId);
      dispatch(setCurrentCustomerProperty(property));
    } else {
      setSelectedNote(null);
      dispatch(setCurrentCustomerProperty(null));
    }
    setAddressType(type);
    setOpenAddressDialog(true);
  };

  const handleCloseAddressDialog = () => {
    dispatch(setCurrentCustomerProperty(null));
    setOpenAddressDialog(false);
  };

  const handleOpenNoteDialog = () => setOpenNoteDialog(true);

  const handleCloseNoteDialog = () => {
    dispatch(setCurrentCustomerNote(null));
    setOpenNoteDialog(false);
  };

  const handleNoteEdit = note => {
    dispatch(setCurrentCustomerNote(note));
    setOpenNoteDialog(true);
  };

  const handleNoteDelete = note => {
    setSelectedNote(note);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);

    if (currentCustomer && selectedNote && selectedNote[CUSTOMER_NOTE_KEYS.ID]) {
      const customerNoteData = {
        authcode: authUser.authcode,
        [CUSTOMER_NOTE_KEYS.USER_ID]: authUser.id,
        [CUSTOMER_NOTE_KEYS.CUSTOMER_ID]: currentCustomer[CUSTOMER_KEYS.ID],
        [CUSTOMER_NOTE_KEYS.NOTE_ID]: selectedNote[CUSTOMER_NOTE_KEYS.ID],
      };

      dispatch(
        deleteCustomerNote(
          { customerNoteData: customerNoteData },
          () => {
            if (_isMounted.current) setSelectedNote(null);
          },
          () => {
            if (_isMounted.current) setSelectedNote(null);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        setSelectedNote(null);
        Alert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid note',
        });
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelectedNote(null);
  };

  return (
    <React.Fragment>
      <Accordion classes={{ root: classes.accordion }} expanded={expanded} onClick={handleAccordionExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="info-content" id="info-header">
          <Typography className={classes.accordionHeading}>Info</Typography>
        </AccordionSummary>
        <AccordionDetails onClick={e => e.stopPropagation()}>
          <form onSubmit={handleFormSubmit}>
            <GridContainer>
              <Grid item xs={12} md={6}>
                <GridContainer>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h6">
                      Customer Details
                    </Typography>
                  </Grid>
                  {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                    <Grid item xs={12}>
                      {miscellaneousErrors.map((miscellaneousError, idx) => (
                        <Typography
                          variant="caption"
                          display="block"
                          color="error"
                          gutterBottom
                          key={`customer-misc-error-${idx}`}>
                          {miscellaneousError}
                        </Typography>
                      ))}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <AppTextInput
                      type="text"
                      name="first_name"
                      variant="outlined"
                      label="First Name"
                      value={firstName}
                      onChange={e => {
                        setFirstName(e.target.value);
                        setFirstNameError('');
                      }}
                      onBlur={firstNameBlurHandler}
                      helperText={firstNameError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AppTextInput
                      type="text"
                      name="last_name"
                      variant="outlined"
                      label="Last Name"
                      value={lastName}
                      onChange={e => {
                        setLastName(e.target.value);
                        setLastNameError('');
                      }}
                      onBlur={lastNameBlurHandler}
                      helperText={lastNameError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AppTextInput
                      type="text"
                      name="company_name"
                      variant="outlined"
                      label="Company Name"
                      value={companyName}
                      onChange={e => {
                        setCompanyName(e.target.value);
                        setCompanyNameError('');
                      }}
                      onBlur={companyNameBlurHandler}
                      helperText={companyNameError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h6">
                      Contact Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} md={7}>
                    <AppTextInput
                      fullWidth
                      type="text"
                      name="phone_one"
                      variant="outlined"
                      label="Primary Phone Number"
                      value={phoneOne}
                      onChange={e => {
                        setPhoneOne(e.target.value);
                        setPhoneOneError('');
                      }}
                      onBlur={phoneOneBlurHandler}
                      helperText={phoneOneError}
                      InputProps={{
                        inputComponent: PhoneNumberFormat,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4} md={3}>
                    <AppTextInput
                      fullWidth
                      type="text"
                      name="extension_one"
                      variant="outlined"
                      label="EXT"
                      value={extensionOne}
                      onChange={e => {
                        setExtensionOne(e.target.value);
                        setExtensionOneError('');
                      }}
                      onBlur={extensionOneBlurHandler}
                      helperText={extensionOneError}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={2}>
                    <IconButton aria-label="call" component="a" href={`tel:${phoneOne}`}>
                      <PhoneEnabledIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={5} md={7}>
                    <AppTextInput
                      fullWidth
                      type="text"
                      name="phone_two"
                      variant="outlined"
                      label="Secondary Phone Number"
                      value={phoneTwo}
                      onChange={e => {
                        setPhoneTwo(e.target.value);
                        setPhoneTwoError('');
                      }}
                      onBlur={phoneTwoBlurHandler}
                      helperText={phoneTwoError}
                      InputProps={{
                        inputComponent: PhoneNumberFormat,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4} md={3}>
                    <AppTextInput
                      fullWidth
                      type="text"
                      name="extension_two"
                      variant="outlined"
                      label="EXT"
                      value={extensionTwo}
                      onChange={e => {
                        setExtensionTwo(e.target.value);
                        setExtensionTwoError('');
                      }}
                      onBlur={extensionTwoBlurHandler}
                      helperText={extensionTwoError}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={2}>
                    <IconButton aria-label="call" component="a" href={`tel:${phoneTwo}`}>
                      <PhoneEnabledIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <AppTextInput
                      fullWidth
                      type="text"
                      name="email"
                      variant="outlined"
                      label="Email Address"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                        setEmailError('');
                      }}
                      onBlur={emailBlurHandler}
                      helperText={emailError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h6">
                      Ad Source
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AppSelectBox
                      fullWidth
                      name="ad_source"
                      data={adSources}
                      label="Select Ad Source"
                      valueKey={AD_SOURCES_KEYS.ID}
                      variant="outlined"
                      labelKey={AD_SOURCES_KEYS.NAME}
                      value={adSource}
                      onChange={adSourceChangeHandler}
                      helperText={adSourceError}
                    />
                  </Grid>
                </GridContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <GridContainer>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h6">
                      Customer Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" variant="subtitle1">
                      Main Address
                    </Typography>
                    <Button
                      fullWidth
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      type="button"
                      variant="contained"
                      color="primary"
                      classes={{ root: classes.addressBtn, label: classes.addressBtnLabel }}
                      onClick={e => handleOpenAddressDialog(CUSTOMER_MAIN_ADDRESS, mainAddress)}
                      startIcon={<LocationOnIcon />}>
                      {mainAddressText}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" variant="subtitle1">
                      Billing Address
                    </Typography>
                    <Button
                      fullWidth
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      type="button"
                      variant="contained"
                      color="primary"
                      classes={{ root: classes.addressBtn, label: classes.addressBtnLabel }}
                      onClick={e => handleOpenAddressDialog(CUSTOMER_BILL_ADDRESS, billAddress)}
                      startIcon={<LocationOnIcon />}>
                      {billAddressText}
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="baseline">
                      <Typography component="span" variant="h6">
                        Customer Notes
                      </Typography>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleOpenNoteDialog}>
                        Add note
                      </Button>
                    </Box>
                  </Grid>
                  {notes && notes.length > 0 && (
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <List dense>
                        {notes.map(note => (
                          <React.Fragment key={`note-${note[CUSTOMER_NOTE_KEYS.ID]}`}>
                            <ListItem classes={{ root: classes.note }}>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      className={classes.inline}
                                      color="textPrimary">
                                      {note[CUSTOMER_NOTE_KEYS.USER_NAME]}
                                    </Typography>
                                    {` on ${note[CUSTOMER_NOTE_KEYS.CREATION_DATE]}`}
                                  </React.Fragment>
                                }
                                secondary={note[CUSTOMER_NOTE_KEYS.NOTE]}
                                classes={{ root: classes.noteItem }}
                              />
                              <Box className={classes.noteAction}>
                                <Button
                                  type="button"
                                  variant="text"
                                  color="default"
                                  size="small"
                                  onClick={() => handleNoteEdit(note)}
                                  style={{ marginRight: 8 }}>
                                  Edit
                                </Button>
                                <Button
                                  type="button"
                                  variant="text"
                                  color="default"
                                  size="small"
                                  onClick={() => handleNoteDelete(note)}>
                                  Delete
                                </Button>
                              </Box>
                            </ListItem>
                            <Divider component="li" />
                          </React.Fragment>
                        ))}
                      </List>
                    </Grid>
                  )}
                  {(!notes || notes.length === 0) && (
                    <Grid item xs={12}>
                      <AlertText severity="warning">No notes found!</AlertText>
                    </Grid>
                  )}
                </GridContainer>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </GridContainer>
          </form>

          {selectedNote && (
            <ConfirmDialog
              open={openConfirmDialog}
              title={`Delete Note?`}
              content={'This action cannot be undone.'}
              onClose={handleCancelDelete}
              onConfirm={handleConfirmDelete}
            />
          )}
        </AccordionDetails>
      </Accordion>

      {openAddressDialog && (
        <EditAddressDialog
          tab={CUSTOMER_INFO_TAB}
          open={openAddressDialog}
          onClose={handleCloseAddressDialog}
          addressType={addressType}
        />
      )}

      {openNoteDialog && <NoteDialog open={openNoteDialog} onClose={handleCloseNoteDialog} />}
    </React.Fragment>
  );
};

CustomerInfo.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleAccordionExpand: PropTypes.func.isRequired,
};

export default CustomerInfo;

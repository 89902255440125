import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { GET_USER_TYPES } from '../../@jumbo/constants/ActionTypes';
import { GET_USER_TYPES_LINK } from '../../@jumbo/constants/APIConstants';

export const getUserTypes = (request = {}) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_USER_TYPES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.usertypes) {
              const userTypes = data.usertypes;
              dispatch(fetchSuccess());
              dispatch({ type: GET_USER_TYPES, payload: userTypes });
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

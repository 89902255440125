import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { requiredMessage, percentageNotValid } from '../../../@jumbo/constants/ErrorMessages';
import { TAX_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { addNewTax, updateTax } from '../../../redux/actions/Taxes';
import { isValidPercentage } from '../../../utils/FormValidation';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const AddEditTax = ({ open, onCloseDialog }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const currentTax = useSelector(({ taxesReducer }) => taxesReducer.currentTax);

  const [taxName, setTaxName] = useState('');
  const [taxNameError, setTaxNameError] = useState('');
  const [taxRate, setTaxRate] = useState('');
  const [taxRateError, setTaxRateError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setTaxName('');
      setTaxNameError('');
      setTaxRate('');
      setTaxRateError('');
      setErrorMessages([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentTax) {
      setTaxName(currentTax[TAX_KEYS.NAME]);
      setTaxRate(currentTax[TAX_KEYS.RATE]);
    }
  }, [currentTax]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case TAX_KEYS.NAME:
          setTaxNameError(msg);
          break;

        case TAX_KEYS.RATE:
          setTaxRateError(msg);
          break;

        default:
          break;
      }
    }
  }, [errorMessages]);

  const taxNameBlurHandler = event => {
    if (!event.target.value) {
      setTaxNameError(requiredMessage);
    }
  };

  const taxRateBlurHandler = event => {
    if (!event.target.value) {
      setTaxRateError(requiredMessage);
    } else if (!isValidPercentage(event.target.value)) {
      setTaxRateError(percentageNotValid);
    }
  };

  const onSubmitClick = () => {
    let formIsValid = true;

    if (!taxName) {
      formIsValid = false;
      setTaxNameError(requiredMessage);
    }

    if (!taxRate) {
      formIsValid = false;
      setTaxRateError(requiredMessage);
    } else if (!isValidPercentage(taxRate)) {
      formIsValid = false;
      setTaxRateError(percentageNotValid);
    }

    if (formIsValid) {
      const taxData = {
        authcode: authUser.authcode,
        [TAX_KEYS.NAME]: taxName,
        [TAX_KEYS.RATE]: taxRate,
      };

      if (currentTax) {
        taxData[TAX_KEYS.TAX_ID] = currentTax[TAX_KEYS.ID];
        console.log(taxData);
        dispatch(
          updateTax(
            { taxData: taxData },
            () => {
              if (_isMounted.current) onCloseDialog();
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      } else {
        dispatch(
          addNewTax(
            { taxData: taxData },
            () => {
              if (_isMounted.current) onCloseDialog();
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      }
    }
  };

  return (
    <Dialog fullWidth open={open} className={classes.dialogRoot} onClose={onCloseDialog}>
      <DialogTitle className={classes.dialogTitleRoot}>Add New Tax</DialogTitle>
      <DialogContent dividers>
        <form>
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                type="text"
                name="name"
                fullWidth
                variant="outlined"
                label="Name"
                value={taxName}
                onChange={e => {
                  setTaxName(e.target.value);
                  setTaxNameError('');
                }}
                onBlur={taxNameBlurHandler}
                helperText={taxNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                type="text"
                name="rate"
                fullWidth
                variant="outlined"
                label="Rate"
                value={taxRate}
                onChange={e => {
                  setTaxRate(e.target.value);
                  setTaxRateError('');
                }}
                onBlur={taxRateBlurHandler}
                helperText={taxRateError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="tax rate percentage">
                        <Icon>percentage</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </GridContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onCloseDialog}>
          Cancel
        </Button>
        <Button type="button" variant="contained" color="primary" onClick={onSubmitClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddEditTax.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};

export default AddEditTax;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { ROLE_KEYS } from '../../../../@jumbo/constants/AppDataConstants';

import { addNewRole } from '../../../../redux/actions/Roles';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const AddRoleDialog = ({ open, onCloseDialog }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);

  const [roleName, setRoleName] = useState('');
  const [roleNameError, setRoleNameError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setRoleName('');
      setRoleNameError('');
      setErrorMessages([]);
    }
  }, [open]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case ROLE_KEYS.NAME:
          setRoleNameError(msg);
          break;

        default:
          break;
      }
    }
  }, [errorMessages]);

  const roleNameBlurHandler = event => {
    if (!event.target.value) {
      setRoleNameError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    if (!roleName) {
      setRoleNameError(requiredMessage);
    } else {
      const roleData = {
        authcode: authUser.authcode,
        [ROLE_KEYS.NAME]: roleName,
      };
      dispatch(
        addNewRole(
          { roleData: roleData },
          () => {
            if (_isMounted.current) onCloseDialog();
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{'Create Role'}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleFormSubmit}>
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Role Name"
              value={roleName}
              onChange={e => {
                setRoleName(e.target.value);
                setRoleNameError('');
              }}
              onBlur={roleNameBlurHandler}
              helperText={roleNameError}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button type="button" variant="outlined" onClick={onCloseDialog}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddRoleDialog;

AddRoleDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PageviewIcon from '@material-ui/icons/Pageview';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CreateIcon from '@material-ui/icons/Create';
import RateReviewIcon from '@material-ui/icons/RateReview';
import DoneIcon from '@material-ui/icons/Done';
/* import RepeatIcon from '@material-ui/icons/Repeat'; */
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import { INVOICE_KEYS, INVOICE_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  smRightAlign: {
    [theme.breakpoints.up('sm')]: { textAlign: 'right' },
  },
  invoiceDataGrid: {
    paddingBottom: '0 !important',
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '20vw',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '10vw',
    },
  },
  subHeading: {
    padding: theme.spacing(2),
  },
}));

const TextOnlyTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const JobInvoiceHeader = ({
  miscellaneousErrors,
  onPreviewInvoice,
  onDownloadInvoice,
  onDeleteInvoice,
  onSignatureClick,
  onRequestSignature,
  /* onAutoInvoicing, */
  onMarkSent,
  onSendInvoice,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { currentInvoice, currentInvoiceCustomer } = useSelector(({ invoicesReducer }) => invoicesReducer);
  const { invoiceStatuses } = useSelector(({ invoiceStatusesReducer }) => invoiceStatusesReducer);

  const [anchorEl, setAnchorEl] = useState(null);
  const [bill, setBill] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (currentInvoiceCustomer) {
      setBill(currentInvoiceCustomer[INVOICE_KEYS.CUSTOMER_BILL]);
      setService(currentInvoiceCustomer[INVOICE_KEYS.CUSTOMER_SERVICE]);
    }
  }, [currentInvoiceCustomer]);

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleViewJob = () => {
    handleClose();
    history.push(`/job/${currentInvoice[INVOICE_KEYS.JOB_ID]}/`);
  };

  const handlePreviewInvoice = () => {
    handleClose();
    onPreviewInvoice();
  };

  const handleDownloadInvoice = () => {
    handleClose();
    onDownloadInvoice();
  };

  const handleSignature = () => {
    handleClose();
    onSignatureClick();
  };

  const handleRequestSignature = () => {
    handleClose();
    onRequestSignature();
  };

  /* const handleAutoInvoicing = () => {
    handleClose();
    onAutoInvoicing();
  }; */

  const handleMarkSent = () => {
    handleClose();
    onMarkSent();
  };

  const handleSendInvoice = () => {
    handleClose();
    onSendInvoice();
  };

  const handleDeleteInvoice = () => {
    handleClose();
    onDeleteInvoice();
  };

  const jobId = currentInvoice ? currentInvoice[INVOICE_KEYS.JOB_ID] : '';

  const statusObj = invoiceStatuses.find(
    invoiceStatus => invoiceStatus[INVOICE_STATUS_KEYS.ID] === currentInvoice[INVOICE_KEYS.STATUS],
  );
  const status = statusObj ? statusObj[INVOICE_STATUS_KEYS.NAME] : 'NA';

  return (
    <GridContainer>
      <Grid item xs={12} sm={10} md={8}>
        <Typography variant="h2" component="h2">
          Customer{bill ? ` - ${bill[INVOICE_KEYS.CUSTOMER_NAME]} ${bill[INVOICE_KEYS.CUSTOMER_COMPANY]}` : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2} md={4} className={classes.smRightAlign}>
        <Button
          type="button"
          aria-controls="invoice-actions-menu"
          aria-haspopup="true"
          variant="contained"
          color="secondary"
          endIcon={<ExpandMoreIcon />}
          onClick={handleOpen}>
          Actions
        </Button>
        <Menu
          id="invoice-actions-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}>
          <MenuItem onClick={handleViewJob}>
            <ListItemIcon>
              <PageviewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Job" />
          </MenuItem>
          <MenuItem onClick={handlePreviewInvoice}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Preview" />
          </MenuItem>
          <MenuItem onClick={handleDownloadInvoice}>
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </MenuItem>
          <MenuItem onClick={handleSignature}>
            <ListItemIcon>
              <CreateIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sign" />
          </MenuItem>
          <MenuItem onClick={handleRequestSignature}>
            <ListItemIcon>
              <RateReviewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Request Signature" />
          </MenuItem>
          {/* <MenuItem onClick={handleAutoInvoicing}>
            <ListItemIcon>
              <RepeatIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Auto Invoicing" secondary="Coming Soon" />
          </MenuItem> */}
          <MenuItem onClick={handleSendInvoice}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Send" />
          </MenuItem>
          <MenuItem onClick={handleMarkSent}>
            <ListItemIcon>
              <DoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Mark Sent" />
          </MenuItem>
          <MenuItem onClick={handleDeleteInvoice}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography component={Link} to={`/job/${jobId}/`} variant="h2" className={classes.subHeading}>
              Job #{jobId}
            </Typography>
            <TextOnlyTooltip title={currentInvoice[INVOICE_KEYS.FORMATTED_CREATED]} placement="top">
              <Typography variant="body1" display="block" noWrap>
                {currentInvoice[INVOICE_KEYS.FORMATTED_CREATED]}
              </Typography>
            </TextOnlyTooltip>
            <Typography variant="body1" display="block" noWrap>
              {status}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          {bill && (
            <TextOnlyTooltip
              title={
                <React.Fragment>
                  <p>
                    {bill[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]} {bill[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]}
                  </p>
                  <p>
                    {bill[INVOICE_KEYS.CUSTOMER_CITY]}, {bill[INVOICE_KEYS.CUSTOMER_STATE]}{' '}
                    {bill[INVOICE_KEYS.CUSTOMER_ZIP_CODE]}
                  </p>
                </React.Fragment>
              }
              placement="top">
              <CardContent>
                <Typography variant="h3">Billing Details</Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]}
                  {bill[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO] ? ` ${bill[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]}` : ''}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[INVOICE_KEYS.CUSTOMER_CITY]}, {bill[INVOICE_KEYS.CUSTOMER_STATE]}{' '}
                  {bill[INVOICE_KEYS.CUSTOMER_ZIP_CODE]}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  <NumberFormat
                    isNumericString
                    displayType="text"
                    value={bill[INVOICE_KEYS.CUSTOMER_PHONE_ONE]}
                    format="(###) ###-####"
                  />
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {bill[INVOICE_KEYS.CUSTOMER_EMAIL]}
                </Typography>
              </CardContent>
            </TextOnlyTooltip>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          {service && (
            <TextOnlyTooltip
              title={
                <React.Fragment>
                  <p>
                    {service[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]} {service[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]}
                  </p>
                  <p>
                    {service[INVOICE_KEYS.CUSTOMER_CITY]}, {service[INVOICE_KEYS.CUSTOMER_STATE]}{' '}
                    {service[INVOICE_KEYS.CUSTOMER_ZIP_CODE]}
                  </p>
                </React.Fragment>
              }
              placement="top">
              <CardContent>
                <Typography variant="h3">Service Details</Typography>
                <Typography variant="body1" display="block" noWrap>
                  {service[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]}
                  {service[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO] ? ` ${service[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]}` : ''}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {service[INVOICE_KEYS.CUSTOMER_CITY]}, {service[INVOICE_KEYS.CUSTOMER_STATE]}{' '}
                  {service[INVOICE_KEYS.CUSTOMER_ZIP_CODE]}
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  <NumberFormat
                    isNumericString
                    displayType="text"
                    value={service[INVOICE_KEYS.CUSTOMER_PHONE_ONE]}
                    format="(###) ###-####"
                  />
                </Typography>
                <Typography variant="body1" display="block" noWrap>
                  {service[INVOICE_KEYS.CUSTOMER_EMAIL]}
                </Typography>
              </CardContent>
            </TextOnlyTooltip>
          )}
        </Card>
      </Grid>
      {miscellaneousErrors && miscellaneousErrors.length > 0 && (
        <Grid item xs={12}>
          {miscellaneousErrors.map((miscellaneousError, idx) => (
            <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
              {miscellaneousError}
            </Typography>
          ))}
        </Grid>
      )}
    </GridContainer>
  );
};

JobInvoiceHeader.propTypes = {
  miscellaneousErrors: PropTypes.array.isRequired,
  onPreviewInvoice: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  onSignatureClick: PropTypes.func.isRequired,
  onRequestSignature: PropTypes.func.isRequired,
  /* onAutoInvoicing: PropTypes.func.isRequired, */
  onMarkSent: PropTypes.func.isRequired,
  onSendInvoice: PropTypes.func.isRequired,
  onDeleteInvoice: PropTypes.func.isRequired,
};

export default JobInvoiceHeader;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  ADD_CUSTOMER_NOTE,
  DELETE_CUSTOMER_NOTE,
  EDIT_CUSTOMER_NOTE,
  GET_CUSTOMER_NOTES,
  SET_CUSTOMER_NOTE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_CUSTOMER_NOTES_LINK,
  ADD_CUSTOMER_NOTE_LINK,
  UPDATE_CUSTOMER_NOTE_LINK,
  DELETE_CUSTOMER_NOTE_LINK,
} from '../../@jumbo/constants/APIConstants';
import { CUSTOMER_NOTE_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getCustomerNotes = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_NOTES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.notes) {
              const customerNotes = data.notes;
              dispatch(fetchSuccess());
              dispatch({ type: GET_CUSTOMER_NOTES, payload: customerNotes });
              if (callbackFun) callbackFun(customerNotes);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentCustomerNote = customerNote => {
  return dispatch => {
    dispatch({ type: SET_CUSTOMER_NOTE_DETAILS, payload: customerNote });
  };
};

export const addNewCustomerNote = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerNoteData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_CUSTOMER_NOTE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.note) {
              dispatch(fetchSuccess('Note was added successfully.'));
              dispatch({ type: ADD_CUSTOMER_NOTE, payload: data.note });
              if (callbackFun) callbackFun(data.note);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateCustomerNote = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerNoteData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_CUSTOMER_NOTE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.note) {
              dispatch(fetchSuccess('Selected note was updated successfully.'));
              dispatch({ type: EDIT_CUSTOMER_NOTE, payload: data.note });
              if (callbackFun) callbackFun(data.note);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteCustomerNote = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerNoteData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    axios
      .post(DELETE_CUSTOMER_NOTE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected note was deleted successfully.'));
            dispatch({ type: DELETE_CUSTOMER_NOTE, payload: request.customerNoteData[CUSTOMER_NOTE_KEYS.NOTE_ID] });
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

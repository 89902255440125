import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { lighten, makeStyles } from '@material-ui/core/styles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { jobNotValid, statusNotValid } from '../../../@jumbo/constants/ErrorMessages';
import {
  ESTIMATE_KEYS,
  INVOICE_KEYS,
  JOB_KEYS,
  JOB_PAYMENT_KEYS,
  JOB_STATUS_KEYS,
  JOB_TYPE_KEYS,
  /* PRODUCT_KEYS, */
  PROPERTY_KEYS,
  /* JOB_FILE_KEYS, */
} from '../../../@jumbo/constants/AppDataConstants';

import { getAdSources } from '../../../redux/actions/AdSources';
import { getCountries } from '../../../redux/actions/Countries';
import { getCustomerProperties } from '../../../redux/actions/CustomerProperties';
import { getEstimateStatusList } from '../../../redux/actions/EstimateStatuses';
import { getJobStatusList } from '../../../redux/actions/JobStatuses';
import { addNewInvoice } from '../../../redux/actions/Invoices';
import {
  deleteJob,
  getJob,
  getJobEstimates,
  setCurrentJob,
  updateJobStatus,
  /* duplicateJob, */
  /* markJobDone, */
} from '../../../redux/actions/Jobs';
/* import { getJobFiles } from '../../../redux/actions/JobFiles'; */
import { getJobPayments } from '../../../redux/actions/JobPayments';
/* import { getJobProducts } from '../../../redux/actions/JobProducts'; */
import { getJobJobTypes, setJobJobTypes, setJobJobTypesTotals } from '../../../redux/actions/JobJobTypes';
import { getJobTypes } from '../../../redux/actions/JobTypes';
/* import { getProducts } from '../../../redux/actions/Products'; */
import { getSquadUsers } from '../../../redux/actions/Squad';
import { getUSAStates } from '../../../redux/actions/USAStates';

import EditJobDetails from '../EditJobDetails';
import EditJobHeader from '../EditJobHeader';
import JobServices from '../JobServices';
import JobEstimates from '../JobEstimates';
/* import JobFiles from '../JobFiles'; */
/* import JobPayments from '../JobPayments'; */
/* import JobProducts from '../JobProducts'; */
import SendCustomerJobLinkDialog from '../SendCustomerJobLinkDialog';
import TabPanel from '../../UI/TabPanel';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.dark}`,
  },
  tabPanel: {
    paddingTop: theme.spacing(5),
  },
  deleteAlert: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
}));

function a11yProps(index) {
  return {
    id: `job-tab-${index}`,
    'aria-controls': `job-tabpanel-${index}`,
  };
}

const EditJob = () => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const jobId = params.jobId;

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentJob } = useSelector(({ jobsReducer }) => jobsReducer);
  const { jobStatuses } = useSelector(({ jobStatusesReducer }) => jobStatusesReducer);

  const [currentTab, setCurrentTab] = useState(0);
  const [detailsFetching, setDetailsFetching] = useState(true);
  const [detailsFetched, setDetailsFetched] = useState(false);
  const [propertiesFetched, setPropertiesFetched] = useState(false);
  /* const [productsFetching, setProductsFetching] = useState(false);
  const [productsFetched, setProductsFetched] = useState(false); */
  const [jobTypesFetching, setJobTypesFetching] = useState(false);
  const [jobTypesFetched, setJobTypesFetched] = useState(false);
  const [paymentsFetching, setPaymentsFetching] = useState(false);
  const [paymentsFetched, setPaymentsFetched] = useState(false);
  const [estimatesFetching, setEstimatesFetching] = useState(false);
  const [estimatesFetched, setEstimatesFetched] = useState(false);
  /* const [filesFetching, setFilesFetching] = useState(false); */
  /* const [filesFetched, setFilesFetched] = useState(false); */
  const [openCustomerLinkDialog, setOpenCustomerLinkDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  const jobIsValid = jobId && detailsFetched;

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      dispatch(setCurrentJob(null));
      dispatch(setJobJobTypes([]));
      dispatch(setJobJobTypesTotals(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;

    const fetchData = (payload, jobPayload) => {
      return (dispatch, getState) => {
        return dispatch(getAdSources(payload)).then(() => {
          return dispatch(getCountries(payload)).then(() => {
            return dispatch(getUSAStates(payload)).then(() => {
              return dispatch(getJobTypes(payload)).then(() => {
                return dispatch(getSquadUsers(payload)).then(() => {
                  return dispatch(getJobStatusList(payload)).then(() => {
                    return dispatch(getJob({ jobData: jobPayload }));
                  });
                });
              });
            });
          });
        });
      };
    };

    const payload = { authcode: authUser.authcode };
    const jobPayload = { ...payload, [JOB_KEYS.JOB_ID]: jobId };

    const promise = dispatch(fetchData(payload, jobPayload));
    promise
      .then(() => {
        if (isActive) {
          setDetailsFetching(false);
          setDetailsFetched(true);
        }
      })
      .catch(error => {
        if (isActive) {
          setDetailsFetching(false);
          setDetailsFetched(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId]);

  useEffect(() => {
    let isActive = true;

    if (currentJob && currentJob[JOB_KEYS.CUSTOMER_ID] && !propertiesFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getCustomerProperties(payload));
        };
      };

      const payload = {
        customerData: {
          authcode: authUser.authcode,
          [PROPERTY_KEYS.CUSTOMER_ID]: currentJob[JOB_KEYS.CUSTOMER_ID],
        },
      };

      const promise = dispatch(fetchData(payload));
      promise
        .then(() => {
          if (isActive) {
            setPropertiesFetched(true);
          }
        })
        .catch(error => {
          if (isActive) {
            setPropertiesFetched(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, currentJob, propertiesFetched]);

  /* useEffect(() => {
    let isActive = true;

    if (productsFetching && !productsFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getProducts(payload)).then(() => {
            const jobProductData = { ...payload, [PRODUCT_KEYS.JOB_ID]: jobId };
            return dispatch(getJobProducts({ jobProductData: jobProductData }));
          });
        };
      };

      const promise = dispatch(fetchData({ authcode: authUser.authcode }));
      promise
        .then(() => {
          if (isActive) {
            setProductsFetching(false);
            setProductsFetched(true);
          }
        })
        .catch(error => {
          if (isActive) {
            setProductsFetching(false);
            setProductsFetched(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId, productsFetching, productsFetched]); */

  useEffect(() => {
    let isActive = true;

    if (jobTypesFetching && !jobTypesFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getJobTypes(payload)).then(() => {
            const jobJobTypeData = { ...payload, [JOB_TYPE_KEYS.JOB_ID]: jobId };
            return dispatch(getJobJobTypes({ jobJobTypeData: jobJobTypeData }));
          });
        };
      };

      const promise = dispatch(fetchData({ authcode: authUser.authcode }));
      promise
        .then(() => {
          if (isActive) {
            setJobTypesFetching(false);
            setJobTypesFetched(true);
          }
        })
        .catch(error => {
          if (isActive) {
            setJobTypesFetching(false);
            setJobTypesFetched(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId, jobTypesFetching, jobTypesFetched]);

  useEffect(() => {
    let isActive = true;

    if (paymentsFetching && !paymentsFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getJobPayments(payload));
        };
      };

      const jobPaymentData = { authcode: authUser.authcode, [JOB_PAYMENT_KEYS.JOB_ID]: jobId };
      const promise = dispatch(fetchData({ jobPaymentData: jobPaymentData }));
      promise
        .then(() => {
          if (isActive) {
            setPaymentsFetching(false);
            setPaymentsFetched(true);
          }
        })
        .catch(error => {
          if (isActive) {
            setPaymentsFetching(false);
            setPaymentsFetched(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId, paymentsFetching, paymentsFetched]);

  useEffect(() => {
    let isActive = true;

    if (estimatesFetching && !estimatesFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getEstimateStatusList(payload)).then(() => {
            const jobEstimateData = { ...payload, [ESTIMATE_KEYS.JOB_ID]: jobId };
            return dispatch(getJobEstimates({ jobEstimateData: jobEstimateData }));
          });
        };
      };

      const promise = dispatch(fetchData({ authcode: authUser.authcode }));
      promise
        .then(() => {
          if (isActive) {
            setEstimatesFetching(false);
            setEstimatesFetched(true);
          }
        })
        .catch(error => {
          if (isActive) {
            setEstimatesFetching(false);
            setEstimatesFetched(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId, estimatesFetching, estimatesFetched]);

  /* useEffect(() => {
    let isActive = true;

    if (filesFetching && !filesFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getJobFiles(payload));
        };
      };

      const jobFileData = { authcode: authUser.authcode, [JOB_FILE_KEYS.JOB_ID]: jobId };
      const promise = dispatch(fetchData({ jobFileData: jobFileData }));
      promise
        .then(() => {
          if (isActive) {
            setFilesFetching(false);
            setFilesFetched(true);
          }
        })
        .catch(error => {
          if (isActive) {
            setFilesFetching(false);
            setFilesFetched(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId, filesFetching, filesFetched]); */

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const handleTabSelection = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue === 1 && !jobTypesFetched) {
      setJobTypesFetching(true);
    } else if (newValue === 2 && !estimatesFetched) {
      setEstimatesFetching(true);
    } /* else if (newValue === 3 && !paymentsFetched) {
      setPaymentsFetching(true);
    } */ /*  else if (newValue === 4 && !filesFetched) {
      setFilesFetching(true);
    } */ /* else if (newValue === 5 && !productsFetched) {
      setProductsFetching(true);
    } */
  };

  const handleJobStatusChange = event => {
    const newStatus = event.target.value;

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    if (!jobStatuses.map(jobStatusObj => jobStatusObj[JOB_STATUS_KEYS.ID]).includes(newStatus)) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: statusNotValid,
        customClass: { container: classes.deleteAlert },
      });
    } else {
      const jobData = {
        authcode: authUser.authcode,
        [JOB_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
        [JOB_KEYS.STATUS_ID]: newStatus,
      };

      dispatch(
        updateJobStatus({ jobData: jobData }, null, messages => {
          if (_isMounted.current) setErrorMessages(messages);
        }),
      );
    }
  };

  const handleCreateInvoice = event => {
    const invoiceData = {
      authcode: authUser.authcode,
      [INVOICE_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
      [INVOICE_KEYS.CUSTOMER_ID]: currentJob[JOB_KEYS.CUSTOMER_ID],
    };

    dispatch(
      addNewInvoice(
        { invoiceData: invoiceData },
        invoice => {
          if (_isMounted.current) history.push(`/jobInvoice/${invoice[INVOICE_KEYS.ID]}/`);
        },
        messages => {
          if (_isMounted.current) setErrorMessages(messages);
        },
      ),
    );
  };

  const handleSendCustomerJobLink = () => setOpenCustomerLinkDialog(true);

  const handleCloseSendCustomerLinkDialog = () => setOpenCustomerLinkDialog(false);

  /* const handleMarkJobDone = event => {
    const jobData = {
      authcode: authUser.authcode,
      [JOB_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
    };
    dispatch(
      markJobDone(
        { jobData: jobData },
        () => {
          if (_isMounted.current) history.push(`/jobs`);
        },
        messages => {
          if (_isMounted.current) setErrorMessages(messages);
        },
      ),
    );
  }; */

  /* const handleDuplicateJob = event => {
    const jobData = {
      authcode: authUser.authcode,
      [JOB_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
    };
    dispatch(
      duplicateJob(
        { jobData: jobData },
        () => {
          if (_isMounted.current) history.push(`/jobs`);
        },
        messages => {
          if (_isMounted.current) setErrorMessages(messages);
        },
      ),
    );
  }; */

  const handleDeleteJob = event => setOpenConfirmDialog(true);

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);

    if (currentJob && currentJob[JOB_KEYS.ID]) {
      const jobData = {
        authcode: authUser.authcode,
        [JOB_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
      };

      dispatch(
        deleteJob(
          { jobData: jobData },
          () => {
            if (_isMounted.current) history.push(`/jobs`);
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid job',
        });
      }
    }
  };

  const handleCancelDelete = () => setOpenConfirmDialog(false);

  const jobTitle = jobId ? `Job #${jobId}${currentJob ? ` - ${currentJob[JOB_KEYS.CUSTOMER_COMPANY]}` : ''}` : '';
  const pageName = 'Edit Job';
  const breadcrumbs = [
    { label: 'Jobs', link: '/jobs' },
    { label: pageName, isActive: true },
  ];
  document.title = `${AppName} - ${pageName}${jobTitle ? ` (${jobTitle})` : ''}`;

  return (
    <PageContainer heading={jobTitle} breadcrumbs={breadcrumbs}>
      {!detailsFetching && jobIsValid && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12}>
              <EditJobHeader
                miscellaneousErrors={miscellaneousErrors}
                onStatusChange={handleJobStatusChange}
                onCreateInvoice={handleCreateInvoice}
                /* onMarkJobDone={handleMarkJobDone} */
                /* onDuplicateJob={handleDuplicateJob} */
                onSendCustomerJobLink={handleSendCustomerJobLink}
                onDeleteJob={handleDeleteJob}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box>
                  <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabSelection}
                    aria-label="Edit Job Tabs"
                    classes={{ root: classes.tabsRoot }}>
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Services" {...a11yProps(1)} />
                    <Tab label="Estimates" {...a11yProps(2)} />
                    {/* <Tab label="Payments" {...a11yProps(3)} /> */}
                    {/* <Tab label="Files" {...a11yProps(4)} /> */}
                    {/* <Tab label="Products" {...a11yProps(5)} /> */}
                  </Tabs>
                </Box>
                <TabPanel
                  index={0}
                  value={currentTab}
                  id="job-tabpanel-0"
                  ariaLabel="job-tab-0"
                  className={classes.tabPanel}>
                  <EditJobDetails />
                </TabPanel>
                <TabPanel
                  index={1}
                  value={currentTab}
                  id="job-tabpanel-1"
                  ariaLabel="job-tab-1"
                  className={classes.tabPanel}>
                  {!jobTypesFetching && jobTypesFetched && <JobServices />}
                  {!jobTypesFetching && !jobTypesFetched && <Alert severity="error">Unable to fetch services data!</Alert>}
                </TabPanel>
                <TabPanel
                  index={2}
                  value={currentTab}
                  id="job-tabpanel-2"
                  ariaLabel="job-tab-2"
                  className={classes.tabPanel}>
                  {!estimatesFetching && estimatesFetched && <JobEstimates />}
                  {!estimatesFetching && !estimatesFetched && (
                    <Alert severity="error">Unable to fetch estimates data!</Alert>
                  )}
                </TabPanel>
                {/* <TabPanel
                  index={3}
                  value={currentTab}
                  id="job-tabpanel-3"
                  ariaLabel="job-tab-3"
                  className={classes.tabPanel}>
                  {!paymentsFetching && paymentsFetched && <JobPayments />}
                  {!paymentsFetching && !paymentsFetched && <Alert severity="error">Unable to fetch payments data!</Alert>}
                </TabPanel> */}
                {/* <TabPanel
                  index={4}
                  value={currentTab}
                  id="job-tabpanel-4"
                  ariaLabel="job-tab-4"
                  className={classes.tabPanel}>
                  {!filesFetching && filesFetched && <JobFiles />}
                  {!filesFetching && !filesFetched && <Alert severity="error">Unable to fetch files data!</Alert>}
                </TabPanel> */}
                {/* <TabPanel
                  index={5}
                  value={currentTab}
                  id="job-tabpanel-5"
                  ariaLabel="job-tab-5"
                  className={classes.tabPanel}>
                  {!productsFetching && productsFetched && <JobProducts />}
                  {!productsFetching && !productsFetched && <Alert severity="error">Unable to fetch products data!</Alert>}
                </TabPanel> */}
              </Paper>
            </Grid>
          </GridContainer>

          {openCustomerLinkDialog && (
            <SendCustomerJobLinkDialog open={openCustomerLinkDialog} onClose={handleCloseSendCustomerLinkDialog} />
          )}

          {openConfirmDialog && (
            <ConfirmDialog
              open={openConfirmDialog}
              title={`Delete Job?`}
              content={'This action cannot be undone.'}
              onClose={handleCancelDelete}
              onConfirm={handleConfirmDelete}
            />
          )}
        </React.Fragment>
      )}
      {!detailsFetching && !jobIsValid && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">{jobNotValid}</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default EditJob;

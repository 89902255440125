import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { COLOR_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import ColoredChip from '../../UI/ColoredChip';

const JobsAutocompleteFilter = ({
  id,
  value,
  options,
  label,
  labelKey,
  idKey,
  colorKey,
  colorTags,
  colorNamesProvided,
  defaultValue,
  setValue,
}) => {
  const { colors } = useSelector(({ colorsReducer }) => colorsReducer);

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      id={id}
      value={value}
      options={options}
      getOptionLabel={option => option[labelKey]}
      renderOption={(option, state) => {
        let color = 'default';
        let chipColor = 'default';
        if (colorNamesProvided) {
          chipColor = option[colorKey];
        } else if (colorTags && colorKey) {
          const colorObj = colors.find(tempColor => tempColor[COLOR_KEYS.ID] === option[colorKey]);
          if (colorObj) {
            chipColor = colorObj[COLOR_KEYS.NAME];
          }
        }
        return <ColoredChip label={option[labelKey]} color={color} chipcolor={chipColor} variant="default" />;
      }}
      renderInput={params => <TextField {...params} label={label} variant="outlined" />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          let color = 'default';
          let chipColor = 'default';
          if (colorNamesProvided) {
            chipColor = option[colorKey];
          } else if (colorTags && colorKey) {
            const colorObj = colors.find(tempColor => tempColor[COLOR_KEYS.ID] === option[colorKey]);
            if (colorObj) {
              chipColor = colorObj[COLOR_KEYS.NAME];
            }
          }
          return (
            <ColoredChip
              label={option[labelKey]}
              color={color}
              chipcolor={chipColor}
              variant="default"
              {...getTagProps({ index })}
            />
          );
        })
      }
      onChange={(event, values, reason) => {
        if (['select-option', 'remove-option'].includes(reason)) {
          if (values && values.length > 0) {
            setValue(values);
          } else {
            setValue(defaultValue);
          }
        } else if (reason === 'clear') {
          setValue(defaultValue);
        }
      }}
      getOptionSelected={(option, value) => option[idKey] === value[idKey]}
    />
  );
};

JobsAutocompleteFilter.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.array,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  colorKey: PropTypes.string,
  colorTags: PropTypes.bool.isRequired,
  defaultValue: PropTypes.array,
  setValue: PropTypes.func.isRequired,
};

export default JobsAutocompleteFilter;

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { INVOICE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';

import { updateInvoiceNote } from '../../../redux/actions/Invoices';

const useStyles = makeStyles(theme => ({
  headerDivider: {
    marginTop: theme.spacing(2),
  },
}));

const JobInvoiceNotes = () => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentInvoice } = useSelector(({ invoicesReducer }) => invoicesReducer);

  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (currentInvoice) {
      setNotes(currentInvoice[INVOICE_KEYS.NOTE] ? currentInvoice[INVOICE_KEYS.NOTE] : '');
    }
  }, [currentInvoice]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case INVOICE_KEYS.NOTE:
          setNotesError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const notesBlurHandler = event => {
    if (!event.target.value) {
      setNotesError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;

    if (!notes) {
      setNotesError(requiredMessage);
      formIsValid = false;
    }

    if (formIsValid) {
      const invoiceData = {
        authcode: authUser.authcode,
        [INVOICE_KEYS.INVOICE_ID]: currentInvoice[INVOICE_KEYS.ID],
        [INVOICE_KEYS.NOTE]: notes,
      };

      dispatch(
        updateInvoiceNote({ invoiceData: invoiceData }, null, messages => {
          if (_isMounted.current) setErrorMessages(messages);
        }),
      );
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Notes
          </Typography>
          <Divider classes={{ root: classes.headerDivider }} />
        </Grid>
        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
          <Grid item xs={12}>
            {miscellaneousErrors.map((miscellaneousError, idx) => (
              <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                {miscellaneousError}
              </Typography>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <AppTextInput
            fullWidth
            multiline
            minRows={4}
            name="notes"
            variant="outlined"
            label="Notes"
            value={notes}
            onChange={e => {
              setNotes(e.target.value);
              setNotesError('');
            }}
            onBlur={notesBlurHandler}
            helperText={notesError}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" size="small" color="primary">
            Save Note
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default JobInvoiceNotes;

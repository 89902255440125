import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';

import GridContainer from '../../../@jumbo/components/GridContainer';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import { CUSTOMER_KEYS, ESTIMATE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { getCustomerEstimates } from '../../../redux/actions/Customers';

import EditCustomerTableHead from '../EditCustomerTableHead';
import EditCustomerTableToolbar from '../EditCustomerTableToolbar';
import NoRecordFound from '../../NoRecordFound';

import useStyles from '../customerTable.style';

const headCells = [
  { id: ESTIMATE_KEYS.ID, disablePadding: false, label: 'Id', sortColumn: false },
  { id: ESTIMATE_KEYS.FORMATTED_CREATED, disablePadding: false, label: 'Created', sortColumn: false },
  { id: ESTIMATE_KEYS.FORMATTED_ADDRESS_TWO, disablePadding: false, label: 'Address', sortColumn: false },
  { id: ESTIMATE_KEYS.JOB_ID, disablePadding: false, label: 'Job', sortColumn: false },
  { id: ESTIMATE_KEYS.STATUS_NAME, disablePadding: false, label: 'Status', sortColumn: false },
];

const colspan = headCells && headCells.length > 0 ? headCells.length : 1;

const CustomerEstimates = ({ customerId, expanded, handleAccordionExpand }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);

  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ orderBy: ESTIMATE_KEYS.ID, order: 'asc' });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const [customerEstimatesFetched, setCustomerEstimatesFetched] = useState(false);
  const [customerEstimatesFetching, setCustomerEstimatesFetching] = useState(true);
  const [fetchCriteria, setFetchCriteria] = useState(null);
  const [customerEstimates, setCustomerEstimates] = useState([]);
  const [customerEstimatesCounts, setCustomerEstimatesCounts] = useState({
    total: 0,
  });
  const [estimatesFilterChanged, setEstimatesFilterChanged] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setFetchCriteria({
      search: debouncedSearchTerm,
      order: sort.order,
      orderBy: sort.orderBy,
      exportCSV: '0',
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
    });

    if (!estimatesFilterChanged) {
      setCustomerEstimatesFetching(true);
      setCustomerEstimatesFetched(false);
    } else {
      setPagination(prevState => {
        return { ...prevState, page: 0 };
      });
      setEstimatesFilterChanged(false);
    }
  }, [debouncedSearchTerm, sort, pagination, estimatesFilterChanged]);

  useEffect(() => {
    let isActive = true;

    if (expanded && customerEstimatesFetching && !customerEstimatesFetched && customerId) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getCustomerEstimates(payload, (customerEstimates, customerEstimatesCounts) => {
              if (isActive) {
                setCustomerEstimates(customerEstimates);
                setCustomerEstimatesCounts(customerEstimatesCounts);
                setCustomerEstimatesFetched(true);
                setCustomerEstimatesFetching(false);
              }
            }),
          );
        };
      };

      const customerData = {
        authcode: authUser.authcode,
        [CUSTOMER_KEYS.CUSTOMER_ID]: customerId,
        search: fetchCriteria.search,
        order: fetchCriteria.order,
        order_by: fetchCriteria.orderBy,
        export_list: '0',
        page: fetchCriteria.page + 1,
        rows_per_page: fetchCriteria.rowsPerPage,
      };

      const promise = dispatch(fetchData({ customerData: customerData }));
      promise.catch(error => {
        if (isActive) {
          setCustomerEstimatesFetched(false);
          setCustomerEstimatesFetching(false);
        }
      });
    } else if (!customerId) {
      if (isActive) {
        setCustomerEstimatesFetched(false);
        setCustomerEstimatesFetching(false);
      }
    }

    return () => {
      isActive = false;
    };
  }, [
    dispatch,
    authUser.authcode,
    customerId,
    fetchCriteria,
    customerEstimatesFetching,
    customerEstimatesFetched,
    expanded,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = sort.orderBy === property && sort.order === 'asc';
    setSort(prevState => {
      return { ...prevState, orderBy: property, order: isAsc ? 'desc' : 'asc' };
    });
  };

  const handleSearch = searchTerm => setSearchTerm(searchTerm);

  const handleSearchChipDelete = () => setSearchTerm('');

  const handlePageChange = (event, newPage) => {
    setPagination(prevState => {
      return { ...prevState, page: newPage };
    });
  };

  const handleRowsPerPageChange = event => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <Accordion classes={{ root: classes.accordion }} expanded={expanded} onClick={handleAccordionExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="estimates-content" id="estimates-header">
        <Typography className={classes.accordionHeading}>Estimates</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }} onClick={e => e.stopPropagation()}>
        {!customerEstimatesFetching && customerEstimatesFetched && (
          <React.Fragment>
            <EditCustomerTableToolbar
              type="estimate"
              searchTerm={searchTerm}
              onSearch={handleSearch}
              onSearchChipDelete={handleSearchChipDelete}
            />
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="customerEstimatesTableTitle"
                aria-label="Customer Estimates Table">
                <EditCustomerTableHead
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />

                <TableBody>
                  {!!customerEstimates.length ? (
                    customerEstimates.map((row, index) => {
                      const customerEstimateId = row[ESTIMATE_KEYS.ID];
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          classes={{
                            hover: classes.tableRow,
                          }}>
                          <TableCell
                            component="th"
                            id={`estimate-${customerEstimateId}`}
                            scope="row"
                            className={classes.tableCell}>
                            <Typography
                              component={Link}
                              to={`/estimate/${customerEstimateId}/`}
                              className={classes.titleRoot}
                              variant="h4">
                              {customerEstimateId}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCell}>{`${row[ESTIMATE_KEYS.FORMATTED_CREATED]}`}</TableCell>
                          <TableCell className={classes.tableCell}>{`${
                            row[ESTIMATE_KEYS.FORMATTED_ADDRESS_TWO]
                          }`}</TableCell>
                          <TableCell className={classes.tableCell}>
                            <Typography
                              component={Link}
                              to={`/job/${row[ESTIMATE_KEYS.JOB_ID]}/`}
                              className={classes.titleRoot}
                              variant="h4">
                              {row[ESTIMATE_KEYS.JOB_ID]}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCell}>{row[ESTIMATE_KEYS.STATUS_NAME]}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={colspan} rowSpan={10}>
                        <NoRecordFound>There are no records found</NoRecordFound>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={customerEstimatesCounts.total}
              rowsPerPage={pagination.rowsPerPage}
              labelRowsPerPage=""
              page={pagination.page}
              classes={{ root: classes.pagination }}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </React.Fragment>
        )}
        {!customerEstimatesFetching && !customerEstimatesFetched && (
          <GridContainer>
            <Grid item xs={12}>
              <Alert severity="error">Unable to fetch estimates data!</Alert>
            </Grid>
          </GridContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

CustomerEstimates.propTypes = {
  customerId: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleAccordionExpand: PropTypes.func.isRequired,
};

export default CustomerEstimates;

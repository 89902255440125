import React from 'react';

import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  eventItemRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  titleRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));

const Event = ({ event }) => {
  const title = event.title;
  const statusName = event[JOB_KEYS.STATUS];

  const classes = useStyles();

  return (
    <div className={classes.eventItemRoot}>
      <Tooltip title={`${title} - ${statusName}`}>
        <Typography variant="body1" className={classes.titleRoot}>
          {title}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default Event;

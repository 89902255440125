import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '24%',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '24%',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
    minHeight: 64,
    position: 'relative',
    zIndex: 1,
    /* [theme.breakpoints.up('md')]: {
      minHeight: 72,
    }, */
  },
  drawerContent: {
    flex: 1,
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
  drawerScroll: {
    height: 'calc(100vh - 64px)',
    overflowX: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 72px)',
    },
  },
}));

export default useStyles;

import React from 'react';
import axios from 'axios';

import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import { SQUAD_USER_KEYS, ROLE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import {
  LOGIN_API_LINK,
  LOGOUT_API_LINK,
  FORGOT_PASSWORD_API_LINK,
  RESET_PASSWORD_API_LINK,
} from '../../../@jumbo/constants/APIConstants';

const BasicAuth = {
  /* onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = { name: name, email: email, password: password };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    };
  }, */

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        const body = {
          email: email,
          password: password,
        };
        const headers = { 'Content-Type': 'application/json' };
        dispatch(fetchStart());

        axios
          .post(LOGIN_API_LINK, body, { headers })
          .then(response => {
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.status && responseData.status === 'true') {
                const userData = responseData.data.user;
                const user = {};
                user[SQUAD_USER_KEYS.ID] = userData[SQUAD_USER_KEYS.ID];
                user[SQUAD_USER_KEYS.EMAIL] = userData[SQUAD_USER_KEYS.EMAIL];
                user[SQUAD_USER_KEYS.ROLE] = userData[SQUAD_USER_KEYS.ROLE];
                user[SQUAD_USER_KEYS.STATUS] = userData[SQUAD_USER_KEYS.STATUS];
                user[SQUAD_USER_KEYS.AUTH_CODE] = userData[SQUAD_USER_KEYS.AUTH_CODE];
                user[SQUAD_USER_KEYS.AUTH_CODE_EXPIRE_DT] = userData[SQUAD_USER_KEYS.AUTH_CODE_EXPIRE_DT];
                user[SQUAD_USER_KEYS.AUTH_CODE_EXPIRE_TS] = userData[SQUAD_USER_KEYS.AUTH_CODE_EXPIRE_TS];

                const rolesData = responseData.data.role;
                const role = {};
                role[ROLE_KEYS.LOGIN] = rolesData[ROLE_KEYS.LOGIN];
                role[ROLE_KEYS.ACCESS_ALL_JOBS] = rolesData[ROLE_KEYS.ACCESS_ALL_JOBS];
                role[ROLE_KEYS.ACCESS_ASSIGNED_JOBS] = rolesData[ROLE_KEYS.ACCESS_ASSIGNED_JOBS];
                /* role[ROLE_KEYS.DASHBOARD] = rolesData[ROLE_KEYS.DASHBOARD];
                role[ROLE_KEYS.ACCOUNT_SETTINGS] = rolesData[ROLE_KEYS.ACCOUNT_SETTINGS]; */
                role[ROLE_KEYS.USER_SETTINGS] = rolesData[ROLE_KEYS.USER_SETTINGS];
                /* role[ROLE_KEYS.MESSAGING] = rolesData[ROLE_KEYS.MESSAGING];
                role[ROLE_KEYS.CLIENTS] = rolesData[ROLE_KEYS.CLIENTS];
                role[ROLE_KEYS.JOB_SETTINGS] = rolesData[ROLE_KEYS.JOB_SETTINGS];
                role[ROLE_KEYS.NOTIFICATIONS] = rolesData[ROLE_KEYS.NOTIFICATIONS];
                role[ROLE_KEYS.ADD_JOBS] = rolesData[ROLE_KEYS.ADD_JOBS];
                role[ROLE_KEYS.MODIFY_DONE_JOBS] = rolesData[ROLE_KEYS.MODIFY_DONE_JOBS];
                role[ROLE_KEYS.OTHER_USER_JOBS] = rolesData[ROLE_KEYS.OTHER_USER_JOBS];
                role[ROLE_KEYS.JOB_HISTORY] = rolesData[ROLE_KEYS.JOB_HISTORY];
                role[ROLE_KEYS.CHARGE_CREDIT_CARDS] = rolesData[ROLE_KEYS.CHARGE_CREDIT_CARDS];
                role[ROLE_KEYS.CLOSE_JOBS] = rolesData[ROLE_KEYS.CLOSE_JOBS];
                role[ROLE_KEYS.CANCEL_JOBS] = rolesData[ROLE_KEYS.CANCEL_JOBS];
                role[ROLE_KEYS.OVERRIDE_RATES] = rolesData[ROLE_KEYS.OVERRIDE_RATES];
                role[ROLE_KEYS.UPDATE_JOB_COST] = rolesData[ROLE_KEYS.UPDATE_JOB_COST];
                role[ROLE_KEYS.SETTLE_JOBS] = rolesData[ROLE_KEYS.SETTLE_JOBS];
                role[ROLE_KEYS.CHANGE_JOB_TYPE] = rolesData[ROLE_KEYS.CHANGE_JOB_TYPE];
                role[ROLE_KEYS.CALENDAR_SYNC] = rolesData[ROLE_KEYS.CALENDAR_SYNC];
                role[ROLE_KEYS.LEADS] = rolesData[ROLE_KEYS.LEADS];
                role[ROLE_KEYS.LEAD_SETTINGS] = rolesData[ROLE_KEYS.LEAD_SETTINGS];
                role[ROLE_KEYS.VOICE] = rolesData[ROLE_KEYS.VOICE];
                role[ROLE_KEYS.VOICE_SETTINGS] = rolesData[ROLE_KEYS.VOICE_SETTINGS];
                role[ROLE_KEYS.FRANCHISES] = rolesData[ROLE_KEYS.FRANCHISES];
                role[ROLE_KEYS.INVENTORY] = rolesData[ROLE_KEYS.INVENTORY];
                role[ROLE_KEYS.FINANCIAL_DATA] = rolesData[ROLE_KEYS.FINANCIAL_DATA];
                role[ROLE_KEYS.CLIENT_MESSAGES] = rolesData[ROLE_KEYS.CLIENT_MESSAGES];
                role[ROLE_KEYS.FRANCHISE_SETTINGS] = rolesData[ROLE_KEYS.FRANCHISE_SETTINGS];
                role[ROLE_KEYS.JOB_REPORT] = rolesData[ROLE_KEYS.JOB_REPORT];
                role[ROLE_KEYS.COMMISSIONS_REPORT] = rolesData[ROLE_KEYS.COMMISSIONS_REPORT];
                role[ROLE_KEYS.CALL_REPORTS] = rolesData[ROLE_KEYS.CALL_REPORTS];
                role[ROLE_KEYS.ACTIVITY_REPORT] = rolesData[ROLE_KEYS.ACTIVITY_REPORT];
                role[ROLE_KEYS.PAYMENTS_REPORT] = rolesData[ROLE_KEYS.PAYMENTS_REPORT];
                role[ROLE_KEYS.ITEM_SERVICES_REPORT] = rolesData[ROLE_KEYS.ITEM_SERVICES_REPORT];
                role[ROLE_KEYS.INVOICE_REPORT] = rolesData[ROLE_KEYS.INVOICE_REPORT];
                role[ROLE_KEYS.ESTIMATE_REPORT] = rolesData[ROLE_KEYS.ESTIMATE_REPORT];
                role[ROLE_KEYS.BALANCE_REPORT] = rolesData[ROLE_KEYS.BALANCE_REPORT];
                role[ROLE_KEYS.HOURS_REPORT] = rolesData[ROLE_KEYS.HOURS_REPORT];
                role[ROLE_KEYS.STATISTICS_REPORT] = rolesData[ROLE_KEYS.STATISTICS_REPORT];
                role[ROLE_KEYS.AD_STATISTICS] = rolesData[ROLE_KEYS.AD_STATISTICS];
                role[ROLE_KEYS.TECH_STATISTICS] = rolesData[ROLE_KEYS.TECH_STATISTICS];
                role[ROLE_KEYS.AREA_STATISTICS] = rolesData[ROLE_KEYS.AREA_STATISTICS];
                role[ROLE_KEYS.DISPATCH_STATISTICS] = rolesData[ROLE_KEYS.DISPATCH_STATISTICS];
                role[ROLE_KEYS.VIEW_PROFIT] = rolesData[ROLE_KEYS.VIEW_PROFIT];
                role[ROLE_KEYS.LEAD_REPORT] = rolesData[ROLE_KEYS.LEAD_REPORT];
                role[ROLE_KEYS.SALES_REPORT] = rolesData[ROLE_KEYS.SALES_REPORT];
                role[ROLE_KEYS.METRO_AREAS_ACCESS_IDS] = rolesData[ROLE_KEYS.METRO_AREAS_ACCESS_IDS];
                role[ROLE_KEYS.USERS_ACCESS_IDS] = rolesData[ROLE_KEYS.USERS_ACCESS_IDS];
                role[ROLE_KEYS.STATUS_ACCESS_IDS] = rolesData[ROLE_KEYS.STATUS_ACCESS_IDS]; */

                user[SQUAD_USER_KEYS.PERMISSIONS] = role;
                user[SQUAD_USER_KEYS.ROLE_NAME] = rolesData[ROLE_KEYS.NAME];

                dispatch(fetchSuccess());
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(setAuthUser(user));
              } else {
                dispatch(fetchError(responseData.message ? responseData.message : 'There was a problem'));
              }
            } else {
              dispatch(fetchError('Something went wrong'));
            }
          })
          .catch(error => {
            dispatch(fetchError('Something went wrong'));
          });

        /* setTimeout(() => {
          const user = { name: 'Admin', email: email, password: password };
          dispatch(fetchSuccess());
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(setAuthUser(user));
        }, 300); */
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: ({ tokenExpire = false } = {}) => {
    const authUser = JSON.parse(localStorage.getItem('user'));
    const authCode = authUser && authUser.authcode ? authUser.authcode : null;

    return dispatch => {
      try {
        const body = {
          authcode: authCode,
        };

        const headers = {
          headers: {
            'Content-Type': 'application/json',
            /* Authorization: `Bearer ${authCode}`, */
          },
        };

        dispatch(fetchStart());

        axios
          .post(LOGOUT_API_LINK, body, headers)
          .then(response => {
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.status && responseData.status === 'true') {
                tokenExpire ? dispatch(fetchError('Session has expired. Please login again.')) : dispatch(fetchSuccess());
                localStorage.removeItem('user');
                dispatch(setAuthUser(null));
              } else {
                dispatch(fetchError(responseData.message ? responseData.message : 'There was a problem'));
              }
            } else {
              dispatch(fetchError('Something went wrong'));
            }
          })
          .catch(error => {
            dispatch(fetchError('Something went wrong'));
          });

        /* setTimeout(() => {
          dispatch(fetchSuccess());
          localStorage.removeItem('user');
          dispatch(setAuthUser(null));
        }, 300); */
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
      }, 300);
    };
  },

  onForgotPassword: (request = {}, callbackFun) => {
    return dispatch => {
      try {
        const body = request.authData;
        const headers = { 'Content-Type': 'application/json' };
        dispatch(fetchStart());

        axios
          .post(FORGOT_PASSWORD_API_LINK, body, { headers })
          .then(response => {
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.status && responseData.status === 'true') {
                dispatch(setForgetPassMailSent(true));
                dispatch(fetchSuccess());
                if (callbackFun) callbackFun();
              } else {
                dispatch(fetchError(responseData.message ? responseData.message : 'There was a problem'));
              }
            } else {
              dispatch(fetchError('Something went wrong'));
            }
          })
          .catch(error => {
            dispatch(fetchError('Something went wrong'));
          });

        /* dispatch(fetchStart());

        setTimeout(() => {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        }, 300); */
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onUpdatePassword: (request = {}, callbackFun, errorCallbackFun) => {
    return dispatch => {
      try {
        const body = request.authData;
        const headers = { 'Content-Type': 'application/json' };
        dispatch(fetchStart());

        axios
          .post(RESET_PASSWORD_API_LINK, body, { headers })
          .then(response => {
            let isError = false;
            let errorMsg = null;
            let apiErrors = null;

            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.status && responseData.status === 'true') {
                dispatch(fetchSuccess('Password updated successfully!'));
                if (callbackFun) callbackFun();
              } else {
                isError = true;
                errorMsg = responseData.message ? responseData.message : 'There was a problem';
                apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
              }
            } else {
              isError = true;
              errorMsg = 'Something went wrong';
            }

            if (isError) {
              dispatch(fetchError(errorMsg));
              if (errorCallbackFun) errorCallbackFun(apiErrors);
            }
          })
          .catch(error => {
            dispatch(fetchError('Something went wrong'));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  /* getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  }, */
};

export default BasicAuth;

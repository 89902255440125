import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { JOB_TYPE_KEYS, ACTIVE_JOB_TYPE, INACTIVE_JOB_TYPE } from '../../../@jumbo/constants/AppDataConstants';

import { getJobTypes, setCurrentJobType, updateJobType } from '../../../redux/actions/JobTypes';
import AddEditJobType from './AddEditJobType';
import JobTypesTableToolbar from './JobTypesTableToolbar';
import JobTypesTableHead from './JobTypesTableHead';
import JobTypeListRow from './JobTypeListRow';
import Alert from '../../UI/Alert';
import NoRecordFound from '../../NoRecordFound';

import useStyles from './index.style';

const pageName = 'Job Types';

const breadcrumbs = [
  { label: 'Settings', link: '/settings' },
  { label: pageName, isActive: true },
];

const JobTypes = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);

  const [filteredJobTypes, setFilteredJobTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [orderBy, setOrderBy] = useState(JOB_TYPE_KEYS.NAME);
  const [order, setOrder] = useState('asc');
  const [jobTypesFetched, setJobTypesFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddEditJobType, setOpenAddEditJobType] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(
          getJobTypes(payload, () => {
            if (isActive) setJobTypesFetched(true);
          }),
        );
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise.catch(error => {
      /* Setting to 'true' means API has been executed, not necessarily successfully */
      if (isActive) setJobTypesFetched(true);
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    let tempFilteredJobTypes = jobTypes;

    if (filterOptions.length > 0) {
      tempFilteredJobTypes = tempFilteredJobTypes.filter(jobType => filterOptions.includes(jobType[JOB_TYPE_KEYS.STATUS]));
    }

    if (debouncedSearchTerm) {
      tempFilteredJobTypes = tempFilteredJobTypes.filter(jobType => {
        return (
          (jobType[JOB_TYPE_KEYS.NAME] &&
            jobType[JOB_TYPE_KEYS.NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
          /* (jobType[JOB_TYPE_KEYS.DISPLAY_ORDER] && jobType[JOB_TYPE_KEYS.DISPLAY_ORDER].toString().includes(debouncedSearchTerm.toLowerCase()) ||) */
          /* (jobType[JOB_TYPE_KEYS.DAYS] &&
            jobType[JOB_TYPE_KEYS.DAYS].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (jobType[JOB_TYPE_KEYS.HOURS] &&
            jobType[JOB_TYPE_KEYS.HOURS].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (jobType[JOB_TYPE_KEYS.MINUTES] &&
            jobType[JOB_TYPE_KEYS.MINUTES].toString().includes(debouncedSearchTerm.toLowerCase())) || */
          (jobType[JOB_TYPE_KEYS.STATUS] &&
            jobType[JOB_TYPE_KEYS.STATUS].toString().includes(debouncedSearchTerm.toLowerCase()))
        );
      });
    }

    setFilteredJobTypes(tempFilteredJobTypes);
    setFilterApplied(filterOptions.length > 0 || !!debouncedSearchTerm);
  }, [jobTypes, filterOptions, debouncedSearchTerm]);

  const handleOpenJobTypeDialog = () => {
    setOpenAddEditJobType(true);
  };

  const handleCloseJobTypeDialog = () => {
    setOpenAddEditJobType(false);
    dispatch(setCurrentJobType(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleJobTypeEdit = jobType => {
    dispatch(setCurrentJobType(jobType));
    setOpenAddEditJobType(true);
  };

  const handleJobTypeStatusChange = jobType => {
    if (
      !jobType ||
      !jobType[JOB_TYPE_KEYS.ID] ||
      !jobType[JOB_TYPE_KEYS.STATUS] ||
      ![ACTIVE_JOB_TYPE, INACTIVE_JOB_TYPE].includes(jobType[JOB_TYPE_KEYS.STATUS])
    ) {
      Alert({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid job type',
      });
      return false;
    }

    const status = jobType[JOB_TYPE_KEYS.STATUS] === '0' ? '1' : '0';
    const jobTypeData = {
      authcode: authUser.authcode,
      [JOB_TYPE_KEYS.JOB_TYPE_ID]: jobType[JOB_TYPE_KEYS.ID],
      [JOB_TYPE_KEYS.STATUS]: status,
    };
    dispatch(
      updateJobType({ jobTypeData: jobTypeData }, null, messages => {
        const msgArray = messages ? Object.values(messages) : [];
        if (_isMounted.current && msgArray.length > 0) {
          Alert({
            icon: 'error',
            title: 'Oops...',
            html: msgArray.join('<br>'),
          });
        }
      }),
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: JOB_TYPE_KEYS.NAME, disablePadding: false, label: 'Name' },
    /* { id: JOB_TYPE_KEYS.DISPLAY_ORDER, disablePadding: false, label: 'Display Order' }, */
    /* { id: JOB_TYPE_KEYS.DURATION, disablePadding: false, label: 'Time to Complete' }, */
    { id: JOB_TYPE_KEYS.STATUS, disablePadding: false, label: 'Status' },
  ];

  /* Add 1 because there is an 'actions' column */
  const colspan = headCells && headCells.length > 0 ? headCells.length + 1 : 1;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12} className={classes.orderLg1}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenJobTypeDialog}>
            Add New
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.orderLg2}>
          <Paper className={classes.paper}>
            <JobTypesTableToolbar
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="jobTypesTableTitle"
                aria-label="Job Types Table">
                <JobTypesTableHead
                  headCells={headCells}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!!filteredJobTypes.length ? (
                    stableSort(filteredJobTypes, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <JobTypeListRow
                          key={index}
                          row={row}
                          onJobTypeEdit={handleJobTypeEdit}
                          onStatusChange={handleJobTypeStatusChange}
                        />
                      ))
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={colspan} rowSpan={10}>
                        {isFilterApplied ? (
                          <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                        ) : (
                          <NoRecordFound>
                            {jobTypesFetched ? 'There are no records found.' : 'Loading job types...'}
                          </NoRecordFound>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={filteredJobTypes.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Grid>
      </GridContainer>

      {openAddEditJobType && <AddEditJobType open={openAddEditJobType} onCloseDialog={handleCloseJobTypeDialog} />}
    </PageContainer>
  );
};

export default JobTypes;

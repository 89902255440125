import { GET_ESTIMATE_SIGNATURES, ADD_ESTIMATE_SIGNATURE } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  estimateSignatures: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTIMATE_SIGNATURES: {
      return {
        ...state,
        estimateSignatures: action.payload,
      };
    }
    case ADD_ESTIMATE_SIGNATURE: {
      return {
        ...state,
        estimateSignatures: [action.payload, ...state.estimateSignatures],
      };
    }
    default:
      return state;
  }
};

export const BACKEND_BASE_URL = 'https://schedulerapi.aaarousse.com';
export const FROTNEND_BASE_URL = 'https://scheduler.aaarousse.com';

// login, signup, forgot password
export const LOGIN_API_LINK = `${BACKEND_BASE_URL}/login/authenticate`;
export const LOGOUT_API_LINK = `${BACKEND_BASE_URL}/logout`;
export const FORGOT_PASSWORD_API_LINK = `${BACKEND_BASE_URL}/login/sendpassword_request`;
export const RESET_PASSWORD_API_LINK = `${BACKEND_BASE_URL}/login/resetpassword_request`;

// roles
export const GET_ROLES_LINK = `${BACKEND_BASE_URL}/role/list`;
export const ADD_ROLE_LINK = `${BACKEND_BASE_URL}/role/create`;
export const UPDATE_ROLE_LINK = `${BACKEND_BASE_URL}/role/update`;
export const DELETE_ROLE_LINK = `${BACKEND_BASE_URL}/role/delete`;

// job types
export const GET_JOB_TYPES_LINK = `${BACKEND_BASE_URL}/jobtype/list`;
export const ADD_JOB_TYPE_LINK = `${BACKEND_BASE_URL}/jobtype/create`;
export const UPDATE_JOB_TYPE_LINK = `${BACKEND_BASE_URL}/jobtype/update`;

// taxes
export const GET_TAXES_LINK = `${BACKEND_BASE_URL}/tax/list`;
export const ADD_TAX_LINK = `${BACKEND_BASE_URL}/tax/create`;
export const UPDATE_TAX_LINK = `${BACKEND_BASE_URL}/tax/update`;

// products
export const GET_PRODUCTS_LINK = `${BACKEND_BASE_URL}/item/list`;
export const GET_PRODUCT_LINK = `${BACKEND_BASE_URL}/item`;
export const ADD_PRODUCT_LINK = `${BACKEND_BASE_URL}/item/create`;
export const UPDATE_PRODUCT_LINK = `${BACKEND_BASE_URL}/item/update`;

// ad sources
export const GET_AD_SOURCES_LINK = `${BACKEND_BASE_URL}/adsource/list`;

// metro areas
export const GET_METRO_AREAS_LINK = `${BACKEND_BASE_URL}/metroarea/list`;

// squad
export const GET_SQUAD_USERS_LINK = `${BACKEND_BASE_URL}/user/list`;
export const GET_SQUAD_USER_LINK = `${BACKEND_BASE_URL}/user`;
export const GET_SQUAD_FILTERS_LINK = `${BACKEND_BASE_URL}/userfilter/list`;
export const ADD_SQUAD_USER_LINK = `${BACKEND_BASE_URL}/user/create`;
export const UPDATE_SQUAD_USER_LINK = `${BACKEND_BASE_URL}/user/update`;
export const UPDATE_SQUAD_USER_STATUS_LINK = `${BACKEND_BASE_URL}/user/update_status`;
export const UPDATE_SQUAD_USER_PASSWORD_LINK = `${BACKEND_BASE_URL}/user/reset_password`;
export const CHECK_SQUAD_USER_INVITE_LINK = `${BACKEND_BASE_URL}/user/check_invite`;
export const USER_ACCEPT_INVITE_LINK = `${BACKEND_BASE_URL}/user/invite_user_reset_password`;
export const USER_RESEND_INVITE_LINK = `${BACKEND_BASE_URL}/user/resend_invite`;
export const DELETE_SQUAD_USER_LINK = `${BACKEND_BASE_URL}/user/delete`;

// user type
export const GET_USER_TYPES_LINK = `${BACKEND_BASE_URL}/usertype/list`;

// color
export const GET_COLORS_LINK = `${BACKEND_BASE_URL}/color/list`;

// customer
export const GET_CUSTOMERS_LINK = `${BACKEND_BASE_URL}/client/list`;
export const GET_CUSTOMER_LINK = `${BACKEND_BASE_URL}/client`;
export const ADD_CUSTOMER_LINK = `${BACKEND_BASE_URL}/client/create`;
export const UPDATE_CUSTOMER_LINK = `${BACKEND_BASE_URL}/client/update`;
export const DELETE_CUSTOMER_LINK = `${BACKEND_BASE_URL}/client/delete`;
export const CUSTOMERS_SEARCH_LINK = `${BACKEND_BASE_URL}/client/list_with_search`;
export const CUSTOMERS_EMAIL_SEARCH_LINK = `${BACKEND_BASE_URL}/client/list_with_search_email`;
export const GET_CUSTOMER_JOBS_LINK = `${BACKEND_BASE_URL}/client/jobslist`;
export const GET_CUSTOMER_ESTIMATES_LINK = `${BACKEND_BASE_URL}/estimate/clientestimates`;
export const GET_CUSTOMER_INVOICES_LINK = `${BACKEND_BASE_URL}/invoice/clientinvoices`;

// customer note
export const GET_CUSTOMER_NOTES_LINK = `${BACKEND_BASE_URL}/note/list`;
export const GET_CUSTOMER_NOTE_LINK = `${BACKEND_BASE_URL}/note`;
export const ADD_CUSTOMER_NOTE_LINK = `${BACKEND_BASE_URL}/note/create`;
export const UPDATE_CUSTOMER_NOTE_LINK = `${BACKEND_BASE_URL}/note/update`;
export const DELETE_CUSTOMER_NOTE_LINK = `${BACKEND_BASE_URL}/note/delete`;

// customer property
export const GET_CUSTOMER_PROPERTIES_LINK = `${BACKEND_BASE_URL}/property/list`;
export const SEARCH_CUSTOMER_PROPERTIES_LINK = `${BACKEND_BASE_URL}/client/properties`;
export const ADD_CUSTOMER_PROPERTY_LINK = `${BACKEND_BASE_URL}/property/create`;
export const ADD_CUSTOMER_PROPERTY_WITH_TYPE_LINK = `${BACKEND_BASE_URL}/property/create_with_type`;
export const UPDATE_CUSTOMER_PROPERTY_LINK = `${BACKEND_BASE_URL}/property/update`;
export const UPDATE_CUSTOMER_PROPERTY_WITH_TYPE_LINK = `${BACKEND_BASE_URL}/property/update_with_type`;
export const DELETE_CUSTOMER_PROPERTY_LINK = `${BACKEND_BASE_URL}/property/delete`;

// country
export const GET_COUNTRIES_LINK = `${BACKEND_BASE_URL}/country/list`;

// state
export const GET_USA_STATES_LINK = `${BACKEND_BASE_URL}/state/list`;

// jobs
export const GET_JOBS_LINK = `${BACKEND_BASE_URL}/job/list`;
export const GET_JOB_LINK = `${BACKEND_BASE_URL}/job`;
export const ADD_JOB_LINK = `${BACKEND_BASE_URL}/job/create`;
export const SAVE_JOB_PROPERTY_LINK = `${BACKEND_BASE_URL}/job/create_property_job`;
export const UPDATE_JOB_LINK = `${BACKEND_BASE_URL}/job/update`;
export const UPDATE_JOB_STATUS_LINK = `${BACKEND_BASE_URL}/job/status`;
export const MARK_JOB_DONE_LINK = `${BACKEND_BASE_URL}/job/mark_done`;
export const DUPLICATE_JOB_LINK = `${BACKEND_BASE_URL}/job/duplicate`;
export const DELETE_JOB_LINK = `${BACKEND_BASE_URL}/job/delete`;
export const GET_JOB_STATUS_LIST_LINK = `${BACKEND_BASE_URL}/status/list`;
export const GET_JOB_FILTERS_LINK = `${BACKEND_BASE_URL}/jobfilter/list`;
export const SEND_CUSTOMER_JOB_LINK = `${BACKEND_BASE_URL}/job/sendjoblink`;
export const GET_JOB_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/job/jobdetailnoauth`;
export const CONFIRM_JOB_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/job/customerconfirmjob`;
export const UPDATE_JOB_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/job/updatescheduled`;
export const CANCEL_JOB_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/job/canceljob`;
export const CONFIRM_JOB_FOR_MEMBER_LINK = `${BACKEND_BASE_URL}/job/jobconfirmbyteam`;

// job products
export const GET_JOB_PRODUCTS_LINK = `${BACKEND_BASE_URL}/job/list_jobitem`;
export const ADD_JOB_PRODUCT_LINK = `${BACKEND_BASE_URL}/job/create_jobitem`;
export const UPDATE_JOB_PRODUCT_LINK = `${BACKEND_BASE_URL}/job/update_jobitem`;
export const UPDATE_JOB_PRODUCT_TOTALS_LINK = `${BACKEND_BASE_URL}/job/update_jobitemtax`;
export const DELETE_JOB_PRODUCT_LINK = `${BACKEND_BASE_URL}/job/delete_jobitem`;

// job 'job types'
export const GET_JOB_JOB_TYPES_LINK = `${BACKEND_BASE_URL}/job/list_jobservices`;
export const ADD_JOB_JOB_TYPE_LINK = `${BACKEND_BASE_URL}/job/create_jobservices`;
export const UPDATE_JOB_JOB_TYPE_LINK = `${BACKEND_BASE_URL}/job/update_jobservice`;
export const UPDATE_JOB_JOB_TYPE_TOTALS_LINK = UPDATE_JOB_PRODUCT_TOTALS_LINK;
export const DELETE_JOB_JOB_TYPE_LINK = `${BACKEND_BASE_URL}/job/delete_jobservice`;

// job payments
export const GET_JOB_PAYMENTS_LINK = `${BACKEND_BASE_URL}/jobPayments/list`;
export const ADD_JOB_PAYMENT_LINK = `${BACKEND_BASE_URL}/jobPayment/create`;

// job files
export const GET_JOB_FILES_LINK = `${BACKEND_BASE_URL}/jobFiles/list`;
export const ADD_JOB_FILE_LINK = `${BACKEND_BASE_URL}/jobFile/create`;

// invoices
export const GET_INVOICES_LINK = `${BACKEND_BASE_URL}/invoice/list`;
export const GET_INVOICE_LINK = `${BACKEND_BASE_URL}/invoice`;
export const ADD_INVOICE_LINK = `${BACKEND_BASE_URL}/invoice/create`;
export const UPDATE_INVOICE_LINK = `${BACKEND_BASE_URL}/invoice/update`;
export const DELETE_INVOICE_LINK = `${BACKEND_BASE_URL}/invoice/delete`;
export const GET_INVOICE_STATUS_LIST_LINK = `${BACKEND_BASE_URL}/invoicestatus/list`;
export const UPDATE_INVOICE_STATUS_LINK = `${BACKEND_BASE_URL}/invoice/updatestatus`;
export const UPDATE_INVOICE_NOTE_LINK = `${BACKEND_BASE_URL}/invoice/upinvoicenote`;
export const PREVIEW_INVOICE_LINK = `${BACKEND_BASE_URL}/invoice/invoice_preview`;
export const DOWNLOAD_INVOICE_LINK = PREVIEW_INVOICE_LINK;
export const SEND_INVOICE_LINK = `${BACKEND_BASE_URL}/invoice/send_invoice`;
export const MARK_INVOICE_SENT_LINK = `${BACKEND_BASE_URL}/invoice/updatemailstatus`;
export const GET_INVOICE_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/invoice/invoicedetail`;
export const PREVIEW_INVOICE_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/invoice/invoice_preview_cust`;
export const DOWNLOAD_INVOICE_FOR_CUSTOMER_LINK = PREVIEW_INVOICE_FOR_CUSTOMER_LINK;
export const PAY_INVOICE_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/payment/process`;

// invoice products
export const ADD_INVOICE_PRODUCT_LINK = ADD_JOB_PRODUCT_LINK;
export const UPDATE_INVOICE_PRODUCT_LINK = UPDATE_JOB_PRODUCT_LINK;
export const UPDATE_INVOICE_PRODUCT_TOTALS_LINK = UPDATE_JOB_PRODUCT_TOTALS_LINK;
export const DELETE_INVOICE_PRODUCT_LINK = DELETE_JOB_PRODUCT_LINK;

// invoice signatures
export const ADD_INVOICE_SIGNATURE_LINK = `${BACKEND_BASE_URL}/invoice/invoicesign`;
export const ADD_INVOICE_SIGNATURE_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/invoice/invoicesignwauth`;

// estimates
export const GET_ESTIMATES_LINK = `${BACKEND_BASE_URL}/estimate/list`;
export const GET_JOB_ESTIMATES_LINK = `${BACKEND_BASE_URL}/estimate/jobestimates`;
export const GET_ESTIMATE_LINK = `${BACKEND_BASE_URL}/estimate`;
export const ADD_ESTIMATE_LINK = `${BACKEND_BASE_URL}/estimate/create`;
export const UPDATE_ESTIMATE_LINK = `${BACKEND_BASE_URL}/estimate/update`;
export const DELETE_ESTIMATE_LINK = `${BACKEND_BASE_URL}/estimate/delete`;
export const GET_ESTIMATE_STATUS_LIST_LINK = `${BACKEND_BASE_URL}/estimatestatus/list`;
export const UPDATE_ESTIMATE_STATUS_LINK = `${BACKEND_BASE_URL}/estimate/updatestatus`;
export const UPDATE_ESTIMATE_NOTE_LINK = `${BACKEND_BASE_URL}/estimate/upestimatenote`;
export const PREVIEW_ESTIMATE_LINK = `${BACKEND_BASE_URL}/estimate/estimate_preview`;
export const SEND_ESTIMATE_LINK = `${BACKEND_BASE_URL}/estimate/send_estimate`;
export const DOWNLOAD_ESTIMATE_LINK = PREVIEW_ESTIMATE_LINK;
export const GET_ESTIMATE_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/estimate/estimatedetail`;
export const UPDATE_ESTIMATE_STATUS_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/estimate/estimateaccstatus`;
export const PREVIEW_ESTIMATE_FOR_CUSTOMER_LINK = `${BACKEND_BASE_URL}/estimate/estimate_preview_cust`;
export const DOWNLOAD_ESTIMATE_FOR_CUSTOMER_LINK = PREVIEW_ESTIMATE_FOR_CUSTOMER_LINK;

// estimate products
export const ADD_ESTIMATE_PRODUCT_LINK = ADD_JOB_PRODUCT_LINK;
export const UPDATE_ESTIMATE_PRODUCT_LINK = UPDATE_JOB_PRODUCT_LINK;
export const UPDATE_ESTIMATE_PRODUCT_TOTALS_LINK = UPDATE_JOB_PRODUCT_TOTALS_LINK;
export const DELETE_ESTIMATE_PRODUCT_LINK = DELETE_JOB_PRODUCT_LINK;

// estimate signatures
export const ADD_ESTIMATE_SIGNATURE_LINK = `${BACKEND_BASE_URL}/estimate/estimatesign`;

// schedule
export const GET_SCHEDULE_LINK = `${BACKEND_BASE_URL}/job/calendar`;

// dashboard
export const GET_DASHBOARD_DATA_LINK = `${BACKEND_BASE_URL}/job/dashboard`;

// squad portal
export const SQUAD_PORTAL_JOB_CONFIRMATION_LINK = `${FROTNEND_BASE_URL}/memberPortal/confirmJob`;

// customer portal
export const CUSTOMER_PORTAL_ESTIMATE_LINK = `${FROTNEND_BASE_URL}/customerPortal/estimate`;
export const CUSTOMER_PORTAL_INVOICE_LINK = `${FROTNEND_BASE_URL}/customerPortal/invoice`;
export const CUSTOMER_PORTAL_JOB_LINK = `${FROTNEND_BASE_URL}/customerPortal/job`;
export const CUSTOMER_PORTAL_MESSAGE_LINK = `${FROTNEND_BASE_URL}/customerPortal/message`;
export const CUSTOMER_PORTAL_JOB_CONFIRMATION_LINK = `${FROTNEND_BASE_URL}/customerPortal/confirmJob`;

// messages
export const GET_MESSAGES_JOBS_LINK = `${BACKEND_BASE_URL}/message/joblist`;
export const GET_MESSAGES_JOB_USERS_LINK = `${BACKEND_BASE_URL}/message/jobusers`;
export const GET_USER_MESSAGES_LINK = `${BACKEND_BASE_URL}/message/getusermessages`;
export const SEND_USER_MESSAGE_LINK = `${BACKEND_BASE_URL}/message/sendmessage`;
export const GET_CUSTOMER_MESSAGE_LINK = `${BACKEND_BASE_URL}/message/getsinglemsg`;
export const SEND_CUSTOMER_MESSAGE_LINK = `${BACKEND_BASE_URL}/message/sendreply`;

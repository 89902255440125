import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { useDebounce } from '../../@jumbo/utils/commonHelper';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { JOB_KEYS } from '../../@jumbo/constants/AppDataConstants';

import { getColors } from '../../redux/actions/Colors';
/* import { getMetroAreas } from '../../redux/actions/MetroAreas'; */
import { getJobTypes } from '../../redux/actions/JobTypes';
import { getSquadUsers } from '../../redux/actions/Squad';
import { getFilteredJobs } from '../../redux/actions/Jobs';
import { getJobStatusList } from '../../redux/actions/JobStatuses';

import JobsListHeader from './JobsListHeader';
import JobsTable from './JobsTable';

import useStyles from './index.style';

const pageName = 'Jobs';
const breadcrumbs = [{ label: pageName, isActive: true }];

const JobsNew = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { jobs, jobCounts } = useSelector(({ jobsReducer }) => jobsReducer);
  const { authUser } = useSelector(({ auth }) => auth);

  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({
    [JOB_KEYS.USER_ID]: [],
    /* [JOB_KEYS.TAG_ID]: [], */
    /* [JOB_KEYS.SERVICE_AREA_ID]: [], */
    [JOB_KEYS.JOB_TYPE_ID]: [],
    [JOB_KEYS.STATUS_ID]: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ orderBy: JOB_KEYS.CREATED_AT, order: 'desc' });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const [jobsFetching, setJobsFetching] = useState(true);
  const [jobStatusFetched, setJobStatusFetched] = useState(false);
  const [jobsFetched, setJobsFetched] = useState(false);
  const [jobsExportFetched, setJobsExportFetched] = useState(true);
  const [exportCSV, setExportCSV] = useState('0');
  const [fetchCriteria, setFetchCriteria] = useState(null);
  const [jobsFilterChanged, setJobsFilterChanged] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getColors(payload)).then(() => {
          /* return dispatch(getMetroAreas(payload)).then(() => { */
          return dispatch(getJobTypes(payload)).then(() => {
            return dispatch(getSquadUsers(payload)).then(() => {
              return dispatch(getJobStatusList(payload));
            });
          });
          /* }); */
        });
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise
      .then(() => {
        if (isActive) {
          setJobStatusFetched(true);
        }
      })
      .catch(error => {
        if (isActive) {
          setJobStatusFetched(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    setFetchCriteria({
      filters: filters,
      search: debouncedSearchTerm,
      order: sort.order,
      orderBy: sort.orderBy,
      exportCSV: '0',
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
    });

    if (!jobsFilterChanged) {
      setJobsFetched(false);
    } else {
      setPagination(prevState => {
        return { ...prevState, page: 0 };
      });
      setJobsFilterChanged(false);
    }
  }, [filters, debouncedSearchTerm, sort, pagination, jobsFilterChanged]);

  useEffect(() => {
    if (exportCSV === '1') {
      setFetchCriteria(prevState => {
        return {
          ...prevState,
          exportCSV: '1',
        };
      });
      setJobsExportFetched(false);
    }
  }, [exportCSV]);

  useEffect(() => {
    let isActive = true;

    if (jobStatusFetched && (!jobsFetched || !jobsExportFetched)) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getFilteredJobs(payload, () => {
              if (isActive) {
                setJobsFetching(false);
                setJobsFetched(true);
                setJobsExportFetched(true);
                setExportCSV('0');
              }
            }),
          );
        };
      };

      const tempFilters = { ...fetchCriteria.filters };

      let tempOrderBy = fetchCriteria.orderBy;
      switch (fetchCriteria.orderBy) {
        case JOB_KEYS.NAME:
          tempOrderBy = JOB_KEYS.CUSTOMER_FNAME;
          break;

        default:
          break;
      }

      const jobData = {
        authcode: authUser.authcode,
        filters: {
          user_id:
            tempFilters[JOB_KEYS.USER_ID] && tempFilters[JOB_KEYS.USER_ID].length > 0
              ? tempFilters[JOB_KEYS.USER_ID].join(',')
              : '',
          job_type_id:
            tempFilters[JOB_KEYS.JOB_TYPE_ID] && tempFilters[JOB_KEYS.JOB_TYPE_ID].length > 0
              ? tempFilters[JOB_KEYS.JOB_TYPE_ID].join(',')
              : '',
          status_id:
            tempFilters[JOB_KEYS.STATUS_ID] && tempFilters[JOB_KEYS.STATUS_ID].length > 0
              ? tempFilters[JOB_KEYS.STATUS_ID].join(',')
              : '',
        },
        search: fetchCriteria.search,
        order: fetchCriteria.order,
        order_by: tempOrderBy,
        export_list: fetchCriteria.exportCSV,
        page: fetchCriteria.page + 1,
        rows_per_page: fetchCriteria.rowsPerPage,
      };

      const promise = dispatch(fetchData({ jobData: jobData }));
      promise.catch(error => {
        /* Setting to 'true' means API has been executed, not necessarily successfully */
        if (isActive) {
          setJobsFetching(false);
          setJobsFetched(true);
          setJobsExportFetched(true);
          setExportCSV('0');
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobStatusFetched, jobsFetched, jobsExportFetched, fetchCriteria]);

  const handleSetFilterChanged = () => setJobsFilterChanged(true);

  const onSearchChipDelete = () => setSearchTerm('');

  const handleRequestSort = (event, property) => {
    const isAsc = sort.orderBy === property && sort.order === 'asc';
    setSort(prevState => {
      return { ...prevState, orderBy: property, order: isAsc ? 'desc' : 'asc' };
    });
  };

  const handlePageChange = (event, newPage) => {
    setPagination(prevState => {
      return { ...prevState, page: newPage };
    });
  };

  const handleRowsPerPageChange = event => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleRowClick = (event, id) => history.push(`/job/${id}/`);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = jobs.map(n => n[JOB_KEYS.ID]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  /* const handleExportJobsList = () => setExportCSV('1'); */

  const handleBulkJobDeletion = () => {};

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      {!jobsFetching && jobStatusFetched && (
        <GridContainer>
          <Grid item xs={12}>
            <JobsListHeader
              filters={filters}
              numSelected={selected.length}
              setFilters={setFilters}
              onBulkDeleteJobs={handleBulkJobDeletion}
              handleSetFilterChanged={handleSetFilterChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <JobsTable
                classes={classes}
                jobs={jobs}
                jobCounts={jobCounts}
                jobsFetched={jobsFetched}
                order={sort.order}
                orderBy={sort.orderBy}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                searchTerm={searchTerm}
                numSelected={selected.length}
                setSearchTerm={setSearchTerm}
                isSelected={isSelected}
                onSearchChipDelete={onSearchChipDelete}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                handleCheckboxClick={handleCheckboxClick}
                handleRowClick={handleRowClick}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                /* handleExportJobsList={handleExportJobsList} */
              />
            </Paper>
          </Grid>
        </GridContainer>
      )}

      {!jobsFetching && !jobStatusFetched && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Status data not found</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default JobsNew;

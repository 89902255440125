import React, { useEffect /* , useState */ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

/* import { useDebounce } from '../../../@jumbo/utils/commonHelper'; */
import { MESSAGE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { getMessagesUsers, setCurrentUser, setUsersList } from '../../../redux/actions/Messages';

/* import SidebarHeader from '../SidebarHeader'; */
import UsersList from '../UsersList';

import useStyles from '../index.style';

const Sidebar = ({ jobId }) => {
  const { isSideBarCollapsed } = useSelector(({ messagesReducer }) => messagesReducer);
  const classes = useStyles({ isCollapsed: isSideBarCollapsed });
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);

  /* const [searchTerm, setSearchTerm] = useState(''); */

  /* const debouncedSearchTerm = useDebounce(searchTerm, 500); */

  useEffect(() => {
    return () => {
      dispatch(setCurrentUser(null));
      dispatch(setUsersList([]));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getMessagesUsers(payload));
      };
    };

    const messageData = {
      authcode: authUser.authcode,
      /* search: debouncedSearchTerm, */
      [MESSAGE_KEYS.JOB_ID]: jobId,
    };

    const promise = dispatch(fetchData({ messageData: messageData }));
    promise.catch(error => {
      if (isActive) {
        dispatch(setCurrentUser(null));
      }
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId /* , debouncedSearchTerm */]);

  const handleUserSelect = user => dispatch(setCurrentUser(user));

  return (
    <Box className={classes.inBuildAppSidebar}>
      {/* <SidebarHeader search={searchTerm} setSearch={setSearchTerm} /> */}
      <UsersList onContactSelect={handleUserSelect} />
    </Box>
  );
};

Sidebar.prototype = {
  jobId: PropTypes.string.isRequired,
};

export default Sidebar;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CreateIcon from '@material-ui/icons/Create';
import Alert from '@material-ui/lab/Alert';

import GridContainer from '../../../@jumbo/components/GridContainer';
import {
  INVOICE_KEYS,
  INVOICE_PRODUCTS_TOTALS_KEYS,
  INVOICE_PAYMENT_KEYS,
  ALLOW_CUSTOMER_TO_PAY_INVOICE,
  INVOICE_PAYMENT_COMPLETE,
  INVOICE_PAYMENT_NOT_COMPLETE,
  INVOICE_PAYMENT_NOT_CERTAIN,
} from '../../../@jumbo/constants/AppDataConstants';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import { isCardDateValid } from '../../../@jumbo/utils/dateHelper';
import { /* amountNotValid, */ emailNotValid, requiredMessage } from '../../../@jumbo/constants/ErrorMessages';

import { payInvoiceForCustomer } from '../../../redux/actions/Invoices';

import { /* isValidAmount, */ isPositiveInteger } from '../../../utils/FormValidation';

const CardNumberFormat = props => {
  /**
   * Value can be passed as string or number,
   * but if it is passed as string it should be either formatted value
   * or if it is a numeric string, you have to set isNumericString props to true.
   */
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            formattedValue: values.formattedValue,
          },
        });
      }}
      isNumericString
      format="#### #### #### ####"
    />
  );
};

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';

      /* this can happen when user paste number */
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function cardMonth(val) {
  const month = limit(val.substring(0, 2), '12');
  return month;
}

const CardMonthFormat = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      format={cardMonth}
      mask={['M', 'M']}
    />
  );
};

function cardYear(val) {
  const year = val.substring(0, 2);
  return year;
}

const CardYearFormat = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      format={cardYear}
      mask={['Y', 'Y']}
    />
  );
};

const CardCVVFormat = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            formattedValue: values.formattedValue,
          },
        });
      }}
      isNumericString
      format="###"
    />
  );
};

const InvoicePayment = ({
  classes,
  invoice,
  customer,
  totals,
  showSignatureBox,
  onSignatureClick,
  onSetInvoiceAfterPayment,
  onSetInvoiceAfterFailedPayment,
}) => {
  const _isMounted = useRef(true);
  const dispatch = useDispatch();

  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardMonth, setCardMonth] = useState(null);
  const [cardMonthError, setCardMonthError] = useState('');
  const [cardYear, setCardYear] = useState(null);
  const [cardYearError, setCardYearError] = useState('');
  const [cardDateError, setCardDateError] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [cardCVVError, setCardCVVError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  /* const [chargeAmount, setChargeAmount] = useState(''); */
  /* const [chargeAmountError, setChargeAmountError] = useState(''); */
  const [transactionNo, setTransactionNo] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (customer) {
      const bill = customer[INVOICE_KEYS.CUSTOMER_BILL];
      setEmail(bill[INVOICE_KEYS.CUSTOMER_EMAIL]);
      setZipcode(bill[INVOICE_KEYS.CUSTOMER_ZIP_CODE]);
    }
  }, [customer]);

  useEffect(() => {
    setCardDateError('');
    if (cardMonth && cardYear && !cardMonthError && !cardYearError) {
      if (!isCardDateValid(cardMonth, cardYear)) {
        setCardDateError('Date is not valid!');
      }
    }
  }, [cardMonth, cardMonthError, cardYear, cardYearError]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case INVOICE_PAYMENT_KEYS.CARD_NUMBER:
          setCardNumberError(msg);
          break;

        case INVOICE_PAYMENT_KEYS.CARD_MONTH:
          setCardMonthError(msg);
          break;

        case INVOICE_PAYMENT_KEYS.CARD_YEAR:
          setCardYearError(msg);
          break;

        case INVOICE_PAYMENT_KEYS.CARD_CVV:
          setCardCVVError(msg);
          break;

        case INVOICE_PAYMENT_KEYS.EMAIL:
          setEmailError(msg);
          break;

        case INVOICE_PAYMENT_KEYS.ZIP_CODE:
          setZipcodeError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const cardNumberChangeHandler = event => {
    const tempCardNumber = event.target.value;

    setCardNumber(tempCardNumber);
    setCardNumberError('');

    if (!tempCardNumber) {
      setCardNumberError(requiredMessage);
    } else if (!isPositiveInteger(tempCardNumber)) {
      setCardNumberError('Card number is not valid!');
    }
  };

  const cardMonthChangeHandler = event => {
    const tempCardMonth = event.target.value;

    setCardMonth(tempCardMonth);
    setCardMonthError('');

    if (!tempCardMonth) {
      setCardMonthError(requiredMessage);
    } else if (!/^\d{2}$/.test(tempCardMonth)) {
      setCardMonthError('Month is not valid!');
    }
  };

  const cardYearChangeHandler = event => {
    const tempCardYear = event.target.value;

    setCardYear(tempCardYear);
    setCardYearError('');

    if (!tempCardYear) {
      setCardYearError(requiredMessage);
    } else if (!/^\d{2}$/.test(tempCardYear)) {
      setCardYearError('Year is not valid!');
    }
  };

  const cardCVVChangeHandler = event => {
    const tempCardCVV = event.target.value;

    setCardCVV(tempCardCVV);
    setCardCVVError('');

    if (!tempCardCVV) {
      setCardCVVError(requiredMessage);
    } else if (!/^\d{3}$/.test(tempCardCVV)) {
      setCardCVVError('CVV is not valid!');
    }
  };

  const zipcodeBlurHandler = event => {
    if (!event.target.value) {
      setZipcodeError(requiredMessage);
    }
  };

  const emailBlurHandler = event => {
    if (!event.target.value) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  };

  /* const chargeBlurHandler = event => {
    if (!event.target.value) {
      setChargeAmountError(requiredMessage);
    } else if (!isValidAmount(event.target.value)) {
      setChargeAmountError(amountNotValid);
    }
  }; */

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!cardNumber) {
      formIsValid = false;
      setCardNumberError(requiredMessage);
    } else if (!isPositiveInteger(cardNumber)) {
      formIsValid = false;
      setCardNumberError('Card number is not valid!');
    }

    if (!cardMonth) {
      formIsValid = false;
      setCardMonthError(requiredMessage);
    } else if (!/^\d{2}$/.test(cardMonth)) {
      formIsValid = false;
      setCardMonthError('Month is not valid!');
    }

    if (!cardYear) {
      formIsValid = false;
      setCardYearError(requiredMessage);
    } else if (!/^\d{2}$/.test(cardYear)) {
      formIsValid = false;
      setCardYearError('Year is not valid!');
    }

    if (!cardCVV) {
      formIsValid = false;
      setCardCVVError(requiredMessage);
    } else if (!/^\d{3}$/.test(cardCVV)) {
      formIsValid = false;
      setCardCVVError('CVV is not valid!');
    }

    if (!zipcode) {
      formIsValid = false;
      setZipcodeError(requiredMessage);
    }

    if (!email) {
      formIsValid = false;
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      formIsValid = false;
      setEmailError(emailNotValid);
    }

    if (formIsValid) {
      const paymentData = {
        [INVOICE_PAYMENT_KEYS.INVOICE_ID]: invoice[INVOICE_KEYS.ID],
        [INVOICE_PAYMENT_KEYS.JOB_ID]: invoice[INVOICE_KEYS.JOB_ID],
        [INVOICE_PAYMENT_KEYS.CARD_NUMBER]: cardNumber,
        [INVOICE_PAYMENT_KEYS.CARD_MONTH]: cardMonth,
        [INVOICE_PAYMENT_KEYS.CARD_YEAR]: cardYear,
        [INVOICE_PAYMENT_KEYS.CARD_CVV]: cardCVV,
        [INVOICE_PAYMENT_KEYS.EMAIL]: email,
        [INVOICE_PAYMENT_KEYS.ZIP_CODE]: zipcode,
      };

      dispatch(
        payInvoiceForCustomer(
          { paymentData: paymentData },
          transaction => {
            if (_isMounted.current) {
              onSetInvoiceAfterPayment();
              setTransactionNo(transaction);
            }
          },
          messages => {
            if (_isMounted.current) {
              onSetInvoiceAfterFailedPayment();
              setErrorMessages(messages);
            }
          },
        ),
      );
    }
  };

  const handleSignatureClick = () => onSignatureClick();

  return (
    <GridContainer>
      {showSignatureBox && (
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardContent>
              <Typography gutterBottom variant="h2" component="h2">
                AAArousse Junk Removal has requested a signature for this invoice.
              </Typography>
              <Typography gutterBottom variant="body1">
                Please review the document and click to sign.
              </Typography>
              <Box display="block" mt={2}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={<CreateIcon />}
                  onClick={handleSignatureClick}>
                  Sign Now
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Invoice Balance</Typography>
          <Box>
            <Typography variant="h1" display="inline" className={classes.largeH1}>
              {totals[INVOICE_PRODUCTS_TOTALS_KEYS.TOTAL]}
            </Typography>
            <Typography variant="subtitle1" display="inline">
              {' '}
              / {totals[INVOICE_PRODUCTS_TOTALS_KEYS.TOTAL]}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {invoice[INVOICE_KEYS.PAYMENT_COMPLETE] === INVOICE_PAYMENT_NOT_COMPLETE &&
        invoice[INVOICE_KEYS.ALLOW_PAYMENT] === ALLOW_CUSTOMER_TO_PAY_INVOICE && (
          <Grid item xs={12}>
            <form onSubmit={handleFormSubmit}>
              <GridContainer>
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.headingTwo}>
                    Make a payment
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="primary" className={classes.boldPlainText}>
                    Amount: {totals[INVOICE_PRODUCTS_TOTALS_KEYS.TOTAL]}
                  </Typography>
                </Grid>
                {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                  <Grid item xs={12}>
                    {miscellaneousErrors.map((miscellaneousError, idx) => (
                      <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                        {miscellaneousError}
                      </Typography>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AppTextInput
                    type="text"
                    name="card_number"
                    variant="outlined"
                    label="Card Number"
                    value={cardNumber}
                    helperText={cardNumberError}
                    onChange={cardNumberChangeHandler}
                    InputProps={{
                      inputComponent: CardNumberFormat,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <GridContainer>
                    <Grid item xs={12} md={6}>
                      <AppTextInput
                        type="text"
                        name="card_month"
                        variant="outlined"
                        label="MM"
                        value={cardMonth}
                        error={!!cardMonthError || !!cardDateError}
                        helperText={cardMonthError || cardDateError}
                        onChange={cardMonthChangeHandler}
                        InputProps={{
                          inputComponent: CardMonthFormat,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppTextInput
                        type="text"
                        name="card_year"
                        variant="outlined"
                        label="YY"
                        value={cardYear}
                        error={!!cardYearError || !!cardDateError}
                        helperText={cardYearError}
                        onChange={cardYearChangeHandler}
                        InputProps={{
                          inputComponent: CardYearFormat,
                        }}
                      />
                    </Grid>
                  </GridContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                  <AppTextInput
                    fullWidth
                    type="text"
                    name="cvv"
                    variant="outlined"
                    label="CVV"
                    value={cardCVV}
                    onChange={cardCVVChangeHandler}
                    helperText={cardCVVError}
                    InputProps={{
                      inputComponent: CardCVVFormat,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    fullWidth
                    type="text"
                    name="email"
                    variant="outlined"
                    label="Email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setEmailError('');
                    }}
                    onBlur={emailBlurHandler}
                    helperText={emailError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppTextInput
                    fullWidth
                    type="text"
                    name="zip_code"
                    variant="outlined"
                    label="Zip Code"
                    value={zipcode}
                    onChange={e => {
                      setZipcode(e.target.value);
                      setZipcodeError('');
                    }}
                    onBlur={zipcodeBlurHandler}
                    helperText={zipcodeError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Pay
                  </Button>
                </Grid>
              </GridContainer>
            </form>
          </Grid>
        )}
      {invoice[INVOICE_KEYS.PAYMENT_COMPLETE] === INVOICE_PAYMENT_COMPLETE && (
        <Grid item xs={12}>
          <Alert severity="success">
            Payment is complete for this invoice.{transactionNo ? ` Transaction No is ${transactionNo}` : ''}
          </Alert>
        </Grid>
      )}
      {invoice[INVOICE_KEYS.PAYMENT_COMPLETE] === INVOICE_PAYMENT_NOT_CERTAIN && (
        <Grid item xs={12}>
          <Alert severity="warning">
            The status of the payment is not certain. Please refresh the page to check the status.
          </Alert>
        </Grid>
      )}
    </GridContainer>
  );
};

InvoicePayment.prototype = {
  invoice: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  showSignatureBox: PropTypes.bool.isRequired,
  onSignatureClick: PropTypes.func.isRequired,
  onSetInvoiceAfterPayment: PropTypes.func.isRequired,
  onSetInvoiceAfterFailedPayment: PropTypes.func.isRequired,
};

export default InvoicePayment;

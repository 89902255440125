import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

/* App specific paths: */
import RestrictedRoute from './RestrictedRoute';
/* Auth */
import Login from './Auth/Login';
import Invite from './Auth/Invite';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import Dashboards from './Dashboards';
/* Settings */
import Settings from './Settings';
/* Roles */
import Roles from './Settings/Roles';
/* Job Types */
import JobTypes from './Settings/JobTypes';
/* Taxes */
import Taxes from './Taxes';
/* Products */
/* import Products from './Products';
import AddEditProduct from './Products/AddEditProduct'; */
/* Ad Groups */
/* import AdGroups from './Settings/AdGroups'; */
/* Metro Areas */
/* import MetroAreas from './Settings/MetroAreas'; */
/* Squad */
import Squad from './Squad';
import AddUserForm from './Squad/AddUserForm';
import EditUserForm from './Squad/EditUserForm';
/* Customers */
import Customers from './Customers';
import AddCustomerForm from './Customers/AddCustomerForm';
import EditCustomer from './Customers/EditCustomer';
/* Jobs */
import Jobs from './Jobs';
import NewJob from './Jobs/NewJob';
import EditJob from './Jobs/EditJob';
/* Invoices */
import Invoices from './Invoices';
import JobInvoice from './Invoices/JobInvoice';
/* Estimates */
import Estimates from './Estimates';
import Estimate from './Estimates/Estimate';
/* Calendar */
import Schedule from './Schedule';
/* Messages */
import Messages from './Messages';
import JobMessages from './Messages/JobMessages';
/* Notifications */
/* import Notifications from './Notifications'; */
/* Customer Portal */
import CustomerPortalEstimate from './CustomerPortalEstimate';
import CustomerPortalInvoice from './CustomerPortalInvoice';
import CustomerPortalJob from './CustomerPortalJob';
/* import CustomerConfirmJob from './CustomerPortalJob/CustomerConfirmJob'; */
import CustomerPortalMessage from './CustomerPortalMessage';
/* Member Portal */
import MemberConfirmJob from './MemberPortal/MemberConfirmJob';
/* Miscellanous */
import Error404 from './ExtraPages/404';

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (
    authUser &&
    (['/signin', '/forgotPassword'].includes(location.pathname) ||
      /^\/(invite|resetPassword)\/[^\/\\]+\/$/gm.test(location.pathname))
  ) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} alternatePath="/messages" />
        <RestrictedRoute path="/settings" component={Settings} />
        <RestrictedRoute path="/roles" component={Roles} />
        <RestrictedRoute path="/jobTypes" component={JobTypes} />
        <RestrictedRoute path="/taxes" component={Taxes} />
        {/* <RestrictedRoute path="/products" component={Products} />
        <RestrictedRoute path="/product/:productId/" exact strict component={AddEditProduct} /> */}
        {/* <RestrictedRoute path="/adgroups" component={AdGroups} /> */}
        {/* <RestrictedRoute path="/metroAreas" component={MetroAreas} /> */}
        <RestrictedRoute path="/squad" component={Squad} />
        <RestrictedRoute path="/newUser" exact strict component={AddUserForm} />
        <RestrictedRoute path="/user/:userId/" exact strict component={EditUserForm} />
        <RestrictedRoute path="/customers" component={Customers} />
        <RestrictedRoute path="/newCustomer" component={AddCustomerForm} />
        <RestrictedRoute path="/customer/:customerId/" exact strict component={EditCustomer} />
        <RestrictedRoute path="/jobs" component={Jobs} />
        <RestrictedRoute path="/newJob" component={NewJob} />
        <RestrictedRoute path="/job/:jobId/" exact strict component={EditJob} />
        <RestrictedRoute path="/invoices" component={Invoices} />
        <RestrictedRoute path="/jobInvoice/:invoiceId/" exact strict component={JobInvoice} />
        <RestrictedRoute path="/estimates" component={Estimates} />
        <RestrictedRoute path="/estimate/:estimateId/" exact strict component={Estimate} />
        <RestrictedRoute path="/calendar" component={Schedule} />
        <RestrictedRoute path="/messages" component={Messages} />
        <RestrictedRoute path="/jobMessages/:jobId/" component={JobMessages} />
        {/* <RestrictedRoute path="/notificationCenter" component={Notifications} /> */}

        <Route path="/signin" component={Login} />
        {<Route path="/forgotPassword" component={ForgotPassword} />}
        {<Route path="/resetPassword/:resetCode/" exact strict component={ResetPassword} />}
        <Route path="/invite/:inviteId/" exact strict component={Invite} />
        <Route path="/customerPortal/estimate/:estimateId/" exact strict component={CustomerPortalEstimate} />
        <Route path="/customerPortal/invoice/:invoiceId/" exact strict component={CustomerPortalInvoice} />
        <Route path="/customerPortal/job/:customerId/:jobId/" exact strict component={CustomerPortalJob} />
        {/* <Route path="/customerPortal/confirmJob/:customerId/:jobId/" exact strict component={CustomerConfirmJob} /> */}
        <Route path="/customerPortal/message/:messageId/" exact strict component={CustomerPortalMessage} />
        <Route path="/memberPortal/confirmJob/:userId/:jobId/" exact strict component={MemberConfirmJob} />

        <RestrictedRoute path="*" component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
/* import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms'; */
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import { requiredMessage, emailNotValid, phoneNotValid } from '../../../@jumbo/constants/ErrorMessages';
import { ESTIMATE_KEYS } from '@jumbo/constants/AppDataConstants';
import { CUSTOMER_PORTAL_ESTIMATE_LINK } from '@jumbo/constants/APIConstants';

import { isValidPhoneNumber } from '../../../utils/FormValidation';
import { sendEstimate } from '../../../redux/actions/Estimates';

import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  deleteAlert: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
  contentArea: {
    width: '100%',
    padding: theme.spacing(3),
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
    '&:hover, &:active, &:focus': {
      border: `1px solid rgba(0, 0, 0, 0.87)`,
    },
    '&:focus-visible': {
      outlineColor: theme.palette.primary.main,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendEstimateDialog = ({ open, onClose }) => {
  const _isMounted = useRef(true);
  const contentRef = useRef('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentEstimate, currentEstimateCustomer } = useSelector(({ estimatesReducer }) => estimatesReducer);

  const [toEmail, setToEmail] = useState('');
  const [toEmailError, setToEmailError] = useState('');
  const [ccEmail, setCCEmail] = useState('');
  const [ccEmailError, setCCEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [subject, setSubject] = useState('Your service with AAA Rousse Junk Removal');
  const [subjectError, setSubjectError] = useState('');
  const [content, setContent] = useState('');
  const [contentError, setContentError] = useState('');
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      contentRef.current = '';
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setToEmail('');
      setToEmailError('');
      setCCEmail('');
      setCCEmailError('');
      setPhone('');
      setPhoneError('');
      setSubject('');
      setSubjectError('');
      setContent('');
      setContentError('');
      setErrorMessages([]);
      setMiscellaneousErrors([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentEstimate && currentEstimateCustomer) {
      const bill = currentEstimateCustomer[ESTIMATE_KEYS.CUSTOMER_BILL];

      setToEmail(bill[ESTIMATE_KEYS.CUSTOMER_EMAIL]);
      setPhone(bill[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]);

      const message = `<p>Greetings ${bill[ESTIMATE_KEYS.NAME]} ${
        bill[ESTIMATE_KEYS.CUSTOMER_COMPANY]
      }.</p><p>Thank you for your recent business with us.</p><p>Click the link below to view your estimate.</p><p><a href="${CUSTOMER_PORTAL_ESTIMATE_LINK}/${
        currentEstimate[ESTIMATE_KEYS.ID]
      }/" target="_blank">View Document</a></p>`;

      setContent(message);
      contentRef.current = message;
    }
  }, [currentEstimate, currentEstimateCustomer]);

  useEffect(() => {
    if (errorMessages) {
      setMiscellaneousErrors([]);
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case ESTIMATE_KEYS.SEND_TO_EMAIL:
            setToEmailError(msg);
            break;

          case ESTIMATE_KEYS.SEND_CC_EMAIL:
            setCCEmailError(msg);
            break;

          case ESTIMATE_KEYS.SEND_PHONE:
            setPhoneError(msg);
            break;

          case ESTIMATE_KEYS.SEND_SUBJECT:
            setSubjectError(msg);
            break;

          case ESTIMATE_KEYS.SEND_CONTENT:
            setContentError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const toEmailBlurHandler = event => {
    if (!event.target.value) {
      setToEmailError(requiredMessage);
    } else if (!isValidEmail(event.target.value)) {
      setToEmailError(emailNotValid);
    }
  };

  const ccEmailBlurHandler = event => {
    if (!event.target.value) {
      /* setCCEmailError(requiredMessage); */
    } else if (!isValidEmail(event.target.value)) {
      setCCEmailError(emailNotValid);
    }
  };

  const phoneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneError(phoneNotValid);
    }
  };

  const subjectBlurHandler = event => {
    if (!event.target.value) {
      setSubjectError(requiredMessage);
    }
  };

  const contentChangeHandler = event => {
    const newContent = event.target.value;
    contentRef.current = newContent;
    setContent(newContent);
    setContentError('');
  };

  const contentBlurHandler = () => {
    if (!contentRef.current) {
      setContentError(requiredMessage);
    }
  };

  const isFormValid = () => {
    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!toEmail) {
      setToEmailError(requiredMessage);
      formIsValid = false;
    } else if (!isValidEmail(toEmail)) {
      setToEmailError(emailNotValid);
      formIsValid = false;
    }

    if (!ccEmail) {
      /* setCCEmailError(requiredMessage);
      formIsValid = false; */
    } else if (!isValidEmail(ccEmail)) {
      setCCEmailError(emailNotValid);
      formIsValid = false;
    }

    if (!isValidPhoneNumber(phone)) {
      setPhoneError(phoneNotValid);
      formIsValid = false;
    }

    if (!subject) {
      setSubjectError(requiredMessage);
      formIsValid = false;
    }

    if (!content) {
      setContentError(requiredMessage);
      formIsValid = false;
    }

    return formIsValid;
  };

  /* const handleSendEmail = event => {
    event.preventDefault();
    const formIsValid = isFormValid();
    if (formIsValid) sendMessage('email');
  }; */

  /* const handleSendSMS = event => {
    event.preventDefault();
    const formIsValid = isFormValid();
    if (formIsValid) sendMessage('sms');
  }; */

  const handleSendEstimate = event => {
    event.preventDefault();
    const formIsValid = isFormValid();
    if (formIsValid) sendMessage('');
  };

  const sendMessage = type => {
    const estimateData = {
      authcode: authUser.authcode,
      [ESTIMATE_KEYS.ESTIMATE_ID]: currentEstimate[ESTIMATE_KEYS.ID],
      [ESTIMATE_KEYS.SEND_TO_EMAIL]: toEmail,
      [ESTIMATE_KEYS.SEND_CC_EMAIL]: ccEmail,
      [ESTIMATE_KEYS.SEND_PHONE]: phone,
      [ESTIMATE_KEYS.SEND_SUBJECT]: subject,
      [ESTIMATE_KEYS.SEND_CONTENT]: content,
      [ESTIMATE_KEYS.SEND_TYPE]: type,
    };

    dispatch(
      sendEstimate(
        { estimateData: estimateData },
        () => {
          if (_isMounted.current) onClose();
        },
        messages => {
          if (_isMounted.current) setErrorMessages(messages);
        },
      ),
    );
  };

  const estimateIsValid = currentEstimate && currentEstimate[ESTIMATE_KEYS.ID];

  return (
    <Dialog fullScreen open={open} onClose={onClose} className={classes.dialogRoot} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Send Estimate
          </Typography>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {estimateIsValid && (
          <form>
            <GridContainer>
              {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                <Grid item xs={12}>
                  {miscellaneousErrors.map((miscellaneousError, idx) => (
                    <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                      {miscellaneousError}
                    </Typography>
                  ))}
                </Grid>
              )}
              <Grid item xs={12}>
                <AppTextInput
                  type="text"
                  name="to_email"
                  variant="outlined"
                  label="To"
                  value={toEmail}
                  onChange={e => {
                    setToEmail(e.target.value);
                    setToEmailError('');
                  }}
                  onBlur={toEmailBlurHandler}
                  helperText={toEmailError}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextInput
                  type="text"
                  name="cc_email"
                  variant="outlined"
                  label="CC"
                  value={ccEmail}
                  onChange={e => {
                    setCCEmail(e.target.value);
                    setCCEmailError('');
                  }}
                  onBlur={ccEmailBlurHandler}
                  helperText={ccEmailError}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextInput
                  type="text"
                  name="phone"
                  variant="outlined"
                  label="Phone"
                  value={phone}
                  onChange={e => {
                    setPhone(e.target.value);
                    setPhoneError('');
                  }}
                  onBlur={phoneBlurHandler}
                  helperText={phoneError}
                  InputProps={{
                    inputComponent: PhoneNumberFormat,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextInput
                  type="text"
                  name="suject"
                  variant="outlined"
                  label="Subject"
                  value={subject}
                  onChange={e => {
                    setSubject(e.target.value);
                    setSubjectError('');
                  }}
                  onBlur={subjectBlurHandler}
                  helperText={subjectError}
                />
              </Grid>
              <Grid item xs={12}>
                <ContentEditable
                  html={contentRef.current}
                  onBlur={contentBlurHandler}
                  onChange={contentChangeHandler}
                  className={classes.contentArea}
                />
                {contentError && (
                  <Typography variant="caption" display="block" color="error">
                    {contentError}
                  </Typography>
                )}
              </Grid>
            </GridContainer>
          </form>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {estimateIsValid && (
            <React.Fragment>
              {/* <Button type="button" variant="contained" color="primary" startIcon={<EmailIcon />} onClick={handleSendEmail}>
                Send Email
              </Button>
              <Button type="button" variant="contained" color="primary" startIcon={<SmsIcon />} onClick={handleSendSMS}>
                Send SMS
              </Button> */}
              <Button
                type="button"
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                onClick={handleSendEstimate}>
                Send Estimate
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
        {!estimateIsValid && (
          <GridContainer>
            <Grid item xs={12}>
              <Alert severity="error">Estimate is not valid!</Alert>
            </Grid>
          </GridContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

SendEstimateDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SendEstimateDialog;

import {
  ADD_METRO_AREA,
  DELETE_METRO_AREA,
  EDIT_METRO_AREA,
  GET_METRO_AREAS,
  SET_METRO_AREA_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  metroAreas: [],
  currentmetroArea: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_METRO_AREAS: {
      return {
        ...state,
        metroAreas: action.payload,
      };
    }
    case SET_METRO_AREA_DETAILS: {
      return {
        ...state,
        currentmetroArea: action.payload,
      };
    }
    case ADD_METRO_AREA: {
      return {
        ...state,
        metroAreas: [action.payload, ...state.metroAreas],
      };
    }
    case EDIT_METRO_AREA: {
      return {
        ...state,
        metroAreas: state.metroAreas.map(metroArea => (metroArea.id === action.payload.id ? action.payload : metroArea)),
      };
    }
    case DELETE_METRO_AREA: {
      return {
        ...state,
        metroAreas: state.metroAreas.filter(metroArea => metroArea.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

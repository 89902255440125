import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  topInfo: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headingOne: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  headingTwo: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  headingThree: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardRoot: {
    borderRadius: 0,
  },
  plainText: {
    fontFamily: ['Arial', 'sans-serif'],
  },
  boldPlainText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  contentArea: {
    width: '100%',
    padding: 0,
    border: '0',
    fontFamily: ['Arial', 'sans-serif'],
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
    '&:hover, &:active, &:focus': {
      border: '0',
    },
  },
}));

export default useStyles;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import { CUSTOMER_KEYS, ESTIMATE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { customerNotValid } from '../../../@jumbo/constants/ErrorMessages';

import { searchCustomers } from '../../../redux/actions/Customers';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const AddNewEstimateDialog = ({ open, onClose }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);

  const [customer, setCustomer] = useState(null);
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [customerIdError, setCustomerIdError] = useState('');

  let customerIsValid = false;
  if (customerId) {
    if (customer) {
      if (
        !isNaN(customerId) &&
        !isNaN(parseInt(customerId)) &&
        parseInt(customerId) > 0 &&
        customer[CUSTOMER_KEYS.ID] &&
        customerId === customer[CUSTOMER_KEYS.ID]
      ) {
        customerIsValid = true;
      }
    }
  }

  const debouncedCustomerSearchTerm = useDebounce(customerSearchTerm ? customerSearchTerm : '', 500);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setCustomer(null);
      setCustomerSearchTerm('');
      setCustomersList([]);
      setCustomerId('');
      setCustomerIdError('');
    }
  }, [open]);

  useEffect(() => {
    if (customerIsValid) {
      return undefined;
    }

    if (debouncedCustomerSearchTerm === '') {
      return undefined;
    }

    const customerData = {
      authcode: authUser.authcode,
      search: debouncedCustomerSearchTerm,
    };

    dispatch(
      searchCustomers({ customerData: customerData }, customers => {
        if (_isMounted.current) {
          let newList = [];

          if (customers) {
            newList = [...customers];
          }

          setCustomersList(newList);
        }
      }),
    ).catch(error => {
      setCustomersList([]);
    });
  }, [dispatch, authUser.authcode, customerIsValid, debouncedCustomerSearchTerm]);

  useEffect(() => {
    if (customer) {
      setCustomerId(customer[CUSTOMER_KEYS.ID]);
    }
  }, [customer]);

  const handleFormSubmit = () => {
    let formIsValid = true;

    if (!customerIsValid) {
      formIsValid = false;
      setCustomerIdError(customerNotValid);
    }

    if (formIsValid) {
      const formData = {
        authcode: authUser.authcode,
        [ESTIMATE_KEYS.CUSTOMER_ID]: customerId,
      };
      console.log('Add new estimate form submitted', formData);
    }
  };

  return (
    <Dialog fullWidth open={open} className={classes.dialogRoot} onClose={onClose}>
      <DialogTitle className={classes.dialogTitleRoot}>Create New Estimate</DialogTitle>
      <DialogContent dividers>
        <GridContainer>
          <Grid item xs={12}>
            <Button
              fullWidth
              component={Link}
              to={`/newCustomer`}
              type="button"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}>
              Add new customer
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" display="block" style={{ textAlign: 'center' }}>
              or
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              filterSelectedOptions
              clearOnBlur={false}
              popupIcon={null}
              id="customer"
              value={customer}
              options={customersList}
              getOptionLabel={option => {
                return option && option[CUSTOMER_KEYS.ID]
                  ? `${option[CUSTOMER_KEYS.FIRST_NAME]} ${option[CUSTOMER_KEYS.LAST_NAME]}`
                  : '';
              }}
              getOptionSelected={(option, value) => option[CUSTOMER_KEYS.ID] === value[CUSTOMER_KEYS.ID]}
              onChange={(event, newValue) => {
                setCustomer(newValue ? newValue : null);
                setCustomersList([]);
                setCustomerSearchTerm('');
                setCustomerIdError('');
              }}
              onInputChange={(event, newInputValue) => {
                setCustomerSearchTerm(newInputValue ? newInputValue : '');
              }}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  type="text"
                  name="customer"
                  label="Select a customer"
                  variant="outlined"
                  margin="dense"
                  helperText={customerIdError}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                  }}
                />
              )}
              renderOption={option => {
                return (
                  <GridContainer>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="textPrimary">
                        {`${option[CUSTOMER_KEYS.FIRST_NAME]} ${option[CUSTOMER_KEYS.LAST_NAME]}`}
                      </Typography>

                      <Divider />
                    </Grid>
                  </GridContainer>
                );
              }}
              classes={{ root: classes.listAutocomplete }}
            />
          </Grid>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button type="button" variant="contained" color="primary" onClick={handleFormSubmit}>
          Update
        </Button>
        <Button type="button" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNewEstimateDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddNewEstimateDialog;

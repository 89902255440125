import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { ADD_ROLE, DELETE_ROLE, EDIT_ROLE, GET_ROLES, SET_ROLE_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { GET_ROLES_LINK, ADD_ROLE_LINK, UPDATE_ROLE_LINK, DELETE_ROLE_LINK } from '../../@jumbo/constants/APIConstants';
import { ROLE_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getRoles = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_ROLES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.roles) {
              const roles = data.roles;
              dispatch(fetchSuccess());
              dispatch({ type: GET_ROLES, payload: roles });
              if (callbackFun) callbackFun(roles);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentRole = role => {
  return dispatch => {
    dispatch({ type: SET_ROLE_DETAILS, payload: role });
  };
};

export const addNewRole = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.roleData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_ROLE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.role) {
              dispatch(fetchSuccess('Role was added successfully.'));
              dispatch({ type: ADD_ROLE, payload: data.role });
              if (callbackFun) callbackFun(data.role);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateRole = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.roleData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_ROLE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.role) {
              dispatch(fetchSuccess('Selected role was updated successfully.'));
              dispatch({ type: EDIT_ROLE, payload: data.role });
              if (callbackFun) callbackFun(data.role);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteRole = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.roleData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_ROLE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected role was deleted successfully.'));
            dispatch({ type: DELETE_ROLE, payload: request.roleData[ROLE_KEYS.ROLE_ID] });
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

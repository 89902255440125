import React from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { alpha, makeStyles } from '@material-ui/core/styles';

import { INVOICE_SIGNATURE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import CmtImage from '../../../@coremat/CmtImage';

const useStyles = makeStyles(theme => ({
  tableRowCellRoot: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.text.secondary,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      paddingRight: 24,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
}));

const SignaturesTable = ({ data }) => {
  const classes = useStyles();

  return (
    <Box className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableRowCellRoot} style={{ width: '30%' }}>
              Signature
            </TableCell>
            <TableCell className={classes.tableRowCellRoot} style={{ width: '35%' }}>
              Signer Name
            </TableCell>
            <TableCell className={classes.tableRowCellRoot} style={{ width: '35%' }}>
              Signed On
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={`invoice-sign-${idx}`} className={clsx(classes.tableRowRoot)}>
              <TableCell className={classes.tableCellRoot}>
                <CmtImage
                  src={row[INVOICE_SIGNATURE_KEYS.SIGN_LINK]}
                  alt={`Signature of ${row[INVOICE_SIGNATURE_KEYS.SIGNER_NAME]}`}
                  width="50%"
                />
              </TableCell>
              <TableCell className={classes.tableCellRoot}>
                <Box>{row[INVOICE_SIGNATURE_KEYS.SIGNER_NAME]}</Box>
              </TableCell>
              <TableCell className={clsx(classes.tableCellRoot, classes.tableCellSecondaryColor)}>
                <Box display="flex" alignItems="center">
                  <Box component="span" mr={1}>
                    {row[INVOICE_SIGNATURE_KEYS.FORMATTED_CREATED]}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SignaturesTable;

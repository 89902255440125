import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import GridContainer from '../../../@jumbo/components/GridContainer';
import { AAA_ROUSE_PHONE_NO, ESTIMATE_KEYS, COLOR_LOGO_LINK } from '../../../@jumbo/constants/AppDataConstants';

import CmtImage from '../../../@coremat/CmtImage';

import ProductsTable from '../ProductsTable';
import SignaturesTable from '../SignaturesTable';

const EstimateInfo = ({ classes, estimate, customer, products, totals, signatures }) => {
  const bill = customer[ESTIMATE_KEYS.CUSTOMER_BILL];

  return (
    <Card className={classes.cardRoot}>
      <CardContent>
        <GridContainer>
          <Grid item xs={6} style={{ paddingBottom: 0 }}>
            <CmtImage src={COLOR_LOGO_LINK} alt="AAA Rousse Logo" width={200} />
          </Grid>
          <Grid item xs={2} style={{ paddingBottom: 0 }}>
            <Box className={classes.topInfo}>
              <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
                Estimate #
              </Typography>
              <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
                Date
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ paddingBottom: 0 }}>
            <Box className={classes.topInfo}>
              <Typography variant="body1" className={clsx(classes.plainText, classes.textAlignRight)}>
                {estimate[ESTIMATE_KEYS.ID]}
              </Typography>
              <Typography variant="body1" className={clsx(classes.plainText, classes.textAlignRight)}>
                {estimate[ESTIMATE_KEYS.CREATED_AT]}
              </Typography>
            </Box>
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <Typography variant="body1" className={classes.plainText}>
              AAArousse Junk Removal
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              dba Pro Junk Dispatch
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              <NumberFormat
                isNumericString
                displayType="text"
                value={AAA_ROUSE_PHONE_NO}
                format="(###) ###-####"
                className={classes.plainText}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
              Prepared For:
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              {bill[ESTIMATE_KEYS.CUSTOMER_NAME]} {bill[ESTIMATE_KEYS.CUSTOMER_COMPANY]}
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              {bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_ONE]} {bill[ESTIMATE_KEYS.CUSTOMER_ADDRESS_TWO]}
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              {bill[ESTIMATE_KEYS.CUSTOMER_CITY]}, {bill[ESTIMATE_KEYS.CUSTOMER_STATE]}{' '}
              {bill[ESTIMATE_KEYS.CUSTOMER_ZIP_CODE]}
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              <NumberFormat
                isNumericString
                displayType="text"
                value={bill[ESTIMATE_KEYS.CUSTOMER_PHONE_ONE]}
                format="(###) ###-####"
                className={classes.plainText}
              />
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              {bill[ESTIMATE_KEYS.CUSTOMER_EMAIL]}
            </Typography>
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12}>
            <ProductsTable classes={classes} products={products} totals={totals} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
              Terms:
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              By paying the due balance of this invoice the customer hereby acknowledges to AAA Rousse Junk Removal dba Pro
              Junk Dispatch that all requested services and/or any other items listed above in the description section of the
              invoice have been performed and completed to the customer's satisfaction. By accepting this invoice, the
              customer agrees to pay in full the total amount due.
            </Typography>
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
              Notes:
            </Typography>
            <Typography variant="body1" className={classes.plainText}>
              {estimate[ESTIMATE_KEYS.NOTE]}
            </Typography>
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12}>
            <Typography variant="h1" style={{ textAlign: 'center' }}>
              Thank you for your business
            </Typography>
          </Grid>
        </GridContainer>
        {signatures && signatures.length > 0 && (
          <GridContainer className={classes.signTableContainer}>
            <Grid item xs={12}>
              <SignaturesTable classes={classes} signatures={signatures} />
            </Grid>
          </GridContainer>
        )}
      </CardContent>
    </Card>
  );
};

EstimateInfo.prototype = {
  estimate: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  totals: PropTypes.object.isRequired,
  signatures: PropTypes.array.isRequired,
};

export default EstimateInfo;

import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  topInfo: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headingOne: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  headingTwo: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  headingThree: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardRoot: {
    borderRadius: 0,
  },
  plainText: {
    fontSize: 14,
    fontFamily: ['Arial', 'sans-serif'],
  },
  largeH1: {
    fontSize: 32,
  },
  boldPlainText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    color: theme.palette.common.black,
    letterSpacing: 0.25,
    fontFamily: ['Arial', 'sans-serif'],
  },
  titleRoot2: {
    fontSize: 14,
    color: theme.palette.text.disabled,
    letterSpacing: 0.25,
    fontFamily: ['Arial', 'sans-serif'],
  },
  tableFooterCell: {
    fontSize: 14,
    borderBottom: 'none',
  },
  signTableContainer: {
    marginTop: theme.spacing(8),
  },
  signTableHeadCell: {
    fontWeight: theme.typography.fontWeightBold,
  },
  cardDateInput: {
    border: `1px solid ${theme.palette.borderColor.dark}`,
    borderRadius: 2,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
  },
}));

export default useStyles;

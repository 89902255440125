import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { /* useHistory, */ NavLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
/* import Link from '@material-ui/core/Link'; */
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { alpha, makeStyles } from '@material-ui/core/styles';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { isValidEmail } from '../../../utils/commonHelper';
import { AuhMethods } from '../../../../services/auth';
import { AppName, CurrentAuthMethod } from '../../../constants/AppConstants';
import { COLOR_LOGO_LINK, SQUAD_USER_KEYS } from '../../../constants/AppDataConstants';
/* import { setForgetPassMailSent } from '../../../../redux/actions/Auth'; */

import AuthWrapper from './AuthWrapper';
import ContentLoader from '../../ContentLoader';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  document.title = `${AppName} - Forgot Password`;

  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  /* const history = useHistory(); */

  const { send_forget_password_email } = useSelector(({ auth }) => auth);

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      /* timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 1500); */
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const emailBlurHandler = event => {
    if (!event.target.value) {
      setEmailIsInvalid(true);
    } else if (!isValidEmail(event.target.value)) {
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      setEmailIsInvalid(true);
    } else if (!isValidEmail(email)) {
      formIsValid = false;
      setEmailIsInvalid(true);
    }

    if (formIsValid) {
      const authData = {
        [SQUAD_USER_KEYS.EMAIL]: email,
      };
      dispatch(
        AuhMethods[method].onForgotPassword({ authData: authData }, () => {
          setEmail('');
        }),
      );
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'}>
          <CmtImage src={COLOR_LOGO_LINK} width={240} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="appModule.forgotPassword" />
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form onSubmit={handleFormSubmit}>
          <div className={'mb-5'}>
            <TextField
              fullWidth
              value={email}
              label={<IntlMessages id="appModule.email" />}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!emailIsInvalid}
              helperText={!!emailIsInvalid ? <IntlMessages id="appModule.emailIsInvalid" /> : ''}
              onChange={event => setEmail(event.target.value)}
              onBlur={emailBlurHandler}
            />
          </div>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button type="submit" variant="contained" color="primary">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signin">
                <IntlMessages id="appModule.signIn" />
              </NavLink>
            </Box>
          </Box>
          {/* <div className={'mb-5'}>
            <Typography>
              Don't remember your email?
              <span className={'ml-2'}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </span>
            </Typography>
          </div> */}
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import { lighten, makeStyles } from '@material-ui/core/styles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { requiredMessage, percentageNotValid, statusNotValid } from '../../../@jumbo/constants/ErrorMessages';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import {
  ESTIMATE_KEYS,
  JOB_JOB_TYPE_KEYS,
  JOB_JOB_TYPES_TOTALS_KEYS,
  ESTIMATE_STATUS_KEYS,
} from '../../../@jumbo/constants/AppDataConstants';

import { isValidPercentage } from '../../../utils/FormValidation';
import {
  getEstimate,
  updateEstimateStatus,
  deleteEstimate,
  setCurrentEstimate,
  previewEstimate,
  downloadEstimate,
} from '../../../redux/actions/Estimates';
import { getEstimateStatusList } from '../../../redux/actions/EstimateStatuses';
import {
  deleteJobJobType,
  setCurrentJobJobType,
  setJobJobTypes,
  setJobJobTypesTotals,
  updateJobJobTypesCalculations,
} from '../../../redux/actions/JobJobTypes';
/* import { getJobEstimates } from '../../../redux/actions/Jobs'; */
import { getJobTypes } from '../../../redux/actions/JobTypes';

import EstimateHeader from '../EstimateHeader';
import CustomerInfoSection from '../CustomerInfoSection';
import JobJobTypesTable from '../../Jobs/JobJobTypesTable';
import JobTypesDialog from '../../Jobs/JobTypesDialog';
import EstimateNotes from '../EstimateNotes';
import EstimateSignature from '../EstimateSignature';
import EstimatePreviewDialog from '../EstimatePreviewDialog';
import EstimateSignatureDialog from '../EstimateSignatureDialog';
import SendEstimateDialog from '../SendEstimateDialog';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  headerDivider: {
    marginTop: theme.spacing(2),
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.dark}`,
  },
  downloadLink: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  deleteAlert: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
}));

const pageName = 'Estimate';
const breadcrumbs = [
  { label: 'Estimates', link: '/estimates' },
  { label: pageName, isActive: true },
];

const Estimate = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const estimateId = params.estimateId;

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentEstimate } = useSelector(({ estimatesReducer }) => estimatesReducer);
  /* const { jobEstimates } = useSelector(({ jobsReducer }) => jobsReducer); */
  const { jobJobTypes, jobJobTypesTotals } = useSelector(({ jobJobTypesReducer }) => jobJobTypesReducer);
  const { estimateStatuses } = useSelector(({ estimateStatusesReducer }) => estimateStatusesReducer);

  /* const jobId = currentEstimate ? currentEstimate[ESTIMATE_KEYS.JOB_ID] : ''; */

  const [dataFetching, setDataFetching] = useState(true);
  const [estimateFetched, setEstimateFetched] = useState(false);
  const [subTotal, setSubTotal] = useState('0.00');
  const [taxPercent, setTaxPercent] = useState('0.00');
  const [taxPercentError, setTaxPercentError] = useState('');
  const [tax, setTax] = useState('0.00');
  const [total, setTotal] = useState('0.00');
  const [calculations, setCalculations] = useState({
    [JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]: '0.00',
  });
  const [calculationsChanged, setCalculationsChanged] = useState(false);
  const [openJobTypesDialog, setOpenJobTypesDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openEstConfirmDialog, setEstOpenConfirmDialog] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [previewLink, setPreviewLink] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [openSendEstimateDialog, setOpenSendEstimateDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [estErrorMessages, setEstErrorMessages] = useState([]);
  const [estMiscellaneousErrors, setEstMiscellaneousErrors] = useState([]);
  /* const [jobEstimatesFetched, setJobEstimatesFetched] = useState(false); */

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      dispatch(setCurrentEstimate(null));
      dispatch(setJobJobTypes([]));
      dispatch(setJobJobTypesTotals(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;

    if (estimateId && dataFetching && !estimateFetched) {
      const fetchData = (payload, estimatePayload) => {
        return (dispatch, getState) => {
          return dispatch(getJobTypes(payload)).then(() => {
            return dispatch(getEstimateStatusList(payload)).then(() => {
              return dispatch(getEstimate(estimatePayload));
            });
          });
        };
      };

      const payload = { authcode: authUser.authcode };
      const estimatePayload = { estimateData: { ...payload, [ESTIMATE_KEYS.ESTIMATE_ID]: estimateId } };

      const promise = dispatch(fetchData(payload, estimatePayload));
      promise
        .then(() => {
          if (isActive) {
            setEstimateFetched(true);
            setDataFetching(false);
          }
        })
        .catch(error => {
          if (isActive) {
            setEstimateFetched(false);
            setDataFetching(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, estimateId, dataFetching, estimateFetched]);

  useEffect(() => {
    if (jobJobTypesTotals) {
      setSubTotal(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.SUB_TOTAL]);
      setTaxPercent(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]);
      setTax(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TAX]);
      setTotal(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TOTAL]);
    }
  }, [jobJobTypesTotals]);

  useEffect(() => {
    if (calculationsChanged) {
      setMiscellaneousErrors([]);

      const jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_ID]: currentEstimate[ESTIMATE_KEYS.JOB_ID],
        [JOB_JOB_TYPE_KEYS.ESTIMATE_ID]: currentEstimate[ESTIMATE_KEYS.ID],
        ...calculations,
      };

      dispatch(
        updateJobJobTypesCalculations(
          { jobJobTypeData: jobJobTypeData },
          () => {
            if (_isMounted.current) setCalculationsChanged(false);
          },
          messages => {
            if (_isMounted.current) {
              setErrorMessages(messages);
              setCalculationsChanged(false);
            }
          },
        ),
      );
    }
  }, [dispatch, authUser.authcode, currentEstimate, calculations, calculationsChanged]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  useEffect(() => {
    for (const fieldName in estErrorMessages) {
      const msg = estErrorMessages[fieldName];
      switch (fieldName) {
        default:
          setEstMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [estErrorMessages]);

  /* useEffect(() => {
    let isActive = true;

    if (jobId && dataFetching && !jobEstimatesFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getJobEstimates(payload));
        };
      };

      const payload = { jobEstimateData: { authcode: authUser.authcode, [ESTIMATE_KEYS.JOB_ID]: jobId } };

      const promise = dispatch(fetchData(payload));
      promise
        .then(() => {
          if (isActive) {
            setJobEstimatesFetched(true);
            setDataFetching(false);
          }
        })
        .catch(error => {
          if (isActive) {
            setJobEstimatesFetched(false);
            setDataFetching(false);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, jobId, dataFetching, jobEstimatesFetched]); */

  const handleEstimateStatusChange = event => {
    const newStatus = event.target.value;

    setEstErrorMessages([]);
    setEstMiscellaneousErrors([]);

    if (!estimateStatuses.map(estimateStatusObj => estimateStatusObj[ESTIMATE_STATUS_KEYS.ID]).includes(newStatus)) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: statusNotValid,
        customClass: { container: classes.deleteAlert },
      });
    } else {
      const estimateData = {
        authcode: authUser.authcode,
        [ESTIMATE_KEYS.ESTIMATE_ID]: currentEstimate[ESTIMATE_KEYS.ID],
        [ESTIMATE_KEYS.STATUS]: newStatus,
      };

      dispatch(
        updateEstimateStatus({ estimateData: estimateData }, null, messages => {
          if (_isMounted.current) setEstErrorMessages(messages);
        }),
      );
    }
  };

  const handlePreviewEstimate = () => {
    setPreviewLink('');

    const estimateData = {
      authcode: authUser.authcode,
      [ESTIMATE_KEYS.ESTIMATE_ID]: currentEstimate[ESTIMATE_KEYS.ID],
    };

    dispatch(
      previewEstimate({ estimateData: estimateData }, link => {
        if (_isMounted.current) {
          setPreviewLink(link);
          setOpenPreviewDialog(true);
        }
      }),
    );
  };

  const handleClosePreviewDialog = () => setOpenPreviewDialog(false);

  const handleDeleteEstimate = event => setEstOpenConfirmDialog(true);

  const handleDownloadEstimate = () => {
    const estimateData = {
      authcode: authUser.authcode,
      [ESTIMATE_KEYS.ESTIMATE_ID]: currentEstimate[ESTIMATE_KEYS.ID],
    };

    dispatch(
      downloadEstimate({ estimateData: estimateData }, link => {
        if (_isMounted.current) {
          SweetAlert({
            icon: 'success',
            title: 'Your download is ready!!',
            html: `Click <a href="${link}" target="_blank" download class="${classes.downloadLink}">here</a> to download.`,
          });
        }
      }),
    );
  };

  const handleCloseSignatureDialog = () => setOpenSignatureDialog(false);

  const handleSignatureClick = () => setOpenSignatureDialog(true);

  const handleSendEstimate = () => setOpenSendEstimateDialog(true);

  const handleCloseSendEstimateDialog = () => setOpenSendEstimateDialog(false);

  const handleConfirmDeleteEstimate = () => {
    setEstOpenConfirmDialog(false);

    setEstErrorMessages([]);
    setEstMiscellaneousErrors([]);

    const estimateData = {
      authcode: authUser.authcode,
      [ESTIMATE_KEYS.ESTIMATE_ID]: currentEstimate[ESTIMATE_KEYS.ID],
    };

    dispatch(
      deleteEstimate(
        { estimateData: estimateData },
        () => history.push('/estimates'),
        messages => {
          if (_isMounted.current) setEstErrorMessages(messages);
        },
      ),
    );
  };

  const handleCancelDeleteEstimate = () => setEstOpenConfirmDialog(false);

  const taxPercentBlurHandler = event => {
    if (!event.target.value) {
      setTaxPercentError(requiredMessage);
    } else if (!isValidPercentage(event.target.value)) {
      setTaxPercentError(percentageNotValid);
    } else {
      setCalculations(prevState => {
        return { ...prevState, [JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]: taxPercent };
      });
      setCalculationsChanged(true);
    }
  };

  const handleOpenJobTypesDialog = () => setOpenJobTypesDialog(true);

  const handleCloseJobTypesDialog = () => {
    setOpenJobTypesDialog(false);
    dispatch(setCurrentJobJobType(null));
  };

  const handleRowEdit = jobJobType => {
    dispatch(setCurrentJobJobType(jobJobType));
    setOpenJobTypesDialog(true);
  };

  const handleJobJobTypeDelete = jobJobType => {
    setSelectedJobType(jobJobType);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    if (selectedJobType && selectedJobType[JOB_JOB_TYPE_KEYS.ID]) {
      const jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_JOB_TYPE_ID]: selectedJobType[JOB_JOB_TYPE_KEYS.ID],
      };

      dispatch(
        deleteJobJobType(
          { jobJobTypeData: jobJobTypeData },
          () => {
            if (_isMounted.current) setSelectedJobType(null);
          },
          () => {
            if (_isMounted.current) setSelectedJobType(null);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        setSelectedJobType(null);
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid service',
        });
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelectedJobType(null);
  };

  const estimateIsValid = estimateId && currentEstimate && currentEstimate[ESTIMATE_KEYS.ID];
  /* const jobEstimatesAreValid = jobEstimates && jobEstimates.length > 0; */

  return (
    <PageContainer heading={estimateId ? `Estimate #${estimateId}` : ''} breadcrumbs={breadcrumbs}>
      {!dataFetching && estimateFetched && estimateIsValid /* && jobEstimatesFetched && jobEstimatesAreValid */ && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12}>
              <EstimateHeader
                miscellaneousErrors={estMiscellaneousErrors}
                onStatusChange={handleEstimateStatusChange}
                onPreviewEstimate={handlePreviewEstimate}
                onDownloadEstimate={handleDownloadEstimate}
                onSignatureClick={handleSignatureClick}
                onSendEstimate={handleSendEstimate}
                onDeleteEstimate={handleDeleteEstimate}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerInfoSection />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <GridContainer>
                  {jobJobTypes && jobJobTypesTotals && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <JobJobTypesTable
                          jobJobTypes={jobJobTypes}
                          onEdit={handleRowEdit}
                          onDelete={handleJobJobTypeDelete}
                        />
                      </Grid>
                      {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                        <Grid item xs={12}>
                          {miscellaneousErrors.map((miscellaneousError, idx) => (
                            <Typography
                              variant="caption"
                              display="block"
                              color="error"
                              gutterBottom
                              key={`misc-error-${idx}`}>
                              {miscellaneousError}
                            </Typography>
                          ))}
                        </Grid>
                      )}
                      <Grid item xs={12} md={8}>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={handleOpenJobTypesDialog}>
                          Type of Service
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <GridContainer>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="sub_total"
                              variant="outlined"
                              label="Sub Total"
                              value={subTotal}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="tax_percent"
                              variant="outlined"
                              label="Tax %"
                              value={taxPercent}
                              onChange={e => {
                                setTaxPercent(e.target.value);
                                setTaxPercentError('');
                              }}
                              onBlur={taxPercentBlurHandler}
                              helperText={taxPercentError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="tax"
                              variant="outlined"
                              label="Tax"
                              value={tax}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AppTextInput
                              type="text"
                              name="total"
                              variant="outlined"
                              label="Total"
                              value={total}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </GridContainer>
                      </Grid>
                    </React.Fragment>
                  )}
                  {(!jobJobTypes || !jobJobTypesTotals) && (
                    <Grid item xs={12}>
                      <Alert severity="error">Service data not found!</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <EstimateNotes />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <EstimateSignature onSignatureClick={handleSignatureClick} />
                  </Grid>
                </GridContainer>
              </Paper>
            </Grid>
          </GridContainer>

          {openJobTypesDialog && (
            <JobTypesDialog
              currentJobId={currentEstimate[ESTIMATE_KEYS.JOB_ID]}
              open={openJobTypesDialog}
              onClose={handleCloseJobTypesDialog}
            />
          )}

          {selectedJobType && openConfirmDialog && (
            <ConfirmDialog
              open={openConfirmDialog}
              title="Delete Service?"
              content={'This action cannot be undone.'}
              onClose={handleCancelDelete}
              onConfirm={handleConfirmDelete}
            />
          )}

          {openEstConfirmDialog && (
            <ConfirmDialog
              open={openEstConfirmDialog}
              title="Delete Estimate?"
              content={'This action cannot be undone.'}
              onClose={handleCancelDeleteEstimate}
              onConfirm={handleConfirmDeleteEstimate}
            />
          )}

          {openPreviewDialog && (
            <EstimatePreviewDialog
              link={previewLink}
              estimate={currentEstimate}
              open={openPreviewDialog}
              onClose={handleClosePreviewDialog}
            />
          )}

          {openSignatureDialog && (
            <EstimateSignatureDialog open={openSignatureDialog} onClose={handleCloseSignatureDialog} />
          )}

          {openSendEstimateDialog && (
            <SendEstimateDialog open={openSendEstimateDialog} onClose={handleCloseSendEstimateDialog} />
          )}
        </React.Fragment>
      )}

      {!dataFetching && (!estimateFetched || !estimateIsValid) /*  || !jobEstimatesFetched || !jobEstimatesAreValid */ && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Estimate data not found!</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default Estimate;

import React from 'react';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  headerCard: {
    padding: 0,
    borderLeft: '3px solid',
    boxShadow: theme.overrides.MuiCard.root.boxShadow,
    '&:hover': {
      boxShadow: '0 12px 12px -8px rgb(0 0 0 / 40%)',
    },
    '&.primaryBorder': {
      borderLeftColor: theme.palette.primary.main,
    },
    '&.warningBorder': {
      borderLeftColor: theme.palette.warning.main,
    },
    '&.errorBorder': {
      borderLeftColor: theme.palette.error.main,
    },
  },
  headerCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cardSubtitle: {
    fontSize: '14px',
    color: theme.palette.text.disabled,
  },
}));

const HeaderCard = ({ titleIsAmount = false, cardClass, title, subtitle, onClick }) => {
  const classes = useStyles();

  let titleComponent = (
    <Typography component="h2" variant="h1">
      {title}
    </Typography>
  );

  if (titleIsAmount) {
    titleComponent = (
      <NumberFormat
        value={title}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(formattedValue, customProps) => (
          <Typography component="h2" variant="h1">
            {formattedValue}
          </Typography>
        )}
      />
    );
  }

  return (
    <Card className={clsx(classes.headerCard, cardClass)}>
      <CardActionArea>
        <CardContent className={classes.headerCardContent}>
          {titleComponent}
          <Typography component="p" variant="subtitle1" classes={{ subtitle1: classes.cardSubtitle }}>
            {subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

HeaderCard.prototype = {
  cardClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeaderCard;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { ROLE_KEYS } from '../../../../@jumbo/constants/AppDataConstants';
import { useDebounce } from '../../../../@jumbo/utils/commonHelper';

import { updateRole } from '../../../../redux/actions/Roles';
import EditRoleTab from '../EditRoleTab';
/* import EditRoleAdvancedTab from '../EditRoleAdvancedTab'; */

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  saveBtn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  tabPanelContainer: {
    minHeight: '64vh',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `edit-role-tab-${index}`,
    'aria-controls': `edit-role-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`edit-role-tabpanel-${index}`}
      aria-labelledby={`edit-role-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const EditRoleDialog = ({ open, onClose }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const currentRole = useSelector(({ rolesReducer }) => rolesReducer.currentRole);

  const [selectedTab, setSelectedTab] = useState(0);
  const [roleName, setRoleName] = useState(currentRole[ROLE_KEYS.NAME]);
  const [roleNameError, setRoleNameError] = useState('');
  const [login, setLogin] = useState(currentRole[ROLE_KEYS.LOGIN] === '1' ? '1' : '0');
  const [accessAllJobs, setAccessAllJobs] = useState(currentRole[ROLE_KEYS.ACCESS_ALL_JOBS] === '1' ? '1' : '0');
  const [accessAssignedJobs, setAccessAssignedJobs] = useState(
    currentRole[ROLE_KEYS.ACCESS_ASSIGNED_JOBS] === '1' ? '1' : '0',
  );
  /* const [dashboard, setDashboard] = useState(currentRole[ROLE_KEYS.DASHBOARD] === '1' ? '1' : '0');
  const [accountSettings, setAccountSettings] = useState(currentRole[ROLE_KEYS.ACCOUNT_SETTINGS] === '1' ? '1' : '0'); */
  const [userSettings, setUserSettings] = useState(currentRole[ROLE_KEYS.USER_SETTINGS] === '1' ? '1' : '0');
  /* const [messaging, setMessaging] = useState(currentRole[ROLE_KEYS.MESSAGING] === '1' ? '1' : '0');
  const [clients, setClients] = useState(currentRole[ROLE_KEYS.CLIENTS] === '1' ? '1' : '0');
  const [jobSettings, setJobSettings] = useState(currentRole[ROLE_KEYS.JOB_SETTINGS] === '1' ? '1' : '0');
  const [notifications, setNotifications] = useState(currentRole[ROLE_KEYS.NOTIFICATIONS] === '1' ? '1' : '0');
  const [addJobs, setAddJobs] = useState(currentRole[ROLE_KEYS.ADD_JOBS] === '1' ? '1' : '0');
  const [modifyDoneJobs, setModifyDoneJobs] = useState(currentRole[ROLE_KEYS.MODIFY_DONE_JOBS] === '1' ? '1' : '0');
  const [otherUsersJobs, setOtherUsersJobs] = useState(currentRole[ROLE_KEYS.OTHER_USER_JOBS] === '1' ? '1' : '0');
  const [jobHistory, setJobHistory] = useState(currentRole[ROLE_KEYS.JOB_HISTORY] === '1' ? '1' : '0');
  const [chargeCreditCards, setChargeCreditCards] = useState(currentRole[ROLE_KEYS.CHARGE_CREDIT_CARDS] === '1' ? '1' : '0');
  const [closeJobs, setCloseJobs] = useState(currentRole[ROLE_KEYS.CLOSE_JOBS] === '1' ? '1' : '0');
  const [cancelJobs, setCancelJobs] = useState(currentRole[ROLE_KEYS.CANCEL_JOBS] === '1' ? '1' : '0');
  const [overrideRates, setOverrideRates] = useState(currentRole[ROLE_KEYS.OVERRIDE_RATES] === '1' ? '1' : '0');
  const [updateJobCost, setUpdateJobCost] = useState(currentRole[ROLE_KEYS.UPDATE_JOB_COST] === '1' ? '1' : '0');
  const [settleJobs, setSettleJobs] = useState(currentRole[ROLE_KEYS.SETTLE_JOBS] === '1' ? '1' : '0');
  const [changeJobType, setChangeJobType] = useState(currentRole[ROLE_KEYS.CHANGE_JOB_TYPE] === '1' ? '1' : '0');
  const [calendarSync, setCalendarSync] = useState(currentRole[ROLE_KEYS.CALENDAR_SYNC] === '1' ? '1' : '0');
  const [leads, setLeads] = useState(currentRole[ROLE_KEYS.LEADS] === '1' ? '1' : '0');
  const [leadSettings, setLeadSettings] = useState(currentRole[ROLE_KEYS.LEAD_SETTINGS] === '1' ? '1' : '0');
  const [voice, setVoice] = useState(currentRole[ROLE_KEYS.VOICE] === '1' ? '1' : '0');
  const [voiceSettings, setVoiceSettings] = useState(currentRole[ROLE_KEYS.VOICE_SETTINGS] === '1' ? '1' : '0');
  const [franchises, setFranchises] = useState(currentRole[ROLE_KEYS.FRANCHISES] === '1' ? '1' : '0');
  const [inventory, setInventory] = useState(currentRole[ROLE_KEYS.INVENTORY] === '1' ? '1' : '0');
  const [financialData, setFinancialData] = useState(currentRole[ROLE_KEYS.FINANCIAL_DATA] === '1' ? '1' : '0');
  const [clientMessages, setClientMessages] = useState(currentRole[ROLE_KEYS.CLIENT_MESSAGES] === '1' ? '1' : '0');
  const [franchiseSettings, setFranchiseSettings] = useState(currentRole[ROLE_KEYS.FRANCHISE_SETTINGS] === '1' ? '1' : '0');
  const [jobReport, setJobReport] = useState(currentRole[ROLE_KEYS.JOB_REPORT] === '1' ? '1' : '0');
  const [commissionsReport, setCommissionsReport] = useState(currentRole[ROLE_KEYS.COMMISSIONS_REPORT] === '1' ? '1' : '0');
  const [callReports, setCallReports] = useState(currentRole[ROLE_KEYS.CALL_REPORTS] === '1' ? '1' : '0');
  const [activityReport, setActivityReport] = useState(currentRole[ROLE_KEYS.ACTIVITY_REPORT] === '1' ? '1' : '0');
  const [paymentsReport, setPaymentsReport] = useState(currentRole[ROLE_KEYS.PAYMENTS_REPORT] === '1' ? '1' : '0');
  const [itemsServicesReport, setItemsServicesReport] = useState(
    currentRole[ROLE_KEYS.ITEM_SERVICES_REPORT] === '1' ? '1' : '0',
  );
  const [invoiceReport, setInvoiceReport] = useState(currentRole[ROLE_KEYS.INVOICE_REPORT] === '1' ? '1' : '0');
  const [estimateReport, setEstimateReport] = useState(currentRole[ROLE_KEYS.ESTIMATE_REPORT] === '1' ? '1' : '0');
  const [balanceReport, setBalanceReport] = useState(currentRole[ROLE_KEYS.BALANCE_REPORT] === '1' ? '1' : '0');
  const [hoursReport, setHoursReport] = useState(currentRole[ROLE_KEYS.HOURS_REPORT] === '1' ? '1' : '0');
  const [statisticsReport, setStatisticsReport] = useState(currentRole[ROLE_KEYS.STATISTICS_REPORT] === '1' ? '1' : '0');
  const [adStatistics, setAdStatistics] = useState(currentRole[ROLE_KEYS.AD_STATISTICS] === '1' ? '1' : '0');
  const [techStatistics, setTechStatistics] = useState(currentRole[ROLE_KEYS.TECH_STATISTICS] === '1' ? '1' : '0');
  const [areaStatistics, setAreaStatistics] = useState(currentRole[ROLE_KEYS.AREA_STATISTICS] === '1' ? '1' : '0');
  const [dispatchStatistics, setDispatchStatistics] = useState(
    currentRole[ROLE_KEYS.DISPATCH_STATISTICS] === '1' ? '1' : '0',
  );
  const [viewProfit, setViewProfit] = useState(currentRole[ROLE_KEYS.VIEW_PROFIT] === '1' ? '1' : '0');
  const [leadReport, setLeadReport] = useState(currentRole[ROLE_KEYS.LEAD_REPORT] === '1' ? '1' : '0');
  const [salesReport, setSalesReport] = useState(currentRole[ROLE_KEYS.SALES_REPORT] === '1' ? '1' : '0'); */
  /* const [metroAreaAccessId, setMetroAreaAccessId] = useState(currentRole[ROLE_KEYS.METRO_AREAS_ACCESS_IDS]);
  const [userAccessId, setUserAccessId] = useState(currentRole[ROLE_KEYS.USERS_ACCESS_IDS]); */

  const [errorMessages, setErrorMessages] = useState([]);

  const [actionsSearchTerm, setActionsSearchTerm] = useState('');
  const [filteredActionsTabList, setFilteredActionsTabList] = useState([]);
  const debouncedActionsSearchTerm = useDebounce(actionsSearchTerm, 500);

  /* const [reportsSearchTerm, setReportsSearchTerm] = useState('');
  const [filteredReportsTabList, setFilteredReportsTabList] = useState([]);
  const debouncedReportsSearchTerm = useDebounce(reportsSearchTerm, 500); */

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const actionsTabListItems = [
      {
        title: 'Login',
        subtitle: 'Login Jobs Scheduler',
        state: login,
        handleChange: () => setLogin(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Access ALL Jobs',
        subtitle: 'View all the jobs',
        state: accessAllJobs,
        handleChange: () => setAccessAllJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Access Assigned Jobs',
        subtitle: 'View only assigned jobs',
        state: accessAssignedJobs,
        handleChange: () => setAccessAssignedJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      /* {
        title: 'Dashboard',
        subtitle: 'View dashboard statistics',
        state: dashboard,
        handleChange: () => setDashboard(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Account Settings',
        subtitle: 'Access the account settings page and change preferences',
        state: accountSettings,
        handleChange: () => setAccountSettings(prevState => (prevState === '0' ? '1' : '0')),
      }, */
      {
        title: 'User Settings',
        subtitle: 'Manage users and users settings, manage user roles and restrictions',
        state: userSettings,
        handleChange: () => setUserSettings(prevState => (prevState === '0' ? '1' : '0')),
      },
      /* {
        title: 'Messaging',
        subtitle: 'View the messages inbox',
        state: messaging,
        handleChange: () => setMessaging(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Clients',
        subtitle: 'View and manage clients and client information',
        state: clients,
        handleChange: () => setClients(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Job Settings',
        subtitle: 'Make changes to job settings (Job types, custom fields, metro areas...)',
        state: jobSettings,
        handleChange: () => setJobSettings(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Notifications',
        subtitle: 'Manage auto notifications and notification triggers for clients, users and calls',
        state: notifications,
        handleChange: () => setNotifications(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Add Jobs',
        subtitle: 'Schedule new jobs',
        state: addJobs,
        handleChange: () => setAddJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Modify Done Jobs',
        subtitle: 'Can make changes to done jobs (status: Done)',
        state: modifyDoneJobs,
        handleChange: () => setModifyDoneJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Other Users Jobs',
        subtitle: 'Can view everyones jobs, schedule and messages.',
        state: otherUsersJobs,
        handleChange: () => setOtherUsersJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Job History',
        subtitle: "View the job's history tab (action history, notes, calls and files)",
        state: jobHistory,
        handleChange: () => setJobHistory(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Charge Credit Cards',
        subtitle: 'Add credit charge payments',
        state: chargeCreditCards,
        handleChange: () => setChargeCreditCards(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Close Jobs',
        subtitle: 'Can move jobs to the Done status',
        state: closeJobs,
        handleChange: () => setCloseJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Cancel Jobs',
        subtitle: 'Can cancel jobs',
        state: cancelJobs,
        handleChange: () => setCancelJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Override Rates',
        subtitle: 'Can modify commission rate from the job page',
        state: overrideRates,
        handleChange: () => setOverrideRates(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Update Job Cost',
        subtitle: 'Update job items cost and job cost',
        state: updateJobCost,
        handleChange: () => setUpdateJobCost(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Settle Jobs',
        subtitle: 'Can settle jobs in the commissions report',
        state: settleJobs,
        handleChange: () => setSettleJobs(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Change Job Type',
        subtitle: 'Can change job types',
        state: changeJobType,
        handleChange: () => setChangeJobType(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Calendar Sync',
        subtitle: 'Can sync to external calendar',
        state: calendarSync,
        handleChange: () => setCalendarSync(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Leads',
        subtitle: 'Can view and edit leads',
        state: leads,
        handleChange: () => setLeads(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Lead Settings',
        subtitle: 'Manage lead settings and auto leads',
        state: leadSettings,
        handleChange: () => setLeadSettings(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Voice',
        subtitle: 'Can view voice dashboard and reports',
        state: voice,
        handleChange: () => setVoice(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Voice Settings',
        subtitle: 'Can view voice and manage voice features (voice dashboard, numbers, call masking...)',
        state: voiceSettings,
        handleChange: () => setVoiceSettings(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Franchises',
        subtitle: 'View Franchises',
        state: franchises,
        handleChange: () => setFranchises(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Inventory',
        subtitle: 'Can view and manage inventory (Reports, containers and inventory levels)',
        state: inventory,
        handleChange: () => setInventory(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Financial Data',
        subtitle: 'Can view prices, totals and costs on jobs and invoices.',
        state: financialData,
        handleChange: () => setFinancialData(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: "Client's Messages",
        subtitle: "Can view messages for an open job's client.",
        state: clientMessages,
        handleChange: () => setClientMessages(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Franchise Settings',
        subtitle: 'Can add and edit franchise settings',
        state: franchiseSettings,
        handleChange: () => setFranchiseSettings(prevState => (prevState === '0' ? '1' : '0')),
      }, */
    ];

    let tempActionList = actionsTabListItems;
    if (debouncedActionsSearchTerm) {
      tempActionList = actionsTabListItems.filter(item => {
        return (
          item.title.toLowerCase().includes(debouncedActionsSearchTerm.toLowerCase()) ||
          item.subtitle.toLowerCase().includes(debouncedActionsSearchTerm.toLowerCase())
        );
      });
    }
    setFilteredActionsTabList(tempActionList);
  }, [
    login,
    accessAllJobs,
    accessAssignedJobs,
    /* dashboard,
    accountSettings, */
    userSettings,
    /* messaging,
    clients,
    jobSettings,
    notifications,
    addJobs,
    modifyDoneJobs,
    otherUsersJobs,
    jobHistory,
    chargeCreditCards,
    closeJobs,
    cancelJobs,
    overrideRates,
    updateJobCost,
    settleJobs,
    changeJobType,
    calendarSync,
    leads,
    leadSettings,
    voice,
    voiceSettings,
    franchises,
    inventory,
    financialData,
    clientMessages,
    franchiseSettings, */
    debouncedActionsSearchTerm,
  ]);

  /* useEffect(() => {
    const reportsTabListItems = [
      {
        title: 'Job Report',
        subtitle: 'View jobs report',
        state: jobReport,
        handleChange: () => setJobReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Commissions Report',
        subtitle: 'View finance report',
        state: commissionsReport,
        handleChange: () => setCommissionsReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Call Reports',
        subtitle: 'View call reports',
        state: callReports,
        handleChange: () => setCallReports(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Activity Report',
        subtitle: 'View user activity report',
        state: activityReport,
        handleChange: () => setActivityReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Payments Report',
        subtitle: 'Payments report',
        state: paymentsReport,
        handleChange: () => setPaymentsReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Items And Services Report',
        subtitle: 'Items and services report',
        state: itemsServicesReport,
        handleChange: () => setItemsServicesReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Invoice Report',
        subtitle: 'Invoice report',
        state: invoiceReport,
        handleChange: () => setInvoiceReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Estimate Report',
        subtitle: 'Estimate Report',
        state: estimateReport,
        handleChange: () => setEstimateReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Balance Report',
        subtitle: 'Balance Report',
        state: balanceReport,
        handleChange: () => setBalanceReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Hours Report',
        subtitle: 'View employee hours report',
        state: hoursReport,
        handleChange: () => setHoursReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Statistics Report',
        subtitle: 'View statistics report',
        state: statisticsReport,
        handleChange: () => setStatisticsReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Statistics Report: Ad Statistics',
        subtitle: 'Can change tech rates',
        state: adStatistics,
        handleChange: () => setAdStatistics(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Statistics Report: Tech Statistics',
        subtitle: 'Can change tech rates',
        state: techStatistics,
        handleChange: () => setTechStatistics(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Statistics Report: Area Statistics',
        subtitle: 'Can change tech rates',
        state: areaStatistics,
        handleChange: () => setAreaStatistics(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Statistics Report: Dispatch Statistics',
        subtitle: 'Can change tech rates',
        state: dispatchStatistics,
        handleChange: () => setDispatchStatistics(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Statistics Report: View Profit',
        subtitle: 'Can change tech rates',
        state: viewProfit,
        handleChange: () => setViewProfit(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Lead Report',
        subtitle: 'view lead report',
        state: leadReport,
        handleChange: () => setLeadReport(prevState => (prevState === '0' ? '1' : '0')),
      },
      {
        title: 'Sales Report',
        subtitle: 'Can view sales report',
        state: salesReport,
        handleChange: () => setSalesReport(prevState => (prevState === '0' ? '1' : '0')),
      },
    ];

    let tempReportList = reportsTabListItems;
    if (debouncedReportsSearchTerm) {
      tempReportList = reportsTabListItems.filter(item => {
        return (
          item.title.toLowerCase().includes(debouncedReportsSearchTerm.toLowerCase()) ||
          item.subtitle.toLowerCase().includes(debouncedReportsSearchTerm.toLowerCase())
        );
      });
    }
    setFilteredReportsTabList(tempReportList);
  }, [
    jobReport,
    commissionsReport,
    callReports,
    activityReport,
    paymentsReport,
    itemsServicesReport,
    invoiceReport,
    estimateReport,
    balanceReport,
    hoursReport,
    statisticsReport,
    adStatistics,
    techStatistics,
    areaStatistics,
    dispatchStatistics,
    viewProfit,
    leadReport,
    salesReport,
    debouncedReportsSearchTerm,
  ]); */

  const roleNameBlurHandler = event => {
    if (!event.target.value) {
      setRoleNameError(requiredMessage);
    }
  };

  const handleTabSelection = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;
    if (!roleName) {
      formIsValid = false;
      setRoleNameError(requiredMessage);
    }

    if (formIsValid) {
      const roleData = {
        authcode: authUser.authcode,
        [ROLE_KEYS.ROLE_ID]: currentRole[ROLE_KEYS.ID],
        [ROLE_KEYS.NAME]: roleName,
        [ROLE_KEYS.LOGIN]: login,
        [ROLE_KEYS.ACCESS_ALL_JOBS]: accessAllJobs,
        [ROLE_KEYS.ACCESS_ASSIGNED_JOBS]: accessAssignedJobs,
        /* [ROLE_KEYS.DASHBOARD]: dashboard,
        [ROLE_KEYS.ACCOUNT_SETTINGS]: accountSettings, */
        [ROLE_KEYS.USER_SETTINGS]: userSettings,
        /* [ROLE_KEYS.MESSAGING]: messaging,
        [ROLE_KEYS.CLIENTS]: clients,
        [ROLE_KEYS.JOB_SETTINGS]: jobSettings,
        [ROLE_KEYS.NOTIFICATIONS]: notifications,
        [ROLE_KEYS.ADD_JOBS]: addJobs,
        [ROLE_KEYS.MODIFY_DONE_JOBS]: modifyDoneJobs,
        [ROLE_KEYS.OTHER_USER_JOBS]: otherUsersJobs,
        [ROLE_KEYS.JOB_HISTORY]: jobHistory,
        [ROLE_KEYS.CHARGE_CREDIT_CARDS]: chargeCreditCards,
        [ROLE_KEYS.CLOSE_JOBS]: closeJobs,
        [ROLE_KEYS.CANCEL_JOBS]: cancelJobs,
        [ROLE_KEYS.OVERRIDE_RATES]: overrideRates,
        [ROLE_KEYS.UPDATE_JOB_COST]: updateJobCost,
        [ROLE_KEYS.SETTLE_JOBS]: settleJobs,
        [ROLE_KEYS.CHANGE_JOB_TYPE]: changeJobType,
        [ROLE_KEYS.CALENDAR_SYNC]: calendarSync,
        [ROLE_KEYS.LEADS]: leads,
        [ROLE_KEYS.LEAD_SETTINGS]: leadSettings,
        [ROLE_KEYS.VOICE]: voice,
        [ROLE_KEYS.VOICE_SETTINGS]: voiceSettings,
        [ROLE_KEYS.FRANCHISES]: franchises,
        [ROLE_KEYS.INVENTORY]: inventory,
        [ROLE_KEYS.FINANCIAL_DATA]: financialData,
        [ROLE_KEYS.CLIENT_MESSAGES]: clientMessages,
        [ROLE_KEYS.FRANCHISE_SETTINGS]: franchiseSettings,
        [ROLE_KEYS.JOB_REPORT]: jobReport,
        [ROLE_KEYS.COMMISSIONS_REPORT]: commissionsReport,
        [ROLE_KEYS.CALL_REPORTS]: callReports,
        [ROLE_KEYS.ACTIVITY_REPORT]: activityReport,
        [ROLE_KEYS.PAYMENTS_REPORT]: paymentsReport,
        [ROLE_KEYS.ITEM_SERVICES_REPORT]: itemsServicesReport,
        [ROLE_KEYS.INVOICE_REPORT]: invoiceReport,
        [ROLE_KEYS.ESTIMATE_REPORT]: estimateReport,
        [ROLE_KEYS.BALANCE_REPORT]: balanceReport,
        [ROLE_KEYS.HOURS_REPORT]: hoursReport,
        [ROLE_KEYS.STATISTICS_REPORT]: statisticsReport,
        [ROLE_KEYS.AD_STATISTICS]: adStatistics,
        [ROLE_KEYS.TECH_STATISTICS]: techStatistics,
        [ROLE_KEYS.AREA_STATISTICS]: areaStatistics,
        [ROLE_KEYS.DISPATCH_STATISTICS]: dispatchStatistics,
        [ROLE_KEYS.VIEW_PROFIT]: viewProfit,
        [ROLE_KEYS.LEAD_REPORT]: leadReport,
        [ROLE_KEYS.SALES_REPORT]: salesReport, */
      };

      dispatch(
        updateRole(
          { roleData: roleData },
          () => {
            if (_isMounted.current) onClose();
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Edit Restrictions for Role '{currentRole ? currentRole[ROLE_KEYS.NAME] : ''}'
          </Typography>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {currentRole && (
        <Box pb={4}>
          <form onSubmit={handleFormSubmit}>
            <Box m={4}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Role Name"
                value={roleName}
                onChange={e => {
                  setRoleName(e.target.value);
                  setRoleNameError('');
                }}
                onBlur={roleNameBlurHandler}
                helperText={roleNameError}
              />
            </Box>
            <Box>
              <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabSelection}
                aria-label="Edit Role Tabs">
                <Tab label="Actions" {...a11yProps(0)} />
                {/* <Tab label="Reports" {...a11yProps(1)} /> */}
                {/* <Tab label="Advanced" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <Box className={classes.tabPanelContainer}>
              <TabPanel value={selectedTab} index={0}>
                <EditRoleTab
                  items={filteredActionsTabList}
                  searchTerm={actionsSearchTerm}
                  setSearchTerm={setActionsSearchTerm}
                  errorMessages={errorMessages}
                />
              </TabPanel>
              {/* <TabPanel value={selectedTab} index={1}>
                <EditRoleTab
                  items={filteredReportsTabList}
                  searchTerm={reportsSearchTerm}
                  setSearchTerm={setReportsSearchTerm}
                  errorMessages={errorMessages}
                />
              </TabPanel> */}
              {/* <TabPanel value={selectedTab} index={2}>
                <EditRoleAdvancedTab
                  currentMetroAreaId={currentRole[ROLE_KEYS.METRO_AREAS_ACCESS_IDS]}
                  metroAreaAccessId={metroAreaAccessId}
                  setMetroAreaAccessId={setMetroAreaAccessId}
                  currentUserId={currentRole[ROLE_KEYS.USERS_ACCESS_IDS]}
                  userAccessId={userAccessId}
                  setUserAccessId={setUserAccessId}
                />
              </TabPanel> */}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button type="button" size="large" variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" size="large" variant="contained" color="primary" className={classes.saveBtn}>
                Save
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {!currentRole && (
        <Box p={4}>
          <Alert severity="error">Role not found</Alert>
        </Box>
      )}
    </Dialog>
  );
};

EditRoleDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditRoleDialog;

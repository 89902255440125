import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Edit, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import {
  ADMIN_ROLE,
  DISPATCH_ROLE,
  EMPLOYEE_ROLE,
  SUBCONTRACTOR_ROLE,
  ROLE_KEYS,
} from '../../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  actionBtn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

const RolesListRow = ({ row, onRoleEdit, onRoleDelete }) => {
  const classes = useStyles();

  const roleID = row[ROLE_KEYS.ID];
  const roleName = row[ROLE_KEYS.NAME];
  const labelId = `roles-row-${roleID}`;

  const handleRoleEdit = event => onRoleEdit(row);
  const handleRoleDelete = event => onRoleDelete(row);

  return (
    <TableRow hover tabIndex={-1} key={roleID}>
      <TableCell component="th" id={labelId} scope="row">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {roleName}
        </Typography>
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        {![ADMIN_ROLE].includes(roleID) && (
          <IconButton aria-label="edit" color="primary" onClick={handleRoleEdit} classes={{ root: classes.actionBtn }}>
            <Edit />
          </IconButton>
        )}
        {![ADMIN_ROLE, DISPATCH_ROLE, EMPLOYEE_ROLE, SUBCONTRACTOR_ROLE].includes(roleID) && (
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={handleRoleDelete}
            classes={{ root: classes.actionBtn, colorPrimary: classes.deleteBtn }}>
            <Delete />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(RolesListRow);

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { requiredMessage, percentageNotValid } from '../../../@jumbo/constants/ErrorMessages';
import { JOB_KEYS, JOB_JOB_TYPE_KEYS, JOB_JOB_TYPES_TOTALS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { isValidPercentage } from '../../../utils/FormValidation';
import { deleteJobJobType, setCurrentJobJobType, updateJobJobTypesCalculations } from '../../../redux/actions/JobJobTypes';

import JobJobTypesTable from '../JobJobTypesTable';
import JobTypesDialog from '../JobTypesDialog';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  headerDivider: {
    marginTop: theme.spacing(2),
  },
}));

const JobServices = () => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentJob } = useSelector(({ jobsReducer }) => jobsReducer);
  const { jobJobTypes, jobJobTypesTotals } = useSelector(({ jobJobTypesReducer }) => jobJobTypesReducer);

  const [subTotal, setSubTotal] = useState('0.00');
  const [taxPercent, setTaxPercent] = useState('0.00');
  const [taxPercentError, setTaxPercentError] = useState('');
  const [tax, setTax] = useState('0.00');
  const [total, setTotal] = useState('0.00');
  const [calculations, setCalculations] = useState({
    [JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]: '0.00',
  });
  const [calculationsChanged, setCalculationsChanged] = useState(false);
  const [openJobTypesDialog, setOpenJobTypesDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch]);

  useEffect(() => {
    if (jobJobTypesTotals) {
      setSubTotal(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.SUB_TOTAL]);
      setTaxPercent(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]);
      setTax(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TAX]);
      setTotal(jobJobTypesTotals[JOB_JOB_TYPES_TOTALS_KEYS.TOTAL]);
    }
  }, [jobJobTypesTotals]);

  useEffect(() => {
    if (calculationsChanged) {
      setErrorMessages([]);
      setMiscellaneousErrors([]);

      const jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_ID]: currentJob[JOB_KEYS.ID],
        ...calculations,
      };

      dispatch(
        updateJobJobTypesCalculations(
          { jobJobTypeData: jobJobTypeData },
          () => {
            if (_isMounted.current) setCalculationsChanged(false);
          },
          messages => {
            if (_isMounted.current) {
              setErrorMessages(messages);
              setCalculationsChanged(false);
            }
          },
        ),
      );
    }
  }, [dispatch, authUser.authcode, currentJob, calculations, calculationsChanged]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const taxPercentBlurHandler = event => {
    if (!event.target.value) {
      setTaxPercentError(requiredMessage);
    } else if (!isValidPercentage(event.target.value)) {
      setTaxPercentError(percentageNotValid);
    } else {
      setCalculations(prevState => {
        return { ...prevState, [JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]: taxPercent };
      });
      setCalculationsChanged(true);
    }
  };

  const handleOpenJobTypesDialog = () => setOpenJobTypesDialog(true);

  const handleCloseJobTypesDialog = () => {
    setOpenJobTypesDialog(false);
    dispatch(setCurrentJobJobType(null));
  };

  const handleRowEdit = jobJobType => {
    console.log('onEdit', { jobJobType });
    dispatch(setCurrentJobJobType(jobJobType));
    setOpenJobTypesDialog(true);
  };

  const handleJobJobTypeDelete = jobJobType => {
    setSelectedJobType(jobJobType);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    if (selectedJobType && selectedJobType[JOB_JOB_TYPE_KEYS.ID]) {
      const jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_JOB_TYPE_ID]: selectedJobType[JOB_JOB_TYPE_KEYS.ID],
      };

      dispatch(
        deleteJobJobType(
          { jobJobTypeData: jobJobTypeData },
          () => {
            if (_isMounted.current) setSelectedJobType(null);
          },
          () => {
            if (_isMounted.current) setSelectedJobType(null);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        setSelectedJobType(null);
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid service',
        });
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelectedJobType(null);
  };

  return (
    <React.Fragment>
      <GridContainer>
        {jobJobTypes && jobJobTypesTotals && (
          <Grid item xs={12}>
            <form>
              <GridContainer>
                <Grid item xs={12}>
                  <Typography variant="h3" component="h3">
                    Services
                  </Typography>
                  <Divider classes={{ root: classes.headerDivider }} />
                </Grid>
                <Grid item xs={12}>
                  <JobJobTypesTable jobJobTypes={jobJobTypes} onEdit={handleRowEdit} onDelete={handleJobJobTypeDelete} />
                </Grid>
                {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                  <Grid item xs={12}>
                    {miscellaneousErrors.map((miscellaneousError, idx) => (
                      <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                        {miscellaneousError}
                      </Typography>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12} md={8}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenJobTypesDialog}>
                    Type of Service
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GridContainer>
                    <Grid item xs={12}>
                      <AppTextInput
                        type="text"
                        name="sub_total"
                        variant="outlined"
                        label="Sub Total"
                        value={subTotal}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppTextInput
                        type="text"
                        name="tax_percent"
                        variant="outlined"
                        label="Tax %"
                        value={taxPercent}
                        onChange={e => {
                          setTaxPercent(e.target.value);
                          setTaxPercentError('');
                        }}
                        onBlur={taxPercentBlurHandler}
                        helperText={taxPercentError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppTextInput
                        type="text"
                        name="tax"
                        variant="outlined"
                        label="Tax"
                        value={tax}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppTextInput
                        type="text"
                        name="total"
                        variant="outlined"
                        label="Total"
                        value={total}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </GridContainer>
                </Grid>
              </GridContainer>
            </form>
          </Grid>
        )}
        {(!jobJobTypes || !jobJobTypesTotals) && (
          <Grid item xs={12}>
            <Alert severity="error">Service data not found!</Alert>
          </Grid>
        )}
      </GridContainer>

      {openJobTypesDialog && (
        <JobTypesDialog
          currentJobId={currentJob[JOB_KEYS.ID]}
          open={openJobTypesDialog}
          onClose={handleCloseJobTypesDialog}
        />
      )}

      {selectedJobType && (
        <ConfirmDialog
          open={openConfirmDialog}
          title="Delete Service?"
          content={'This action cannot be undone.'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </React.Fragment>
  );
};

export default JobServices;

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons';

import { TAX_KEYS, ACTIVE_TAX } from '../../../@jumbo/constants/AppDataConstants';

const TaxListRow = ({ row, onTaxEdit }) => {
  const taxId = row[TAX_KEYS.ID];
  const labelId = `tax-${taxId}`;

  const handleTaxEdit = event => onTaxEdit(row);

  return (
    <TableRow hover tabIndex={-1} key={taxId}>
      <TableCell component="th" id={labelId} scope="row">
        {row[TAX_KEYS.NAME]}
      </TableCell>
      <TableCell>{row[TAX_KEYS.RATE]}</TableCell>
      <TableCell>{row[TAX_KEYS.STATUS] === ACTIVE_TAX ? 'Enabled' : 'Disabled'}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <IconButton color="primary" onClick={handleTaxEdit}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TaxListRow);

import React from 'react';
import NumberFormat from 'react-number-format';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { CUSTOMER_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
  },
  hideOverflow: {
    maxWidth: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CustomerListRow = ({ row, onRowClick }) => {
  const classes = useStyles();

  const customerId = row[CUSTOMER_KEYS.ID];
  const labelId = `customer-${customerId}`;

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, customerId)}
      tabIndex={-1}
      key={customerId}
      classes={{
        hover: classes.tableRow,
      }}>
      <TableCell component="th" id={labelId} scope="row">
        {customerId}
      </TableCell>
      <TableCell>{`${row[CUSTOMER_KEYS.FIRST_NAME]} ${row[CUSTOMER_KEYS.LAST_NAME]}`}</TableCell>
      <TableCell>{row[CUSTOMER_KEYS.COMPANY_NAME]}</TableCell>
      <TableCell className={classes.hideOverflow}>{row[CUSTOMER_KEYS.FORMATTED_ADDRESS]}</TableCell>
      <TableCell onClick={event => event.stopPropagation()}>
        <a href={`tel:${row[CUSTOMER_KEYS.PHONE_ONE]}`}>
          <NumberFormat isNumericString displayType="text" value={row[CUSTOMER_KEYS.PHONE_ONE]} format="(###) ###-####" />
        </a>
      </TableCell>
      <TableCell onClick={event => event.stopPropagation()}>
        <a href={`tel:${row[CUSTOMER_KEYS.PHONE_TWO]}`}>
          <NumberFormat isNumericString displayType="text" value={row[CUSTOMER_KEYS.PHONE_TWO]} format="(###) ###-####" />
        </a>
      </TableCell>
      <TableCell className={classes.hideOverflow}>{row[CUSTOMER_KEYS.FORMATTED_CREATED]}</TableCell>
    </TableRow>
  );
};

export default React.memo(CustomerListRow);

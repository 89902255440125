import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  ADD_JOB_JOB_TYPE,
  DELETE_JOB_JOB_TYPE,
  EDIT_JOB_JOB_TYPE,
  GET_JOB_JOB_TYPES,
  GET_JOB_JOB_TYPES_TOTALS,
  SET_JOB_JOB_TYPE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  ADD_JOB_JOB_TYPE_LINK,
  GET_JOB_JOB_TYPES_LINK,
  DELETE_JOB_JOB_TYPE_LINK,
  UPDATE_JOB_JOB_TYPE_LINK,
  UPDATE_JOB_JOB_TYPE_TOTALS_LINK,
} from '../../@jumbo/constants/APIConstants';
import { JOB_JOB_TYPE_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getJobJobTypes = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobJobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_JOB_JOB_TYPES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobsitems) {
              const jobJobTypes = data.jobsitems;
              dispatch(fetchSuccess());
              dispatch({ type: GET_JOB_JOB_TYPES, payload: jobJobTypes });
              if (data.total) dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: data.total });
              if (callbackFun) callbackFun(jobJobTypes);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: GET_JOB_JOB_TYPES, payload: [] });
        dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: { tax_per: '0', tax: '0', total: '0', subtotal: '0' } });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setJobJobTypes = jobJobTypes => {
  return dispatch => {
    dispatch({ type: GET_JOB_JOB_TYPES, payload: jobJobTypes });
  };
};

export const setJobJobTypesTotals = totals => {
  return dispatch => {
    dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: totals });
  };
};

export const addJobJobType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobJobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_JOB_JOB_TYPE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.item) {
              dispatch(fetchSuccess('Service was added successfully.'));
              dispatch({ type: ADD_JOB_JOB_TYPE, payload: data.item });
              if (data.total) dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: data.total });
              if (callbackFun) callbackFun(data.item);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const setCurrentJobJobType = jobJobType => {
  return dispatch => {
    dispatch({ type: SET_JOB_JOB_TYPE_DETAILS, payload: jobJobType });
  };
};

export const updateJobJobType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobJobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_JOB_JOB_TYPE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.item) {
              dispatch(fetchSuccess('Selected service was updated successfully.'));
              dispatch({ type: EDIT_JOB_JOB_TYPE, payload: data.item });
              if (data.total) dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: data.total });
              if (callbackFun) callbackFun(data.item);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateJobJobTypesCalculations = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobJobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_JOB_JOB_TYPE_TOTALS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.total) {
              dispatch(fetchSuccess('Totals were updated successfully.'));
              dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: data.total });
              if (callbackFun) callbackFun(data.total);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteJobJobType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobJobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_JOB_JOB_TYPE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected service was deleted successfully.'));
            dispatch({ type: DELETE_JOB_JOB_TYPE, payload: request.jobJobTypeData[JOB_JOB_TYPE_KEYS.JOB_JOB_TYPE_ID] });
            if (responseData.data && responseData.data.total)
              dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: responseData.data.total });
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

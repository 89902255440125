const settingsSectionsList = [
  /* {
    id: 'generalSettings',
    sectionName: 'General Settings',
    sectionSettings: [
      {
        id: 'generalSettings_Notifications',
        name: 'Notifications',
        icon: 'Notifications',
        link: '/notificationCenter',
      },
      {
        id: 'generalSettings_Automation',
        name: 'Automation Center',
        icon: 'Adb',
        link: '/automationCenter',
      },
      {
        id: 'generalSettings_Documents',
        name: 'Documents',
        icon: 'Assignment',
        link: '/documents',
      },
    ],
  }, */
  {
    id: 'userRoles',
    sectionName: 'User & Roles',
    sectionSettings: [
      {
        id: 'userRoles_Team',
        name: 'Team Management',
        icon: 'Group',
        link: '/squad',
      },
      {
        id: 'userRoles_Roles',
        name: 'Roles Management',
        icon: 'SupervisedUserCircle',
        link: '/roles',
      },
    ],
  },
  {
    id: 'jobSettings',
    sectionName: 'Job Settings',
    sectionSettings: [
      /* {
        id: 'jobSettings_metroAreas',
        name: 'Metro Areas',
        icon: 'Map',
        link: '/metroAreas',
      }, */
      {
        id: 'jobSettings_JobTypes',
        name: 'Job Types',
        icon: 'Build',
        link: '/jobTypes',
      },
      /* {
        id: 'jobSettings_AdGroups',
        name: 'Ad Groups',
        icon: 'Web',
        link: '/adgroups',
      }, */
      /* {
        id: 'jobSettings_Products',
        name: 'Products',
        icon: 'BrandingWatermark',
        link: '/products',
      }, */
      /* {
        id: 'jobSettings_SubStatus',
        name: 'Sub-Status',
        icon: 'LocalOffer',
        link: '/subStatus',
      }, */
      {
        id: 'jobSettings_Taxes',
        name: 'Taxes',
        icon: 'AccountBalanceWallet',
        link: '/taxes',
      },
    ],
  },
  /* {
    id: 'callsText',
    sectionName: 'Calls & Text',
    sectionSettings: [
      {
        id: 'callsText_SmsSettings',
        name: 'Text Messages',
        icon: 'QuestionAnswer',
        link: '/smsSettings',
      },
      {
        id: 'callsText_Numbers',
        name: 'Numbers',
        icon: 'Dialpad',
        link: '/numbers',
      },
    ],
  }, */
];

export default settingsSectionsList;

import React from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';

import CmtSearch from '../../../@coremat/CmtSearch';

import useStyles from './index.style';

const CustomersTableToolbar = ({ searchTerm, setSearchTerm, handleCSVExport }) => {
  const classes = useStyles();

  const onSearchChipDelete = () => setSearchTerm('');
  const onExportCSV = () => handleCSVExport();

  return (
    <Toolbar className={classes.root}>
      <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
      <div className={classes.chipsRoot}>{searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}</div>
      <Button type="button" variant="outlined" startIcon={<DescriptionIcon />} onClick={onExportCSV}>
        Export
      </Button>
    </Toolbar>
  );
};

export default CustomersTableToolbar;

CustomersTableToolbar.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { getComparator, stableSort } from '../../@jumbo/utils/tableHelper';
import { useDebounce } from '../../@jumbo/utils/commonHelper';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { TAX_KEYS } from '../../@jumbo/constants/AppDataConstants';

import { getTaxes, setCurrentTax } from '../../redux/actions/Taxes';
import AddEditTax from './AddEditTax';
import TaxesTableToolbar from './TaxesTableToolbar';
import TaxesTableHead from './TaxesTableHead';
import TaxListRow from './TaxListRow';
import NoRecordFound from '../NoRecordFound';

import useStyles from './index.style';

const pageName = 'Taxes';

const breadcrumbs = [
  { label: 'Settings', link: '/settings' },
  { label: pageName, isActive: true },
];

const Taxes = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { taxes } = useSelector(({ taxesReducer }) => taxesReducer);

  const [filteredTaxes, setFilteredTaxes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [orderBy, setOrderBy] = useState(TAX_KEYS.RATE);
  const [order, setOrder] = useState('asc');
  const [taxesFetched, setTaxesFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddEditTaxDialog, setOpenAddEditTaxDialog] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(
          getTaxes(payload, () => {
            if (isActive) setTaxesFetched(true);
          }),
        );
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise.catch(error => {
      /* Setting to 'true' means API has been executed, not necessarily successfully */
      if (isActive) setTaxesFetched(true);
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    let tempFilteredTaxes = taxes;

    if (filterOptions.length > 0) {
      tempFilteredTaxes = tempFilteredTaxes.filter(tax => filterOptions.includes(tax[TAX_KEYS.STATUS]));
    }

    if (debouncedSearchTerm) {
      tempFilteredTaxes = tempFilteredTaxes.filter(tax => {
        return (
          (tax[TAX_KEYS.ID] && tax[TAX_KEYS.ID].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (tax[TAX_KEYS.NAME] && tax[TAX_KEYS.NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
          (tax[TAX_KEYS.RATE] && tax[TAX_KEYS.RATE].toString().includes(debouncedSearchTerm.toLowerCase()))
        );
      });
    }

    setFilteredTaxes(tempFilteredTaxes);
    setFilterApplied(filterOptions.length > 0 || !!debouncedSearchTerm);
  }, [taxes, filterOptions, debouncedSearchTerm]);

  const handleOpenTaxDialog = () => {
    setOpenAddEditTaxDialog(true);
  };

  const handleCloseTaxDialog = () => {
    setOpenAddEditTaxDialog(false);
    dispatch(setCurrentTax(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTaxEdit = tax => {
    dispatch(setCurrentTax(tax));
    setOpenAddEditTaxDialog(true);
  };

  const headCells = [
    { id: TAX_KEYS.NAME, disablePadding: false, label: 'Name', width: '40%' },
    { id: TAX_KEYS.RATE, disablePadding: false, label: 'Rate', width: '25%' },
    { id: TAX_KEYS.STATUS, disablePadding: false, label: 'Status', width: '20%' },
  ];

  /* Add 1 because there is an 'actions' column */
  const colspan = headCells && headCells.length > 0 ? (headCells.length + 1) : 1;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenTaxDialog}>
            Add New
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <TaxesTableToolbar
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="taxesTableTitle" aria-label="Taxes Table">
                <TaxesTableHead
                  headCells={headCells}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!!filteredTaxes.length ? (
                    stableSort(filteredTaxes, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => <TaxListRow key={index} row={row} onTaxEdit={handleTaxEdit} />)
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={colspan} rowSpan={10}>
                        {isFilterApplied ? (
                          <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                        ) : (
                          <NoRecordFound>{taxesFetched ? 'There are no records found.' : 'Loading taxes...'}</NoRecordFound>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={filteredTaxes.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Grid>
      </GridContainer>

      {openAddEditTaxDialog && <AddEditTax open={openAddEditTaxDialog} onCloseDialog={handleCloseTaxDialog} />}
    </PageContainer>
  );
};

export default Taxes;

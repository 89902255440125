import React from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import { alpha, makeStyles } from '@material-ui/core/styles';

import { getChatSidebarHeight } from '../../../@jumbo/constants/AppConstants';
import { MESSAGE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import CmtList from '../../../@coremat/CmtList';

import UserCell from '../UserCell';
import NoRecordFound from '../NoRecordFound';

const useStyles = makeStyles(theme => ({
  chatCellHeader: {
    backgroundColor: alpha(theme.palette.common.dark, 0.04),
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.04)}`,
    padding: 16,
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    letterSpacing: 1.5,
  },
  perfectScrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px)`,
  },
}));

const UsersList = ({ width, onContactSelect }) => {
  const classes = useStyles({ height: getChatSidebarHeight(width) });

  const { users, currentUser } = useSelector(({ messagesReducer }) => messagesReducer);

  return users.length > 0 ? (
    <PerfectScrollbar className={classes.perfectScrollbarRoot}>
      <CmtList
        data={users}
        renderRow={(user, index) => (
          <UserCell
            key={`${index}-${user[MESSAGE_KEYS.RECEIVER_ID]}`}
            data={user}
            currentUser={currentUser}
            onContactSelect={onContactSelect}
          />
        )}
      />
    </PerfectScrollbar>
  ) : (
    <NoRecordFound />
  );
};

UsersList.prototype = {
  onContactSelect: PropTypes.func.isRequired,
};

export default UsersList;

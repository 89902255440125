import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AdSources from './AdSources';
import Auth from './Auth';
import Colors from './Colors';
import Common from './Common';
import Countries from './Countries';
import Customers from './Customers';
import CustomerNotes from './CustomerNotes';
import CustomerProperties from './CustomerProperties';
import Dashboard from './Dashboard';
import Estimates from './Estimates';
import EstimateProducts from './EstimateProducts';
import EstimateSignatures from './EstimateSignatures';
import EstimateStatuses from './EstimateStatuses';
import Invoices from './Invoices';
import InvoiceProducts from './InvoiceProducts';
import InvoiceSignatures from './InvoiceSignatures';
import InvoiceStatuses from './InvoiceStatuses';
import Jobs from './Jobs';
import JobFiles from './JobFiles';
import JobJobTypes from './JobJobTypes';
import JobPayments from './JobPayments';
import JobProducts from './JobProducts';
import JobStatuses from './JobStatuses';
import JobTypes from './JobTypes';
import Messages from './Messages';
import MetroAreas from './MetroAreas';
import Products from './Products';
import Roles from './Roles';
import Squad from './Squad';
import Taxes from './Taxes';
import USAStates from './USAStates';
import UserTypes from './UserTypes';
/* import TaskList from './TaskList';
import MailApp from './MailApp';
import Chat from './Chat';
import ContactApp from './ContactApp';
import ProfileApp from './ProfileApp';
import WallApp from './WallApp';
import Users from './Users'; */

export default history =>
  combineReducers({
    router: connectRouter(history),
    adSourcesReducer: AdSources,
    auth: Auth,
    colorsReducer: Colors,
    common: Common,
    countriesReducer: Countries,
    customerNotesReducer: CustomerNotes,
    customerPropertiesReducer: CustomerProperties,
    customersReducer: Customers,
    dashboardReducer: Dashboard,
    estimatesReducer: Estimates,
    estimateProductsReducer: EstimateProducts,
    estimateSignaturesReducer: EstimateSignatures,
    estimateStatusesReducer: EstimateStatuses,
    invoicesReducer: Invoices,
    invoiceProductsReducer: InvoiceProducts,
    invoiceSignaturesReducer: InvoiceSignatures,
    invoiceStatusesReducer: InvoiceStatuses,
    jobsReducer: Jobs,
    jobFilesReducer: JobFiles,
    jobJobTypesReducer: JobJobTypes,
    jobPaymentsReducer: JobPayments,
    jobProductsReducer: JobProducts,
    jobStatusesReducer: JobStatuses,
    jobTypesReducer: JobTypes,
    messagesReducer: Messages,
    metroAreasReducer: MetroAreas,
    productsReducer: Products,
    rolesReducer: Roles,
    squadReducer: Squad,
    taxesReducer: Taxes,
    usaStatesReducer: USAStates,
    userTypesReducer: UserTypes,
    /* taskList: TaskList,
    mailApp: MailApp,
    chat: Chat,
    contactApp: ContactApp,
    profileApp: ProfileApp,
    wallApp: WallApp,
    usersReducer: Users, */
  });

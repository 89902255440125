import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { JOB_JOB_TYPE_KEYS, JOB_JOB_TYPES_TOTALS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const headCells = [
  { id: JOB_JOB_TYPE_KEYS.NAME, label: 'Name', width: '80%', align: 'left' },
  /* { id: ESTIMATE_PRODUCT_KEYS.QUANTITY, label: 'Quantity', width: '15%' },
  { id: ESTIMATE_PRODUCT_KEYS.PRICE, label: 'Price', width: '15%' }, */
  { id: JOB_JOB_TYPE_KEYS.AMOUNT, label: 'Amount', width: '20%', align: 'right' },
];

const ProductsTable = ({ classes, products, totals }) => {
  const colspan = headCells.length;
  return (
    <Table className={classes.table} aria-labelledby="estimatesTableTitle" aria-label="Estimates Table">
      <TableHead>
        <TableRow>
          {headCells.map(headCell => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding="normal"
                sortDirection={false}
                style={{ width: headCell.width, textAlign: headCell.align }}>
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {!!products.length
          ? products.map((row, index) => (
              <TableRow key={row[JOB_JOB_TYPE_KEYS.ID]} tabIndex={-1}>
                <TableCell component="th" id={`estimate-product-${row[JOB_JOB_TYPE_KEYS.ID]}`} scope="row">
                  <Box display="flex" flexDirection="column">
                    <Typography className={classes.titleRoot} component="div" variant="h4">
                      {row[JOB_JOB_TYPE_KEYS.NAME]}
                    </Typography>
                    <Typography className={classes.titleRoot2} component="div" variant="h5">
                      {row[JOB_JOB_TYPE_KEYS.DESCRIPTION]}
                    </Typography>
                  </Box>
                </TableCell>
                {/* <TableCell>{row[ESTIMATE_PRODUCT_KEYS.QUANTITY]}</TableCell>
                <TableCell>{row[ESTIMATE_PRODUCT_KEYS.PRICE]}</TableCell> */}
                <TableCell className={classes.textAlignRight}>{row[JOB_JOB_TYPE_KEYS.AMOUNT]}</TableCell>
              </TableRow>
            ))
          : null}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={colspan - 1} className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            Sub Total
          </TableCell>
          <TableCell className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            {totals[JOB_JOB_TYPES_TOTALS_KEYS.SUB_TOTAL]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colspan - 1} className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            Tax Rate
          </TableCell>
          <TableCell className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            {totals[JOB_JOB_TYPES_TOTALS_KEYS.TAX_PERCENT]}%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colspan - 1} className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            Tax
          </TableCell>
          <TableCell className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            {totals[JOB_JOB_TYPES_TOTALS_KEYS.TAX]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colspan - 1} className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            Total
          </TableCell>
          <TableCell className={clsx(classes.textAlignRight, classes.tableFooterCell)}>
            {totals[JOB_JOB_TYPES_TOTALS_KEYS.TOTAL]}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

ProductsTable.prototype = {
  products: PropTypes.array.isRequired,
  totals: PropTypes.object.isRequired,
};

export default ProductsTable;

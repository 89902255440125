import {
  ADD_SQUAD_USER,
  DELETE_SQUAD_USER,
  EDIT_SQUAD_USER,
  GET_SQUAD_USERS,
  GET_SQUAD_USERS_FILTERS,
  SET_SQUAD_USER_DETAILS,
  SET_SQUAD_USER_INVITE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  squadUsers: [],
  currentSquadUser: null,
  currentSquadInviteUser: null,
  filterGroups: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SQUAD_USERS: {
      return {
        ...state,
        squadUsers: action.payload,
      };
    }
    case GET_SQUAD_USERS_FILTERS: {
      return {
        ...state,
        filterGroups: action.payload,
      };
    }
    case SET_SQUAD_USER_DETAILS: {
      return {
        ...state,
        currentSquadUser: action.payload,
      };
    }
    case SET_SQUAD_USER_INVITE_DETAILS: {
      return {
        ...state,
        currentSquadInviteUser: action.payload,
      };
    }
    case ADD_SQUAD_USER: {
      return {
        ...state,
        squadUsers: [action.payload, ...state.squadUsers],
      };
    }
    case EDIT_SQUAD_USER: {
      return {
        ...state,
        squadUsers: state.squadUsers.map(squadUser => (squadUser.id === action.payload.id ? action.payload : squadUser)),
      };
    }
    case DELETE_SQUAD_USER: {
      return {
        ...state,
        squadUsers: state.squadUsers.filter(squadUser => squadUser.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

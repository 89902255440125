import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { alpha, makeStyles } from '@material-ui/core/styles';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AppName } from '../../../constants/AppConstants';
import { userNotValid, requiredMessage, passwordMisMatch } from '../../../constants/ErrorMessages';
import { SQUAD_USER_KEYS, COLOR_LOGO_LINK } from '../../../constants/AppDataConstants';

import { getSquadInviteUser, setCurrentSquadInviteUser, userAcceptInvite } from '../../../../redux/actions/Squad';

import ContentLoader from '../../ContentLoader';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const Invite = ({ variant = 'default', wrapperVariant = 'default' }) => {
  document.title = `${AppName} - Invite`;

  const _isMounted = useRef(true);
  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  const params = useParams();

  const inviteId = params.inviteId;

  const { currentSquadInviteUser: inviteUser } = useSelector(({ squadReducer }) => squadReducer);

  const [inviteError, setInviteError] = useState('');
  const [userId, setUserId] = useState('');
  const [userIdError, setUserIdError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [userFetched, setUserFetched] = useState(false);
  const [inviteValid, setInviteValid] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [inviteAccepted, setInviteAccepted] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setCurrentSquadInviteUser(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(
          getSquadInviteUser(
            payload,
            () => {
              if (isActive) {
                setInviteValid(true);
                setUserFetched(true);
              }
            },
            messages => {
              if (isActive) setErrorMessages(messages);
            },
          ),
        );
      };
    };

    const promise = dispatch(fetchData({ inviteData: { [SQUAD_USER_KEYS.INVITE_CODE]: inviteId } }));
    promise.catch(error => {
      /* Setting to 'true' means API has been executed, not necessarily successfully */
      if (isActive) setUserFetched(true);
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, inviteId]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case SQUAD_USER_KEYS.USER_ID:
          setUserIdError(msg);
          break;

        case SQUAD_USER_KEYS.INVITE_CODE:
          setInviteError(msg);
          break;

        case SQUAD_USER_KEYS.PASSWORD:
          setPasswordError(msg);
          break;

        case SQUAD_USER_KEYS.CONFIRM_PASSWORD:
          setConfirmPasswordError(msg);
          break;

        default:
          break;
      }
    }
  }, [errorMessages]);

  useEffect(() => {
    if (userFetched && inviteUser) {
      setUserId(inviteUser[SQUAD_USER_KEYS.ID]);
      setEmail(inviteUser[SQUAD_USER_KEYS.EMAIL]);
    }
  }, [userFetched, inviteUser]);

  const handleEmailBlur = event => {
    if (!event.target.value) {
      setEmailError(requiredMessage);
    }
  };

  const handlePasswordBlur = event => {
    if (!event.target.value) {
      setPasswordError(requiredMessage);
    } else if (event.target.value !== confirmPassword) {
      setConfirmPasswordError(passwordMisMatch);
    } else if (event.target.value === confirmPassword) {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPasswordBlur = event => {
    if (!event.target.value) {
      setConfirmPasswordError(requiredMessage);
    } else if (event.target.value !== password) {
      setConfirmPasswordError(passwordMisMatch);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;

    setUserIdError('');

    if (!userId) {
      formIsValid = false;
      setUserIdError(userNotValid);
    }

    if (!email) {
      formIsValid = false;
      setEmailError(requiredMessage);
    }

    if (!password) {
      formIsValid = false;
      setPasswordError(requiredMessage);
    }

    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPasswordError(requiredMessage);
    }

    if (password && confirmPassword && password !== confirmPassword) {
      formIsValid = false;
      setConfirmPasswordError(passwordMisMatch);
    }

    if (formIsValid) {
      const userData = {
        [SQUAD_USER_KEYS.USER_ID]: userId,
        [SQUAD_USER_KEYS.EMAIL]: email,
        [SQUAD_USER_KEYS.PASSWORD]: password,
        [SQUAD_USER_KEYS.CONFIRM_PASSWORD]: confirmPassword,
        [SQUAD_USER_KEYS.INVITE_CODE]: inviteId,
      };
      dispatch(
        userAcceptInvite(
          { userData: userData },
          () => {
            if (_isMounted.current) setInviteAccepted(true);
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'}>
          <CmtImage src={COLOR_LOGO_LINK} width={240} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Invite
        </Typography>
        {inviteValid && !inviteAccepted && userFetched && (
          <form onSubmit={handleFormSubmit}>
            {userIdError && (
              <div className={'mb-5'}>
                <Typography variant="caption" display="block" color="error" gutterBottom>
                  {userIdError}
                </Typography>
              </div>
            )}
            <div className={'mb-5'}>
              <TextField
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                value={email}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                onChange={event => {
                  setEmail(event.target.value);
                  setEmailError('');
                }}
                onBlur={handleEmailBlur}
                error={!!emailError}
                helperText={emailError}
              />
            </div>
            <div className={'mb-5'}>
              <TextField
                fullWidth
                type="password"
                label={<IntlMessages id="appModule.password" />}
                value={password}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                onChange={event => {
                  setPassword(event.target.value);
                  setPasswordError('');
                }}
                onBlur={handlePasswordBlur}
                error={!!passwordError}
                helperText={passwordError}
              />
            </div>
            <div className={'mb-5'}>
              <TextField
                fullWidth
                type="password"
                label={<IntlMessages id="appModule.confirmPassword" />}
                value={confirmPassword}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                onChange={event => {
                  setConfirmPassword(event.target.value);
                  setConfirmPasswordError('');
                }}
                onBlur={handleConfirmPasswordBlur}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
              />
            </div>
            <div className={'mb-5'}>
              <Button type="submit" variant="contained" color="primary">
                <IntlMessages id="appModule.resetPassword" />
              </Button>
            </div>
          </form>
        )}
        {inviteError && <Alert severity="error">{inviteError}</Alert>}
        {inviteAccepted && (
          <React.Fragment>
            <Box mt={8}>
              <Alert severity="success">Password has been successfully updated!</Alert>
            </Box>
            <Box mt={8}>
              <Button component={Link} to="/signin" variant="contained" color="primary">
                <IntlMessages id="sidebar.appModule.login" />
              </Button>
            </Box>
          </React.Fragment>
        )}
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default Invite;

import React /* , { useEffect, useState } */ from 'react';
import { /* useDispatch, */ useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

/* import { AuhMethods } from '../../services/auth';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants'; */
import { /* isLoginSessionTimeout, */ permitPageAccess } from '../../utils/AccessCheck';
import Error403 from '../ExtraPages/403';

const RestrictedRoute = ({ component: Component, path, ...rest }) => {
  /* const dispatch = useDispatch(); */

  const { authUser } = useSelector(({ auth }) => auth);

  /* const [checkingTimeout, setCheckingTimeout] = useState(true);

  useEffect(() => {
    if (
      authUser &&
      checkingTimeout &&
      isLoginSessionTimeout(authUser['login_expire_timestamp'])
    ) {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout(true));
    } else {
      setCheckingTimeout(false);
    }
  }, [authUser, checkingTimeout]);

  if (checkingTimeout) {
    return null;
  } */

  if (!authUser) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  if (path !== '*') {
    if (!permitPageAccess(path, authUser)) {
      if (path === '/dashboard' && rest.alternatePath) {
        return (
          <Route
            {...rest}
            render={props => (
              <Redirect
                to={{
                  pathname: rest.alternatePath,
                  state: { from: props.location },
                }}
              />
            )}
          />
        );
      } else {
        return <Route {...rest} render={props => <Error403 {...props} />} />;
      }
    }
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default RestrictedRoute;

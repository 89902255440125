import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Drawer from '../../UI/Drawer';

const FieldsVisibilityDrawer = ({ open, onClose, headCells: items, handleFieldVisibility }) => {
  return (
    <Drawer open={open} onClose={onClose} title="Fields Visibility">
      <List>
        {items.map(item => {
          const id = item.id;
          const labelId = `head-cell-${id}`;

          return item.useColumn ? (
            <ListItem key={id} role={undefined} dense button onClick={() => handleFieldVisibility(id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={item.show}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.label} />
            </ListItem>
          ) : null;
        })}
      </List>
    </Drawer>
  );
};

FieldsVisibilityDrawer.prototype = {
  items: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default FieldsVisibilityDrawer;

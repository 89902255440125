import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/* import Box from '@material-ui/core/Box'; */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { purple, indigo, blue, green, orange, red } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
/* import DeleteIcon from '@material-ui/icons/Delete'; */
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import {
  JOB_KEYS,
  JOB_STATUS_KEYS,
  JOB_TYPE_KEYS,
  /* METRO_AREA_KEYS, */ SQUAD_USER_KEYS,
  JOB_SUBMITTED_STATUS,
  JOB_IN_PROGRESS_STATUS,
  JOB_RESCHEDULE_STATUS,
  JOB_DONE_STATUS,
  JOB_CANCEL_STATUS,
  JOB_ASSIGNED_STATUS,
} from '../../../@jumbo/constants/AppDataConstants';

import JobsAutocompleteFilter from '../JobsAutocompleteFilter';

const useStyles = makeStyles(theme => ({
  mdRightAlign: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  btnDelete: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const JobsListHeader = ({ filters, /* numSelected, */ setFilters, handleSetFilterChanged /* , onBulkDeleteJobs */ }) => {
  const classes = useStyles();

  const { jobStatuses } = useSelector(({ jobStatusesReducer }) => jobStatusesReducer);
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);
  /* const { metroAreas } = useSelector(({ metroAreasReducer }) => metroAreasReducer); */
  const { squadUsers } = useSelector(({ squadReducer }) => squadReducer);

  const jobStatusesWithColors = jobStatuses.map(jobStatus => {
    const statusId = jobStatus[JOB_STATUS_KEYS.ID];

    let jobStatusColor = '';
    switch (statusId) {
      case JOB_SUBMITTED_STATUS:
        jobStatusColor = blue[500];
        break;

      case JOB_ASSIGNED_STATUS:
        jobStatusColor = indigo[500];
        break;

      case JOB_IN_PROGRESS_STATUS:
        jobStatusColor = purple[500];
        break;

      case JOB_DONE_STATUS:
        jobStatusColor = green[500];
        break;

      case JOB_RESCHEDULE_STATUS:
        jobStatusColor = orange[500];
        break;

      case JOB_CANCEL_STATUS:
        jobStatusColor = red[500];
        break;

      default:
        jobStatusColor = '#000';
        break;
    }

    return {
      ...jobStatus,
      [JOB_STATUS_KEYS.COLOR]: jobStatusColor,
    };
  });

  let selectedStatuses = [];
  const filteredStatusIds = filters[JOB_KEYS.STATUS_ID].length > 0 ? filters[JOB_KEYS.STATUS_ID] : [];
  if (filteredStatusIds) {
    selectedStatuses = filteredStatusIds.map(filteredStatusId =>
      jobStatusesWithColors.find(jobStatus => jobStatus[JOB_STATUS_KEYS.ID] === filteredStatusId),
    );
  }

  let selectedUsers = [];
  const filteredUserIds = filters[JOB_KEYS.USER_ID].length > 0 ? filters[JOB_KEYS.USER_ID] : [];
  if (filteredUserIds) {
    selectedUsers = filteredUserIds.map(filteredUserId =>
      squadUsers.find(squadUser => squadUser[SQUAD_USER_KEYS.ID] === filteredUserId),
    );
  }

  let selectedJobTypes = [];
  const filteredJobTypeIds = filters[JOB_KEYS.JOB_TYPE_ID].length > 0 ? filters[JOB_KEYS.JOB_TYPE_ID] : [];
  if (filteredJobTypeIds) {
    selectedJobTypes = filteredJobTypeIds.map(filteredJobTypeId =>
      jobTypes.find(jobType => jobType[JOB_TYPE_KEYS.ID] === filteredJobTypeId),
    );
  }

  /* let selectedMetroAreas = null;
  const filteredMetroAreaIds = filters[JOB_KEYS.SERVICE_AREA_ID].length > 0 ? filters[JOB_KEYS.SERVICE_AREA_ID] : [];
  if (filteredMetroAreaIds) {
    selectedMetroAreas = filteredMetroAreaIds.map(filteredMetroAreaId =>
      metroAreas.find(metroArea => metroArea[METRO_AREA_KEYS.ID] === filteredMetroAreaId),
    );
  } */

  return (
    <GridContainer>
      <Grid item xs={12} md={4}>
        <JobsAutocompleteFilter
          id="status-filter-autocomplete"
          label="Status"
          value={selectedStatuses}
          options={jobStatusesWithColors}
          labelKey={JOB_STATUS_KEYS.NAME}
          idKey={JOB_STATUS_KEYS.ID}
          defaultValue={[]}
          colorTags={true}
          colorKey={JOB_STATUS_KEYS.COLOR}
          colorNamesProvided={true}
          setValue={statusesData => {
            const updatedFilteredStatusIds = statusesData.map(statusData => statusData[JOB_STATUS_KEYS.ID]);
            handleSetFilterChanged();
            setFilters(currentState => {
              return {
                ...currentState,
                [JOB_KEYS.STATUS_ID]:
                  updatedFilteredStatusIds && updatedFilteredStatusIds.length > 0 ? updatedFilteredStatusIds : [],
              };
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <JobsAutocompleteFilter
          id="user-filter-autocomplete"
          label="User"
          value={selectedUsers}
          options={squadUsers}
          labelKey={SQUAD_USER_KEYS.NAME}
          idKey={SQUAD_USER_KEYS.ID}
          defaultValue={[]}
          colorTags={false}
          colorKey=""
          colorNamesProvided={false}
          setValue={usersData => {
            const updatedFilteredUserIds = usersData.map(userData => userData[SQUAD_USER_KEYS.ID]);
            handleSetFilterChanged();
            setFilters(currentState => {
              return {
                ...currentState,
                [JOB_KEYS.USER_ID]:
                  updatedFilteredUserIds && updatedFilteredUserIds.length > 0 ? updatedFilteredUserIds : [],
              };
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <JobsAutocompleteFilter
          id="job-types-filter-autocomplete"
          label="Job Type"
          value={selectedJobTypes}
          options={jobTypes}
          labelKey={JOB_TYPE_KEYS.NAME}
          idKey={JOB_TYPE_KEYS.ID}
          defaultValue={[]}
          colorTags={false}
          colorKey=""
          colorNamesProvided={false}
          setValue={jobTypesData => {
            const updatedFilteredTypeIds = jobTypesData.map(jobTypeData => jobTypeData[JOB_TYPE_KEYS.ID]);
            handleSetFilterChanged();
            setFilters(currentState => {
              return {
                ...currentState,
                [JOB_KEYS.JOB_TYPE_ID]:
                  updatedFilteredTypeIds && updatedFilteredTypeIds.length > 0 ? updatedFilteredTypeIds : [],
              };
            });
          }}
        />
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <JobsAutocompleteFilter
          id="metro-areas-filter-autocomplete"
          label="Service Area"
          value={selectedMetroAreas}
          options={metroAreas}
          labelKey={METRO_AREA_KEYS.AREA_NAME}
          idKey={METRO_AREA_KEYS.ID}
          defaultValue={[]}
          colorTags={false}
          colorKey=""
          setValue={metroAreasData => {
            const updatedFilteredAreaIds = metroAreasData.map(metroAreaData => metroAreaData[METRO_AREA_KEYS.ID]);
            handleSetFilterChanged();
            setFilters(currentState => {
              return {
                ...currentState,
                [JOB_KEYS.SERVICE_AREA_ID]:
                  updatedFilteredAreaIds && updatedFilteredAreaIds.length > 0 ? updatedFilteredAreaIds : [],
              };
            });
          }}
        />
      </Grid> */}
      <Grid item xs={12} className={classes.mdRightAlign}>
        {/* <Button
          type="button"
          variant="outlined"
          color="primary"
          disabled={numSelected === 0}
          startIcon={<DeleteIcon />}
          classes={{ outlinedPrimary: classes.btnDelete }}
          onClick={onBulkDeleteJobs}>
          Delete
        </Button> */}

        <Button component={Link} to="/newJob" variant="contained" color="primary" startIcon={<AddIcon />}>
          Add New
        </Button>
      </Grid>
    </GridContainer>
  );
};

JobsListHeader.propTypes = {
  filters: PropTypes.object.isRequired,
  /* numSelected: PropTypes.number.isRequired, */
  setFilters: PropTypes.func.isRequired,
  handleSetFilterChanged: PropTypes.func.isRequired,
  /* onBulkDeleteJobs: PropTypes.func.isRequired, */
};

export default React.memo(JobsListHeader);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import GridContainer from '../../../@jumbo/components/GridContainer';

const Header = ({ classes, onPreviewEstimate, onDownloadEstimate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handlePreviewEstimate = () => {
    handleClose();
    onPreviewEstimate();
  };

  const handleDownloadEstimate = () => {
    handleClose();
    onDownloadEstimate();
  };

  return (
    <GridContainer>
      <Grid item xs={12} md={6}>
        <Typography component="h1" variant="h1" className={classes.headingOne}>
          AAA Rousse Junk Removal
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
        <Button
          type="button"
          aria-controls="estimate-actions-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          endIcon={<ExpandMoreIcon />}
          onClick={handleOpen}>
          Actions
        </Button>
        <Menu
          id="estimate-actions-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}>
          <MenuItem onClick={handlePreviewEstimate}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Preview" />
          </MenuItem>
          <MenuItem onClick={handleDownloadEstimate}>
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </GridContainer>
  );
};

Header.prototype = {
  onPreviewEstimate: PropTypes.func.isRequired,
  onDownloadEstimate: PropTypes.func.isRequired,
};

export default Header;

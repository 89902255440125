import React from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const Alert = ({ icon, title, text, html, footer, customClass }) => {
  let options = {};

  if (icon) options.icon = icon;
  if (title) options.title = title;
  if (text) options.text = text;
  if (html) options.html = html;
  if (footer) options.footer = footer;
  if (customClass) options.customClass = customClass;

  return MySwal.fire(options);
};

export default Alert;

import React, { useState } from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AppsIcon from '@material-ui/icons/Apps';
/* import DescriptionIcon from '@material-ui/icons/Description'; */

import { JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import CmtSearch from '../../../@coremat/CmtSearch';

import JobsTableHead from '../JobsTableHead';
import JobsListRow from '../JobsListRow';
import FieldsVisibilityDrawer from '../FieldsVisibilityDrawer';
import NoRecordFound from '../../NoRecordFound';

const allHeadCells = [
  { id: JOB_KEYS.ID, label: 'Job ID', show: true, sortColumn: true, useColumn: true },
  { id: JOB_KEYS.STATUS, label: 'Status', show: true, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.NAME, label: 'Customer', show: true, sortColumn: true, useColumn: true },
  { id: JOB_KEYS.TAG_ID, label: 'Tags', show: false, sortColumn: false, useColumn: false },
  { id: JOB_KEYS.JOB_TYPE_NAME, label: 'Job Type', show: true, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.SCHEDULED, label: 'Scheduled', show: true, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.END, label: 'End', show: false, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.CUSTOMER_PHONE_ONE, label: 'Phone', show: true, sortColumn: true, useColumn: true },
  { id: JOB_KEYS.CUSTOMER_EMAIL, label: 'Email', show: false, sortColumn: true, useColumn: true },
  { id: JOB_KEYS.TECH, label: 'Tech', show: true, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.CREATED_BY, label: 'Created By', show: true, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.FORMATTED_ADDRESS, label: 'Address', show: true, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.CUSTOMER_CITY, label: 'City', show: false, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.CUSTOMER_STATE_NAME, label: 'State', show: false, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.CUSTOMER_ZIP_CODE, label: 'Zip Code', show: false, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.SERVICE_AREA_ID, label: 'Service Area', show: false, sortColumn: false, useColumn: false },
  { id: JOB_KEYS.SOURCE_NAME, label: 'Source', show: false, sortColumn: false, useColumn: true },
  { id: JOB_KEYS.CUSTOMER_COMPANY, label: 'External Company', show: false, sortColumn: true, useColumn: true },
  { id: JOB_KEYS.TIME_IN_STATUS, label: 'Time in Status', show: false, sortColumn: false, useColumn: false },
  { id: JOB_KEYS.CREATED_AT, label: 'Created At', show: true, sortColumn: true, useColumn: true },
];

const JobsTable = ({
  classes,
  jobs,
  jobCounts,
  jobsFetched,
  order,
  orderBy,
  page,
  rowsPerPage,
  searchTerm,
  numSelected,
  setSearchTerm,
  isSelected,
  handleSearchChipDelete,
  handleRequestSort,
  handleSelectAllClick,
  handleCheckboxClick,
  handleRowClick,
  handlePageChange,
  handleRowsPerPageChange,
  /* handleExportJobsList, */
}) => {
  const [headCells, setHeadCells] = useState(allHeadCells);
  const [openVisibilityDrawer, setOpenVisibilityDrawer] = useState(false);

  const handleOpenVisibilityDrawer = () => setOpenVisibilityDrawer(true);
  const handleCloseVisibilityDrawer = () => setOpenVisibilityDrawer(false);

  const handleFieldVisibility = id => {
    setHeadCells(prevState => {
      const updatedState = [...prevState];
      const idx = updatedState.findIndex(field => field.id === id);
      if (idx > -1) {
        const selectedField = updatedState[idx];
        selectedField.show = !selectedField.show;
        updatedState[idx] = selectedField;
      }
      return updatedState;
    });
  };

  let colspan = headCells.filter(headCell => headCell.show).length;
  colspan = colspan > 0 ? colspan + 1 : 1;

  const jobCountNonZero =
    jobCounts &&
    jobCounts[JOB_KEYS.TOTAL] &&
    !isNaN(parseInt(jobCounts[JOB_KEYS.TOTAL])) &&
    parseInt(jobCounts[JOB_KEYS.TOTAL]) > 0;

  const fetchedJobsCount = jobs.length;

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbarRoot}>
        <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
        <div className={classes.chipsRoot}>
          {searchTerm && <Chip label={searchTerm} onDelete={handleSearchChipDelete} />}
        </div>
        <Button
          type="button"
          variant="outlined"
          startIcon={<AppsIcon />}
          className={classes.fieldsBtn}
          onClick={handleOpenVisibilityDrawer}>
          Fields
        </Button>
        {/* <Button type="button" variant="outlined" startIcon={<DescriptionIcon />} onClick={handleExportJobsList}>
          Export
        </Button> */}
      </Toolbar>
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table} aria-labelledby="jobsTableTitle" aria-label="Jobs Table">
          <JobsTableHead
            headCells={headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            rowCount={jobs && fetchedJobsCount > 0 ? fetchedJobsCount : 0}
            numSelected={numSelected}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {jobCountNonZero ? (
              jobs.map((row, index) => (
                <JobsListRow
                  key={index}
                  row={row}
                  onRowClick={handleRowClick}
                  headCells={headCells}
                  isSelected={isSelected}
                  onCheckboxClick={handleCheckboxClick}
                />
              ))
            ) : (
              <TableRow style={{ height: 53 * 6 }}>
                <TableCell colSpan={colspan} rowSpan={10}>
                  <NoRecordFound>{jobsFetched ? 'There are no records found.' : 'Loading jobs...'}</NoRecordFound>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        component="div"
        count={jobCountNonZero ? parseInt(jobCounts[JOB_KEYS.TOTAL]) : 0}
        labelRowsPerPage=""
        rowsPerPage={rowsPerPage}
        page={jobCountNonZero ? page : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      {openVisibilityDrawer && (
        <FieldsVisibilityDrawer
          headCells={headCells}
          open={openVisibilityDrawer}
          handleFieldVisibility={handleFieldVisibility}
          onClose={handleCloseVisibilityDrawer}
        />
      )}
    </React.Fragment>
  );
};

export default JobsTable;

import React /* , { useState } */ from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
/* import { USER_TYPE_KEYS } from '../../../@jumbo/constants/AppDataConstants'; */

/* import Alert from '../../UI/Alert'; */
/* import AddUserDrawer from '../AddUserDrawer'; */

const useStyles = makeStyles(theme => ({
  listbox: {
    display: 'flex',
    flexDirection: 'column',
    '& li': {
      flex: '1 1 100%',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
}));

const SquadHeader = ({ filterOptions, setFilterOptions }) => {
  const classes = useStyles();
  const { filterGroups } = useSelector(({ squadReducer }) => squadReducer);
  /* const { userTypes } = useSelector(({ userTypesReducer }) => userTypesReducer); */
  /* const [openAddUserDrawer, setOpenAddUserDrawer] = useState(false); */

  /* const subcontractorIdx = userTypes.findIndex(userType => userType[USER_TYPE_KEYS.NAME].toLowerCase() === 'subcontractor');
  const subcontractorID = subcontractorIdx > -1 ? userTypes[subcontractorIdx][USER_TYPE_KEYS.ID] : null; */

  /* const handleOpenAddUserDrawer = () => setOpenAddUserDrawer(true);
  const handleCloseAddUserDrawer = () => setOpenAddUserDrawer(false); */

  /* const showErrorAlert = () => {
    Alert({
      icon: 'error',
      title: 'Oops...',
      text: 'One or more user types is not valid',
    });
  }; */

  return (
    <Box>
      <GridContainer>
        <Grid item xs={12} md={9} lg={10}>
          <Autocomplete
            multiple
            filterSelectedOptions
            id="filter-squad"
            value={filterOptions}
            options={filterGroups}
            getOptionLabel={option => option.label}
            groupBy={option => option.group}
            renderInput={params => <TextField {...params} label="Filter Results" variant="outlined" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip label={`${option.group}: ${option.label}`} {...getTagProps({ index })} />)
            }
            onChange={(event, values, reason) => {
              if (['select-option', 'remove-option'].includes(reason)) {
                let newValues = [...values];
                if (values.length > 1) {
                  const latestValue = values[values.length - 1];
                  newValues = values.filter(value => value.group !== latestValue.group || value.id === latestValue.id);
                }
                setFilterOptions(newValues);
              } else if (reason === 'clear') {
                setFilterOptions([]);
              }
            }}
            getOptionSelected={(option, value) => option.group === value.group && option.id === value.id}
            classes={{
              listbox: classes.listbox,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          {/* <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={subcontractorID ? handleOpenAddUserDrawer : showErrorAlert}>
            Add New
          </Button> */}
          <Button component={Link} to="/newUser" variant="contained" color="primary" startIcon={<AddIcon />}>
            Add New
          </Button>
        </Grid>
      </GridContainer>

      {/* openAddUserDrawer && <AddUserDrawer open={openAddUserDrawer} onCloseDrawer={handleCloseAddUserDrawer} /> */}
    </Box>
  );
};

SquadHeader.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  setFilterOptions: PropTypes.func.isRequired,
};

export default SquadHeader;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  /* ADD_JOB, DELETE_JOB, EDIT_JOB, */
  GET_JOBS,
  GET_JOB_COUNTS,
  SET_JOB_DETAILS,
  GET_JOB_ESTIMATES,
  ADD_JOB_ESTIMATE,
  DELETE_JOB_ESTIMATE,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_JOBS_LINK,
  GET_JOB_LINK,
  ADD_JOB_LINK,
  UPDATE_JOB_LINK,
  UPDATE_JOB_STATUS_LINK,
  MARK_JOB_DONE_LINK,
  DUPLICATE_JOB_LINK,
  DELETE_JOB_LINK,
  GET_JOB_ESTIMATES_LINK,
  ADD_ESTIMATE_LINK,
  DELETE_ESTIMATE_LINK,
  SEND_CUSTOMER_JOB_LINK,
  GET_JOB_FOR_CUSTOMER_LINK,
  CONFIRM_JOB_FOR_CUSTOMER_LINK,
  UPDATE_JOB_FOR_CUSTOMER_LINK,
  CANCEL_JOB_FOR_CUSTOMER_LINK,
  CONFIRM_JOB_FOR_MEMBER_LINK,
} from '../../@jumbo/constants/APIConstants';
import { ESTIMATE_KEYS, JOB_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getFilteredJobs = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_JOBS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobs) {
              const jobs = data.jobs;
              const counts = data.counts ? data.counts : null;
              let totals = null;
              if (counts) {
                totals = {
                  [JOB_KEYS.TOTAL]: counts[JOB_KEYS.TOTAL] ? counts[JOB_KEYS.TOTAL] : '0',
                };
              } else {
                totals = {
                  [JOB_KEYS.TOTAL]: '0',
                };
              }

              dispatch(fetchSuccess());
              dispatch({ type: GET_JOBS, payload: jobs });
              dispatch({ type: GET_JOB_COUNTS, payload: totals });
              if (callbackFun) callbackFun(jobs);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: GET_JOBS, payload: [] });
        dispatch({
          type: GET_JOB_COUNTS,
          payload: {
            [JOB_KEYS.TOTAL]: '0',
          },
        });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentJob = job => {
  return dispatch => {
    dispatch({ type: SET_JOB_DETAILS, payload: job });
  };
};

export const addNewJob = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_JOB_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              dispatch(fetchSuccess('Job was added successfully.'));
              /* dispatch({ type: ADD_JOB, payload: data.job }); */
              if (callbackFun) callbackFun(data.job);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getJob = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_JOB_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              const job = data.job;
              dispatch(fetchSuccess());
              dispatch({ type: SET_JOB_DETAILS, payload: job });
              if (callbackFun) callbackFun(job);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: SET_JOB_DETAILS, payload: null });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateJobStatus = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_JOB_STATUS_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              console.log(data.job);
              dispatch(fetchSuccess('Job status was updated successfully.'));
              dispatch({ type: SET_JOB_DETAILS, payload: data.job });
              if (callbackFun) callbackFun(data.job);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const markJobDone = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(MARK_JOB_DONE_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Job status was updated successfully.'));
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateJob = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_JOB_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              dispatch(fetchSuccess('Selected job was updated successfully.'));
              dispatch({ type: SET_JOB_DETAILS, payload: data.job });
              if (callbackFun) callbackFun(data.job);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const duplicateJob = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DUPLICATE_JOB_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Job was created successfully.'));
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteJob = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_JOB_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected job was deleted successfully.'));
            /* dispatch({ type: DELETE_JOB, payload: request.jobData[JOB_KEYS.JOB_ID] }); */
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getJobEstimates = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobEstimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_JOB_ESTIMATES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimate) {
              const jobEstimates = data.estimate;
              dispatch(fetchSuccess());
              dispatch({ type: GET_JOB_ESTIMATES, payload: jobEstimates });
              if (callbackFun) callbackFun(jobEstimates);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: GET_JOB_ESTIMATES, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const addJobEstimate = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobEstimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.estimates && data.estimates.length === 1) {
              const estimate = data.estimates[0];
              dispatch(fetchSuccess('Estimate was added successfully.'));
              dispatch({ type: ADD_JOB_ESTIMATE, payload: estimate });
              if (callbackFun) callbackFun(estimate);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteJobEstimate = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobEstimateData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_ESTIMATE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected estimate was deleted successfully.'));
            dispatch({ type: DELETE_JOB_ESTIMATE, payload: request.jobEstimateData[ESTIMATE_KEYS.ESTIMATE_ID] });
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sendJobLinkToCustomer = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(SEND_CUSTOMER_JOB_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Message was sent successfully.'));
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getJobForCustomer = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_JOB_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              const jobData = data.job;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(jobData);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateJobForCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_JOB_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              const jobData = data.job;
              dispatch(fetchSuccess('Job was updated successfully.'));
              if (callbackFun) callbackFun(jobData);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const cancelJobForCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(CANCEL_JOB_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.job) {
              const jobData = data.job;
              dispatch(fetchSuccess('Job was canceled successfully.'));
              if (callbackFun) callbackFun(jobData);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const confirmJobForCustomer = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(CONFIRM_JOB_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess());
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const confirmJobForMember = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.jobData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(CONFIRM_JOB_FOR_MEMBER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess());
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

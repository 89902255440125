import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { JOB_KEYS } from '../../@jumbo/constants/AppDataConstants';

import { getMessagesJobs } from '../../redux/actions/Messages';

import useStyles from './index.style';

const pageName = 'Messages';
const breadcrumbs = [{ label: pageName, isActive: true }];

const ListItemLink = props => {
  return <ListItem button component={Link} {...props} />;
};

const Messages = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { jobs } = useSelector(({ messagesReducer }) => messagesReducer);

  const [jobsFetching, setJobsFetching] = useState(true);
  const [jobsFetched, setJobsFetched] = useState(false);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getMessagesJobs(payload));
      };
    };

    const promise = dispatch(fetchData({ messageData: { authcode: authUser.authcode } }));
    promise
      .then(() => {
        if (isActive) {
          setJobsFetched(true);
          setJobsFetching(false);
        }
      })
      .catch(error => {
        if (isActive) {
          setJobsFetched(false);
          setJobsFetching(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      {!jobsFetching && jobsFetched && (
        <GridContainer>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <GridContainer>
                <Grid item xs={12} style={{ paddingTop: 32, paddingBottom: 8, paddingLeft: 32, paddingRight: 32 }}>
                  <Typography variant="h2">Click on a job to view its messages</Typography>
                </Grid>
                {jobs && jobs.length > 0 && (
                  <Grid item xs={12}>
                    <List component="nav" aria-label={`Messages Jobs List`} disablePadding>
                      {jobs.map(job => (
                        <React.Fragment key={job[JOB_KEYS.ID]}>
                          <ListItemLink to={`/jobMessages/${job[JOB_KEYS.ID]}/`}>
                            <ListItemText
                              primary={`Job # ${job[JOB_KEYS.ID]}`}
                              secondary={`${job[JOB_KEYS.CUSTOMER_FNAME]} ${job[JOB_KEYS.CUSTOMER_LNAME]} ${
                                job[JOB_KEYS.CUSTOMER_COMPANY]
                              }`}
                            />
                            <ListItemIcon>
                              <ArrowForwardIcon />
                            </ListItemIcon>
                          </ListItemLink>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {(!jobs || jobs.length === 0) && <Alert severity="error">No jobs found!</Alert>}
                </Grid>
              </GridContainer>
            </Paper>
          </Grid>
        </GridContainer>
      )}

      {!jobsFetching && !jobsFetched && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Jobs data not found!</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default Messages;

import React /* useState */ from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
/* import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'; */
/* import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'; */
import Autocomplete from '@material-ui/lab/Autocomplete';
/* import SendIcon from '@material-ui/icons/Send';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DeleteIcon from '@material-ui/icons/Delete'; */
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { ALL_INVOICES_STATUS_ID, INVOICE_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

/* import InvoicesHeaderCard from '../InvoicesHeaderCard'; */

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  statusFilterContainer: {
    flex: '1 1 100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(4),
    },
  },
  timeFilterContainer: {
    flex: '1 1 100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  dateFilterList: {
    border: `1px solid ${theme.palette.borderColor.dark}`,
  },
  dateFilterListItem: {
    padding: theme.spacing(0, 2),
  },
  btnMarkSent: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      borderColor: theme.palette.success.main,
    },
  },
  btnDelete: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

/* const invoiceDateFilter = [
  {
    id: 'all',
    primary: 'All',
    secondary: 'All - secondary',
  },
  {
    id: 'today',
    primary: 'Today',
    secondary: 'Today - secondary',
  },
  {
    id: 'yesterday',
    primary: 'Yesterday',
    secondary: 'Yesterday - secondary',
  },
  {
    id: 'last7Days',
    primary: 'Last 7 Days',
    secondary: 'Last 7 Days - secondary',
  },
  {
    id: 'custom',
    primary: 'Custom',
    secondary: 'Custom - secondary',
  },
]; */

const InvoicesHeader = ({
  invoiceStatusList,
  selectedStatus,
  /* numInvoicesSelected, */
  setSelectedStatus,
  handleSetFilterChanged,
}) => {
  const classes = useStyles();

  /* const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1); */

  /* const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  }; */

  /* const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  }; */

  /* const handleClose = () => {
    setAnchorEl(null);
  }; */

  return (
    <Box>
      <GridContainer>
        {/* <Grid item xs={12} sm={6} md={3}>
          <InvoicesHeaderCard titleIsAmount={true} title="18615.8" subtitle="Due from 14 invoices" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InvoicesHeaderCard titleIsAmount={true} title="23851.8" subtitle="Overdue from 17 invoices" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InvoicesHeaderCard title="1 invoices" subtitle="Unsent" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InvoicesHeaderCard title="4 jobs" subtitle="Need invoices" />
        </Grid> */}
        <Grid item xs={12}>
          <Box className={classes.filtersContainer}>
            <Box className={classes.statusFilterContainer}>
              <Autocomplete
                fullWidth
                disableClearable
                id="invoice_status"
                value={selectedStatus}
                options={invoiceStatusList.map(invoiceStatus => invoiceStatus[INVOICE_STATUS_KEYS.ID])}
                getOptionLabel={option => {
                  if (option) {
                    const selectedStatus = invoiceStatusList.find(
                      invoiceStatus => invoiceStatus[INVOICE_STATUS_KEYS.ID] === option,
                    );
                    if (selectedStatus) {
                      return selectedStatus[INVOICE_STATUS_KEYS.NAME];
                    } else {
                      return '';
                    }
                  } else {
                    return '';
                  }
                }}
                getOptionSelected={(option, value) => option === value || option === null}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    type="text"
                    name="invoice_status"
                    label="Status"
                    variant="outlined"
                    margin="dense"
                  />
                )}
                onChange={(e, value, reason) => {
                  if (['select-option', 'remove-option'].includes(reason)) {
                    const selectedStatus = value ? value : ALL_INVOICES_STATUS_ID;
                    setSelectedStatus(selectedStatus);
                  } else if (reason === 'clear') {
                    setSelectedStatus(ALL_INVOICES_STATUS_ID);
                  }
                  handleSetFilterChanged();
                }}
                classes={{ root: classes.listAutocomplete }}
              />
            </Box>
            <Box className={classes.timeFilterContainer}>
              {/* <List
                disablePadding
                component="nav"
                aria-label="Invoice Date Filters"
                classes={{ root: classes.dateFilterList }}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="invoice-date-filters-menu"
                  aria-label="Invoice date filter"
                  classes={{ root: classes.dateFilterListItem }}
                  onClick={handleClickListItem}>
                  <ListItemText
                    primary={invoiceDateFilter[selectedIndex].primary}
                    secondary={invoiceDateFilter[selectedIndex].secondary}
                  />
                </ListItem>
              </List>
              <Menu
                id="invoice-date-filters-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {invoiceDateFilter.map((option, index) => (
                  <MenuItem
                    key={option.id}
                    selected={index === selectedIndex}
                    onClick={event => handleMenuItemClick(event, index)}>
                    {option.primary}
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <Typography component="span" variant="h4">
              {numInvoicesSelected} invoices selected
            </Typography>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              disabled={numInvoicesSelected === 0}
              startIcon={<SendIcon />}>
              Send Invoice
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              disabled={numInvoicesSelected === 0}
              startIcon={<TouchAppIcon />}>
              Send Reminder
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              disabled={numInvoicesSelected === 0}
              startIcon={<ThumbUpIcon />}
              classes={{ outlinedPrimary: classes.btnMarkSent }}>
              Mark Sent
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              disabled={numInvoicesSelected === 0}
              startIcon={<DeleteIcon />}
              classes={{ outlinedPrimary: classes.btnDelete }}>
              Delete
            </Button>
          </Box>
        </Grid> */}
      </GridContainer>
    </Box>
  );
};

InvoicesHeader.propTypes = {
  invoiceStatusList: PropTypes.array.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  /* numInvoicesSelected: PropTypes.number.isRequired, */
  setSelectedStatus: PropTypes.func.isRequired,
  handleSetFilterChanged: PropTypes.func.isRequired,
};

export default InvoicesHeader;

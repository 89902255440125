import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
  },
}));

const EditRoleErrors = ({ errorMessages }) => {
  const classes = useStyles();
  const errors = [];
  for (const fieldName in errorMessages) {
    errors.push({
      field: fieldName,
      msg: errorMessages[fieldName],
    });
  }
  if (!errors || errors.length === 0) return null;
  return (
    <Alert severity="error" className={classes.alert}>
      <List className={classes.listRoot}>
        {errors.map((error, idx) => (
          <ListItem key={`${error.field}-${idx}`}>
            <ListItemText primary={error.msg} />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};

export default EditRoleErrors;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

import CmtSearch from '../../../../@coremat/CmtSearch';
import EditRoleErrors from '../EditRoleErrors';

const useStyles = makeStyles(theme => ({
  switchLabelBottom: {
    alignItems: 'flex-end',
  },
  switchLabelText: {
    ...theme.overrides.MuiTypography.subtitle2,
  },
  searchRoot: {
    display: 'inline-block',
    width: '50%',
    marginTop: theme.spacing(2),
  },
  chipsRoot: {
    display: 'inline-flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const EditRoleTab = ({ items, searchTerm, setSearchTerm, errorMessages }) => {
  const classes = useStyles();

  const onSearchChipDelete = () => setSearchTerm('');

  return (
    <React.Fragment>
      <Box>
        {errorMessages && <EditRoleErrors errorMessages={errorMessages} />}
        <Typography variant="h4" display="block">
          Please check the areas you wish to restrict
        </Typography>
        <CmtSearch
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
          onlyIcon
          className={classes.searchRoot}
        />
        <div className={classes.chipsRoot}>{searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}</div>
      </Box>
      <List>
        {items.map(item => {
          const itemId = item.title
            .toLowerCase()
            .split(' ')
            .join('-');
          return (
            <ListItem key={itemId} divider>
              <ListItemText id={itemId} primary={item.title} secondary={item.subtitle} />
              <ListItemSecondaryAction>
                <FormControlLabel
                  control={
                    <Switch
                      edge="end"
                      onChange={item.handleChange}
                      checked={item.state === '1'}
                      inputProps={{ 'aria-labelledby': itemId }}
                    />
                  }
                  label={item.state === '1' ? 'Allow' : 'Restricted'}
                  labelPlacement="bottom"
                  classes={{
                    labelPlacementBottom: classes.switchLabelBottom,
                    label: classes.switchLabelText,
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

EditRoleTab.propTypes = {
  items: PropTypes.array,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

/* export default React.memo(EditRoleTab); */
export default EditRoleTab;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { requiredMessage, jobNotValid } from '../../../@jumbo/constants/ErrorMessages';
import { JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { CUSTOMER_PORTAL_JOB_LINK } from '../../../@jumbo/constants/APIConstants';

import { sendJobLinkToCustomer } from '../../../redux/actions/Jobs';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentArea: {
    width: '100%',
    padding: theme.spacing(3),
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
    '&:hover, &:active, &:focus': {
      border: `1px solid rgba(0, 0, 0, 0.87)`,
    },
    '&:focus-visible': {
      outlineColor: theme.palette.primary.main,
    },
  },
}));

const SendCustomerJobLinkDialog = ({ open, onClose }) => {
  const _isMounted = useRef(true);
  const contentRef = useRef('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentJob } = useSelector(({ jobsReducer }) => jobsReducer);

  const [jobId, setJobId] = useState('');
  const [subject, setSubject] = useState('Your service with AAA Rousse Junk Removal');
  const [subjectError, setSubjectError] = useState('');
  const [content, setContent] = useState('');
  const [contentError, setContentError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      contentRef.current = '';
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setJobId('');
      setSubject('');
      setSubjectError('');
      setContent('');
      setContentError('');
      setErrorMessages([]);
      setMiscellaneousErrors([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentJob) {
      const tempJobId = currentJob[JOB_KEYS.ID];

      setJobId(tempJobId);

      const message = `<p>Greetings ${currentJob[JOB_KEYS.CUSTOMER_FNAME]} ${currentJob[JOB_KEYS.CUSTOMER_LNAME]} ${
        currentJob[JOB_KEYS.CUSTOMER_COMPANY]
      }.</p><p>Thank you for your recent business with us.</p><p>Click the link below to view your job #${
        currentJob[JOB_KEYS.ID]
      }.</p><p><a href="${CUSTOMER_PORTAL_JOB_LINK}/${
        currentJob[JOB_KEYS.CUSTOMER_ID]
      }/${tempJobId}/" target="_blank">View Job</a></p>`;

      setContent(message);
      contentRef.current = message;
    }
  }, [currentJob]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case JOB_KEYS.CUSTOMER_MSG_SUBJECT:
          setSubjectError(msg);
          break;

        case JOB_KEYS.CUSTOMER_MSG_CONTENT:
          setContentError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const subjectBlurHandler = event => {
    if (!event.target.value) {
      setSubjectError(requiredMessage);
    }
  };

  const contentChangeHandler = event => {
    const newContent = event.target.value;
    contentRef.current = newContent;
    setContent(newContent);
    setContentError('');
  };

  const contentBlurHandler = () => {
    if (!contentRef.current) {
      setContentError(requiredMessage);
    }
  };

  const handleFormSubmit = () => {
    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!subject) {
      setSubjectError(requiredMessage);
      formIsValid = false;
    }

    if (!content) {
      setContentError(requiredMessage);
      formIsValid = false;
    }

    if (formIsValid) {
      const jobData = {
        authcode: authUser.authcode,
        [JOB_KEYS.JOB_ID]: jobId,
        [JOB_KEYS.CUSTOMER_MSG_SUBJECT]: subject,
        [JOB_KEYS.CUSTOMER_MSG_CONTENT]: content,
      };

      dispatch(
        sendJobLinkToCustomer(
          { jobData: jobData },
          () => {
            if (_isMounted.current) onClose();
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  const jobIsValid = !isNaN(jobId) && !isNaN(parseInt(jobId)) && parseInt(jobId) > 0;

  return (
    <Dialog fullWidth maxWidth="md" open={open} className={classes.dialogRoot} onClose={onClose}>
      <DialogTitle className={classes.dialogTitleRoot}>Customer Message</DialogTitle>
      <DialogContent dividers>
        {jobIsValid && (
          <form>
            <GridContainer>
              {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                <Grid item xs={12}>
                  {miscellaneousErrors.map((miscellaneousError, idx) => (
                    <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                      {miscellaneousError}
                    </Typography>
                  ))}
                </Grid>
              )}
              <Grid item xs={12}>
                <AppTextInput
                  type="text"
                  name="suject"
                  variant="outlined"
                  label="Subject"
                  value={subject}
                  onChange={e => {
                    setSubject(e.target.value);
                    setSubjectError('');
                  }}
                  onBlur={subjectBlurHandler}
                  helperText={subjectError}
                />
              </Grid>
              <Grid item xs={12}>
                <ContentEditable
                  html={contentRef.current}
                  onBlur={contentBlurHandler}
                  onChange={contentChangeHandler}
                  className={classes.contentArea}
                />
                {contentError && (
                  <Typography variant="caption" display="block" color="error">
                    {contentError}
                  </Typography>
                )}
              </Grid>
            </GridContainer>
          </form>
        )}
        {!jobIsValid && (
          <GridContainer>
            <Grid item xs={12}>
              <Alert severity="error">{jobNotValid}</Alert>
            </Grid>
          </GridContainer>
        )}
        <DialogActions className={classes.dialogActions}>
          {jobIsValid && (
            <Button type="button" variant="contained" color="primary" onClick={handleFormSubmit}>
              Send
            </Button>
          )}
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

SendCustomerJobLinkDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SendCustomerJobLinkDialog;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';

import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { CUSTOMER_PORTAL_MESSAGE_LINK } from '../../../@jumbo/constants/APIConstants';
import { MESSAGE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';

import { sendMessage } from '../../../redux/actions/Messages';

import SendMessageButton from '../SendMessageButton';

const useStyles = makeStyles(theme => ({
  chatFooterContainerRoot: {
    borderTop: `1px solid ${theme.palette.borderColor.main}`,
    backgroundColor: alpha(theme.palette.common.dark, 0.04),
  },
  chatFooterRoot: {
    padding: '14px 20px 15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 'auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  textFieldRoot: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
      marginLeft: 5,
      marginRight: 5,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 16px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
    },
  },
  sendBtnContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  chatErrorContainer: {
    marginTop: 'auto',
    padding: '0 20px 15px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
      marginLeft: 5,
      marginRight: 5,
    },
  },
}));

const ChatFooter = ({ user, jobId }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [sendDisable, setSendDisable] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    if (errorMessages) {
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case MESSAGE_KEYS.MESSAGE:
            setMessageError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const handleSendMessage = method => {
    if (!message) {
      setMessageError(requiredMessage);
      return false;
    }

    setSendDisable(true);

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    const messageData = {
      authcode: authUser.authcode,
      [MESSAGE_KEYS.RECEIVER_ID]: user[MESSAGE_KEYS.RECEIVER_ID],
      [MESSAGE_KEYS.RECEIVER_TYPE]: user[MESSAGE_KEYS.RECEIVER_TYPE],
      [MESSAGE_KEYS.JOB_ID]: jobId,
      [MESSAGE_KEYS.MESSAGE]: message,
      [MESSAGE_KEYS.METHOD]: method,
      [MESSAGE_KEYS.REPLY_LINK]: CUSTOMER_PORTAL_MESSAGE_LINK,
    };

    dispatch(
      sendMessage(
        { messageData: messageData },
        () => {
          if (_isMounted.current) {
            setMessage('');
            setSendDisable(false);
          }
        },
        messages => {
          if (_isMounted.current) {
            setMessage('');
            setSendDisable(false);
            setErrorMessages(messages);
          }
        },
      ),
    ).catch(error => {
      console.log({ error });
      setMessage('');
      setSendDisable(false);
    });
  };

  return (
    <Box className={classes.chatFooterContainerRoot}>
      <div className={classes.chatFooterRoot}>
        <AppTextInput
          multiline
          id="chat-textarea"
          variant="outlined"
          placeholder="Type message..."
          value={message}
          error={!!messageError}
          onChange={e => {
            setMessage(e.target.value);
            setMessageError('');
          }}
          className={classes.textFieldRoot}
        />
        <SendMessageButton classes={classes} disabled={sendDisable} onSendMessage={handleSendMessage} />
      </div>
      {!!messageError && (
        <div className={classes.chatErrorContainer}>
          <Typography variant="caption" display="block" color="error" gutterBottom>
            {messageError}
          </Typography>
        </div>
      )}
      {miscellaneousErrors && miscellaneousErrors.length > 0 && (
        <div className={classes.chatErrorContainer}>
          {miscellaneousErrors.map((miscellaneousError, idx) => (
            <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
              {miscellaneousError}
            </Typography>
          ))}
        </div>
      )}
    </Box>
  );
};

ChatFooter.prototype = {
  user: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
};

export default ChatFooter;

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import { INVOICE_KEYS, INVOICE_SIGNATURE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import CmtCard from '../../../@coremat/CmtCard';

import { addInvoiceSignatureForCustomer } from '../../../redux/actions/InvoiceSignatures';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dialogActions: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: { justifyContent: 'flex-end' },
  },
  signatureCanvas: {
    width: '100%',
    height: '100%',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobInvoiceSignatureDialog = ({ invoice, customer, open, onSignatureSuccess, onClose }) => {
  const _isMounted = useRef(true);
  const sigCanvas = useRef({});
  const classes = useStyles();
  const dispatch = useDispatch();

  const [signatureError, setSignatureError] = useState('');
  const [signerName, setSignerName] = useState('');
  const [signerNameError, setSignerNameError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (customer) {
      const bill = customer[INVOICE_KEYS.CUSTOMER_BILL];
      setSignerName(`${bill[INVOICE_KEYS.CUSTOMER_NAME]} ${bill[INVOICE_KEYS.CUSTOMER_COMPANY]}`);
    }
  }, [customer]);

  useEffect(() => {
    if (!open) {
      setSignatureError('');
      setSignerName('');
      setSignerNameError('');
      setErrorMessages([]);
      setMiscellaneousErrors([]);
    }
  }, [open]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case INVOICE_SIGNATURE_KEYS.SIGN:
          setSignatureError(msg);
          break;

        case INVOICE_SIGNATURE_KEYS.SIGNER_NAME:
          setSignerNameError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const handleClearCanvas = () => sigCanvas.current.clear();

  const signerNameBlurHandler = event => {
    if (!event.target.value) {
      setSignerNameError(requiredMessage);
    }
  };

  const handleSaveSignature = () => {
    let formIsValid = true;

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    if (sigCanvas.current.isEmpty()) {
      setSignatureError(requiredMessage);
      formIsValid = false;
    } else {
      setSignatureError('');
    }

    if (!signerName) {
      setSignerNameError(requiredMessage);
      formIsValid = false;
    }

    if (formIsValid) {
      const url = sigCanvas.current.getTrimmedCanvas().toDataURL('img/png');
      if (url) {
        const invoiceSignatureData = {
          [INVOICE_SIGNATURE_KEYS.INVOICE_ID]: invoice[INVOICE_KEYS.ID],
          [INVOICE_SIGNATURE_KEYS.SIGN]: url,
          [INVOICE_SIGNATURE_KEYS.SIGNER_NAME]: signerName,
        };

        dispatch(
          addInvoiceSignatureForCustomer(
            { invoiceSignatureData: invoiceSignatureData },
            signature => {
              if (_isMounted.current) {
                onSignatureSuccess(signature);
                onClose();
              }
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      } else {
        setSignatureError('Signature is not valid!');
      }
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} className={classes.dialogRoot} TransitionComponent={Transition}>
      <DialogTitle className={classes.dialogTitleRoot}>Invoice Signature</DialogTitle>
      <DialogContent dividers>
        <GridContainer>
          {miscellaneousErrors && miscellaneousErrors.length > 0 && (
            <Grid item xs={12}>
              {miscellaneousErrors.map((miscellaneousError, idx) => (
                <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                  {miscellaneousError}
                </Typography>
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            <CmtCard>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: classes.signatureCanvas,
                }}
              />
            </CmtCard>
          </Grid>
          {signatureError && (
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color="error" gutterBottom>
                {signatureError}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <AppTextInput
              fullWidth
              type="text"
              name="signer_name"
              variant="outlined"
              label="Signer Name"
              value={signerName}
              onChange={e => {
                setSignerName(e.target.value);
                setSignerNameError('');
              }}
              onBlur={signerNameBlurHandler}
              helperText={signerNameError}
            />
          </Grid>
        </GridContainer>
        <DialogActions className={classes.dialogActions}>
          <Button type="button" variant="contained" color="primary" onClick={handleSaveSignature}>
            Save
          </Button>
          <Button type="button" variant="contained" color="secondary" onClick={handleClearCanvas}>
            Clear
          </Button>
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

JobInvoiceSignatureDialog.prototype = {
  invoice: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSignatureSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JobInvoiceSignatureDialog;

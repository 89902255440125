import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { AD_SOURCES_KEYS, COUNTRY_KEYS, CUSTOMER_KEYS, USA_STATE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import {
  emailNotValid,
  requiredMessage,
  phoneNotValid,
  stateNotValid,
  countryNotValid,
} from '../../../@jumbo/constants/ErrorMessages';

import { addNewCustomer } from '../../../redux/actions/Customers';
import { getUSAStates } from '../../../redux/actions/USAStates';
import { getCountries } from '../../../redux/actions/Countries';
import { getAdSources } from '../../../redux/actions/AdSources';
import { isValidPhoneNumber } from '../../../utils/FormValidation';

import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(4),
  },
  cardHeader: {
    borderBottom: `1px solid ${theme.palette.borderColor.dark}`,
  },
  cardHeaderTitle: {
    fontSize: theme.overrides.MuiTypography.h2.fontSize,
    fontWeight: theme.overrides.MuiTypography.h2.fontWeight,
  },
  mapAddressInput: {
    width: '100%',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.borderColor.dark}`,
    borderRadius: 2,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.overrides.MuiTypography.body1.fontSize,
  },
}));

const defaultCoordinates = { lat: 0, lng: 0 };
const CustomerAddressMap = withGoogleMap(props => (
  <GoogleMap zoom={12} center={props.center}>
    {props.markers && <Marker position={{ lat: props.markers.lat, lng: props.markers.lng }} />}
  </GoogleMap>
));
let autoComplete;

async function handlePlaceSelect(updateAddress, updateAddressComponents, updateMarkers, updateCenter) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateAddress(query);
  const components = addressObject.address_components;
  updateAddressComponents(components);
  if (addressObject.geometry && addressObject.geometry.location) {
    let lat = addressObject.geometry.location.lat();
    lat = !isNaN(lat) && !isNaN(parseFloat(lat)) ? lat : 0;
    let lng = addressObject.geometry.location.lng();
    lng = !isNaN(lng) && !isNaN(parseFloat(lng)) ? lng : 0;
    updateCenter({ lat, lng });
    updateMarkers({ lat, lng });
  } else {
    updateCenter(defaultCoordinates);
    updateMarkers(null);
  }
}

const pageName = 'Add Customer';
const breadcrumbs = [
  { label: 'Customers', link: '/customers' },
  { label: pageName, isActive: true },
];

const AddCustomerForm = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const autoCompleteRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { authUser } = useSelector(({ auth }) => auth);
  const { countries } = useSelector(({ countriesReducer }) => countriesReducer);
  const { usaStates } = useSelector(({ usaStatesReducer }) => usaStatesReducer);
  const { adSources } = useSelector(({ adSourcesReducer }) => adSourcesReducer);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneOneError, setPhoneOneError] = useState('');
  const [extensionOne, setExtensionOne] = useState('');
  const [extensionOneError, setExtensionOneError] = useState('');
  const [phoneTwo, setPhoneTwo] = useState('');
  const [phoneTwoError, setPhoneTwoError] = useState('');
  const [extensionTwo, setExtensionTwo] = useState('');
  const [extensionTwoError, setExtensionTwoError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [adSource, setAdSource] = useState('');
  const [adSourceError, setAdSourceError] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [addressOneError, setAddressOneError] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [addressTwoError, setAddressTwoError] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [state, setState] = useState('');
  const [stateError, setStateError] = useState('');
  /* const [region, setRegion] = useState('');
  const [regionError, setRegionError] = useState(''); */
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState('');
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [mapAddress, setMapAddress] = useState('');
  const [mapAddressComponents, setMapAddressComponents] = useState(null);
  const [mapMarkers, setMapMarkers] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCoordinates);
  const [dataFetching, setDataFetching] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      autoCompleteRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!dataFetching && dataFetched) {
      autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
      autoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon']);
      autoComplete.addListener('place_changed', () =>
        handlePlaceSelect(setMapAddress, setMapAddressComponents, setMapMarkers, setMapCenter),
      );
    }
  }, [dataFetching, dataFetched]);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getAdSources(payload)).then(() => {
          return dispatch(getCountries(payload)).then(() => {
            return dispatch(getUSAStates(payload));
          });
        });
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise
      .then(() => {
        if (isActive) {
          setDataFetching(false);
          setDataFetched(true);
        }
      })
      .catch(error => {
        if (isActive) {
          setDataFetching(false);
          setDataFetched(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    if (mapAddressComponents) {
      let tempAddressOne = '';
      mapAddressComponents.forEach(function(mapAddressComponent) {
        const types = mapAddressComponent.types;
        const longName = mapAddressComponent.long_name;
        const shortName = mapAddressComponent.short_name;

        if (types.includes('street_number')) {
          tempAddressOne = `${longName} ${tempAddressOne}`;
          /* setAddressOne(longName);
          setAddressOneError(''); */
        } else if (types.includes('route')) {
          tempAddressOne = `${tempAddressOne} ${shortName}`;
          /* setAddressTwo(shortName);
          setAddressTwoError(''); */
        } else if (types.includes('locality') || types.includes('sublocality') || types.includes('sublocality_level_1')) {
          setCity(longName);
          setCityError('');
        } else if (types.includes('administrative_area_level_1')) {
          const mapState = usaStates.find(
            usaStateObj => usaStateObj[USA_STATE_KEYS.NAME] === longName || usaStateObj[USA_STATE_KEYS.CODE] === shortName,
          );
          if (mapState) {
            setState(mapState[USA_STATE_KEYS.ID]);
            setStateError('');
          } else {
            setState('');
          }
        } else if (types.includes('postal_code')) {
          setZipcode(longName);
          setZipcodeError('');
        } else if (types.includes('country')) {
          const mapCountry = countries.find(
            countryObj => countryObj[COUNTRY_KEYS.NAME] === longName || countryObj[COUNTRY_KEYS.CODE] === shortName,
          );
          if (mapCountry) {
            setCountry(mapCountry[COUNTRY_KEYS.ID]);
            setCountryError('');
          } else {
            setCountry('');
          }
        }
      });
      setAddressOne(tempAddressOne);
      setAddressOneError('');
    }
  }, [mapAddressComponents, countries, usaStates]);

  /* useEffect(() => {
    setState('');
    setStateError('');
    setRegion('');
    setRegionError('');
  }, [country]); */

  useEffect(() => {
    if (errorMessages) {
      setMiscellaneousErrors([]);
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case CUSTOMER_KEYS.FIRST_NAME:
            setFirstNameError(msg);
            break;

          case CUSTOMER_KEYS.LAST_NAME:
            setLastNameError(msg);
            break;

          case CUSTOMER_KEYS.EMAIL:
            setEmailError(msg);
            break;

          case CUSTOMER_KEYS.PHONE_ONE:
            setPhoneOneError(msg);
            break;

          case CUSTOMER_KEYS.EXTENSION_ONE:
            setExtensionOneError(msg);
            break;

          case CUSTOMER_KEYS.PHONE_TWO:
            setPhoneTwoError(msg);
            break;

          case CUSTOMER_KEYS.EXTENSION_TWO:
            setExtensionTwoError(msg);
            break;

          case CUSTOMER_KEYS.ADDRESS_ONE:
            setAddressOneError(msg);
            break;

          case CUSTOMER_KEYS.ADDRESS_TWO:
            setAddressTwoError(msg);
            break;

          case CUSTOMER_KEYS.CITY:
            setCityError(msg);
            break;

          case CUSTOMER_KEYS.STATE:
            setStateError(msg);
            break;

          case CUSTOMER_KEYS.ZIP_CODE:
            setZipcodeError(msg);
            break;

          case CUSTOMER_KEYS.COUNTRY:
            setCountryError(msg);
            break;

          case CUSTOMER_KEYS.AD_SOURCE:
            setAdSourceError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const firstNameBlurHandler = event => {
    if (!event.target.value) {
      setFirstNameError(requiredMessage);
    }
  };

  const lastNameBlurHandler = event => {
    if (!event.target.value) {
      setLastNameError(requiredMessage);
    }
  };

  const companyNameBlurHandler = event => {
    /* if (!event.target.value) {
      setCompanyNameError(requiredMessage);
    } */
  };

  const phoneOneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneOneError(phoneNotValid);
    }
  };

  const extensionOneBlurHandler = event => {
    if (!event.target.value) {
      setExtensionOneError(requiredMessage);
    }
  };

  const phoneTwoBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneTwoError(phoneNotValid);
    }
  };

  const extensionTwoBlurHandler = event => {
    if (!event.target.value) {
      setExtensionTwoError(requiredMessage);
    }
  };

  const emailBlurHandler = event => {
    if (!event.target.value) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  };

  const adSourceChangeHandler = event => {
    setAdSource(event.target.value);
    setAdSourceError('');

    /* if (!event.target.value) {
      setAdSourceError(requiredMessage);
    } */
  };

  const addressOneBlurHandler = event => {
    if (!event.target.value) {
      setAddressOneError(requiredMessage);
    }
  };

  const addressTwoBlurHandler = event => {
    /* if (!event.target.value) {
      setAddressTwoError(requiredMessage);
    } */
  };

  const cityBlurHandler = event => {
    if (!event.target.value) {
      setCityError(requiredMessage);
    }
  };

  const stateChangeHandler = event => {
    setState(event.target.value);
    setStateError('');

    if (!event.target.value) {
      setStateError(requiredMessage);
    } else if (!usaStates.map(usaState => usaState[USA_STATE_KEYS.ID]).includes(event.target.value)) {
      setStateError(stateNotValid);
    }
  };

  /* const regionBlurHandler = event => {
    if (!event.target.value) {
      setRegionError(requiredMessage);
    }
  }; */

  const countryChangeHandler = event => {
    setCountry(event.target.value);
    setCountryError('');

    if (!event.target.value) {
      setCountryError(requiredMessage);
    } else if (!countries.map(country => country[COUNTRY_KEYS.ID]).includes(event.target.value)) {
      setCountryError(countryNotValid);
    }
  };

  const zipcodeBlurHandler = event => {
    if (!event.target.value) {
      setZipcodeError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!firstName) {
      formIsValid = false;
      setFirstNameError(requiredMessage);
    }

    if (!lastName) {
      formIsValid = false;
      setLastNameError(requiredMessage);
    }

    if (!email) {
      formIsValid = false;
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      formIsValid = false;
      setEmailError(emailNotValid);
    }

    if (!isValidPhoneNumber(phoneOne)) {
      formIsValid = false;
      setPhoneOneError(phoneNotValid);
    }

    if (!extensionOne) {
      formIsValid = false;
      setExtensionOneError(requiredMessage);
    }

    if (!isValidPhoneNumber(phoneTwo)) {
      formIsValid = false;
      setPhoneTwoError(phoneNotValid);
    }

    if (!extensionTwo) {
      formIsValid = false;
      setExtensionTwoError(requiredMessage);
    }

    if (!addressOne) {
      formIsValid = false;
      setAddressOneError(requiredMessage);
    }

    if (!city) {
      formIsValid = false;
      setCityError(requiredMessage);
    }

    if (!state) {
      formIsValid = false;
      setStateError(requiredMessage);
    } else if (!usaStates.map(usaState => usaState[USA_STATE_KEYS.ID]).includes(state)) {
      formIsValid = false;
      setCountryError(stateNotValid);
    }

    if (!zipcode) {
      formIsValid = false;
      setZipcodeError(requiredMessage);
    }

    if (!country) {
      formIsValid = false;
      setCountryError(requiredMessage);
    } else if (!countries.map(country => country[COUNTRY_KEYS.ID]).includes(country)) {
      formIsValid = false;
      setCountryError(countryNotValid);
    }

    if (formIsValid) {
      const customerData = {
        authcode: authUser.authcode,
        [CUSTOMER_KEYS.FIRST_NAME]: firstName,
        [CUSTOMER_KEYS.LAST_NAME]: lastName,
        [CUSTOMER_KEYS.COMPANY_NAME]: companyName,
        [CUSTOMER_KEYS.EMAIL]: email,
        [CUSTOMER_KEYS.PHONE_ONE]: phoneOne,
        [CUSTOMER_KEYS.EXTENSION_ONE]: extensionOne,
        [CUSTOMER_KEYS.PHONE_TWO]: phoneTwo,
        [CUSTOMER_KEYS.EXTENSION_TWO]: extensionTwo,
        [CUSTOMER_KEYS.ADDRESS_ONE]: addressOne,
        [CUSTOMER_KEYS.ADDRESS_TWO]: addressTwo,
        [CUSTOMER_KEYS.ZIP_CODE]: zipcode,
        [CUSTOMER_KEYS.CITY]: city,
        [CUSTOMER_KEYS.STATE]: state,
        [CUSTOMER_KEYS.COUNTRY]: country,
        [CUSTOMER_KEYS.AD_SOURCE]: adSource,
      };

      dispatch(
        addNewCustomer(
          { customerData: customerData },
          () => {
            if (_isMounted.current) history.push('/customers');
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          {!dataFetching && dataFetched && (
            <form onSubmit={handleFormSubmit}>
              <GridContainer>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader
                      title="Customer Details"
                      classes={{
                        root: classes.cardHeader,
                        title: classes.cardHeaderTitle,
                      }}
                    />
                    <CardContent>
                      <GridContainer>
                        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                          <Grid item xs={12}>
                            {miscellaneousErrors.map((miscellaneousError, idx) => (
                              <Typography
                                variant="caption"
                                display="block"
                                color="error"
                                gutterBottom
                                key={`misc-error-${idx}`}>
                                {miscellaneousError}
                              </Typography>
                            ))}
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="first_name"
                            variant="outlined"
                            label="First Name"
                            value={firstName}
                            onChange={e => {
                              setFirstName(e.target.value);
                              setFirstNameError('');
                            }}
                            onBlur={firstNameBlurHandler}
                            helperText={firstNameError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="last_name"
                            variant="outlined"
                            label="Last Name"
                            value={lastName}
                            onChange={e => {
                              setLastName(e.target.value);
                              setLastNameError('');
                            }}
                            onBlur={lastNameBlurHandler}
                            helperText={lastNameError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="company_name"
                            variant="outlined"
                            label="Company Name"
                            value={companyName}
                            onChange={e => {
                              setCompanyName(e.target.value);
                              setCompanyNameError('');
                            }}
                            onBlur={companyNameBlurHandler}
                            helperText={companyNameError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component="p" variant="h6">
                            Contact Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="phone_one"
                            variant="outlined"
                            label="Primary Phone Number"
                            value={phoneOne}
                            onChange={e => {
                              setPhoneOne(e.target.value);
                              setPhoneOneError('');
                            }}
                            onBlur={phoneOneBlurHandler}
                            helperText={phoneOneError}
                            InputProps={{
                              inputComponent: PhoneNumberFormat,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="extension_one"
                            variant="outlined"
                            label="EXT"
                            value={extensionOne}
                            onChange={e => {
                              setExtensionOne(e.target.value);
                              setExtensionOneError('');
                            }}
                            onBlur={extensionOneBlurHandler}
                            helperText={extensionOneError}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="phone_two"
                            variant="outlined"
                            label="Secondary Phone Number"
                            value={phoneTwo}
                            onChange={e => {
                              setPhoneTwo(e.target.value);
                              setPhoneTwoError('');
                            }}
                            onBlur={phoneTwoBlurHandler}
                            helperText={phoneTwoError}
                            InputProps={{
                              inputComponent: PhoneNumberFormat,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="extension_two"
                            variant="outlined"
                            label="EXT"
                            value={extensionTwo}
                            onChange={e => {
                              setExtensionTwo(e.target.value);
                              setExtensionTwoError('');
                            }}
                            onBlur={extensionTwoBlurHandler}
                            helperText={extensionTwoError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="email"
                            variant="outlined"
                            label="Email Address"
                            value={email}
                            onChange={e => {
                              setEmail(e.target.value);
                              setEmailError('');
                            }}
                            onBlur={emailBlurHandler}
                            helperText={emailError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component="p" variant="h6">
                            Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomerAddressMap
                            loadingElement={<Box height={1} />}
                            containerElement={<Box height={{ xs: 125, sm: 250 }} />}
                            mapElement={<Box height={1} />}
                            center={mapCenter}
                            markers={mapMarkers}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <input
                            type="text"
                            ref={autoCompleteRef}
                            onChange={event => setMapAddress(event.target.value)}
                            placeholder="Search location on map"
                            value={mapAddress}
                            className={classes.mapAddressInput}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="address"
                            variant="outlined"
                            label="Address"
                            value={addressOne}
                            onChange={e => {
                              setAddressOne(e.target.value);
                              setAddressOneError('');
                            }}
                            onBlur={addressOneBlurHandler}
                            helperText={addressOneError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            type="text"
                            name="address_two"
                            variant="outlined"
                            label="Apt/Suite/Floor"
                            value={addressTwo}
                            onChange={e => {
                              setAddressTwo(e.target.value);
                              setAddressTwoError('');
                            }}
                            onBlur={addressTwoBlurHandler}
                            helperText={addressTwoError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="city"
                            variant="outlined"
                            label="City"
                            value={city}
                            onChange={e => {
                              setCity(e.target.value);
                              setCityError('');
                            }}
                            onBlur={cityBlurHandler}
                            helperText={cityError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {
                            /* country === '' || country === 'usa' ? ( */
                            <AppSelectBox
                              fullWidth
                              name="state"
                              data={usaStates}
                              label="State"
                              valueKey={USA_STATE_KEYS.ID}
                              variant="outlined"
                              labelKey={USA_STATE_KEYS.NAME}
                              value={state}
                              onChange={stateChangeHandler}
                              helperText={stateError}
                            />
                            /* ) : (
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="region"
                            variant="outlined"
                            label="Region"
                            value={region}
                            onChange={e => {
                              setRegion(e.target.value);
                              setRegionError('');
                            }}
                            onBlur={regionBlurHandler}
                            helperText={regionError}
                          />
                        ) */
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="zipcode"
                            variant="outlined"
                            label="Zip"
                            value={zipcode}
                            onChange={e => {
                              setZipcode(e.target.value);
                              setZipcodeError('');
                            }}
                            onBlur={zipcodeBlurHandler}
                            helperText={zipcodeError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="country"
                            data={countries}
                            label="Country"
                            valueKey={COUNTRY_KEYS.ID}
                            variant="outlined"
                            labelKey={COUNTRY_KEYS.NAME}
                            value={country}
                            onChange={countryChangeHandler}
                            helperText={countryError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component="p" variant="h6">
                            Ad Source
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="ad_source"
                            data={adSources}
                            label="Select Ad Source"
                            valueKey={AD_SOURCES_KEYS.ID}
                            variant="outlined"
                            labelKey={AD_SOURCES_KEYS.NAME}
                            value={adSource}
                            onChange={adSourceChangeHandler}
                            helperText={adSourceError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button type="submit" variant="contained" color="primary">
                            Save
                          </Button>
                        </Grid>
                      </GridContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </GridContainer>
            </form>
          )}
          {!dataFetching && !dataFetched && <Alert severity="error">Data not found!</Alert>}
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default AddCustomerForm;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
/* import Typography from '@material-ui/core/Typography'; */
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
/* import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox'; */
import { JOB_TYPE_KEYS } from '../../../../@jumbo/constants/AppDataConstants';
import { requiredMessage /* , positiveOnlyIntegers */ } from '../../../../@jumbo/constants/ErrorMessages';

import { addNewJobType, updateJobType } from '../../../../redux/actions/JobTypes';
import { isEmpty /* , isPositiveInteger */ } from '../../../../utils/FormValidation';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

let jobCompletionAllDays = [],
  jobCompletionAllHrs = [],
  jobCompletionAllMins = [];
for (let i = 0; i < 60; i++) {
  if (i <= 31) {
    jobCompletionAllDays.push({ title: i.toString(), slug: i.toString() });
  }

  if (i <= 23) {
    jobCompletionAllHrs.push({ title: i.toString(), slug: i.toString() });
  }

  jobCompletionAllMins.push({ title: i.toString(), slug: i.toString() });
}

const AddEditJobType = ({ open, onCloseDialog }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const currentJobType = useSelector(({ jobTypesReducer }) => jobTypesReducer.currentJobType);

  const [jobTypeName, setJobTypeName] = useState('');
  const [jobTypeNameError, setJobTypeNameError] = useState('');
  /* const [displayOrder, setDisplayOrder] = useState('1');
  const [displayOrderError, setDisplayOrderError] = useState(''); */
  /* const [jobCompletionDays, setJobCompletionDays] = useState('0');
  const [jobCompletionDaysError, setJobCompletionDaysError] = useState('');
  const [jobCompletionHrs, setJobCompletionHrs] = useState('2');
  const [jobCompletionHrsError, setJobCompletionHrsError] = useState('');
  const [jobCompletionMins, setJobCompletionMins] = useState('0');
  const [jobCompletionMinsError, setJobCompletionMinsError] = useState(''); */
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setJobTypeName('');
      setJobTypeNameError('');
      /* setDisplayOrder('1');
      setDisplayOrderError(''); */
      /* setJobCompletionDays('0');
      setJobCompletionDaysError('');
      setJobCompletionHrs('2');
      setJobCompletionHrsError('');
      setJobCompletionMins('0');
      setJobCompletionMinsError(''); */
      setErrorMessages([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentJobType) {
      setJobTypeName(currentJobType[JOB_TYPE_KEYS.NAME]);
      /* setDisplayOrder(currentJobType[JOB_TYPE_KEYS.DISPLAY_ORDER]); */
      /* setJobCompletionDays(currentJobType[JOB_TYPE_KEYS.DAYS]);
      setJobCompletionHrs(currentJobType[JOB_TYPE_KEYS.HOURS]);
      setJobCompletionMins(currentJobType[JOB_TYPE_KEYS.MINUTES]); */
    }
  }, [currentJobType]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case JOB_TYPE_KEYS.NAME:
          setJobTypeNameError(msg);
          break;

        /* case JOB_TYPE_KEYS.DISPLAY_ORDER:
          setDisplayOrderError(msg);
          break; */

        /* case JOB_TYPE_KEYS.DAYS:
          setJobCompletionDaysError(msg);
          break;

        case JOB_TYPE_KEYS.HOURS:
          setJobCompletionHrsError(msg);
          break;

        case JOB_TYPE_KEYS.MINUTES:
          setJobCompletionMinsError(msg);
          break; */

        default:
          break;
      }
    }
  }, [errorMessages]);

  const jobTypeNameBlurHandler = event => {
    if (isEmpty(event.target.value)) {
      setJobTypeNameError(requiredMessage);
    }
  };

  /* const displayOrderBlurHandler = event => {
    if (isEmpty(event.target.value)) {
      setDisplayOrderError(requiredMessage);
    } else if (!isPositiveInteger(event.target.value)) {
      setDisplayOrderError(positiveOnlyIntegers);
    }
  }; */

  /* const jobCompletionDaysChangeHandler = event => {
    setJobCompletionDays(event.target.value);
    setJobCompletionDaysError('');

    // if (isEmpty(event.target.value)) {
    //   setJobCompletionDaysError(requiredMessage);
    // } else if (!isPositiveInteger(event.target.value)) {
    //   setJobCompletionDaysError(positiveOnlyIntegers);
    // }
  }; */

  /* const jobCompletionHrsChangeHandler = event => {
    setJobCompletionHrs(event.target.value);
    setJobCompletionHrsError('');

    // if (isEmpty(event.target.value)) {
    //   setJobCompletionHrsError(requiredMessage);
    // } else if (!isPositiveInteger(event.target.value)) {
    //   setJobCompletionHrsError(positiveOnlyIntegers);
    // }
  }; */

  /* const jobCompletionMinsChangeHandler = event => {
    setJobCompletionMins(event.target.value);
    setJobCompletionMinsError('');

    // if (isEmpty(event.target.value)) {
    //   setJobCompletionMinsError(requiredMessage);
    // } else if (!isPositiveInteger(event.target.value)) {
    //   setJobCompletionMinsError(positiveOnlyIntegers);
    // }
  }; */

  const onSubmitClick = () => {
    let formIsValid = true;

    if (isEmpty(jobTypeName)) {
      formIsValid = false;
      setJobTypeNameError(requiredMessage);
    }

    /* if (isEmpty(displayOrder)) {
      formIsValid = false;
      setDisplayOrderError(requiredMessage);
    } else if (!isPositiveInteger(displayOrder)) {
      formIsValid = false;
      setDisplayOrderError(positiveOnlyIntegers);
    } */

    /* if (isEmpty(jobCompletionDays)) {
      formIsValid = false;
      setJobCompletionDaysError(requiredMessage);
    } else if (!isPositiveInteger(jobCompletionDays)) {
      formIsValid = false;
      setJobCompletionDaysError(positiveOnlyIntegers);
    } */

    /* if (isEmpty(jobCompletionHrs)) {
      formIsValid = false;
      setJobCompletionHrsError(requiredMessage);
    } else if (!isPositiveInteger(jobCompletionHrs)) {
      formIsValid = false;
      setJobCompletionHrsError(positiveOnlyIntegers);
    } */

    /* if (isEmpty(jobCompletionMins)) {
      formIsValid = false;
      setJobCompletionMinsError(requiredMessage);
    } else if (!isPositiveInteger(jobCompletionMins)) {
      formIsValid = false;
      setJobCompletionMinsError(positiveOnlyIntegers);
    } */

    if (formIsValid) {
      const jobTypeData = {
        authcode: authUser.authcode,
        [JOB_TYPE_KEYS.NAME]: jobTypeName,
        /* [JOB_TYPE_KEYS.DISPLAY_ORDER]: displayOrder, */
        /* [JOB_TYPE_KEYS.DAYS]: jobCompletionDays,
        [JOB_TYPE_KEYS.HOURS]: jobCompletionHrs,
        [JOB_TYPE_KEYS.MINUTES]: jobCompletionMins, */
      };

      if (currentJobType) {
        jobTypeData[JOB_TYPE_KEYS.JOB_TYPE_ID] = currentJobType[JOB_TYPE_KEYS.ID];
        dispatch(
          updateJobType(
            { jobTypeData: jobTypeData },
            () => {
              if (_isMounted.current) onCloseDialog();
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      } else {
        dispatch(
          addNewJobType(
            { jobTypeData: jobTypeData },
            () => {
              if (_isMounted.current) onCloseDialog();
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      }
    }
  };

  return (
    <Dialog fullWidth open={open} className={classes.dialogRoot} onClose={onCloseDialog}>
      <DialogTitle className={classes.dialogTitleRoot}>Add New Job Type</DialogTitle>
      <DialogContent dividers>
        <form>
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                type="text"
                name="name"
                fullWidth
                variant="outlined"
                label="Name"
                value={jobTypeName}
                onChange={e => {
                  setJobTypeName(e.target.value);
                  setJobTypeNameError('');
                }}
                onBlur={jobTypeNameBlurHandler}
                helperText={jobTypeNameError}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <AppTextInput
                type="text"
                name="display_order"
                fullWidth
                variant="outlined"
                label="Display Order"
                value={displayOrder}
                onChange={e => {
                  setDisplayOrder(e.target.value);
                  setDisplayOrderError('');
                }}
                onBlur={displayOrderBlurHandler}
                helperText={displayOrderError}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Typography variant="h6">Set Duration</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <AppSelectBox
                name="job_completion_days"
                data={jobCompletionAllDays}
                label="Days"
                valueKey="slug"
                variant="outlined"
                labelKey="title"
                value={jobCompletionDays}
                onChange={jobCompletionDaysChangeHandler}
                helperText={jobCompletionDaysError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppSelectBox
                name="job_completion_hrs"
                data={jobCompletionAllHrs}
                label="Hours"
                valueKey="slug"
                variant="outlined"
                labelKey="title"
                value={jobCompletionHrs}
                onChange={jobCompletionHrsChangeHandler}
                helperText={jobCompletionHrsError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppSelectBox
                name="job_completion_mins"
                data={jobCompletionAllMins}
                label="Minutes"
                valueKey="slug"
                variant="outlined"
                labelKey="title"
                value={jobCompletionMins}
                onChange={jobCompletionMinsChangeHandler}
                helperText={jobCompletionMinsError}
              />
            </Grid> */}
          </GridContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onCloseDialog}>
          Cancel
        </Button>
        <Button type="button" variant="contained" color="primary" onClick={onSubmitClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddEditJobType.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};

export default AddEditJobType;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { ADD_ESTIMATE_SIGNATURE } from '../../@jumbo/constants/ActionTypes';
import { ADD_ESTIMATE_SIGNATURE_LINK } from '../../@jumbo/constants/APIConstants';

export const addEstimateSignature = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.estimateSignatureData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_ESTIMATE_SIGNATURE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.signature) {
              const signature = data.signature;
              dispatch(fetchSuccess('Signature was added successfully.'));
              dispatch({ type: ADD_ESTIMATE_SIGNATURE, payload: signature });
              if (callbackFun) callbackFun(signature);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';

import GridContainer from '../../../@jumbo/components/GridContainer';
import ContentLoader from '../../../@jumbo/components/ContentLoader';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { COLOR_LOGO_LINK, JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import CmtImage from '../../../@coremat/CmtImage';

import { confirmJobForMember } from '../../../redux/actions/Jobs';

import useStyles from '../index.style';

const MemberConfirmJob = () => {
  document.title = `${AppName} - Member Portal | Confirm Job`;

  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const jobId = params.jobId;
  const userId = params.userId;

  const [dataProcessing, setDataProcessing] = useState(true);
  const [dataProcessed, setDataProcessed] = useState(false);

  useEffect(() => {
    let isActive = true;

    if (userId && jobId && dataProcessing && !dataProcessed) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            confirmJobForMember(payload, () => {
              if (isActive) {
                setDataProcessed(true);
                setDataProcessing(false);
              }
            }),
          );
        };
      };

      const payload = { jobData: { [JOB_KEYS.JOB_ID]: jobId, [JOB_KEYS.USER_ID]: userId } };

      const promise = dispatch(fetchData(payload));
      promise.catch(error => {
        if (isActive) {
          setDataProcessed(false);
          setDataProcessing(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, userId, jobId, dataProcessing, dataProcessed]);

  return (
    <Paper className={classes.paper}>
      <GridContainer>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <CmtImage src={COLOR_LOGO_LINK} alt="AAA Rousse Logo" width={120} />
            <Typography component="h1" variant="h1" display="inline" className={classes.headingOne}>
              AAA Rousse Junk Removal
            </Typography>
          </Box>
          <Divider />
        </Grid>

        {!dataProcessing && dataProcessed && (
          <Grid item xs={12}>
            <Alert severity="success">Job has been successfully confirmed!</Alert>
          </Grid>
        )}

        {!dataProcessing && !dataProcessed && (
          <Grid item xs={12}>
            <Alert severity="error">Unable to confirm job!</Alert>
          </Grid>
        )}
      </GridContainer>

      <ContentLoader />
    </Paper>
  );
};

export default MemberConfirmJob;

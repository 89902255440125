import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getChatContainerHeight } from '../../../@jumbo/constants/AppConstants';
import { MESSAGE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';

import { getUserConversation, setCurrentUserMessages } from '../../../redux/actions/Messages';

import CmtImage from '../../../@coremat/CmtImage';

import ContentHeader from '../ContentHeader';
import Conversation from '../Conversation';
import ChatFooter from '../ChatFooter';

import useStyles from '../index.style';

const ChatContainer = ({ jobId, width }) => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({
    height: getChatContainerHeight(width, showFooter),
  });
  const dispatch = useDispatch();
  let scrollBarRef = null;

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentUser, conversation } = useSelector(({ messagesReducer }) => messagesReducer);

  const [refreshChat, setRefreshChat] = useState(false);

  useEffect(() => {
    let isActive = true;

    dispatch(setCurrentUserMessages([]));

    if (currentUser) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(getUserConversation(payload));
        };
      };

      const messageData = {
        authcode: authUser.authcode,
        [MESSAGE_KEYS.RECEIVER_ID]: currentUser[MESSAGE_KEYS.RECEIVER_ID],
        [MESSAGE_KEYS.RECEIVER_TYPE]: currentUser[MESSAGE_KEYS.RECEIVER_TYPE],
      };

      const promise = dispatch(fetchData({ messageData: messageData }));
      promise.catch(error => {
        if (isActive) {
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, currentUser]);

  useEffect(() => {
    let isActive = true;

    if (currentUser && refreshChat) {
      dispatch(setCurrentUserMessages([]));

      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getUserConversation(payload, () => {
              setRefreshChat(false);
            }),
          );
        };
      };

      const messageData = {
        authcode: authUser.authcode,
        [MESSAGE_KEYS.RECEIVER_ID]: currentUser[MESSAGE_KEYS.RECEIVER_ID],
        [MESSAGE_KEYS.RECEIVER_TYPE]: currentUser[MESSAGE_KEYS.RECEIVER_TYPE],
      };

      const promise = dispatch(fetchData({ messageData: messageData }));
      promise.catch(error => {
        if (isActive) {
          setRefreshChat(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, currentUser, refreshChat]);

  useEffect(() => {
    if (scrollBarRef) scrollBarRef._container.scrollTop = 99999;
  }, [conversation, scrollBarRef]);

  const handleRefreshChat = () => setRefreshChat(true);

  /* Not sure what this function. Copied it from theme 'chat' code. */
  const loadPrevious = () => {};

  if (!currentUser) {
    return (
      <Box className={classes.chatBoxRoot}>
        <Box mb={2}>
          <CmtImage src={'/images/chat_bubble.png'} />
        </Box>
        <Typography className={classes.chatBoxTitle}>No conversation selected</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.inBuildAppMainContent}>
      <ContentHeader user={currentUser} onRefreshChat={handleRefreshChat} />
      <PerfectScrollbar
        onScrollY={container => {
          if (container.scrollTop === 0) {
            loadPrevious();
          }
        }}
        className={classes.perfectScrollbarChatCon}
        ref={ref => {
          scrollBarRef = ref;
        }}>
        <Conversation conversation={conversation} selectedUser={currentUser} />
      </PerfectScrollbar>
      <ChatFooter user={currentUser} jobId={jobId} />
    </Box>
  );
};

ChatContainer.prototype = {
  jobId: PropTypes.string.isRequired,
};

export default ChatContainer;

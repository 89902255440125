import React from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Icons from '@material-ui/icons/';

import RestrictedComponent from '../../RestrictedComponent';

const ListItemLink = props => {
  return <ListItem button component={Link} {...props} />;
};

const Icon = ({ name, ...rest }) => {
  const IconComponent = Icons[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

const SettingsSection = props => {
  const section = props.section;
  return (
    <List component="nav" aria-label={`${section.sectionName} Settings List`}>
      {section.sectionSettings.map(setting => (
        <RestrictedComponent key={setting.id} component={setting.link}>
          <ListItemLink to={setting.link}>
            <ListItemIcon>
              <Icon name={setting.icon} />
            </ListItemIcon>
            <ListItemText primary={setting.name} />
          </ListItemLink>
        </RestrictedComponent>
      ))}
    </List>
  );
};

export default SettingsSection;

import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
/* import Checkbox from '@material-ui/core/Checkbox'; */
import TableSortLabel from '@material-ui/core/TableSortLabel';

function JobsTableHead({
  headCells,
  classes,
  order,
  orderBy,
  /* numSelected, rowCount, */ onRequestSort,
  /* onSelectAllClick, */
}) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all jobs' }}
          />
        </TableCell> */}
        {headCells.map(headCell => {
          return headCell.show ? (
            headCell.sortColumn ? (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{ width: headCell.width }}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={onSortOrderChange(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell key={headCell.id} align="left" padding="normal" sortDirection={false}>
                {headCell.label}
              </TableCell>
            )
          ) : null;
        })}
      </TableRow>
    </TableHead>
  );
}

JobsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  /* numSelected: PropTypes.number.isRequired, */
  /* rowCount: PropTypes.number.isRequired, */
  /* onSelectAllClick: PropTypes.func.isRequired, */
  onRequestSort: PropTypes.func.isRequired,
};

export default React.memo(JobsTableHead);

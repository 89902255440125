import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
/* import TextField from '@material-ui/core/TextField'; */
import Typography from '@material-ui/core/Typography';
/* import Autocomplete from '@material-ui/lab/Autocomplete'; */
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { CUSTOMER_KEYS, CUSTOMER_NOTE_KEYS /* , SQUAD_USER_KEYS */ } from '../../../@jumbo/constants/AppDataConstants';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';

import { addNewCustomerNote, updateCustomerNote } from '../../../redux/actions/CustomerNotes';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const NoteDialog = ({ open, onClose }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  /* const { squadUsers } = useSelector(({ squadReducer }) => squadReducer); */
  const { currentCustomer } = useSelector(({ customersReducer }) => customersReducer);
  const { currentCustomerNote } = useSelector(({ customerNotesReducer }) => customerNotesReducer);

  const [noteId, setNoteId] = useState('new');
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState('');
  /* const [value, setValue] = useState(null); */
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    if (!open) {
      setNoteId('new');
      setNote('');
      setNoteError('');
      /* setValue(null); */
      setErrorMessages([]);
      setMiscellaneousErrors([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentCustomerNote) {
      setNoteId(currentCustomerNote[CUSTOMER_NOTE_KEYS.ID]);
      setNote(currentCustomerNote[CUSTOMER_NOTE_KEYS.NOTE]);
      /* setValue(currentCustomerNote[CUSTOMER_NOTE_KEYS.NOTE]); */
    }
  }, [currentCustomerNote]);

  useEffect(() => {
    if (errorMessages) {
      setMiscellaneousErrors([]);
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case CUSTOMER_NOTE_KEYS.NOTE:
            setNoteError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const noteBlurHandler = event => {
    setNoteError('');
    if (!event.target.value) {
      setNoteError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;

    if (!note) {
      setNoteError(requiredMessage);
      formIsValid = false;
    }

    if (formIsValid) {
      let customerNoteData = {
        authcode: authUser.authcode,
        [CUSTOMER_NOTE_KEYS.USER_ID]: authUser.id,
        [CUSTOMER_NOTE_KEYS.CUSTOMER_ID]: currentCustomer[CUSTOMER_KEYS.ID],
        [CUSTOMER_NOTE_KEYS.NOTE]: note,
      };

      if (noteId === 'new') {
        dispatch(
          addNewCustomerNote(
            { customerNoteData: customerNoteData },
            () => {
              if (_isMounted.current) {
                onClose();
              }
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      } else if (currentCustomerNote && noteId) {
        customerNoteData[CUSTOMER_NOTE_KEYS.NOTE_ID] = noteId;

        dispatch(
          updateCustomerNote(
            { customerNoteData: customerNoteData },
            () => {
              if (_isMounted.current) {
                onClose();
              }
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      }
    }
  };

  const noteIsValid = noteId === 'new' || (currentCustomerNote && noteId);

  return (
    <Dialog fullWidth open={open} className={classes.dialogRoot} onClose={onClose}>
      <DialogTitle className={classes.dialogTitleRoot}>Add Note</DialogTitle>
      <DialogContent dividers>
        {noteIsValid && (
          <form onSubmit={handleFormSubmit}>
            <GridContainer>
              {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                <Grid item xs={12}>
                  {miscellaneousErrors.map((miscellaneousError, idx) => (
                    <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                      {miscellaneousError}
                    </Typography>
                  ))}
                </Grid>
              )}
              {/* <Grid item xs={12}>
              <Autocomplete
                freeSolo
                id="note"
                inputValue={note}
                onInputChange={(e, value, reason) => {
                  setNote(value);
                  setNoteError('');
                }}
                value={value}
                onChange={(e, value, reason) => {
                  if (['select-option', 'remove-option'].includes(reason)) {
                    setValue(value ? value : null);
                  } else if (reason === 'clear') {
                    setValue(null);
                  }
                }}
                options={squadUsers.map(squadUser => squadUser[SQUAD_USER_KEYS.NAME])}
                filterOptions={(options, state) => {
                  let typedValue = state.inputValue;
                  if (typedValue && typedValue.length > 0) {
                    if (typedValue[0] === '@') {
                      typedValue = typedValue.substring(1);
                    }
                    typedValue = typedValue.toLowerCase();
                  }
                  return options.filter(option => option.toLowerCase().includes(typedValue));
                }}
                getOptionLabel={option => option}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Note"
                    variant="outlined"
                    error={!!noteError}
                    helperText={!!noteError && noteError}
                  />
                )}
                open={note.length > 1 && note[0] === '@'}
              />
            </Grid> */}
              <Grid item xs={12}>
                <AppTextInput
                  type="text"
                  name="note"
                  variant="outlined"
                  label="Note content"
                  value={note}
                  onChange={e => {
                    setNote(e.target.value);
                    setNoteError('');
                  }}
                  onBlur={noteBlurHandler}
                  helperText={noteError}
                />
              </Grid>
            </GridContainer>
            <DialogActions>
              <Button type="button" variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {noteId === 'new' ? 'Add' : 'Update'}
              </Button>
            </DialogActions>
          </form>
        )}
        {!noteIsValid && (
          <GridContainer>
            <Grid item xs={12}>
              <Alert severity="error">Invalid note</Alert>
            </Grid>
          </GridContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

NoteDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NoteDialog;

import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMERS_DUES,
  SET_CUSTOMER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { CUSTOMER_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  customers: [],
  dues: {},
  currentCustomer: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case GET_CUSTOMERS_DUES: {
      return {
        ...state,
        dues: action.payload,
      };
    }
    case SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        currentCustomer: action.payload,
      };
    }
    case ADD_CUSTOMER: {
      return {
        ...state,
        customers: [action.payload, ...state.customers],
      };
    }
    case EDIT_CUSTOMER: {
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer[CUSTOMER_KEYS.ID] === action.payload[CUSTOMER_KEYS.ID] ? action.payload : customer,
        ),
      };
    }
    case DELETE_CUSTOMER: {
      return {
        ...state,
        customers: state.customers.filter(customer => customer[CUSTOMER_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { requiredMessage, passwordMisMatch } from '../../../@jumbo/constants/ErrorMessages';
import { SQUAD_USER_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { updateSquadUserPassword } from '../../../redux/actions/Squad';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const ResetPasswordDialog = ({ open, onClose, userId }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);

  const [userIdError, setUserIdError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setNewPassword('');
      setNewPasswordError('');
      setConfirmNewPassword('');
      setConfirmNewPasswordError('');
      setErrorMessages([]);
    }
  }, [open]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case SQUAD_USER_KEYS.USER_ID:
          setUserIdError(msg);
          break;

        case SQUAD_USER_KEYS.PASSWORD:
          setNewPasswordError(msg);
          break;

        case SQUAD_USER_KEYS.CONFIRM_PASSWORD:
          setConfirmNewPasswordError(msg);
          break;

        default:
          break;
      }
    }
  }, [errorMessages]);

  const newPasswordBlurHandler = event => {
    if (!event.target.value) {
      setNewPasswordError(requiredMessage);
    } else if (confirmNewPassword !== event.target.value) {
      setConfirmNewPasswordError(passwordMisMatch);
    } else if (confirmNewPassword === event.target.value) {
      setConfirmNewPasswordError('');
    }
  };

  const confirmNewPasswordBlurHandler = event => {
    if (!event.target.value) {
      setConfirmNewPasswordError(requiredMessage);
    } else if (newPassword !== event.target.value) {
      setConfirmNewPasswordError(passwordMisMatch);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    let formIsValid = true;

    if (!newPassword) {
      formIsValid = false;
      setNewPasswordError(requiredMessage);
    }

    if (!confirmNewPassword) {
      formIsValid = false;
      setConfirmNewPasswordError(requiredMessage);
    }

    if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword) {
      formIsValid = false;
      setConfirmNewPasswordError(passwordMisMatch);
    }

    if (formIsValid) {
      const userData = {
        authcode: authUser.authcode,
        [SQUAD_USER_KEYS.USER_ID]: userId,
        [SQUAD_USER_KEYS.PASSWORD]: newPassword,
        [SQUAD_USER_KEYS.CONFIRM_PASSWORD]: confirmNewPassword,
      };
      dispatch(
        updateSquadUserPassword(
          { userData: userData },
          () => {
            if (_isMounted.current) onClose();
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>Reset Password</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleFormSubmit}>
          <GridContainer>
            {userIdError && (
              <Grid item xs={12}>
                <Typography variant="caption" display="block" color="error" gutterBottom>
                  {userIdError}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                type="password"
                variant="outlined"
                label="New Password"
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                  setNewPasswordError('');
                }}
                onBlur={newPasswordBlurHandler}
                helperText={newPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                type="password"
                variant="outlined"
                label="Confirm New Password"
                value={confirmNewPassword}
                onChange={e => {
                  setConfirmNewPassword(e.target.value);
                  setConfirmNewPasswordError('');
                }}
                onBlur={confirmNewPasswordBlurHandler}
                helperText={confirmNewPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <Button type="button" variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Grid>
          </GridContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
};

ResetPasswordDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ResetPasswordDialog;

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';

import { JOB_TYPE_KEYS, ACTIVE_JOB_TYPE } from '../../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
  },
}));

const JobTypeListRow = ({ row, onJobTypeEdit, onStatusChange }) => {
  const classes = useStyles();

  const jobTypeId = row[JOB_TYPE_KEYS.ID];
  const labelId = `job-type-${jobTypeId}`;

  /* const days = row[JOB_TYPE_KEYS.DAYS];
  const hrs = row[JOB_TYPE_KEYS.HOURS];
  const mins = row[JOB_TYPE_KEYS.MINUTES];
  let duration = [];
  if (!isNaN(days) && !isNaN(parseInt(days)) && parseInt(days) > 0) {
    duration.push(`${days} days`);
  }
  if (!isNaN(hrs) && !isNaN(parseInt(hrs)) && parseInt(hrs) > 0) {
    duration.push(`${hrs} hours`);
  }
  if (!isNaN(mins) && !isNaN(parseInt(mins)) && parseInt(mins) > 0) {
    duration.push(`${mins} minutes`);
  } */

  const handleStatusChange = event => onStatusChange(row);

  const handleJobTypeEdit = event => onJobTypeEdit(row);

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={jobTypeId}
      classes={{
        hover: classes.tableRow,
      }}>
      <TableCell component="th" id={labelId} scope="row">
        {row[JOB_TYPE_KEYS.NAME]}
      </TableCell>
      {/* <TableCell>{row[JOB_TYPE_KEYS.DISPLAY_ORDER]}</TableCell> */}
      {/* <TableCell>{duration.join(' ')}</TableCell> */}
      <TableCell>
        <Switch
          checked={row[JOB_TYPE_KEYS.STATUS] === ACTIVE_JOB_TYPE}
          onChange={handleStatusChange}
          name="status"
          inputProps={{ 'aria-label': 'Job Type status' }}
        />
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <IconButton aria-label="delete" color="primary" onClick={handleJobTypeEdit}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(JobTypeListRow);

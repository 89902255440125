import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
/* import Checkbox from '@material-ui/core/Checkbox'; */
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
/* import Autocomplete from '@material-ui/lab/Autocomplete'; */
import { makeStyles } from '@material-ui/core/styles';

/* import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput'; */
import { ESTIMATE_KEYS, ESTIMATE_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
  },
  titleRoot2: {
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
  },
  tableRow: {
    cursor: 'pointer',
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  columnCommon: {
    whiteSpace: 'nowrap',
  },
}));

const EstimatesListRow = ({ row, /* isSelected, */ onRowClick /* onCheckboxClick, */ /* onUpdateStatus */ }) => {
  const classes = useStyles();

  const { estimateStatuses } = useSelector(({ estimateStatusesReducer }) => estimateStatusesReducer);

  const estimateId = row[ESTIMATE_KEYS.ID];
  const labelId = `estimates-table-checkbox-${estimateId}`;
  /* const isItemSelected = isSelected(estimateId); */

  const statusObj = estimateStatuses.find(
    estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID] === row[ESTIMATE_KEYS.STATUS],
  );
  const statusName = statusObj ? statusObj[ESTIMATE_STATUS_KEYS.NAME] : 'NA';

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, estimateId)}
      role="checkbox"
      /* aria-checked={isItemSelected} */
      tabIndex={-1}
      key={estimateId}
      /* selected={isItemSelected} */
      classes={{
        hover: classes.tableRow,
      }}>
      {/* <TableCell padding="checkbox" onClick={event => onCheckboxClick(event, estimateId)}>
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
      <TableCell component="th" id={labelId} scope="row" className={classes.columnCommon}>
        {estimateId}
      </TableCell>
      <TableCell className={classes.columnCommon}>
        <Box display="flex" flexDirection="column">
          <Typography className={classes.titleRoot} component="div" variant="h4">
            {row[ESTIMATE_KEYS.CUSTOMER_NAME]}
          </Typography>
          <Typography className={classes.titleRoot2} component="div" variant="h5">
            {row[ESTIMATE_KEYS.CUSTOMER_EMAIL]}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.columnCommon}>
        <Typography className={classes.created}>{row[ESTIMATE_KEYS.FORMATTED_CREATED]}</Typography>
      </TableCell>
      <TableCell className={classes.columnCommon}>{row[ESTIMATE_KEYS.AMOUNT]}</TableCell>
      <TableCell className={classes.columnCommon}>{statusName}</TableCell>
      {/* <TableCell onClick={event => event.stopPropagation()}>
        <Autocomplete
          fullWidth
          disableClearable
          id={`estimate_${estimateId}_status`}
          value={row[ESTIMATE_KEYS.STATUS]}
          options={estimateStatuses.map(estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID])}
          getOptionLabel={option => {
            if (option) {
              const selectedStatus = estimateStatuses.find(
                estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID] === option,
              );
              if (selectedStatus) {
                return selectedStatus[ESTIMATE_STATUS_KEYS.NAME];
              } else {
                return '';
              }
            } else {
              return '';
            }
          }}
          getOptionSelected={(option, value) => option === value || option === null}
          renderInput={params => (
            <AppTextInput {...params} type="text" name="estimate_status" label="Status" variant="outlined" margin="dense" />
          )}
          onChange={(e, value, reason) => {
            if (['select-option'].includes(reason)) {
              const selectedStatus = value ? value : null;
              if (
                selectedStatus &&
                estimateStatuses.map(estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID]).includes(selectedStatus)
              ) {
                onUpdateStatus(selectedStatus);
              }
            }
          }}
          classes={{ root: classes.listAutocomplete }}
        />
      </TableCell> */}
      <TableCell onClick={event => event.stopPropagation()} className={classes.columnCommon}>
        <Link component={RouterLink} to={`/job/${row[ESTIMATE_KEYS.JOB_ID]}/`}>
          {row[ESTIMATE_KEYS.JOB_ID]}
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(EstimatesListRow);

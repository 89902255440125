import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 415,
  },
  card: {
    [theme.breakpoints.up('md')]: {
      minHeight: 800,
    },
  },
  cardHeaderTitle: {
    fontSize: theme.overrides.MuiTypography.h2.fontSize,
    fontWeight: theme.overrides.MuiTypography.h2.fontWeight,
  },
  btnContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  inviteBtn: {
    marginRight: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  btnDeleteUser: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default useStyles;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CmtDrawer from '../../../@coremat/CmtDrawer';

import useStyles from './index.style';

const Drawer = ({ children, open, onClose, title }) => {
  const classes = useStyles();

  return (
    <CmtDrawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
      className={classes.root}>
      <Box className={classes.drawerHeader}>
        <Typography component="h2" variant="h2">
          {title}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box className={classes.drawerScroll}>
        <Box className={classes.drawerContent}>{children}</Box>
      </Box>
    </CmtDrawer>
  );
};

Drawer.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default Drawer;

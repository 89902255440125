import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
/* import { calendarData } from '../../@fake-db'; */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { purple, indigo, blue, green, orange, red } from '@material-ui/core/colors';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { AppName } from '../../@jumbo/constants/AppConstants';
import {
  JOB_KEYS,
  JOB_SUBMITTED_STATUS,
  JOB_IN_PROGRESS_STATUS,
  JOB_RESCHEDULE_STATUS,
  JOB_DONE_STATUS,
  JOB_CANCEL_STATUS,
  JOB_ASSIGNED_STATUS,
} from '../../@jumbo/constants/AppDataConstants';

import { getSchedule } from '../../redux/actions/Schedule';

import Event from './Event';
import EventDescription from './EventDescription';

import useStyles from './index.style';

const pageName = 'Calendar';
const breadcrumbs = [{ label: pageName, isActive: true }];

const eventStyleGetter = (event, start, end, isSelected) => {
  const statusId = event[JOB_KEYS.STATUS_ID];

  let backgroundColor = '';
  switch (statusId) {
    case JOB_SUBMITTED_STATUS:
      backgroundColor = blue[500];
      break;

    case JOB_ASSIGNED_STATUS:
      backgroundColor = indigo[500];
      break;

    case JOB_IN_PROGRESS_STATUS:
      backgroundColor = purple[500];
      break;

    case JOB_DONE_STATUS:
      backgroundColor = green[500];
      break;

    case JOB_RESCHEDULE_STATUS:
      backgroundColor = orange[500];
      break;

    case JOB_CANCEL_STATUS:
      backgroundColor = red[500];
      break;

    default:
      backgroundColor = '#000';
      break;
  }
  return {
    style: {
      /* minHeight: 'auto', */
      backgroundColor: backgroundColor,
      color: '#fff'
    },
  };
};

const Schedule = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);

  /* const { events } = calendarData; */
  const [events, setEvents] = useState([]);
  const [eventsFetching, setEventsFetching] = useState(true);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openEventDialog, setOpenEventDialog] = useState(false);

  useEffect(() => {
    let isActive = true;

    if (eventsFetching && !eventsFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getSchedule(payload, eventsData => {
              if (isActive) {
                const formattedEventsData = eventsData.map(event => {
                  return {
                    ...event,
                    start: new Date(event['start']),
                    end: new Date(event['end']),
                  };
                });
                setEvents(formattedEventsData);
                setEventsFetched(true);
                setEventsFetching(false);
              }
            }),
          );
        };
      };

      const scheduleData = {
        authcode: authUser.authcode,
      };

      const promise = dispatch(fetchData({ scheduleData: scheduleData }));
      promise.catch(error => {
        if (isActive) {
          setEventsFetched(false);
          setEventsFetching(false);
        }
      });
    } else {
      if (isActive) {
        if (eventsFetching) setEventsFetching(false);
      }
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, eventsFetching, eventsFetched]);

  const handleCloseEventDialog = () => {
    setOpenEventDialog(false);
    setSelectedEvent(null);
  };

  const handleSelectedEvent = event => {
    setSelectedEvent(event);
    setOpenEventDialog(true);
  };

  const today = new Date();
  const localizer = momentLocalizer(moment);

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {!eventsFetching && eventsFetched && (
              <Calendar
                popup
                localizer={localizer}
                events={events}
                defaultDate={today}
                startAccessor="start"
                endAccessor="end"
                tooltipAccessor={null}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={e => handleSelectedEvent(e)}
                components={{
                  event: Event,
                }}
              />
            )}
            {!eventsFetching && !eventsFetched && <Alert severity="error">Unable to fetch calendar data!</Alert>}
          </Paper>
        </Grid>
      </GridContainer>
      {openEventDialog && <EventDescription event={selectedEvent} open={openEventDialog} onClose={handleCloseEventDialog} />}
    </PageContainer>
  );
};

export default Schedule;

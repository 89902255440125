import { ADD_JOB_FILE, DELETE_JOB_FILE, GET_JOB_FILES, SET_JOB_FILE_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { JOB_FILE_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  jobFiles: [],
  currentJobFile: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_FILES: {
      return {
        ...state,
        jobFiles: action.payload,
      };
    }
    case SET_JOB_FILE_DETAILS: {
      return {
        ...state,
        currentJobFile: action.payload,
      };
    }
    case ADD_JOB_FILE: {
      return {
        ...state,
        jobFiles: [action.payload, ...state.jobFiles],
      };
    }
    case DELETE_JOB_FILE: {
      return {
        ...state,
        jobFiles: state.jobFiles.filter(jobFile => jobFile[JOB_FILE_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

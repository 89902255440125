import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { ROLE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { getRoles, setCurrentRole, deleteRole } from '../../../redux/actions/Roles';

import AddRoleDialog from './AddRoleDialog';
import RolesTableToolbar from './RolesTableToolbar';
import RoleTableHead from './RoleTableHead';
import RolesListRow from './RolesListRow';
import EditRoleDialog from './EditRoleDialog';
import Alert from '../../UI/Alert';
import NoRecordFound from '../../NoRecordFound';

import useStyles from './index.style';

const pageName = 'Roles';
const breadcrumbs = [
  { label: 'Settings', link: '/settings' },
  { label: pageName, isActive: true },
];

const Roles = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { roles } = useSelector(({ rolesReducer }) => rolesReducer);

  const [filteredRoles, setFilteredRoles] = useState([]);
  const [orderBy, setOrderBy] = useState(ROLE_KEYS.NAME);
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rolesFetched, setRolesFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [openEditRoleDialog, setOpenEditRoleDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState({ name: '' });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(
          getRoles(payload, () => {
            if (isActive) setRolesFetched(true);
          }),
        );
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise.catch(error => {
      /* Setting to 'true' means API has been executed, not necessarily successfully */
      if (isActive) setRolesFetched(true);
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    let tempFilteredRoles = roles;

    if (debouncedSearchTerm) {
      tempFilteredRoles = tempFilteredRoles.filter(role => {
        return role[ROLE_KEYS.NAME] && role[ROLE_KEYS.NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase());
      });
    }

    setFilteredRoles(tempFilteredRoles);
    setFilterApplied(!!debouncedSearchTerm);
  }, [roles, debouncedSearchTerm]);

  const handleOpenRoleDialog = () => {
    setOpenRoleDialog(true);
  };

  const handleCloseRoleDialog = () => {
    setOpenRoleDialog(false);
  };

  const handleCloseEditRoleDialog = () => {
    setOpenEditRoleDialog(false);
    dispatch(setCurrentRole(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleRowEdit = role => {
    dispatch(setCurrentRole(role));
    setOpenEditRoleDialog(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRoleDelete = role => {
    setSelectedRole(role);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    if (selectedRole && selectedRole[ROLE_KEYS.ID]) {
      const roleData = {
        authcode: authUser.authcode,
        [ROLE_KEYS.ROLE_ID]: selectedRole[ROLE_KEYS.ID],
      };
      dispatch(
        deleteRole(
          { roleData: roleData },
          () => {
            if (_isMounted.current) setSelectedRole(null);
          },
          () => {
            if (_isMounted.current) setSelectedRole(null);
          },
        ),
      );
    } else {
      if (_isMounted.current) {
        setSelectedRole(null);
        Alert({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid role',
        });
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelectedRole(null);
  };

  const headCells = [
    {
      id: ROLE_KEYS.NAME,
      label: 'Role',
      width: '85%',
    },
  ];

  /* Add 1 because there is an 'actions' column */
  const colspan = headCells && headCells.length > 0 ? headCells.length + 1 : 1;

  return (
    <PageContainer heading="Roles & Restrictions" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12} xl={12} className={classes.orderLg1}>
          <AddRoleDialog open={openRoleDialog} onCloseDialog={handleCloseRoleDialog} />
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenRoleDialog}>
            Add New Role
          </Button>
        </Grid>
        <Grid item xs={12} xl={12} className={classes.orderLg2}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <RolesTableToolbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} aria-labelledby={pageName} aria-label={pageName}>
                  <RoleTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {!!filteredRoles.length ? (
                      stableSort(filteredRoles, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <RolesListRow key={index} row={row} onRoleEdit={handleRowEdit} onRoleDelete={handleRoleDelete} />
                        ))
                    ) : (
                      <TableRow style={{ height: 53 * 6 }}>
                        <TableCell colSpan={colspan} rowSpan={10}>
                          {isFilterApplied ? (
                            <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                          ) : (
                            <NoRecordFound>
                              {rolesFetched ? 'There are no records found.' : 'Loading roles...'}
                            </NoRecordFound>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={filteredRoles.length}
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </div>
        </Grid>
      </GridContainer>

      {openEditRoleDialog && <EditRoleDialog open={openEditRoleDialog} onClose={handleCloseEditRoleDialog} />}

      {selectedRole && (
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Delete Role ${selectedRole[ROLE_KEYS.NAME]}?`}
          content={'This action cannot be undone.'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </PageContainer>
  );
};

export default Roles;

import {
  ADD_ESTIMATE,
  DELETE_ESTIMATE,
  EDIT_ESTIMATE,
  GET_ESTIMATES,
  SET_ESTIMATE_DETAILS,
  SET_ESTIMATE_CUSTOMER_DETAILS,
  GET_ESTIMATE_COUNTS,
} from '../../@jumbo/constants/ActionTypes';
import { ESTIMATE_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  estimates: [],
  estimateCounts: { [ESTIMATE_KEYS.TOTAL]: '0' },
  currentEstimate: null,
  currentEstimateCustomer: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTIMATES: {
      return {
        ...state,
        estimates: action.payload,
      };
    }
    case GET_ESTIMATE_COUNTS: {
      return {
        ...state,
        estimateCounts: action.payload,
      };
    }
    case SET_ESTIMATE_DETAILS: {
      return {
        ...state,
        currentEstimate: action.payload,
      };
    }
    case SET_ESTIMATE_CUSTOMER_DETAILS: {
      return {
        ...state,
        currentEstimateCustomer: action.payload,
      };
    }
    case ADD_ESTIMATE: {
      return {
        ...state,
        estimates: [action.payload, ...state.estimates],
      };
    }
    case EDIT_ESTIMATE: {
      return {
        ...state,
        estimates: state.estimates.map(estimate =>
          estimate[ESTIMATE_KEYS.ID] === action.payload[ESTIMATE_KEYS.ID] ? action.payload : estimate,
        ),
      };
    }
    case DELETE_ESTIMATE: {
      return {
        ...state,
        estimates: state.estimates.filter(estimate => estimate[ESTIMATE_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles, lighten } from '@material-ui/core/styles';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { AppName } from '../../@jumbo/constants/AppConstants';

import { permitSettingsTabAccess } from '../../utils/AccessCheck';

import settingsSectionsList from './SettingsSectionsList';
import SettingsSection from './SettingsSection';

const useStyles = makeStyles(theme => ({
  paper: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const pageName = 'Settings';
const breadcrumbs = [{ label: pageName, isActive: true }];

const Settings = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [value, setValue] = useState(0);

  const filteredSectionsList = settingsSectionsList.filter(section => permitSettingsTabAccess(section.id, authUser));

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12} xl={12}>
          <Paper className={classes.paper}>
            <Tabs value={value} variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="Settings tabs">
              {filteredSectionsList.map((section, idx) => (
                <Tab key={section.id} label={section.sectionName} {...a11yProps(idx)} />
              ))}
            </Tabs>
            <Divider />
            {filteredSectionsList.map((section, idx) => (
              <TabPanel value={value} index={idx} key={section.id} section={section}>
                <SettingsSection key={section.id} section={section} />
              </TabPanel>
            ))}
          </Paper>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Settings;

import React from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { SQUAD_USER_KEYS, /* METRO_AREA_KEYS, */ JOB_TYPE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.black,
  },
  titleRoot2: {
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
  },
  tableRow: {
    cursor: 'pointer',
  },
  created: {
    whiteSpace: 'nowrap',
  },
}));

const SquadListRow = ({ row, onRowClick }) => {
  const classes = useStyles();
  /* const { metroAreas } = useSelector(({ metroAreasReducer }) => metroAreasReducer); */
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);

  /* const userMetroAreaIds = row[SQUAD_USER_KEYS.AREAS] ? row[SQUAD_USER_KEYS.AREAS] : '';
  let userMetroAreaList = [];
  if (userMetroAreaIds) {
    userMetroAreaList = userMetroAreaIds.split(',').map(metroAreaId => {
      const selectedMetroArea = metroAreas.find(metroArea => metroArea[METRO_AREA_KEYS.ID] === metroAreaId);
      return selectedMetroArea ? selectedMetroArea[METRO_AREA_KEYS.AREA_NAME] : '';
    });
  } */

  const userSkillIds = row[SQUAD_USER_KEYS.SKILLS] ? row[SQUAD_USER_KEYS.SKILLS] : '';
  let userSkillsList = [];
  if (userSkillIds) {
    userSkillsList = userSkillIds.split(',').map(skillId => {
      const selectedSkill = jobTypes.find(jobType => jobType[JOB_TYPE_KEYS.ID] === skillId);
      return selectedSkill ? selectedSkill[JOB_TYPE_KEYS.NAME] : '';
    });
  }

  const labelId = `enhanced-table-checkbox-${row[SQUAD_USER_KEYS.ID]}`;

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row[SQUAD_USER_KEYS.ID])}
      tabIndex={-1}
      key={row[SQUAD_USER_KEYS.ID]}
      classes={{
        hover: classes.tableRow,
      }}>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" flexDirection="column">
          <Typography className={classes.titleRoot} component="div" variant="h4">
            {row[SQUAD_USER_KEYS.NAME]}
          </Typography>
          <Typography className={classes.titleRoot2} component="div" variant="h5">
            {row[SQUAD_USER_KEYS.EMAIL]}
          </Typography>
        </Box>
      </TableCell>
      <TableCell onClick={event => event.stopPropagation()}>
        <a href={`tel:${row[SQUAD_USER_KEYS.PHONE]}`}>
          <NumberFormat isNumericString displayType="text" value={row[SQUAD_USER_KEYS.PHONE]} format="(###) ###-####" />
        </a>
      </TableCell>
      <TableCell>{row[SQUAD_USER_KEYS.ROLE_NAME]}</TableCell>
      <TableCell>{row[SQUAD_USER_KEYS.FIELD_TECH] === '1' ? 'Yes' : 'No'}</TableCell>
      <TableCell>{row[SQUAD_USER_KEYS.TYPE_NAME]}</TableCell>
      <TableCell>
        <Typography className={classes.created}>{row[SQUAD_USER_KEYS.CREATED]}</Typography>
      </TableCell>
      <TableCell>{userSkillsList && userSkillsList.length > 0 ? userSkillsList.join(', ') : ''}</TableCell>
      {/* <TableCell>{userMetroAreaList && userMetroAreaList.length > 0 ? userMetroAreaList.join(', ') : ''}</TableCell> */}
    </TableRow>
  );
};

export default React.memo(SquadListRow);

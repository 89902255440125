import {
  ADD_JOB_TYPE,
  EDIT_JOB_TYPE,
  GET_JOB_TYPES,
  SET_JOB_TYPE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  jobTypes: [],
  currentJobType: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_TYPES: {
      return {
        ...state,
        jobTypes: action.payload,
      };
    }
    case SET_JOB_TYPE_DETAILS: {
      return {
        ...state,
        currentJobType: action.payload,
      };
    }
    case ADD_JOB_TYPE: {
      return {
        ...state,
        jobTypes: [action.payload, ...state.jobTypes],
      };
    }
    case EDIT_JOB_TYPE: {
      return {
        ...state,
        jobTypes: state.jobTypes.map(jobType => (jobType.id === action.payload.id ? action.payload : jobType)),
      };
    }
    default:
      return state;
  }
};

import React from 'react';

import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';
import CmtSearch from '../../../../@coremat/CmtSearch';

import useStyles from './index.style';

const RolesTableToolbar = ({ searchTerm, setSearchTerm }) => {
  const classes = useStyles();

  const onSearchChipDelete = () => setSearchTerm('');

  return (
    <React.Fragment>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          Roles
        </Typography>

        <React.Fragment>
          <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
          <div className={classes.chipsRoot}>{searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}</div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

RolesTableToolbar.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default React.memo(RolesTableToolbar);

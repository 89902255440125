import { GET_USER_TYPES } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  userTypes: [],
  /* currentuserType: null, */
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_TYPES: {
      return {
        ...state,
        userTypes: action.payload,
      };
    }
    default:
      return state;
  }
};

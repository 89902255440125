import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  /* ADD_INVOICE, DELETE_INVOICE, EDIT_INVOICE, */ GET_INVOICES,
  SET_INVOICE_DETAILS,
  SET_INVOICE_CUSTOMER_DETAILS,
  /* GET_INVOICE_PRODUCTS,
  GET_INVOICE_PRODUCTS_TOTALS, */
  GET_JOB_JOB_TYPES,
  GET_JOB_JOB_TYPES_TOTALS,
  GET_INVOICE_COUNTS,
  GET_INVOICE_SIGNATURES,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_INVOICES_LINK,
  GET_INVOICE_LINK,
  ADD_INVOICE_LINK /* UPDATE_INVOICE_LINK, */,
  UPDATE_INVOICE_NOTE_LINK,
  PREVIEW_INVOICE_LINK,
  MARK_INVOICE_SENT_LINK,
  SEND_INVOICE_LINK,
  DOWNLOAD_INVOICE_LINK,
  DELETE_INVOICE_LINK,
  GET_INVOICE_FOR_CUSTOMER_LINK,
  PREVIEW_INVOICE_FOR_CUSTOMER_LINK,
  DOWNLOAD_INVOICE_FOR_CUSTOMER_LINK,
  PAY_INVOICE_FOR_CUSTOMER_LINK,
} from '../../@jumbo/constants/APIConstants';
import {
  EXPORT_LINK_KEY,
  INVOICE_KEYS,
  DISALLOW_CUSTOMER_TO_PAY_INVOICE,
  INVOICE_PAYMENT_COMPLETE,
  NOT_REQUEST_CUSTOMER_SIGNATURE,
} from '../../@jumbo/constants/AppDataConstants';

export const getFilteredInvoices = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_INVOICES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.invoices) {
              const invoices = data.invoices;

              const counts = data.counts ? data.counts : null;
              let totals = null;
              if (counts) {
                totals = {
                  [INVOICE_KEYS.TOTAL]: counts[INVOICE_KEYS.TOTAL] ? counts[INVOICE_KEYS.TOTAL] : '0',
                };
              } else {
                totals = {
                  [INVOICE_KEYS.TOTAL]: '0',
                };
              }

              const exportLink = data[EXPORT_LINK_KEY] ? data[EXPORT_LINK_KEY] : null;

              dispatch(fetchSuccess());
              dispatch({ type: GET_INVOICES, payload: invoices });
              dispatch({ type: GET_INVOICE_COUNTS, payload: totals });
              if (callbackFun) callbackFun(invoices, exportLink);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: GET_INVOICES, payload: [] });
        dispatch({
          type: GET_INVOICE_COUNTS,
          payload: {
            [INVOICE_KEYS.TOTAL]: '0',
          },
        });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const addNewInvoice = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_INVOICE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.invoice && data.invoice.length > 0) {
              const invoice = data.invoice[0];
              dispatch(fetchSuccess('Invoice was added successfully.'));
              /* dispatch({ type: ADD_INVOICE, payload: invoice }); */
              if (callbackFun) callbackFun(invoice);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getInvoice = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_INVOICE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (/* data.invoice */ data[INVOICE_KEYS.ID]) {
              /* const invoice = data.invoice; */
              const invoice = {
                [INVOICE_KEYS.ID]: data[INVOICE_KEYS.ID],
                [INVOICE_KEYS.JOB_ID]: data[INVOICE_KEYS.JOB_ID],
                [INVOICE_KEYS.CUSTOMER_ID]: data[INVOICE_KEYS.CUSTOMER_ID],
                [INVOICE_KEYS.NOTE]: data[INVOICE_KEYS.NOTE],
                [INVOICE_KEYS.STATUS]: data[INVOICE_KEYS.STATUS_ID],
                [INVOICE_KEYS.FORMATTED_CREATED]: data[INVOICE_KEYS.CREATED_AT],
                [INVOICE_KEYS.ALLOW_PAYMENT]: data[INVOICE_KEYS.ALLOW_PAYMENT]
                  ? data[INVOICE_KEYS.ALLOW_PAYMENT]
                  : DISALLOW_CUSTOMER_TO_PAY_INVOICE,
                [INVOICE_KEYS.PAYMENT_COMPLETE]: data[INVOICE_KEYS.PAYMENT_COMPLETE]
                  ? data[INVOICE_KEYS.PAYMENT_COMPLETE]
                  : INVOICE_PAYMENT_COMPLETE,
              };

              let invoiceCustomer = null;
              if (data.client) {
                const customer = data.client;
                const serviceAddress = customer[INVOICE_KEYS.CUSTOMER_SERVICE];
                const billAddress = customer[INVOICE_KEYS.CUSTOMER_BILL];

                invoiceCustomer = {
                  [INVOICE_KEYS.CUSTOMER_BILL]: {
                    [INVOICE_KEYS.CUSTOMER_NAME]: customer[INVOICE_KEYS.CUSTOMER_NAME],
                    [INVOICE_KEYS.CUSTOMER_COMPANY]: customer[INVOICE_KEYS.CUSTOMER_COMPANY],
                    [INVOICE_KEYS.CUSTOMER_EMAIL]: customer[INVOICE_KEYS.CUSTOMER_EMAIL],
                    [INVOICE_KEYS.CUSTOMER_PHONE_ONE]: customer[INVOICE_KEYS.CUSTOMER_PHONE_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]: billAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]: billAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [INVOICE_KEYS.CUSTOMER_CITY]: billAddress[INVOICE_KEYS.CUSTOMER_CITY],
                    [INVOICE_KEYS.CUSTOMER_STATE]: billAddress[INVOICE_KEYS.CUSTOMER_STATE],
                    [INVOICE_KEYS.CUSTOMER_ZIP_CODE]: billAddress[INVOICE_KEYS.CUSTOMER_ZIP_CODE],
                    [INVOICE_KEYS.CUSTOMER_COUNTRY]: billAddress[INVOICE_KEYS.CUSTOMER_COUNTRY],
                  },
                  [INVOICE_KEYS.CUSTOMER_SERVICE]: {
                    [INVOICE_KEYS.CUSTOMER_NAME]: serviceAddress[INVOICE_KEYS.CUSTOMER_NAME],
                    [INVOICE_KEYS.CUSTOMER_COMPANY]: serviceAddress[INVOICE_KEYS.CUSTOMER_COMPANY],
                    [INVOICE_KEYS.CUSTOMER_EMAIL]: serviceAddress[INVOICE_KEYS.CUSTOMER_EMAIL],
                    [INVOICE_KEYS.CUSTOMER_PHONE_ONE]: serviceAddress[INVOICE_KEYS.CUSTOMER_PHONE_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]: serviceAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]: serviceAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [INVOICE_KEYS.CUSTOMER_CITY]: serviceAddress[INVOICE_KEYS.CUSTOMER_CITY],
                    [INVOICE_KEYS.CUSTOMER_STATE]: serviceAddress[INVOICE_KEYS.CUSTOMER_STATE],
                    [INVOICE_KEYS.CUSTOMER_ZIP_CODE]: serviceAddress[INVOICE_KEYS.CUSTOMER_ZIP_CODE],
                    [INVOICE_KEYS.CUSTOMER_COUNTRY]: serviceAddress[INVOICE_KEYS.CUSTOMER_COUNTRY],
                  },
                };
              }

              dispatch(fetchSuccess());
              dispatch({ type: SET_INVOICE_DETAILS, payload: invoice });
              dispatch({ type: SET_INVOICE_CUSTOMER_DETAILS, payload: invoiceCustomer });
              if (data.jobitems) {
                /* dispatch({ type: GET_INVOICE_PRODUCTS, payload: data.jobitems }); */
                dispatch({ type: GET_JOB_JOB_TYPES, payload: data.jobitems });
              } else {
                /* dispatch({ type: GET_INVOICE_PRODUCTS, payload: [] }); */
                dispatch({ type: GET_JOB_JOB_TYPES, payload: [] });
              }
              if (data.total) {
                /* dispatch({ type: GET_INVOICE_PRODUCTS_TOTALS, payload: data.total }); */
                dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: data.total });
              } else {
                /* dispatch({ type: GET_INVOICE_PRODUCTS_TOTALS, payload: null }); */
                dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: null });
              }
              if (data.signature) {
                dispatch({ type: GET_INVOICE_SIGNATURES, payload: data.signature });
              } else {
                dispatch({ type: GET_INVOICE_SIGNATURES, payload: [] });
              }

              if (callbackFun) callbackFun(invoice);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: SET_INVOICE_DETAILS, payload: null });
        dispatch({ type: SET_INVOICE_CUSTOMER_DETAILS, payload: null });
        /* dispatch({ type: GET_INVOICE_PRODUCTS, payload: [] });
        dispatch({ type: GET_INVOICE_PRODUCTS_TOTALS, payload: null }); */
        dispatch({ type: GET_JOB_JOB_TYPES, payload: [] });
        dispatch({ type: GET_JOB_JOB_TYPES_TOTALS, payload: null });
        dispatch({ type: GET_INVOICE_SIGNATURES, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentInvoice = invoice => {
  return dispatch => {
    dispatch({ type: SET_INVOICE_DETAILS, payload: invoice });
  };
};

export const updateInvoiceNote = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_INVOICE_NOTE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.invoice && data.invoice.length > 0) {
              const invoiceData = data.invoice[0];
              const invoice = {
                [INVOICE_KEYS.ID]: invoiceData[INVOICE_KEYS.ID],
                [INVOICE_KEYS.JOB_ID]: invoiceData[INVOICE_KEYS.JOB_ID],
                [INVOICE_KEYS.CUSTOMER_ID]: invoiceData[INVOICE_KEYS.CUSTOMER_ID],
                [INVOICE_KEYS.NOTE]: invoiceData[INVOICE_KEYS.NOTE],
                [INVOICE_KEYS.STATUS]: invoiceData[INVOICE_KEYS.STATUS_ID],
                [INVOICE_KEYS.FORMATTED_CREATED]: invoiceData[INVOICE_KEYS.CREATED_AT],
                [INVOICE_KEYS.ALLOW_PAYMENT]: invoiceData[INVOICE_KEYS.ALLOW_PAYMENT]
                  ? invoiceData[INVOICE_KEYS.ALLOW_PAYMENT]
                  : DISALLOW_CUSTOMER_TO_PAY_INVOICE,
                [INVOICE_KEYS.PAYMENT_COMPLETE]: invoiceData[INVOICE_KEYS.PAYMENT_COMPLETE]
                  ? invoiceData[INVOICE_KEYS.PAYMENT_COMPLETE]
                  : INVOICE_PAYMENT_COMPLETE,
              };
              dispatch(fetchSuccess('Note was updated successfully.'));
              dispatch({ type: SET_INVOICE_DETAILS, payload: invoice });
              if (callbackFun) callbackFun(invoice);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const previewInvoice = (request, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(PREVIEW_INVOICE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const downloadInvoice = (request, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DOWNLOAD_INVOICE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const markInvoiceSent = (request, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(MARK_INVOICE_SENT_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Invoice was marked sent successfully.'));
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sendInvoice = (request, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(SEND_INVOICE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.invoice && data.invoice.length > 0) {
              const invoiceData = data.invoice[0];
              const invoice = {
                [INVOICE_KEYS.ID]: invoiceData[INVOICE_KEYS.ID],
                [INVOICE_KEYS.JOB_ID]: invoiceData[INVOICE_KEYS.JOB_ID],
                [INVOICE_KEYS.CUSTOMER_ID]: invoiceData[INVOICE_KEYS.CUSTOMER_ID],
                [INVOICE_KEYS.NOTE]: invoiceData[INVOICE_KEYS.NOTE],
                [INVOICE_KEYS.STATUS]: invoiceData[INVOICE_KEYS.STATUS],
                [INVOICE_KEYS.FORMATTED_CREATED]: invoiceData[INVOICE_KEYS.FORMATTED_CREATED],
                [INVOICE_KEYS.ALLOW_PAYMENT]: invoiceData[INVOICE_KEYS.ALLOW_PAYMENT]
                  ? invoiceData[INVOICE_KEYS.ALLOW_PAYMENT]
                  : DISALLOW_CUSTOMER_TO_PAY_INVOICE,
                [INVOICE_KEYS.PAYMENT_COMPLETE]: invoiceData[INVOICE_KEYS.PAYMENT_COMPLETE]
                  ? invoiceData[INVOICE_KEYS.PAYMENT_COMPLETE]
                  : INVOICE_PAYMENT_COMPLETE,
              };

              dispatch(fetchSuccess('Invoice was sent successfully.'));
              dispatch({ type: SET_INVOICE_DETAILS, payload: invoice });
              if (callbackFun) callbackFun(invoice);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteInvoice = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_INVOICE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected invoice was deleted successfully.'));
            /* dispatch({ type: DELETE_INVOICE, payload: request.invoiceData[INVOICE_KEYS.INVOICE_ID] }); */
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getInvoiceForCustomer = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_INVOICE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (/* data.invoice */ data[INVOICE_KEYS.ID]) {
              /* const invoice = data.invoice; */
              const invoice = {
                [INVOICE_KEYS.ID]: data[INVOICE_KEYS.ID],
                [INVOICE_KEYS.JOB_ID]: data[INVOICE_KEYS.JOB_ID],
                [INVOICE_KEYS.CUSTOMER_ID]: data[INVOICE_KEYS.CUSTOMER_ID],
                [INVOICE_KEYS.NOTE]: data[INVOICE_KEYS.NOTE],
                [INVOICE_KEYS.STATUS]: data[INVOICE_KEYS.STATUS_ID],
                [INVOICE_KEYS.FORMATTED_CREATED]: data[INVOICE_KEYS.CREATED_AT],
                [INVOICE_KEYS.ALLOW_PAYMENT]: data[INVOICE_KEYS.ALLOW_PAYMENT]
                  ? data[INVOICE_KEYS.ALLOW_PAYMENT]
                  : DISALLOW_CUSTOMER_TO_PAY_INVOICE,
                [INVOICE_KEYS.PAYMENT_COMPLETE]: data[INVOICE_KEYS.PAYMENT_COMPLETE]
                  ? data[INVOICE_KEYS.PAYMENT_COMPLETE]
                  : INVOICE_PAYMENT_COMPLETE,
                /* [INVOICE_KEYS.REQUEST_SIGNATURE]: data[INVOICE_KEYS.REQUEST_SIGNATURE]
                  ? data[INVOICE_KEYS.REQUEST_SIGNATURE]
                  : NOT_REQUEST_CUSTOMER_SIGNATURE, */
              };

              const requestSignature = data[INVOICE_KEYS.REQUEST_SIGNATURE]
                ? data[INVOICE_KEYS.REQUEST_SIGNATURE]
                : NOT_REQUEST_CUSTOMER_SIGNATURE;

              let invoiceCustomer = null;
              if (data.client) {
                const customer = data.client;
                const serviceAddress = customer[INVOICE_KEYS.CUSTOMER_SERVICE];
                const billAddress = customer[INVOICE_KEYS.CUSTOMER_BILL];

                invoiceCustomer = {
                  [INVOICE_KEYS.CUSTOMER_BILL]: {
                    [INVOICE_KEYS.CUSTOMER_NAME]: customer[INVOICE_KEYS.CUSTOMER_NAME],
                    [INVOICE_KEYS.CUSTOMER_COMPANY]: customer[INVOICE_KEYS.CUSTOMER_COMPANY],
                    [INVOICE_KEYS.CUSTOMER_EMAIL]: customer[INVOICE_KEYS.CUSTOMER_EMAIL],
                    [INVOICE_KEYS.CUSTOMER_PHONE_ONE]: customer[INVOICE_KEYS.CUSTOMER_PHONE_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]: billAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]: billAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [INVOICE_KEYS.CUSTOMER_CITY]: billAddress[INVOICE_KEYS.CUSTOMER_CITY],
                    [INVOICE_KEYS.CUSTOMER_STATE]: billAddress[INVOICE_KEYS.CUSTOMER_STATE],
                    [INVOICE_KEYS.CUSTOMER_ZIP_CODE]: billAddress[INVOICE_KEYS.CUSTOMER_ZIP_CODE],
                    [INVOICE_KEYS.CUSTOMER_COUNTRY]: billAddress[INVOICE_KEYS.CUSTOMER_COUNTRY],
                  },
                  [INVOICE_KEYS.CUSTOMER_SERVICE]: {
                    [INVOICE_KEYS.CUSTOMER_NAME]: serviceAddress[INVOICE_KEYS.CUSTOMER_NAME],
                    [INVOICE_KEYS.CUSTOMER_COMPANY]: serviceAddress[INVOICE_KEYS.CUSTOMER_COMPANY],
                    [INVOICE_KEYS.CUSTOMER_EMAIL]: serviceAddress[INVOICE_KEYS.CUSTOMER_EMAIL],
                    [INVOICE_KEYS.CUSTOMER_PHONE_ONE]: serviceAddress[INVOICE_KEYS.CUSTOMER_PHONE_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_ONE]: serviceAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_ONE],
                    [INVOICE_KEYS.CUSTOMER_ADDRESS_TWO]: serviceAddress[INVOICE_KEYS.CUSTOMER_ADDRESS_TWO],
                    [INVOICE_KEYS.CUSTOMER_CITY]: serviceAddress[INVOICE_KEYS.CUSTOMER_CITY],
                    [INVOICE_KEYS.CUSTOMER_STATE]: serviceAddress[INVOICE_KEYS.CUSTOMER_STATE],
                    [INVOICE_KEYS.CUSTOMER_ZIP_CODE]: serviceAddress[INVOICE_KEYS.CUSTOMER_ZIP_CODE],
                    [INVOICE_KEYS.CUSTOMER_COUNTRY]: serviceAddress[INVOICE_KEYS.CUSTOMER_COUNTRY],
                  },
                };
              }

              const invoiceJobItems = data.jobitems ? data.jobitems : [];
              const invoiceTotals = data.total ? data.total : null;
              const invoiceSignatures = data.signature ? data.signature : [];

              const invoiceData = {
                invoice: invoice,
                customer: invoiceCustomer,
                products: invoiceJobItems,
                totals: invoiceTotals,
                signatures: invoiceSignatures,
                requestSignature: requestSignature,
              };

              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(invoiceData);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const previewInvoiceForCustomer = (request, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(PREVIEW_INVOICE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const downloadInvoiceForCustomer = (request, callbackFun) => {
  return dispatch => {
    const body = request.invoiceData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DOWNLOAD_INVOICE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.link) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data.link);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const payInvoiceForCustomer = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.paymentData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(PAY_INVOICE_FOR_CUSTOMER_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data[INVOICE_KEYS.TRANSACTION_NO]) {
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(data[INVOICE_KEYS.TRANSACTION_NO]);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
/* import BuildIcon from '@material-ui/icons/Build';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ForumIcon from '@material-ui/icons/Forum'; */
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, lighten } from '@material-ui/core/styles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { CUSTOMER_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import { customerNotValid } from '../../../@jumbo/constants/ErrorMessages';

import { getCustomer, deleteCustomer, setCurrentCustomer } from '../../../redux/actions/Customers';
import { getCustomerNotes } from '../../../redux/actions/CustomerNotes';
import { getCustomerProperties } from '../../../redux/actions/CustomerProperties';
import { getUSAStates } from '../../../redux/actions/USAStates';
import { getCountries } from '../../../redux/actions/Countries';
import { getAdSources } from '../../../redux/actions/AdSources';
/* import { getSquadUsers } from '../../../redux/actions/Squad'; */

import HeaderCard from '../../UI/HeaderCard';
import SweetAlert from '../../UI/Alert';
import CustomerInfo from '../CustomerInfo';
import CustomerProperties from '../CustomerProperties';
import CustomerJobs from '../CustomerJobs';
/* import CustomerPayments from '../CustomerPayments'; */
import CustomerInvoices from '../CustomerInvoices';
import CustomerEstimates from '../CustomerEstimates';

const useStyles = makeStyles(theme => ({
  btnCustomerAction: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  accordionHeading: {
    ...theme.overrides.MuiTypography.h3,
  },
  btnDeleteCustomer: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const pageName = 'Edit Customer';
const breadcrumbs = [
  { label: 'Customers', link: '/customers' },
  { label: pageName, isActive: true },
];

const EditCustomer = () => {
  document.title = `${AppName} - ${pageName}`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const customerId = params.customerId;

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentCustomer } = useSelector(({ customersReducer }) => customersReducer);

  const [dataFetching, setDataFetching] = useState(true);
  const [customerInfoFetched, setCustomerInfoFetched] = useState(false);
  const [expandCustomerAccordion, setExpandCustomerAccordion] = useState(false);
  const [expandPropertiesAccordion, setExpandPropertiesAccordion] = useState(false);
  const [expandJobsAccordion, setExpandJobsAccordion] = useState(false);
  const [expandEstimatesAccordion, setExpandEstimatesAccordion] = useState(false);
  const [expandInvoicesAccordion, setExpandInvoicesAccordion] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      dispatch(setCurrentCustomer(null));
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;

    if (customerId) {
      const fetchData = (payload, customerPayload) => {
        return (dispatch, getState) => {
          /* return dispatch(getSquadUsers(payload)).then(() => { */
          return dispatch(getAdSources(payload)).then(() => {
            return dispatch(getCountries(payload)).then(() => {
              return dispatch(getUSAStates(payload)).then(() => {
                return dispatch(getCustomerNotes(customerPayload)).then(() => {
                  return dispatch(getCustomerProperties(customerPayload)).then(() => {
                    return dispatch(
                      getCustomer(customerPayload, () => {
                        if (isActive) {
                          setDataFetching(false);
                          setCustomerInfoFetched(true);
                          setExpandCustomerAccordion(true);
                        }
                      }),
                    );
                  });
                });
              });
            });
          });
          /* }); */
        };
      };

      const payloadOne = { authcode: authUser.authcode };
      const payloadTwo = {
        customerData: {
          authcode: authUser.authcode,
          [CUSTOMER_KEYS.CUSTOMER_ID]: customerId,
        },
      };

      const promise = dispatch(fetchData(payloadOne, payloadTwo));
      promise.catch(error => {
        if (isActive) {
          setDataFetching(false);
        }
      });
    } else {
      if (isActive) setDataFetching(false);
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, customerId]);

  const handleExpandCustomerAccordion = () => setExpandCustomerAccordion(prevState => !prevState);
  const handleExpandPropertiesAccordion = () => setExpandPropertiesAccordion(prevState => !prevState);
  const handleExpandJobsAccordion = () => setExpandJobsAccordion(prevState => !prevState);
  const handleExpandEstimatesAccordion = () => setExpandEstimatesAccordion(prevState => !prevState);
  const handleExpandInvoicesAccordion = () => setExpandInvoicesAccordion(prevState => !prevState);

  const handleCustomerDelete = () => {
    if (!customerId || !currentCustomer || currentCustomer[CUSTOMER_KEYS.ID] !== customerId) {
      SweetAlert({
        icon: 'error',
        title: 'Oops...',
        text: customerNotValid,
      });
      return false;
    }

    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);

    const customerData = {
      authcode: authUser.authcode,
      [CUSTOMER_KEYS.CUSTOMER_ID]: currentCustomer[CUSTOMER_KEYS.ID],
    };

    dispatch(
      deleteCustomer(
        { customerData: customerData },
        () => {
          if (_isMounted.current) history.push('/customers');
        },
        null,
      ),
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const headerCardsList = [
    /* {
      title: '23851.8',
      subtitle: 'Due',
      isAmount: true,
      classes: 'warningBorder',
    },
    {
      title: '23101.8',
      subtitle: 'Past Due',
      isAmount: true,
      classes: 'errorBorder',
    },
    {
      title: '0.00',
      subtitle: 'Total Revenue',
      isAmount: false,
      classes: 'primaryBorder',
    },
    {
      title: '0',
      subtitle: 'Estimates',
      isAmount: false,
      classes: 'primaryBorder',
    }, */
  ];

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      {!dataFetching && customerId && customerInfoFetched && (
        <GridContainer>
          {headerCardsList.map((headerCard, idx) => (
            <Grid item xs={12} sm={6} md={3} key={`${headerCard.subtitle}-${idx}`}>
              <HeaderCard
                titleIsAmount={headerCard.isAmount}
                title={headerCard.title}
                subtitle={headerCard.subtitle}
                cardClass={headerCard.classes}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" justifyContent={{ md: 'space-between' }} alignItems="center">
              {/* <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<BuildIcon />}
                className={classes.btnCustomerAction}>
                Create Job
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<ReceiptIcon />}
                className={classes.btnCustomerAction}>
                Create Invoice
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<AttachFileIcon />}
                className={classes.btnCustomerAction}>
                Create Estimate
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<ForumIcon />}
                className={classes.btnCustomerAction}>
                Send Message
              </Button> */}
              <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<DeleteIcon />}
                className={classes.btnCustomerAction}
                classes={{ outlinedPrimary: classes.btnDeleteCustomer }}
                onClick={handleCustomerDelete}>
                Delete Customer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <CustomerInfo
                classes="accordionHeading"
                expanded={expandCustomerAccordion}
                handleAccordionExpand={handleExpandCustomerAccordion}
              />
              <CustomerProperties
                classes="accordionHeading"
                expanded={expandPropertiesAccordion}
                handleAccordionExpand={handleExpandPropertiesAccordion}
              />
              <CustomerJobs
                classes="accordionHeading"
                customerId={customerId}
                expanded={expandJobsAccordion}
                handleAccordionExpand={handleExpandJobsAccordion}
              />
              {/* <CustomerPayments classes="accordionHeading" /> */}
              <CustomerInvoices
                classes="accordionHeading"
                customerId={customerId}
                expanded={expandInvoicesAccordion}
                handleAccordionExpand={handleExpandInvoicesAccordion}
              />
              <CustomerEstimates
                classes="accordionHeading"
                customerId={customerId}
                expanded={expandEstimatesAccordion}
                handleAccordionExpand={handleExpandEstimatesAccordion}
              />
            </Paper>
          </Grid>
        </GridContainer>
      )}
      {!dataFetching &&
        ((!customerId && (
          <GridContainer>
            <Grid item xs={12}>
              <Alert severity="error">{customerNotValid}</Alert>
            </Grid>
          </GridContainer>
        )) ||
          (!customerInfoFetched && (
            <GridContainer>
              <Grid item xs={12}>
                <Alert severity="error">Customer data not found</Alert>
              </Grid>
            </GridContainer>
          )))}

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Delete Customer?`}
          content={'This action cannot be undone.'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </PageContainer>
  );
};

export default EditCustomer;

import {
  ADD_JOB,
  DELETE_JOB,
  EDIT_JOB,
  GET_JOBS,
  GET_JOB_COUNTS,
  SET_JOB_DETAILS,
  GET_JOBS_FILTERS,
  ADD_JOB_ESTIMATE,
  GET_JOB_ESTIMATES,
  DELETE_JOB_ESTIMATE,
} from '../../@jumbo/constants/ActionTypes';
import { JOB_KEYS, ESTIMATE_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  jobs: [],
  jobCounts: { [JOB_KEYS.TOTAL]: '0' },
  jobEstimates: [],
  currentJob: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOBS: {
      return {
        ...state,
        jobs: action.payload,
      };
    }
    case GET_JOB_COUNTS: {
      return {
        ...state,
        jobCounts: action.payload,
      };
    }
    case GET_JOBS_FILTERS: {
      return {
        ...state,
        filterGroups: action.payload,
      };
    }
    case SET_JOB_DETAILS: {
      return {
        ...state,
        currentJob: action.payload,
      };
    }
    case ADD_JOB: {
      return {
        ...state,
        jobs: [action.payload, ...state.jobs],
      };
    }
    case EDIT_JOB: {
      return {
        ...state,
        jobs: state.jobs.map(job => (job[JOB_KEYS.ID] === action.payload[JOB_KEYS.ID] ? action.payload : job)),
      };
    }
    case DELETE_JOB: {
      return {
        ...state,
        jobs: state.jobs.filter(job => job[JOB_KEYS.ID] !== action.payload),
      };
    }
    case GET_JOB_ESTIMATES: {
      return {
        ...state,
        jobEstimates: action.payload,
      };
    }
    case ADD_JOB_ESTIMATE: {
      return {
        ...state,
        jobEstimates: [action.payload, ...state.jobEstimates],
      };
    }
    case DELETE_JOB_ESTIMATE: {
      return {
        ...state,
        jobEstimates: state.jobEstimates.filter(jobEstimate => jobEstimate[ESTIMATE_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

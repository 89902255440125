export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';

// Roles Module
export const GET_ROLES = 'GET_ROLES';
export const ADD_ROLE = 'ADD_ROLE';
export const SET_ROLE_DETAILS = 'SET_ROLE_DETAILS';
export const EDIT_ROLE = 'EDIT_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

// Job Types Module
export const GET_JOB_TYPES = 'GET_JOB_TYPES';
export const ADD_JOB_TYPE = 'ADD_JOB_TYPE';
export const SET_JOB_TYPE_DETAILS = 'SET_JOB_TYPE_DETAILS';
export const EDIT_JOB_TYPE = 'EDIT_JOB_TYPE';

// Taxes Module
export const GET_TAXES = 'GET_TAXES';
export const ADD_TAX = 'ADD_TAX';
export const SET_TAX_DETAILS = 'SET_TAX_DETAILS';
export const EDIT_TAX = 'EDIT_TAX';

// Products Module
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';

// Ad Sources Module
export const GET_AD_SOURCES = 'GET_AD_SOURCES';
export const ADD_AD_SOURCE = 'ADD_AD_SOURCE';
export const SET_AD_SOURCE_DETAILS = 'SET_AD_SOURCE_DETAILS';
export const EDIT_AD_SOURCE = 'EDIT_AD_SOURCE';
export const DELETE_AD_SOURCE = 'DELETE_AD_SOURCE';

// Metro Areas Module
export const GET_METRO_AREAS = 'GET_METRO_AREAS';
export const ADD_METRO_AREA = 'ADD_METRO_AREA';
export const SET_METRO_AREA_DETAILS = 'SET_METRO_AREA_DETAILS';
export const EDIT_METRO_AREA = 'EDIT_METRO_AREA';
export const DELETE_METRO_AREA = 'DELETE_METRO_AREA';

// Squad Users Module
export const GET_SQUAD_USERS = 'GET_SQUAD_USERS';
export const GET_SQUAD_USERS_FILTERS = 'GET_SQUAD_USERS_FILTERS';
export const ADD_SQUAD_USER = 'ADD_SQUAD_USER';
export const SET_SQUAD_USER_DETAILS = 'SET_SQUAD_USER_DETAILS';
export const SET_SQUAD_USER_INVITE_DETAILS = 'SET_SQUAD_USER_INVITE_DETAILS';
export const EDIT_SQUAD_USER = 'EDIT_SQUAD_USER';
export const DELETE_SQUAD_USER = 'DELETE_SQUAD_USER';

// User Types Module
export const GET_USER_TYPES = 'GET_USER_TYPES';

// Colors Module
export const GET_COLORS = 'GET_COLORS';

// Jobs Module
export const GET_JOBS = 'GET_JOBS';
export const GET_JOB_COUNTS = 'GET_JOB_COUNTS';
export const GET_JOB = 'GET_JOB';
export const ADD_JOB = 'ADD_JOB';
export const SET_JOB_DETAILS = 'SET_JOB_DETAILS';
export const EDIT_JOB = 'EDIT_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const GET_JOB_STATUSES = 'GET_JOB_STATUSES';
export const GET_JOBS_FILTERS = 'GET_JOBS_FILTERS';

// Job Products Module
export const GET_JOB_PRODUCTS = 'GET_JOB_PRODUCTS';
export const GET_JOB_PRODUCTS_TOTALS = 'GET_JOB_PRODUCTS_TOTALS';
export const ADD_JOB_PRODUCT = 'ADD_JOB_PRODUCT';
export const SET_JOB_PRODUCT_DETAILS = 'SET_JOB_PRODUCT_DETAILS';
export const EDIT_JOB_PRODUCT = 'EDIT_JOB_PRODUCT';
export const DELETE_JOB_PRODUCT = 'DELETE_JOB_PRODUCT';

// Job 'Job Types' Module
export const GET_JOB_JOB_TYPES = 'GET_JOB_JOB_TYPES';
export const GET_JOB_JOB_TYPES_TOTALS = 'GET_JOB_JOB_TYPES_TOTALS';
export const ADD_JOB_JOB_TYPE = 'ADD_JOB_JOB_TYPE';
export const SET_JOB_JOB_TYPE_DETAILS = 'SET_JOB_JOB_TYPE_DETAILS';
export const EDIT_JOB_JOB_TYPE = 'EDIT_JOB_JOB_TYPE';
export const DELETE_JOB_JOB_TYPE = 'DELETE_JOB_JOB_TYPE';

// Job Payments Module
export const GET_JOB_PAYMENTS = 'GET_JOB_PAYMENTS';
export const GET_JOB_PAYMENTS_BALANCE = 'GET_JOB_PAYMENTS_BALANCE';
export const ADD_JOB_PAYMENT = 'ADD_JOB_PAYMENT';
export const DELETE_JOB_PAYMENT = 'DELETE_JOB_PAYMENT';

// Job Estimates Module
export const GET_JOB_ESTIMATES = 'GET_JOB_ESTIMATES';
export const ADD_JOB_ESTIMATE = 'ADD_JOB_ESTIMATE';
export const DELETE_JOB_ESTIMATE = 'DELETE_JOB_ESTIMATE';

// Job Files Module
export const GET_JOB_FILES = 'GET_JOB_FILES';
export const ADD_JOB_FILE = 'ADD_JOB_FILE';
export const SET_JOB_FILE_DETAILS = 'SET_JOB_FILE_DETAILS';
export const DELETE_JOB_FILE = 'DELETE_JOB_FILE';

// Customers Module
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_DUES = 'GET_CUSTOMERS_DUES';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

// Customer Notes Module
export const GET_CUSTOMER_NOTES = 'GET_CUSTOMER_NOTES';
export const ADD_CUSTOMER_NOTE = 'ADD_CUSTOMER_NOTE';
export const SET_CUSTOMER_NOTE_DETAILS = 'SET_CUSTOMER_NOTE_DETAILS';
export const EDIT_CUSTOMER_NOTE = 'EDIT_CUSTOMER_NOTE';
export const DELETE_CUSTOMER_NOTE = 'DELETE_CUSTOMER_NOTE';

// Customer Properties Module
export const GET_CUSTOMER_PROPERTIES = 'GET_CUSTOMER_PROPERTIES';
export const ADD_CUSTOMER_PROPERTY = 'ADD_CUSTOMER_PROPERTY';
export const SET_CUSTOMER_PROPERTY_DETAILS = 'SET_CUSTOMER_PROPERTY_DETAILS';
export const EDIT_CUSTOMER_PROPERTY = 'EDIT_CUSTOMER_PROPERTY';
export const DELETE_CUSTOMER_PROPERTY = 'DELETE_CUSTOMER_PROPERTY';

// Countries Module
export const GET_COUNTRIES = 'GET_COUNTRIES';

// States Module
export const GET_USA_STATES = 'GET_USA_STATES';

// Invoices Module
export const GET_INVOICES = 'GET_INVOICES';
export const ADD_INVOICE = 'ADD_INVOICE';
export const SET_INVOICE_DETAILS = 'SET_INVOICE_DETAILS';
export const SET_INVOICE_CUSTOMER_DETAILS = 'SET_INVOICE_CUSTOMER_DETAILS';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_BULK_INVOICES = 'DELETE_BULK_INVOICES';
export const GET_INVOICE_STATUSES = 'GET_INVOICE_STATUSES';
export const GET_INVOICE_COUNTS = 'GET_INVOICE_COUNTS';

// Invoices Products Module
export const GET_INVOICE_PRODUCTS = 'GET_INVOICE_PRODUCTS';
export const GET_INVOICE_PRODUCTS_TOTALS = 'GET_INVOICE_PRODUCTS_TOTALS';
export const ADD_INVOICE_PRODUCT = 'ADD_INVOICE_PRODUCT';
export const SET_INVOICE_PRODUCT_DETAILS = 'SET_INVOICE_PRODUCT_DETAILS';
export const EDIT_INVOICE_PRODUCT = 'EDIT_INVOICE_PRODUCT';
export const DELETE_INVOICE_PRODUCT = 'DELETE_INVOICE_PRODUCT';

// Invoice Signatures Module
export const GET_INVOICE_SIGNATURES = 'GET_INVOICE_SIGNATURES';
export const ADD_INVOICE_SIGNATURE = 'ADD_INVOICE_SIGNATURE';

// Estimates Module
export const GET_ESTIMATES = 'GET_ESTIMATES';
export const GET_ESTIMATE = 'GET_ESTIMATE';
export const ADD_ESTIMATE = 'ADD_ESTIMATE';
export const SET_ESTIMATE_DETAILS = 'SET_ESTIMATE_DETAILS';
export const SET_ESTIMATE_CUSTOMER_DETAILS = 'SET_ESTIMATE_CUSTOMER_DETAILS';
export const EDIT_ESTIMATE = 'EDIT_ESTIMATE';
export const DELETE_ESTIMATE = 'DELETE_ESTIMATE';
export const GET_ESTIMATE_STATUSES = 'GET_ESTIMATE_STATUSES';
export const GET_ESTIMATE_COUNTS = 'GET_ESTIMATE_COUNTS';

// Estimate Products Module
export const GET_ESTIMATE_PRODUCTS = 'GET_ESTIMATE_PRODUCTS';
export const GET_ESTIMATE_PRODUCTS_TOTALS = 'GET_ESTIMATE_PRODUCTS_TOTALS';
export const ADD_ESTIMATE_PRODUCT = 'ADD_ESTIMATE_PRODUCT';
export const SET_ESTIMATE_PRODUCT_DETAILS = 'SET_ESTIMATE_PRODUCT_DETAILS';
export const EDIT_ESTIMATE_PRODUCT = 'EDIT_ESTIMATE_PRODUCT';
export const DELETE_ESTIMATE_PRODUCT = 'DELETE_ESTIMATE_PRODUCT';

// Estimate Signatures Module
export const GET_ESTIMATE_SIGNATURES = 'GET_ESTIMATE_SIGNATURES';
export const ADD_ESTIMATE_SIGNATURE = 'ADD_ESTIMATE_SIGNATURE';

// Messages
export const SET_MESSAGES_JOBS = 'SET_MESSAGES_JOBS';
export const SET_MESSAGES_USERS = 'SET_MESSAGES_USERS';
export const SET_MESSAGES_FILTER = 'SET_MESSAGES_FILTER';
export const SET_CURRENT_MESSAGES_USER = 'SET_CURRENT_MESSAGES_USER';
export const SET_MESSAGES_CONVERSATION_DATA = 'SET_MESSAGES_CONVERSATION_DATA';
export const TOGGLE_MESSAGES_SIDEBAR = 'TOGGLE_MESSAGES_SIDEBAR';
export const ADD_NEW_CHAT_MESSAGE = 'ADD_NEW_CHAT_MESSAGE';

import { GET_ESTIMATE_STATUSES } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  estimateStatuses: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTIMATE_STATUSES: {
      return {
        ...state,
        estimateStatuses: action.payload,
      };
    }
    default:
      return state;
  }
};

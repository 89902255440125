import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 560,
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.dark}`,
  },
  table: {
    minWidth: 750,
    overflowX: 'auto',
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  fieldsBtn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default useStyles;

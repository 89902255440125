import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppDatePicker from '../../../@jumbo/components/Common/formElements/AppDatePicker';
import {
  ACTIVE_JOB_TYPE,
  ACTIVE_SQUAD_USER,
  AD_SOURCES_KEYS,
  COUNTRY_KEYS,
  JOB_KEYS,
  JOB_TYPE_KEYS,
  PROPERTY_KEYS,
  SQUAD_USER_KEYS,
  USA_STATE_KEYS,
} from '../../../@jumbo/constants/AppDataConstants';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import {
  addUnitsToDateTime,
  /* cloneDateTimeObj, */
  getDateinDesiredFormat,
  getDateObjinDesiredFormat,
  getMomentDateObj,
  getMomentUTCDateObj,
  getTimeList12HrFormat,
  isStartBeforeEnd,
  getCurrentDateinDesiredFormat,
} from '../../../@jumbo/utils/dateHelper';
import {
  emailNotValid,
  requiredMessage,
  phoneNotValid,
  dateNotValid,
  jobTypeNotValid,
  jobSourceNotValid,
  timeNotValid,
  jobNotValid,
} from '../../../@jumbo/constants/ErrorMessages';

import { isValidPhoneNumber } from '../../../utils/FormValidation';
import { getAddressString, getItemObjectNameFromId } from '../../../utils/Helpers';

import { setCurrentCustomerProperty } from '../../../redux/actions/CustomerProperties';
import { updateJob } from '../../../redux/actions/Jobs';

import AddressDialog from '../AddressDialog';
import AddEditJobType from '../../Settings/JobTypes/AddEditJobType';
import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

const useStyles = makeStyles(theme => ({
  autocompleteFlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  listAutocompleteFlex: {
    flex: '1 1 90%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flex: '1 1 100%',
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  addNewBtn: {
    flex: '1 1 20%',
  },
  addressBtn: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.borderColor.dark}`,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  addressBtnLabel: {
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  numUsersClass: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const timeList = getTimeList12HrFormat();

const validateDate = (date, errorFunction, otherFunction) => {
  if (date) {
    if (!date.isValid()) {
      errorFunction(dateNotValid);
      if (otherFunction) otherFunction();
    }
  } else {
    errorFunction(requiredMessage);
    if (otherFunction) otherFunction();
  }
};

const isDateRangeValid = (startDate, startTime, endDate, endTime) => {
  const start = `${getDateinDesiredFormat(startDate, 'YYYY-MM-DD')} ${startTime}`;
  const end = `${getDateinDesiredFormat(endDate, 'YYYY-MM-DD')} ${endTime}`;
  if (isStartBeforeEnd(start, end)) {
    return true;
  } else {
    return false;
  }
};

const EditJobDetails = () => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentJob } = useSelector(({ jobsReducer }) => jobsReducer);
  const { adSources } = useSelector(({ adSourcesReducer }) => adSourcesReducer);
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);
  const { squadUsers } = useSelector(({ squadReducer }) => squadReducer);
  const { customerProperties } = useSelector(({ customerPropertiesReducer }) => customerPropertiesReducer);
  const { countries } = useSelector(({ countriesReducer }) => countriesReducer);
  const { usaStates } = useSelector(({ usaStatesReducer }) => usaStatesReducer);

  const [jobId, setJobId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneOneError, setPhoneOneError] = useState('');
  /* const [extensionOne, setExtensionOne] = useState('');
  const [extensionOneError, setExtensionOneError] = useState(''); */
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [jobType, setJobType] = useState(null);
  const [jobTypeError, setJobTypeError] = useState('');
  const [jobSource, setJobSource] = useState(null);
  const [jobSourceError, setJobSourceError] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobDescriptionError, setJobDescriptionError] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [startTimeError, setStartTimeError] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState('');
  const [endTime, setEndTime] = useState(null);
  const [endTimeError, setEndTimeError] = useState('');
  /* const [updateEndWithStart, setUpdateEndWithStart] = useState(false); */
  const [updateEndDateWithStart, setUpdateEndDateWithStart] = useState(false);
  const [updateEndTimeWithStart, setUpdateEndTimeWithStart] = useState(false);
  const [dateTimeError, setDateTimeError] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersError, setTeamMembersError] = useState('');
  const [propertyId, setPropertyId] = useState('');
  const [propertyText, setPropertyText] = useState('');
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openAddEditJobType, setOpenAddEditJobType] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
  const [activeSquadUsers, setActiveSquadUsers] = useState([]);
  const [activeJobTypes, setActiveJobTypes] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (jobTypes) {
      const tempJobTypes = jobTypes.filter(jobTypeObj => jobTypeObj[JOB_TYPE_KEYS.STATUS] === ACTIVE_JOB_TYPE);
      setActiveJobTypes(tempJobTypes);
    }
  }, [jobTypes]);

  useEffect(() => {
    if (squadUsers) {
      const tempSquadUsers = squadUsers.filter(squadUserObj => squadUserObj[SQUAD_USER_KEYS.STATUS] === ACTIVE_SQUAD_USER);
      setActiveSquadUsers(tempSquadUsers);
    }
  }, [squadUsers]);

  useEffect(() => {
    if (currentJob && customerProperties) {
      setJobId(currentJob[JOB_KEYS.ID]);
      setCustomerId(currentJob[JOB_KEYS.CUSTOMER_ID]);
      setPropertyId(currentJob[JOB_KEYS.CUSTOMER_PROPERTY_ID]);
      setFirstName(currentJob[JOB_KEYS.CUSTOMER_FNAME]);
      setLastName(currentJob[JOB_KEYS.CUSTOMER_LNAME]);
      setCompanyName(currentJob[JOB_KEYS.CUSTOMER_COMPANY]);
      setPhoneOne(currentJob[JOB_KEYS.CUSTOMER_PHONE_ONE]);
      /* setExtensionOne(currentJob[JOB_KEYS.CUSTOMER_EXTENSION_ONE]); */
      setEmail(currentJob[JOB_KEYS.CUSTOMER_EMAIL]);
      setJobType(currentJob[JOB_KEYS.JOB_TYPE_ID]);
      setJobSource(currentJob[JOB_KEYS.AD_SOURCE_ID]);
      setJobDescription(currentJob[JOB_KEYS.JOB_DESCRIPTION]);
      setStartDate(currentJob[JOB_KEYS.START_DATE] ? getMomentUTCDateObj(currentJob[JOB_KEYS.START_DATE]) : null);
      setStartTime(currentJob[JOB_KEYS.START_TIME] ? currentJob[JOB_KEYS.START_TIME] : null);
      setEndDate(currentJob[JOB_KEYS.END_DATE] ? getMomentUTCDateObj(currentJob[JOB_KEYS.END_DATE]) : null);
      setEndTime(currentJob[JOB_KEYS.END_TIME] ? currentJob[JOB_KEYS.END_TIME] : null);
      setTeamMembers(currentJob[JOB_KEYS.TEAM_MEMBERS] ? currentJob[JOB_KEYS.TEAM_MEMBERS].split(',') : []);

      /* const stateName = getItemObjectNameFromId(
        usaStates,
        currentJob[JOB_KEYS.CUSTOMER_STATE],
        USA_STATE_KEYS.ID,
        USA_STATE_KEYS.NAME,
      );

      const countryName = getItemObjectNameFromId(
        countries,
        currentJob[JOB_KEYS.CUSTOMER_COUNTRY],
        COUNTRY_KEYS.ID,
        COUNTRY_KEYS.NAME,
      );

      const tempPropertyText = getAddressString({
        addressOne: currentJob[JOB_KEYS.CUSTOMER_ADDRESS_ONE],
        addressTwo: currentJob[JOB_KEYS.CUSTOMER_ADDRESS_TWO],
        city: currentJob[JOB_KEYS.CUSTOMER_CITY],
        state: stateName,
        zipcode: currentJob[JOB_KEYS.CUSTOMER_ZIP_CODE],
        country: countryName,
      });

      setPropertyText(tempPropertyText); */

      const addressData = customerProperties.find(
        customerProperty => currentJob[JOB_KEYS.CUSTOMER_PROPERTY_ID] === customerProperty[PROPERTY_KEYS.ID],
      );
      if (addressData) {
        const stateName = getItemObjectNameFromId(
          usaStates,
          addressData[PROPERTY_KEYS.STATE],
          USA_STATE_KEYS.ID,
          USA_STATE_KEYS.NAME,
        );

        const countryName = getItemObjectNameFromId(
          countries,
          addressData[PROPERTY_KEYS.COUNTRY],
          COUNTRY_KEYS.ID,
          COUNTRY_KEYS.NAME,
        );

        const tempaddressText = getAddressString({
          addressOne: addressData[PROPERTY_KEYS.ADDRESS_ONE],
          addressTwo: addressData[PROPERTY_KEYS.ADDRESS_TWO],
          city: addressData[PROPERTY_KEYS.CITY],
          state: stateName,
          zipcode: addressData[PROPERTY_KEYS.ZIP_CODE],
          country: countryName,
        });

        setPropertyText(tempaddressText);
      }
    }
  }, [currentJob, customerProperties, usaStates, countries]);

  /* useEffect(() => {
    if (updateEndWithStart) {
      if (startDate && !startDateError) {
        if (!startTime || startTimeError) {
          setEndDate(startDate);
          setEndDateError('');
        } else {
          const startDateTime = `${getDateinDesiredFormat(startDate, 'YYYY-MM-DD')} ${startTime}`;
          const startDateTimeObj = getMomentDateObj(startDateTime);
          const endDateTimeObj = cloneDateTimeObj(startDateTimeObj);

          addUnitsToDateTime(endDateTimeObj, 2, 'hours');

          const newEndDate = getMomentDateObj(getDateObjinDesiredFormat(endDateTimeObj, 'YYYY-MM-DD'));
          const newEndTime = getDateObjinDesiredFormat(endDateTimeObj, 'HH:mm:ss');
          if (newEndDate && newEndTime) {
            setEndDate(newEndDate);
            setEndDateError('');
            setEndTime(newEndTime);
            setEndTimeError('');
          }
        }
      }
    }
  }, [updateEndWithStart, startDate, startDateError, startTime, startTimeError]); */

  useEffect(() => {
    if (updateEndDateWithStart) {
      if (startDate && !startDateError) {
        setEndDate(startDate);
        setEndDateError('');
      }
    }
  }, [updateEndDateWithStart, startDate, startDateError]);

  useEffect(() => {
    if (updateEndTimeWithStart) {
      if (startTime && !startTimeError) {
        const currentDate = getCurrentDateinDesiredFormat('YYYY-MM-DD');
        const dateTime = `${currentDate} ${startTime}`;
        const dateTimeObj = getMomentDateObj(dateTime);

        addUnitsToDateTime(dateTimeObj, 2, 'hours');

        const endTime = getDateObjinDesiredFormat(dateTimeObj, 'HH:mm:ss');
        if (endTime) {
          setEndTime(endTime);
          setEndTimeError('');
        }
      }
    }
  }, [updateEndTimeWithStart, startTime, startTimeError]);

  useEffect(() => {
    if (
      startDate &&
      !startDateError &&
      startTime &&
      !startTimeError &&
      endDate &&
      !endDateError &&
      endTime &&
      !endTimeError
    ) {
      if (!isDateRangeValid(startDate, startTime, endDate, endTime)) {
        setDateTimeError('Start time must be less than end time');
      } else if (dateTimeError) {
        setDateTimeError('');
      }
    } else if (dateTimeError) {
      setDateTimeError('');
    }
  }, [startDate, startDateError, startTime, startTimeError, endDate, endDateError, endTime, endTimeError, dateTimeError]);

  useEffect(() => {
    if (errorMessages) {
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case JOB_KEYS.CUSTOMER_FNAME:
            setFirstNameError(msg);
            break;

          case JOB_KEYS.CUSTOMER_LNAME:
            setLastNameError(msg);
            break;

          case JOB_KEYS.CUSTOMER_EMAIL:
            setEmailError(msg);
            break;

          case JOB_KEYS.CUSTOMER_PHONE_ONE:
            setPhoneOneError(msg);
            break;

          /* case JOB_KEYS.CUSTOMER_EXTENSION_ONE:
            setExtensionOneError(msg);
            break; */

          case JOB_KEYS.JOB_TYPE_ID:
            setJobTypeError(msg);
            break;

          case JOB_KEYS.AD_SOURCE_ID:
            setJobSourceError(msg);
            break;

          case JOB_KEYS.JOB_DESCRIPTION:
            setJobDescriptionError(msg);
            break;

          case JOB_KEYS.START_DATE:
            setStartDateError(msg);
            break;

          case JOB_KEYS.START_TIME:
            setStartTimeError(msg);
            break;

          case JOB_KEYS.END_DATE:
            setEndDateError(msg);
            break;

          case JOB_KEYS.END_TIME:
            setEndTimeError(msg);
            break;

          case JOB_KEYS.TEAM_MEMBERS:
            setTeamMembersError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const firstNameBlurHandler = event => {
    if (!event.target.value) {
      setFirstNameError(requiredMessage);
    }
  };

  const lastNameBlurHandler = event => {
    if (!event.target.value) {
      setLastNameError(requiredMessage);
    }
  };

  const companyNameBlurHandler = event => {
    /* if (!event.target.value) {
      setCompanyNameError(requiredMessage);
    } */
  };

  const phoneOneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneOneError(phoneNotValid);
    }
  };

  /* const extensionOneBlurHandler = event => {
    if (!event.target.value) {
      setExtensionOneError(requiredMessage);
    }
  }; */

  const emailBlurHandler = event => {
    if (!event.target.value) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  };

  const jobDescriptionBlurHandler = event => {
    if (!event.target.value) {
      setJobDescriptionError(requiredMessage);
    }
  };

  const startDateChangeHandler = date => {
    setStartDate(date);
    setStartDateError('');
    validateDate(date, setStartDateError);
    if (/* !updateEndWithStart */ !updateEndDateWithStart) {
      /* setUpdateEndWithStart(true); */
      setUpdateEndDateWithStart(true);
    }
  };

  const endDateChangeHandler = date => {
    setEndDate(date);
    setEndDateError('');
    validateDate(date, setEndDateError);
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!jobId) {
      formIsValid = false;
      setMiscellaneousErrors(prevState => [...prevState, jobNotValid]);
    }

    if (!firstName) {
      formIsValid = false;
      setFirstNameError(requiredMessage);
    }

    if (!lastName) {
      formIsValid = false;
      setLastNameError(requiredMessage);
    }

    if (!isValidPhoneNumber(phoneOne)) {
      formIsValid = false;
      setPhoneOneError(phoneNotValid);
    }

    /* if (!extensionOne) {
      formIsValid = false;
      setExtensionOneError(requiredMessage);
    } */

    if (!email) {
      formIsValid = false;
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      formIsValid = false;
      setEmailError(emailNotValid);
    }

    if (!jobDescription) {
      formIsValid = false;
      setJobDescriptionError(requiredMessage);
    }

    if (jobType) {
      if (!activeJobTypes.map(tempJobType => tempJobType[JOB_TYPE_KEYS.ID]).includes(jobType)) {
        formIsValid = false;
        setJobTypeError(jobTypeNotValid);
      }
    } else {
      formIsValid = false;
      setJobTypeError(requiredMessage);
    }

    if (jobSource) {
      if (!adSources.map(adSource => adSource[AD_SOURCES_KEYS.ID]).includes(jobSource)) {
        formIsValid = false;
        setJobSourceError(jobSourceNotValid);
      }
    }

    validateDate(startDate, setStartDateError, () => {
      formIsValid = false;
    });

    if (startTime) {
      if (!timeList.map(timeItem => timeItem.slug).includes(startTime)) {
        formIsValid = false;
        setStartTimeError(timeNotValid);
      }
    } else {
      formIsValid = false;
      setStartTimeError(requiredMessage);
    }

    validateDate(endDate, setEndDateError, () => {
      formIsValid = false;
    });

    if (endTime) {
      if (!timeList.map(timeItem => timeItem.slug).includes(endTime)) {
        formIsValid = false;
        setEndTimeError(timeNotValid);
      }
    } else {
      formIsValid = false;
      setEndTimeError(requiredMessage);
    }

    if (
      startDate &&
      !startDateError &&
      startTime &&
      !startTimeError &&
      endDate &&
      !endDateError &&
      endTime &&
      !endTimeError
    ) {
      if (!isDateRangeValid(startDate, startTime, endDate, endTime)) {
        formIsValid = false;
        setDateTimeError('Start time must be less than end time');
      }
    }

    if (formIsValid) {
      const jobData = {
        authcode: authUser.authcode,
        [JOB_KEYS.JOB_ID]: jobId,
        [JOB_KEYS.CUSTOMER_ID]: customerId,
        [JOB_KEYS.CUSTOMER_FNAME]: firstName,
        [JOB_KEYS.CUSTOMER_LNAME]: lastName,
        [JOB_KEYS.CUSTOMER_COMPANY]: companyName,
        [JOB_KEYS.CUSTOMER_EMAIL]: email,
        [JOB_KEYS.CUSTOMER_PHONE_ONE]: phoneOne,
        /* [JOB_KEYS.CUSTOMER_EXTENSION_ONE]: extensionOne, */
        [JOB_KEYS.CUSTOMER_PROPERTY_ID]: propertyId,
        [JOB_KEYS.JOB_TYPE_ID]: jobType,
        [JOB_KEYS.AD_SOURCE_ID]: jobSource,
        [JOB_KEYS.JOB_DESCRIPTION]: jobDescription,
        [JOB_KEYS.START_DATE]: getDateinDesiredFormat(startDate, 'YYYY-MM-DD'),
        [JOB_KEYS.START_TIME]: startTime,
        [JOB_KEYS.END_DATE]: getDateinDesiredFormat(endDate, 'YYYY-MM-DD'),
        [JOB_KEYS.END_TIME]: endTime,
        [JOB_KEYS.TEAM_MEMBERS]: teamMembers && teamMembers.length > 0 ? teamMembers.join(',') : '',
      };

      dispatch(
        updateJob(
          { jobData: jobData },
          () => null,
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  const handleOpenAddressDialog = () => {
    if (propertyId) {
      const property = customerProperties.find(customerProperty => customerProperty[PROPERTY_KEYS.ID] === propertyId);
      dispatch(setCurrentCustomerProperty(property));
      setOpenAddressDialog(true);
    }
  };

  const handleCloseAddressDialog = () => {
    dispatch(setCurrentCustomerProperty(null));
    setOpenAddressDialog(false);
  };

  const handleOpenJobTypeDialog = () => setOpenAddEditJobType(true);

  const handleCloseJobTypeDialog = () => setOpenAddEditJobType(false);

  return (
    <React.Fragment>
      <form onSubmit={handleFormSubmit}>
        <GridContainer>
          <Grid item xs={12} md={9}>
            <Typography component="h3" variant="h3">
              Customer Details
            </Typography>
          </Grid>

          {miscellaneousErrors && miscellaneousErrors.length > 0 && (
            <Grid item xs={12}>
              {miscellaneousErrors.map((miscellaneousError, idx) => (
                <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                  {miscellaneousError}
                </Typography>
              ))}
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppTextInput
              fullWidth
              type="text"
              name="first_name"
              variant="outlined"
              label="First Name"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value);
                setFirstNameError('');
              }}
              onBlur={firstNameBlurHandler}
              helperText={firstNameError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextInput
              fullWidth
              type="text"
              name="last_name"
              variant="outlined"
              label="Last Name"
              value={lastName}
              onChange={e => {
                setLastName(e.target.value);
                setLastNameError('');
              }}
              onBlur={lastNameBlurHandler}
              helperText={lastNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <AppTextInput
              fullWidth
              type="text"
              name="company_name"
              variant="outlined"
              label="Company Name"
              value={companyName}
              onChange={e => {
                setCompanyName(e.target.value);
                setCompanyNameError('');
              }}
              onBlur={companyNameBlurHandler}
              helperText={companyNameError}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <AppTextInput
              fullWidth
              type="text"
              name="phone_one"
              variant="outlined"
              label="Phone Number"
              value={phoneOne}
              onChange={e => {
                setPhoneOne(e.target.value);
                setPhoneOneError('');
              }}
              onBlur={phoneOneBlurHandler}
              helperText={phoneOneError}
              InputProps={{
                inputComponent: PhoneNumberFormat,
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <AppTextInput
              fullWidth
              type="text"
              name="extension_one"
              variant="outlined"
              label="EXT"
              value={extensionOne}
              onChange={e => {
                setExtensionOne(e.target.value);
                setExtensionOneError('');
              }}
              onBlur={extensionOneBlurHandler}
              helperText={extensionOneError}
            />
          </Grid> */}
          <Grid item xs={12}>
            <AppTextInput
              fullWidth
              type="text"
              name="email"
              variant="outlined"
              label="Email Address"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              onBlur={emailBlurHandler}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              disableElevation
              disableRipple
              disableFocusRipple
              type="button"
              variant="contained"
              color="primary"
              classes={{ root: classes.addressBtn, label: classes.addressBtnLabel }}
              startIcon={<LocationOnIcon />}
              onClick={handleOpenAddressDialog}>
              {propertyText}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3">
              Job Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box classes={{ root: classes.autocompleteFlexContainer }}>
              <Autocomplete
                fullWidth
                filterSelectedOptions
                id="job_type"
                value={jobType}
                options={activeJobTypes.map(tempJobType => tempJobType[JOB_TYPE_KEYS.ID])}
                getOptionLabel={option => {
                  if (option) {
                    const selectedJobType = activeJobTypes.find(jobType => jobType[JOB_TYPE_KEYS.ID] === option);
                    if (selectedJobType) {
                      return selectedJobType[JOB_TYPE_KEYS.NAME];
                    } else {
                      return '';
                    }
                  } else {
                    return '';
                  }
                }}
                getOptionSelected={(option, value) => option === value || option === null}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    type="text"
                    name="job_type"
                    label="Select Job Type"
                    variant="outlined"
                    margin="dense"
                    helperText={jobTypeError}
                  />
                )}
                onChange={(e, value, reason) => {
                  if (['select-option', 'remove-option'].includes(reason)) {
                    const selectedJobType = value ? value : null;
                    setJobType(selectedJobType);

                    if (selectedJobType) {
                      if (!activeJobTypes.map(tempJobType => tempJobType[JOB_TYPE_KEYS.ID]).includes(selectedJobType)) {
                        setJobTypeError(jobTypeNotValid);
                      } else {
                        setJobTypeError('');
                      }
                    } else {
                      setJobTypeError(requiredMessage);
                    }
                  } else if (reason === 'clear') {
                    setJobType(null);
                    setJobTypeError('');
                  }
                }}
                classes={{ root: clsx(classes.listAutocomplete, classes.listAutocompleteFlex) }}
              />
              <Button
                type="button"
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenJobTypeDialog}
                classes={{ root: classes.addNewBtn }}>
                Add Job Type
              </Button>
            </Box>
            <Box>
              {jobType && (
                <React.Fragment>
                  <Typography variant="body1" display="inline" className={classes.numUsersClass}>
                    {activeSquadUsers.reduce((totalUsers, squadUserObj) => {
                      if (squadUserObj[SQUAD_USER_KEYS.SKILLS]) {
                        const squadUserSkills = squadUserObj[SQUAD_USER_KEYS.SKILLS];
                        if (squadUserSkills && squadUserSkills.split(',').includes(jobType)) {
                          return totalUsers + 1;
                        } else {
                          return totalUsers;
                        }
                      } else {
                        return totalUsers + 1;
                      }
                    }, 0)}
                  </Typography>
                  <Typography variant="body1" display="inline">
                    {' '}
                    users are available for the selected job type
                  </Typography>
                </React.Fragment>
              )}
              {!jobType && (
                <React.Fragment>
                  <Typography variant="body1" display="inline" className={classes.numUsersClass}>
                    {activeSquadUsers.reduce((totalUsers, squadUserObj) => {
                      if (!squadUserObj[SQUAD_USER_KEYS.SKILLS]) {
                        return totalUsers + 1;
                      } else {
                        return totalUsers;
                      }
                    }, 0)}
                  </Typography>
                  <Typography variant="body1" display="inline">
                    {' '}
                    users available for{' '}
                  </Typography>
                  <Typography variant="body1" display="inline" className={classes.numUsersClass}>
                    any
                  </Typography>
                  <Typography variant="body1" display="inline">
                    {' '}
                    job type
                  </Typography>
                </React.Fragment>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              filterSelectedOptions
              id="job_source"
              value={jobSource}
              options={adSources.map(adSource => adSource[AD_SOURCES_KEYS.ID])}
              getOptionLabel={option => {
                if (option) {
                  const selectedJobSource = adSources.find(adSource => adSource[AD_SOURCES_KEYS.ID] === option);
                  if (selectedJobSource) {
                    return selectedJobSource[AD_SOURCES_KEYS.NAME];
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              }}
              getOptionSelected={(option, value) => option === value || option === null}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  type="text"
                  name="job_source"
                  label="Select Job Source"
                  variant="outlined"
                  margin="dense"
                  helperText={jobSourceError}
                />
              )}
              onChange={(e, value, reason) => {
                if (['select-option', 'remove-option'].includes(reason)) {
                  const selectedJobSource = value ? value : null;

                  setJobSource(selectedJobSource);
                  setJobSourceError('');

                  if (
                    selectedJobSource &&
                    !adSources.map(adSource => adSource[AD_SOURCES_KEYS.ID]).includes(selectedJobSource)
                  ) {
                    setJobSourceError(jobSourceNotValid);
                  }
                } else if (reason === 'clear') {
                  setJobSource(null);
                  setJobSourceError('');
                }
              }}
              classes={{ root: classes.listAutocomplete }}
            />
          </Grid>
          <Grid item xs={12}>
            <AppTextInput
              fullWidth
              multiline
              minRows={7}
              name="job_description"
              variant="outlined"
              label="Job Description"
              value={jobDescription}
              onChange={e => {
                setJobDescription(e.target.value);
                setJobDescriptionError('');
              }}
              onBlur={jobDescriptionBlurHandler}
              helperText={jobDescriptionError}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3">
              Schedule Details
            </Typography>
            {!!dateTimeError && (
              <Typography variant="caption" display="block" color="error" gutterBottom>
                {dateTimeError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <AppDatePicker
              fullWidth
              disableToolbar
              name="start_date"
              inputVariant="outlined"
              format="ddd MMM Do YYYY"
              label="Starts"
              value={startDate}
              onChange={startDateChangeHandler}
              helperText={startDateError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              filterSelectedOptions
              id="start_time"
              value={startTime}
              options={timeList.map(timeItem => timeItem.slug)}
              getOptionLabel={option => {
                if (option) {
                  const selectedTime = timeList.find(timeItem => timeItem.slug === option);
                  if (selectedTime) {
                    return selectedTime.title;
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              }}
              getOptionSelected={(option, value) => option === value || option === null}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  type="text"
                  name="start_time"
                  label="At"
                  variant="outlined"
                  margin="dense"
                  helperText={startTimeError}
                />
              )}
              onChange={(e, value, reason) => {
                if (['select-option', 'remove-option'].includes(reason)) {
                  const selectedTime = value ? value : null;
                  setStartTime(selectedTime);

                  if (selectedTime) {
                    if (!timeList.map(timeItem => timeItem.slug).includes(selectedTime)) {
                      setStartTimeError(timeNotValid);
                    } else {
                      setStartTimeError('');
                      if (/* !updateEndWithStart */ !updateEndTimeWithStart) {
                        /* setUpdateEndWithStart(true); */
                        setUpdateEndTimeWithStart(true);
                      }
                    }
                  } else {
                    setStartTimeError(requiredMessage);
                  }
                } else if (reason === 'clear') {
                  setStartTime(null);
                  setStartTimeError(requiredMessage);
                }
              }}
              classes={{ root: classes.listAutocomplete }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppDatePicker
              fullWidth
              disableToolbar
              name="end_date"
              inputVariant="outlined"
              format="ddd MMM Do YYYY"
              label="Ends"
              value={endDate}
              onChange={endDateChangeHandler}
              helperText={endDateError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              filterSelectedOptions
              id="end_time"
              value={endTime}
              options={timeList.map(timeItem => timeItem.slug)}
              getOptionLabel={option => {
                if (option) {
                  const selectedTime = timeList.find(timeItem => timeItem.slug === option);
                  if (selectedTime) {
                    return selectedTime.title;
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              }}
              getOptionSelected={(option, value) => option === value || option === null}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  type="text"
                  name="end_time"
                  label="At"
                  variant="outlined"
                  margin="dense"
                  helperText={endTimeError}
                />
              )}
              onChange={(e, value, reason) => {
                if (['select-option', 'remove-option'].includes(reason)) {
                  const selectedTime = value ? value : null;
                  setEndTime(selectedTime);

                  if (selectedTime) {
                    if (!timeList.map(timeItem => timeItem.slug).includes(selectedTime)) {
                      setEndTimeError(timeNotValid);
                    } else {
                      setEndTimeError('');
                    }
                  } else {
                    setEndTimeError(requiredMessage);
                  }
                } else if (reason === 'clear') {
                  setEndTime(null);
                  setEndTimeError(requiredMessage);
                }
              }}
              classes={{ root: classes.listAutocomplete }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3">
              Team Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              filterSelectedOptions
              id="team_member"
              value={teamMembers}
              options={activeSquadUsers.map(squadUser => squadUser[SQUAD_USER_KEYS.ID])}
              getOptionLabel={option => {
                if (option) {
                  const selectedUser = activeSquadUsers.find(squadUser => squadUser[SQUAD_USER_KEYS.ID] === option);
                  if (selectedUser) {
                    return selectedUser[SQUAD_USER_KEYS.NAME];
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              }}
              getOptionSelected={(option, value) => option === value || option === null}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  type="text"
                  name="team_member"
                  label="Team Member"
                  variant="outlined"
                  margin="dense"
                  helperText={teamMembersError}
                />
              )}
              onChange={(e, values, reason) => {
                if (['select-option', 'remove-option'].includes(reason)) {
                  setTeamMembers(values);
                  setTeamMembersError('');
                } else if (reason === 'clear') {
                  setTeamMembers([]);
                  setTeamMembersError('');
                }
              }}
              classes={{ root: classes.listAutocomplete }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </GridContainer>
      </form>

      {openAddressDialog && <AddressDialog open={openAddressDialog} onClose={handleCloseAddressDialog} />}
      {openAddEditJobType && <AddEditJobType open={openAddEditJobType} onCloseDialog={handleCloseJobTypeDialog} />}
    </React.Fragment>
  );
};

export default EditJobDetails;

import React from 'react';
import PropTypes from 'prop-types';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import { JOB_JOB_TYPE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import NoRecordFound from '../../NoRecordFound';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 415,
    flexBasis: '100%',
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.black,
  },
  titleRoot2: {
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
  },
  actionBtn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

const headCells = [
  { id: JOB_JOB_TYPE_KEYS.ID, disablePadding: false, label: 'Service', width: '70%' },
  { id: JOB_JOB_TYPE_KEYS.AMOUNT, disablePadding: false, label: 'Amount', width: '20%' },
];

const colspan = headCells && headCells.length > 0 ? headCells.length + 1 : 1;

const JobJobTypesTable = ({ jobJobTypes, onEdit, onDelete }) => {
  const classes = useStyles();

  const handleRowClick = row => onEdit(row);

  const handleRowDelete = row => onDelete(row);

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={classes.table} aria-labelledby="jobJobTypesTable" aria-label="Job JobTypes Table">
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={false}
                style={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!!jobJobTypes.length ? (
            jobJobTypes.map((row, index) => {
              return (
                <TableRow
                  key={row[JOB_JOB_TYPE_KEYS.ID]}
                  hover
                  tabIndex={-1}
                  classes={{
                    hover: classes.tableRow,
                  }}
                  onClick={() => handleRowClick(row)}>
                  <TableCell component="th" id={`job-jobType-${row[JOB_JOB_TYPE_KEYS.ID]}`} scope="row">
                    <Box display="flex" flexDirection="column">
                      <Typography className={classes.titleRoot} component="div" variant="h4">
                        {row[JOB_JOB_TYPE_KEYS.NAME]}
                      </Typography>
                      <Typography className={classes.titleRoot2} component="div" variant="h5">
                        {row[JOB_JOB_TYPE_KEYS.DESCRIPTION]}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{row[JOB_JOB_TYPE_KEYS.AMOUNT]}</TableCell>
                  <TableCell align="center" onClick={event => event.stopPropagation()}>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => handleRowDelete(row)}
                      classes={{ root: classes.actionBtn, colorPrimary: classes.deleteBtn }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={colspan} rowSpan={10} classes={{ root: classes.tableCell }}>
                <NoRecordFound>No services have been added for this job!</NoRecordFound>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

JobJobTypesTable.propTypes = {
  jobJobTypes: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default JobJobTypesTable;

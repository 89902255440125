import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
/* import Button from '@material-ui/core/Button'; */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
/* import DescriptionIcon from '@material-ui/icons/Description'; */

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { useDebounce } from '../../@jumbo/utils/commonHelper';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { ALL_INVOICES_STATUS_ID, INVOICE_KEYS, INVOICE_STATUS_KEYS } from '../../@jumbo/constants/AppDataConstants';

import CmtSearch from '../../@coremat/CmtSearch';

import { getFilteredInvoices } from '../../redux/actions/Invoices';
import { getInvoiceStatusList } from '../../redux/actions/InvoiceStatuses';

import InvoicesHeader from './InvoicesHeader';
import InvoicesTableHead from './InvoicesTableHead';
import InvoicesListRow from './InvoicesListRow';
import NoRecordFound from '../NoRecordFound';
import SweetAlert from '../UI/Alert';

import useStyles from './index.style';

const pageName = 'Invoices';
const breadcrumbs = [{ label: pageName, isActive: true }];

const allHeadCells = [
  { id: INVOICE_KEYS.ID, label: 'Invoice', sortColumn: false },
  { id: INVOICE_KEYS.CUSTOMER_ID, label: 'Customer', sortColumn: false },
  { id: INVOICE_KEYS.FORMATTED_CREATED, label: 'Created', sortColumn: false },
  { id: INVOICE_KEYS.AMOUNT, label: 'Amount', sortColumn: false },
  { id: INVOICE_KEYS.STATUS, label: 'Status', sortColumn: false },
  { id: INVOICE_KEYS.JOB_ID, label: 'Job', sortColumn: false },
];

let colspan = allHeadCells.length;
colspan = colspan > 0 ? colspan : 1;

const Invoices = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { invoices, invoiceCounts } = useSelector(({ invoicesReducer }) => invoicesReducer);
  const { invoiceStatuses } = useSelector(({ invoiceStatusesReducer }) => invoiceStatusesReducer);

  /* const [selected, setSelected] = useState([]); */
  const [invoiceStatusFetching, setInvoiceStatusFetching] = useState(true);
  const [invoiceStatusFetched, setInvoiceStatusFetched] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(ALL_INVOICES_STATUS_ID);
  const [sort, setSort] = useState({ orderBy: INVOICE_KEYS.ID, order: 'asc' });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const [invoicesFetched, setInvoicesFetched] = useState(false);
  const [invoicesExportFetched, setInvoicesExportFetched] = useState(true);
  const [exportCSV, setExportCSV] = useState('0');
  const [fetchCriteria, setFetchCriteria] = useState(null);
  const [invoicesFilterChanged, setInvoicesFilterChanged] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isActive = true;

    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getInvoiceStatusList(payload));
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise
      .then(() => {
        if (isActive) {
          setInvoiceStatusFetched(true);
          setInvoiceStatusFetching(false);
        }
      })
      .catch(error => {
        if (isActive) {
          setInvoiceStatusFetched(false);
          setInvoiceStatusFetching(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    setFetchCriteria({
      status: selectedStatus,
      search: debouncedSearchTerm,
      order: sort.order,
      orderBy: sort.orderBy,
      exportCSV: '0',
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
    });

    if (!invoicesFilterChanged) {
      setInvoicesFetched(false);
    } else {
      setPagination(prevState => {
        return { ...prevState, page: 0 };
      });
      setInvoicesFilterChanged(false);
    }
  }, [selectedStatus, debouncedSearchTerm, sort, pagination, invoicesFilterChanged]);

  useEffect(() => {
    if (exportCSV === '1') {
      setFetchCriteria(prevState => {
        return {
          ...prevState,
          exportCSV: '1',
        };
      });
      setInvoicesExportFetched(false);
    }
  }, [exportCSV]);

  useEffect(() => {
    let isActive = true;

    /**
     * Make sure that a status is always present in the filter
     * since state updates can take time
     * (Check the above code to set selectedStatus and filters)
     */
    if (invoiceStatusFetched && (!invoicesFetched || !invoicesExportFetched) && fetchCriteria && fetchCriteria.status) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getFilteredInvoices(payload, (fetchedInvoices, exportLink) => {
              if (isActive) {
                if (payload.invoiceData.export_list === '1' && exportLink) {
                  SweetAlert({
                    icon: 'success',
                    title: 'Your export is ready!!',
                    html: `Click <a href="${exportLink}" target="_blank" download class="${classes.downloadLink}">here</a> to download your export`,
                  });
                }
                setInvoicesFetched(true);
                setInvoicesExportFetched(true);
                setExportCSV('0');
              }
            }),
          );
        };
      };

      const invoiceData = {
        authcode: authUser.authcode,
        filters: {
          status_id: fetchCriteria.status,
        },
        search: fetchCriteria.search,
        order: fetchCriteria.order,
        order_by: fetchCriteria.orderBy,
        export_list: fetchCriteria.exportCSV,
        page: fetchCriteria.page + 1,
        rows_per_page: fetchCriteria.rowsPerPage,
      };
      const promise = dispatch(fetchData({ invoiceData: invoiceData }));
      promise.catch(error => {
        /* Setting to 'true' means API has been executed, not necessarily successfully */
        if (isActive) {
          setInvoicesFetched(true);
          setInvoicesExportFetched(true);
          setExportCSV('0');
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [
    dispatch,
    authUser.authcode,
    invoiceStatusFetched,
    invoicesFetched,
    invoicesExportFetched,
    fetchCriteria,
    classes.downloadLink,
  ]);

  const handleSetFilterChanged = () => setInvoicesFilterChanged(true);

  const onSearchChipDelete = () => {
    setSearchTerm('');
    handleSetFilterChanged();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sort.orderBy === property && sort.order === 'asc';
    setSort(prevState => {
      return { ...prevState, orderBy: property, order: isAsc ? 'desc' : 'asc' };
    });
  };

  const handlePageChange = (event, newPage) => {
    setPagination(prevState => {
      return { ...prevState, page: newPage };
    });
  };

  const handleRowsPerPageChange = event => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleRowClick = (event, id) => history.push(`/jobInvoice/${id}/`);

  /* const handleCheckboxClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  }; */

  /* const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = invoices.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }; */

  /* const handleExportInvoicesList = () => setExportCSV('1'); */

  /* const isSelected = id => selected.indexOf(id) !== -1; */

  const invoiceStatusList = [
    {
      [INVOICE_STATUS_KEYS.ID]: ALL_INVOICES_STATUS_ID,
      [INVOICE_STATUS_KEYS.NAME]: 'All Status',
    },
    ...invoiceStatuses,
  ];

  const invoiceCountNonZero =
    invoiceCounts &&
    invoiceCounts[INVOICE_KEYS.TOTAL] &&
    !isNaN(parseInt(invoiceCounts[INVOICE_KEYS.TOTAL])) &&
    parseInt(invoiceCounts[INVOICE_KEYS.TOTAL]) > 0;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      {!invoiceStatusFetching && invoiceStatusFetched && invoiceStatuses && invoiceStatuses.length > 0 && (
        <GridContainer>
          <Grid item xs={12}>
            <InvoicesHeader
              invoiceStatusList={invoiceStatusList}
              selectedStatus={selectedStatus}
              /* numInvoicesSelected={selected ? selected.length : 0} */
              setSelectedStatus={setSelectedStatus}
              handleSetFilterChanged={handleSetFilterChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Toolbar className={classes.toolbarRoot}>
                <CmtSearch
                  onChange={e => {
                    setSearchTerm(e.target.value);
                    handleSetFilterChanged();
                  }}
                  value={searchTerm}
                  border={false}
                  onlyIcon
                />
                <div className={classes.chipsRoot}>
                  {searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}
                </div>
                {/* <Button type="button" variant="outlined" startIcon={<DescriptionIcon />} onClick={handleExportInvoicesList}>
                  Export
                </Button> */}
              </Toolbar>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="invoicesTableTitle"
                  aria-label="Invoices Table">
                  <InvoicesTableHead
                    headCells={allHeadCells}
                    classes={classes}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    /* numSelected={selected.length} */
                    rowCount={invoices.length}
                    onRequestSort={handleRequestSort}
                    /* onSelectAllClick={handleSelectAllClick} */
                  />
                  <TableBody>
                    {!!invoices.length ? (
                      invoices.map((row, index) => (
                        <InvoicesListRow
                          key={index}
                          row={row}
                          /* isSelected={isSelected} */
                          onRowClick={handleRowClick}
                          /* onCheckboxClick={handleCheckboxClick} */
                        />
                      ))
                    ) : (
                      <TableRow style={{ height: 53 * 6 }}>
                        <TableCell colSpan={colspan} rowSpan={10}>
                          <NoRecordFound>
                            {invoicesFetched ? 'There are no records found.' : 'Loading invoices...'}
                          </NoRecordFound>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={invoiceCountNonZero ? parseInt(invoiceCounts[INVOICE_KEYS.TOTAL]) : 0}
                rowsPerPage={pagination.rowsPerPage}
                labelRowsPerPage=""
                page={pagination.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </Grid>
        </GridContainer>
      )}

      {!invoiceStatusFetching && (!invoiceStatusFetched || !invoiceStatuses || invoiceStatuses.length === 0) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Status data not found</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default Invoices;

/* import moment from 'moment'; */
import {
  ADD_NEW_CHAT_MESSAGE,
  /* SEND_NEW_MEDIA_MESSAGE, */
  SET_MESSAGES_JOBS,
  SET_MESSAGES_USERS,
  SET_MESSAGES_CONVERSATION_DATA,
  SET_CURRENT_MESSAGES_USER,
  SET_MESSAGES_FILTER,
  TOGGLE_MESSAGES_SIDEBAR,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  jobs: [],
  users: [],
  conversation: [],
  currentUser: null,
  isSideBarCollapsed: false,
  filterData: {
    search: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGES_JOBS: {
      return { ...state, jobs: action.payload };
    }
    case SET_MESSAGES_USERS: {
      return { ...state, users: action.payload };
    }
    case SET_CURRENT_MESSAGES_USER: {
      return { ...state, currentUser: action.payload };
    }
    case SET_MESSAGES_CONVERSATION_DATA: {
      return {
        ...state,
        conversation: action.payload,
      };
    }
    case ADD_NEW_CHAT_MESSAGE: {
      return {
        ...state,
        conversation: state.conversation.concat(action.payload),
      };
    }
    /* case SEND_NEW_MEDIA_MESSAGE: {
      return {
        ...state,
        conversation: state.conversation.concat({
          id: new Date().getTime(),
          user: state.currentUser,
          type: 'sent',
          messageType: 'media',
          message: 'kand',
          media: action.payload,
          sentAt: moment(),
        }),
      };
    } */
    case SET_MESSAGES_FILTER: {
      return { ...state, filterData: { ...state.filterData, ...action.payload } };
    }
    case TOGGLE_MESSAGES_SIDEBAR: {
      return { ...state, isSideBarCollapsed: !state.isSideBarCollapsed };
    }
    default:
      return state;
  }
};

import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { AppName } from '../../../@jumbo/constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
}));

const Error403 = () => {
  document.title = `${AppName} - Access Denied`;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        403
      </Box>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
        <IntlMessages id="extraPages.403Msg" />
      </Box>
      <Box mt={8}>
        <Button component={Link} to="/dashboard" variant="contained" color="primary">
          <IntlMessages id="extraPages.goHome" />
        </Button>
      </Box>
    </Box>
  );
};

export default Error403;

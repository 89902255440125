import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import { getComparator, stableSort } from '../../@jumbo/utils/tableHelper';
import { useDebounce } from '../../@jumbo/utils/commonHelper';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { SQUAD_USER_KEYS } from '../../@jumbo/constants/AppDataConstants';

import CmtSearch from '../../@coremat/CmtSearch';

/* import { getMetroAreas } from '../../redux/actions/MetroAreas'; */
import { getJobTypes } from '../../redux/actions/JobTypes';
import { getSquadUsers, getSquadFilters } from '../../redux/actions/Squad';

import SquadHeader from './SquadHeader';
import SquadTableHead from './SquadTableHead';
import SquadListRow from './SquadListRow';
import NoRecordFound from '../NoRecordFound';

import useStyles from './index.style';

const pageName = 'Crew Members';
const breadcrumbs = [{ label: pageName, isActive: true }];

const Squad = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { squadUsers } = useSelector(({ squadReducer }) => squadReducer);

  const [filteredSquadUsers, setfilteredSquadUsers] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [orderBy, setOrderBy] = useState(SQUAD_USER_KEYS.NAME);
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [squadFetched, setSquadFetched] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        /* return dispatch(getMetroAreas(payload)).then(() => { */
        return dispatch(getJobTypes(payload)).then(() => {
          return dispatch(getSquadFilters(payload)).then(() => {
            return dispatch(
              getSquadUsers(payload, () => {
                if (isActive) setSquadFetched(true);
              }),
            );
          });
        });
        /* }); */
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise.catch(error => {
      /* Setting to 'true' means API has been executed, not necessarily successfully */
      if (isActive) setSquadFetched(true);
    });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    if (squadFetched) {
      let tempSquad = squadUsers;

      if (filterOptions.length > 0) {
        filterOptions.forEach(filteredOption => {
          tempSquad = tempSquad.filter(user => {
            if (filteredOption.field === SQUAD_USER_KEYS.STATUS && filteredOption.label === 'All') {
              return true;
            } else {
              /* if (filteredOption.field === SQUAD_USER_KEYS.AREAS) {
                if (user[SQUAD_USER_KEYS.AREAS]) {
                  return user[SQUAD_USER_KEYS.AREAS].split(',').includes(filteredOption.id);
                } else {
                  return false;
                }
              } else { */
              return user[filteredOption.field] === filteredOption.id;
              /* } */
            }
          });
        });
      }

      if (debouncedSearchTerm) {
        tempSquad = tempSquad.filter(user => {
          return (
            (user[SQUAD_USER_KEYS.NAME] &&
              user[SQUAD_USER_KEYS.NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.EMAIL] &&
              user[SQUAD_USER_KEYS.EMAIL].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.PHONE] &&
              user[SQUAD_USER_KEYS.PHONE].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.ROLE_NAME] &&
              user[SQUAD_USER_KEYS.ROLE_NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.FIELD_TECH] &&
              user[SQUAD_USER_KEYS.FIELD_TECH].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.TYPE_NAME] &&
              user[SQUAD_USER_KEYS.TYPE_NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.CREATED] &&
              user[SQUAD_USER_KEYS.CREATED].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            (user[SQUAD_USER_KEYS.SKILLS] &&
              user[SQUAD_USER_KEYS.SKILLS].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
            /* (user[SQUAD_USER_KEYS.AREAS] &&
              user[SQUAD_USER_KEYS.AREAS].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) || */
            (user[SQUAD_USER_KEYS.STATUS] &&
              user[SQUAD_USER_KEYS.STATUS].toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
          );
        });
      }

      setfilteredSquadUsers(tempSquad);
      setFilterApplied(filterOptions.length > 0 || !!debouncedSearchTerm);
    }
  }, [squadFetched, squadUsers, filterOptions, debouncedSearchTerm]);

  const onSearchChipDelete = () => setSearchTerm('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (event, id) => history.push(`/user/${id}/`);

  const headCells = [
    { id: SQUAD_USER_KEYS.NAME, disablePadding: false, label: 'Name' },
    { id: SQUAD_USER_KEYS.PHONE, disablePadding: false, label: 'Phone' },
    { id: SQUAD_USER_KEYS.ROLE_NAME, disablePadding: false, label: 'Role' },
    { id: SQUAD_USER_KEYS.FIELD_TECH, disablePadding: false, label: 'Field Tech' },
    { id: SQUAD_USER_KEYS.TYPE_NAME, disablePadding: false, label: 'Type' },
    { id: SQUAD_USER_KEYS.CREATED, disablePadding: false, label: 'Created' },
    { id: SQUAD_USER_KEYS.SKILLS, disablePadding: false, label: 'Skills' },
    /* { id: SQUAD_USER_KEYS.AREAS, disablePadding: false, label: 'Areas' }, */
  ];

  const colspan = headCells && headCells.length > 0 ? headCells.length : 1;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <SquadHeader filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolbarRoot}>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
              <div className={classes.chipsRoot}>
                {searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}
              </div>
            </Toolbar>
            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="squadTableTitle" aria-label="Squad Table">
                <SquadTableHead
                  headCells={headCells}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!!filteredSquadUsers.length ? (
                    stableSort(filteredSquadUsers, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => <SquadListRow key={index} row={row} onRowClick={handleRowClick} />)
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={colspan} rowSpan={10}>
                        {isFilterApplied ? (
                          <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                        ) : (
                          <NoRecordFound>
                            {squadFetched ? 'There are no records found.' : 'Loading crew members...'}
                          </NoRecordFound>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25, 50]}
              component="div"
              count={filteredSquadUsers.length}
              labelRowsPerPage=""
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Squad;

import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
/* import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'; */
import Autocomplete from '@material-ui/lab/Autocomplete';
/* import AddIcon from '@material-ui/icons/Add'; */
/* import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete'; */
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { ALL_ESTIMATES_STATUS_ID, ESTIMATE_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

/* import EstimatesHeaderCard from '../EstimatesHeaderCard'; */

const useStyles = makeStyles(theme => ({
  mdRightAlign: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  btnDelete: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const EstimatesHeader = ({
  estimateStatusList,
  selectedStatus,
  /* numEstimatesSelected, */
  setSelectedStatus,
  /* onAddNewEstimate, */
  /* onBulkStatusUpdate,
  onBulkStatusDelete, */
  handleSetFilterChanged,
}) => {
  const classes = useStyles();

  return (
    <GridContainer>
      {/* estimateStatusList.map(estimateStatus =>
        estimateStatus[ESTIMATE_STATUS_KEYS.ID] === ALL_ESTIMATES_STATUS_ID ? null : (
          <Grid item xs={12} sm={6} md={2} key={`estimate-status-${estimateStatus[ESTIMATE_STATUS_KEYS.ID]}`}>
            <EstimatesHeaderCard
              itemId={estimateStatus[ESTIMATE_STATUS_KEYS.ID]}
              title={estimateStatus[ESTIMATE_STATUS_KEYS.NAME]}
              subtitle="0 Worth 0"
              onCardClick={setSelectedStatus}
            />
          </Grid>
        ),
      ) */}
      <Grid item xs={12} md={4}>
        <Autocomplete
          fullWidth
          disableClearable
          id="estimate_status"
          value={selectedStatus}
          options={estimateStatusList.map(estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID])}
          getOptionLabel={option => {
            if (option) {
              const selectedStatus = estimateStatusList.find(
                estimateStatus => estimateStatus[ESTIMATE_STATUS_KEYS.ID] === option,
              );
              if (selectedStatus) {
                return selectedStatus[ESTIMATE_STATUS_KEYS.NAME];
              } else {
                return '';
              }
            } else {
              return '';
            }
          }}
          getOptionSelected={(option, value) => option === value || option === null}
          renderInput={params => (
            <AppTextInput {...params} type="text" name="estimate_status" label="Status" variant="outlined" margin="dense" />
          )}
          onChange={(e, value, reason) => {
            if (['select-option', 'remove-option'].includes(reason)) {
              const selectedStatus = value ? value : ALL_ESTIMATES_STATUS_ID;
              setSelectedStatus(selectedStatus);
            } else if (reason === 'clear') {
              setSelectedStatus(ALL_ESTIMATES_STATUS_ID);
            }
            handleSetFilterChanged();
          }}
          classes={{ root: classes.listAutocomplete }}
        />
      </Grid>
      <Grid item xs={12} md={8} className={classes.mdRightAlign}>
        {/* <Button type="button" variant="contained" color="primary" startIcon={<AddIcon />} onClick={onAddNewEstimate}>
          Add New
        </Button> */}
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Typography variant="body1">{numEstimatesSelected} estimates selected</Typography>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={numEstimatesSelected === 0}
            startIcon={<CreateIcon />}
            onClick={onBulkStatusUpdate}>
            Change Status
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={numEstimatesSelected === 0}
            startIcon={<DeleteIcon />}
            classes={{ outlinedPrimary: classes.btnDelete }}
            onClick={onBulkStatusDelete}>
            Delete
          </Button>
        </Box>
      </Grid> */}
    </GridContainer>
  );
};

EstimatesHeader.prototype = {
  estimateStatusList: PropTypes.array.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  /* numEstimatesSelected: PropTypes.number.isRequired, */
  setSelectedStatus: PropTypes.func.isRequired,
  /* onAddNewEstimate: PropTypes.func.isRequired, */
  /* onBulkStatusUpdate: PropTypes.func.isRequired,
  onBulkStatusDelete: PropTypes.func.isRequired, */
  handleSetFilterChanged: PropTypes.func.isRequired,
};

export default EstimatesHeader;

import React from 'react';
import { useSelector } from 'react-redux';

import { permitComponentAccess } from '../../utils/AccessCheck';

const RestrictedComponent = props => {
  const { authUser } = useSelector(({ auth }) => auth);

  if (!authUser || !props.component) {
    return null;
  }

  if (!permitComponentAccess(props.component, authUser)) {
    return null;
  }

  return props.children;
};

export default RestrictedComponent;

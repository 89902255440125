import React from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { AppName } from '../../../@jumbo/constants/AppConstants';
import { jobNotValid } from '../../../@jumbo/constants/ErrorMessages';

import Sidebar from '../Sidebar';
import ChatContainer from '../ChatContainer';

import useStyles from '../index.style';

const pageName = 'Messages';
const breadcrumbs = [{ label: pageName, isActive: true }];

const JobMessages = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const params = useParams();

  const jobId = params.jobId;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      {jobId && (
        <GridContainer>
          <Grid item xs={12}>
            <Box className={classes.inBuildAppContainer}>
              <Sidebar jobId={jobId} />
              <ChatContainer jobId={jobId} />
            </Box>
          </Grid>
        </GridContainer>
      )}

      {!jobId && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">{jobNotValid}</Alert>
          </Grid>
        </GridContainer>
      )}
    </PageContainer>
  );
};

export default JobMessages;

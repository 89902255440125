import React from 'react';
import { useDispatch } from 'react-redux';
/* import { useHistory , Link } from 'react-router-dom'; */
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MoreVertIcon from '@material-ui/icons/MoreVert';
/* import PersonIcon from '@material-ui/icons/Person'; */
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

import { MESSAGE_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';

import { setCurrentUser } from '../../../redux/actions/Messages';

import useStyles from '../index.style';

const actions = [
  /* { label: 'User Profile', slug: 'profile', icon: <PersonIcon /> }, */
  { label: 'Refresh Chat', slug: 'refresh', icon: <RefreshRoundedIcon /> },
  { label: 'End Chat', slug: 'end', icon: <SpeakerNotesOffIcon /> },
];

const ContentHeader = ({ user, onRefreshChat }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  /* const history = useHistory(); */

  const onOptionItemClick = item => {
    /* if (item.slug === 'profile') {
      if (user[MESSAGE_KEYS.RECEIVER_TYPE] === 'customer') {
        history.push(`/customer/${user[MESSAGE_KEYS.RECEIVER_ID]}/`);
      } else {
        history.push(`/user/${user[MESSAGE_KEYS.RECEIVER_ID]}/`);
      }
    } else */ if (
      item.slug === 'end'
    ) {
      dispatch(setCurrentUser(null));
    } else if (item.slug === 'refresh') {
      onRefreshChat();
    }
  };

  return (
    <Box className={classes.appContentHeader}>
      <IconButton className={classes.backBtn} onClick={() => dispatch(setCurrentUser(null))}>
        <KeyboardBackspaceIcon />
      </IconButton>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography className={classes.titleRoot} component="div" variant="h3">
          {user[MESSAGE_KEYS.RECEIVER_NAME]}
        </Typography>
        <Typography className={clsx(classes.titleRoot, classes.userTypeRoot)} component="div" variant="h3">
          {`(${user[MESSAGE_KEYS.RECEIVER_TYPE]})`}
        </Typography>
      </Box>
      <Box ml="auto">
        <CmtDropdownMenu
          TriggerComponent={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          items={actions}
          onItemClick={onOptionItemClick}
        />
      </Box>
    </Box>
  );
};

ContentHeader.prototype = {
  user: PropTypes.object.isRequired,
  onRefreshChat: PropTypes.func.isRequired,
};

export default ContentHeader;

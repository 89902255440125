import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import WorkIcon from '@material-ui/icons/Work';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PersonIcon from '@material-ui/icons/Person';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dialogActions: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: { justifyContent: 'flex-end' },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventDescription = ({ open, event, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth open={open} onClose={onClose} className={classes.dialogRoot} TransitionComponent={Transition}>
      <DialogTitle className={classes.dialogTitleRoot}>Event</DialogTitle>
      <DialogContent dividers>
        {event && event.id && (
          <GridContainer>
            <Grid item xs={12}>
              <ListItem>
                <ListItemIcon>
                  <WorkIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={`Job #${event.id}`} secondary={`(${event.job_status})`} />
                <IconButton component="a" href={`/job/${event.id}/`} target="_blank">
                  <OpenInNewIcon />
                </IconButton>
              </ListItem>
              {event.title && (
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>{event.title}</ListItemText>
                </ListItem>
              )}
              {event.startios && event.endios && (
                <ListItem>
                  <ListItemIcon>
                    <DateRangeIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>{`${event.startios} to ${event.endios}`}</ListItemText>
                </ListItem>
              )}
              {event.email && (
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>{event.email}</ListItemText>
                </ListItem>
              )}
              {event.phone && (
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>{event.phone}</ListItemText>
                </ListItem>
              )}
              {event.client_address && (
                <ListItem>
                  <ListItemIcon>
                    <BusinessIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>{event.client_address}</ListItemText>
                </ListItem>
              )}
              {event.desc && (
                <ListItem>
                  <ListItemIcon>
                    <DescriptionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>{event.desc}</ListItemText>
                </ListItem>
              )}
            </Grid>
          </GridContainer>
        )}
        {(!event || !event.id) && (
          <Alert variant="outlined" severity="error">
            The event is not valid!
          </Alert>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button type="button" variant="outlined" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

EventDescription.prototype = {
  open: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EventDescription;

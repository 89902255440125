import {
  ADD_JOB_PRODUCT,
  DELETE_JOB_PRODUCT,
  EDIT_JOB_PRODUCT,
  GET_JOB_PRODUCTS,
  GET_JOB_PRODUCTS_TOTALS,
  SET_JOB_PRODUCT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { JOB_PRODUCT_KEYS } from '../../@jumbo/constants/AppDataConstants';

const INIT_STATE = {
  jobProducts: [],
  jobProductsTotals: null,
  currentJobProduct: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_PRODUCTS: {
      return {
        ...state,
        jobProducts: action.payload,
      };
    }
    case GET_JOB_PRODUCTS_TOTALS: {
      return {
        ...state,
        jobProductsTotals: action.payload,
      };
    }
    case SET_JOB_PRODUCT_DETAILS: {
      return {
        ...state,
        currentJobProduct: action.payload,
      };
    }
    case ADD_JOB_PRODUCT: {
      return {
        ...state,
        jobProducts: [...state.jobProducts, action.payload],
      };
    }
    case EDIT_JOB_PRODUCT: {
      return {
        ...state,
        jobProducts: state.jobProducts.map(jobProduct =>
          jobProduct[JOB_PRODUCT_KEYS.ID] === action.payload[JOB_PRODUCT_KEYS.ID] ? action.payload : jobProduct,
        ),
      };
    }
    case DELETE_JOB_PRODUCT: {
      return {
        ...state,
        jobProducts: state.jobProducts.filter(jobProduct => jobProduct[JOB_PRODUCT_KEYS.ID] !== action.payload),
      };
    }
    default:
      return state;
  }
};

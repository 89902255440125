import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { ADD_TAX, EDIT_TAX, GET_TAXES, SET_TAX_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { GET_TAXES_LINK, ADD_TAX_LINK, UPDATE_TAX_LINK } from '../../@jumbo/constants/APIConstants';

export const getTaxes = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_TAXES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.taxes) {
              const taxes = data.taxes;
              dispatch(fetchSuccess());
              dispatch({ type: GET_TAXES, payload: taxes });
              if (callbackFun) callbackFun(taxes);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentTax = tax => {
  return dispatch => {
    dispatch({ type: SET_TAX_DETAILS, payload: tax });
  };
};

export const addNewTax = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.taxData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_TAX_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.item) {
              dispatch(fetchSuccess('Tax was added successfully.'));
              dispatch({ type: ADD_TAX, payload: data.item });
              if (callbackFun) callbackFun(data.item);
            } else {
              dispatch(fetchError('Unable to get data'));
            }
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateTax = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.taxData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_TAX_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.tax) {
              dispatch(fetchSuccess('Tax was updated successfully.'));
              dispatch({ type: EDIT_TAX, payload: data.tax });
              if (callbackFun) callbackFun(data.tax);
            } else {
              dispatch(fetchError('Unable to get data'));
            }
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

import React from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
/* import Checkbox from '@material-ui/core/Checkbox'; */
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.black,
  },
  titleRoot2: {
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
  },
  tableRow: {
    cursor: 'pointer',
  },
  created: {
    maxWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(1),
  },
  columnCommon: {
    whiteSpace: 'nowrap',
  },
}));

const JobsListRow = ({ row, onRowClick, headCells /* onCheckboxClick, isSelected */ }) => {
  const classes = useStyles();

  const jobId = row[JOB_KEYS.ID];

  const labelId = `enhanced-table-checkbox-${jobId}`;
  /* const isItemSelected = isSelected(jobId); */

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, jobId)}
      tabIndex={-1}
      key={jobId}
      classes={{
        hover: classes.tableRow,
      }}>
      {/* <TableCell padding="checkbox" onClick={event => onCheckboxClick(event, jobId)}>
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
      {headCells[0].show && (
        <TableCell component="th" id={labelId} scope="row" className={classes.columnCommon}>
          {jobId}
        </TableCell>
      )}
      {headCells[1].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.STATUS]}</TableCell>}
      {headCells[2].show && (
        <TableCell onClick={event => event.stopPropagation()}>
          <Box display="flex" flexDirection="column" className={classes.columnCommon}>
            {(!!row[JOB_KEYS.CUSTOMER_FNAME] || !!row[JOB_KEYS.CUSTOMER_LNAME]) && (
              <Typography
                component={Link}
                to={`/customer/${row[JOB_KEYS.CUSTOMER_ID]}/`}
                className={classes.titleRoot}
                variant="h4">
                {`${row[JOB_KEYS.CUSTOMER_FNAME]} ${row[JOB_KEYS.CUSTOMER_LNAME]}`}
              </Typography>
            )}
            {row[JOB_KEYS.CUSTOMER_COMPANY] && (
              <Typography className={classes.titleRoot2} component="div" variant="h5">
                {`(${row[JOB_KEYS.CUSTOMER_COMPANY]})`}
              </Typography>
            )}
          </Box>
        </TableCell>
      )}
      {headCells[3].show && <TableCell>{/* row[JOB_KEYS.TAG_ID] */}</TableCell>}
      {headCells[4].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.JOB_TYPE_NAME]}</TableCell>}
      {headCells[5].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.SCHEDULED]}</TableCell>}
      {headCells[6].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.END]}</TableCell>}
      {headCells[7].show && (
        <TableCell onClick={event => event.stopPropagation()} className={classes.columnCommon}>
          <a href={`tel:${row[JOB_KEYS.CUSTOMER_PHONE_ONE]}`}>
            <NumberFormat
              isNumericString
              displayType="text"
              value={row[JOB_KEYS.CUSTOMER_PHONE_ONE]}
              format="(###) ###-####"
            />
          </a>
        </TableCell>
      )}
      {headCells[8].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.CUSTOMER_EMAIL]}</TableCell>}
      {headCells[9].show && (
        <TableCell>
          {row[JOB_KEYS.TECH]
            ? row[JOB_KEYS.TECH]
                .split(',')
                .map((tempTech, index) => (
                  <Chip key={`tech-${index}`} label={`${tempTech}`} variant="outlined" className={classes.chip} />
                ))
            : ''}
        </TableCell>
      )}
      {headCells[10].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.CREATED_BY]}</TableCell>}
      {headCells[11].show && <TableCell className={classes.created}>{row[JOB_KEYS.FORMATTED_ADDRESS]}</TableCell>}
      {headCells[12].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.CUSTOMER_CITY]}</TableCell>}
      {headCells[13].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.CUSTOMER_STATE_NAME]}</TableCell>}
      {headCells[14].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.CUSTOMER_ZIP_CODE]}</TableCell>}
      {headCells[15].show && <TableCell>{/* row[JOB_KEYS.SERVICE_AREA_ID] */}</TableCell>}
      {headCells[16].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.SOURCE_NAME]}</TableCell>}
      {headCells[17].show && <TableCell className={classes.columnCommon}>{row[JOB_KEYS.CUSTOMER_COMPANY]}</TableCell>}
      {headCells[18].show && <TableCell>{/* row[JOB_KEYS.TIME_IN_STATUS] */}</TableCell>}
      {headCells[19].show && <TableCell>{row[JOB_KEYS.CREATED_AT]}</TableCell>}
    </TableRow>
  );
};

export default React.memo(JobsListRow);

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
/* import { TwitterPicker } from 'react-color'; */

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
/* import IconButton from '@material-ui/core/IconButton';
import BrushIcon from '@material-ui/icons/Brush'; */
import makeStyles from '@material-ui/core/styles/makeStyles';

import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import {
  SQUAD_USER_KEYS,
  USER_TYPE_KEYS,
  ROLE_KEYS,
  FIELD_TECH_OPTIONS,
  TRACK_LOCATION_OPTIONS,
  /* COLOR_KEYS, */
} from '../../../@jumbo/constants/AppDataConstants';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import { emailNotValid, requiredMessage, phoneNotValid } from '../../../@jumbo/constants/ErrorMessages';

import { isValidPhoneNumber } from '../../../utils/FormValidation';
import { getRoles } from '../../../redux/actions/Roles';
import { getUserTypes } from '../../../redux/actions/UserTypes';
/* import { getColors } from '../../../redux/actions/Colors'; */
import { addNewSquadUser } from '../../../redux/actions/Squad';

import PhoneNumberFormat from '../../UI/PhoneNumberFormat';

const useStyles = makeStyles(theme => ({
  cardHeaderTitle: {
    fontSize: theme.overrides.MuiTypography.h2.fontSize,
    fontWeight: theme.overrides.MuiTypography.h2.fontWeight,
  },
  btnContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  boldCaption: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const pageName = 'Add User';
const breadcrumbs = [
  { label: 'Crew Members', link: '/squad' },
  { label: pageName, isActive: true },
];

const AddUserForm = () => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { authUser } = useSelector(({ auth }) => auth);
  const { roles } = useSelector(({ rolesReducer }) => rolesReducer);
  /* const { colors } = useSelector(({ colorsReducer }) => colorsReducer); */
  const { userTypes } = useSelector(({ userTypesReducer }) => userTypesReducer);

  const [dataFetched, setDataFetched] = useState(false);
  const [userType, setUserType] = useState('');
  const [userTypeError, setUserTypeError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [fieldTech, setFieldTech] = useState('1');
  const [fieldTechError, setFieldTechError] = useState('');
  const [trackLocation, setTrackLocation] = useState('1');
  const [trackLocationError, setTrackLocationError] = useState('');
  /* const [color, setColor] = useState('');
  const [pickerColor, setPickerColor] = useState('');
  const [colorError, setColorError] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false); */
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  /* const colorsList = colors.filter(color => color && color[COLOR_KEYS.NAME]).map(color => color[COLOR_KEYS.NAME]); */

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;
    const fetchData = payload => {
      return (dispatch, getState) => {
        return dispatch(getUserTypes(payload)).then(() => {
          return dispatch(getRoles(payload)).then(() => {
            /* return dispatch(getColors(payload)); */
          });
        });
      };
    };

    const promise = dispatch(fetchData({ authcode: authUser.authcode }));
    promise
      .then(() => {
        if (isActive) setDataFetched(true);
      })
      .catch(error => {
        if (isActive) setDataFetched(false);
      });

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case SQUAD_USER_KEYS.NAME:
          setNameError(msg);
          break;

        case SQUAD_USER_KEYS.EMAIL:
          setEmailError(msg);
          break;

        case SQUAD_USER_KEYS.PHONE:
          setPhoneError(msg);
          break;

        case SQUAD_USER_KEYS.ROLE:
          setRoleError(msg);
          break;

        case SQUAD_USER_KEYS.FIELD_TECH:
          setFieldTechError(msg);
          break;

        case SQUAD_USER_KEYS.TRACK_LOCATION:
          setTrackLocationError(msg);
          break;

        /* case SQUAD_USER_KEYS.COLOR:
          setColorError(msg);
          setShowColorPicker(true);
          break; */

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const userTypeChangeHandler = event => {
    setUserType(event.target.value);
    setUserTypeError('');

    if (!event.target.value) {
      setUserTypeError(requiredMessage);
    }
  };

  const emailBlurHandler = event => {
    if (!isValidEmail(event.target.value)) {
      setEmailError(emailNotValid);
    }
  };

  const nameBlurHandler = event => {
    if (!event.target.value) {
      setNameError(requiredMessage);
    }
  };

  /* const handleColorChange = (selectedColor, event) => {
    setColorError('');

    if (selectedColor) {
      const selectedColorData = colors.find(colorData => colorData[COLOR_KEYS.NAME] === selectedColor.hex);
      if (selectedColorData) {
        setColor(selectedColorData[COLOR_KEYS.ID]);
        setPickerColor(selectedColor);
      } else {
        setColor('');
        setColorError(requiredMessage);
        setPickerColor(null);
      }
    } else {
      setColor('');
      setColorError(requiredMessage);
      setPickerColor(null);
    }
  }; */

  const phoneBlurHandler = event => {
    if (!isValidPhoneNumber(event.target.value)) {
      setPhoneError(phoneNotValid);
    }
  };

  const roleChangeHandler = event => {
    setRole(event.target.value);
    setRoleError('');

    if (!event.target.value) {
      setRoleError(requiredMessage);
    }
  };

  const fieldTechChangeHandler = event => {
    setFieldTech(event.target.value);
    setFieldTechError('');

    if (!event.target.value) {
      setFieldTechError(requiredMessage);
    }
  };

  const trackLocationChangeHandler = event => {
    setTrackLocation(event.target.value);
    setTrackLocationError('');

    if (!event.target.value) {
      setTrackLocationError(requiredMessage);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formValid = true;

    if (!userTypes.map(tempUserType => tempUserType[USER_TYPE_KEYS.ID]).includes(userType)) {
      formValid = false;
      setUserTypeError(requiredMessage);
    }

    if (!isValidEmail(email)) {
      formValid = false;
      setEmailError(emailNotValid);
    }

    if (!name) {
      formValid = false;
      setNameError(requiredMessage);
    }

    /* if (!color) {
      formValid = false;
      setColorError(requiredMessage);
      setShowColorPicker(true);
    } */

    if (!isValidPhoneNumber(phone)) {
      formValid = false;
      setPhoneError(phoneNotValid);
    }

    if (!role) {
      formValid = false;
      setRoleError(requiredMessage);
    }

    if (!fieldTech) {
      formValid = false;
      setFieldTechError(requiredMessage);
    }

    if (formValid) {
      const requestData = {
        authcode: authUser.authcode,
        [SQUAD_USER_KEYS.NAME]: name,
        [SQUAD_USER_KEYS.EMAIL]: email,
        [SQUAD_USER_KEYS.PHONE]: phone,
        [SQUAD_USER_KEYS.TYPE]: userType,
        [SQUAD_USER_KEYS.ROLE]: role,
        [SQUAD_USER_KEYS.FIELD_TECH]: fieldTech,
        [SQUAD_USER_KEYS.TRACK_LOCATION]: trackLocation,
        [SQUAD_USER_KEYS.COLOR]: /* color */ '',
      };

      dispatch(
        addNewSquadUser(
          requestData,
          () => {
            if (_isMounted.current) history.push('/squad');
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        {dataFetched && (
          <Grid item xs={12}>
            <form onSubmit={handleFormSubmit}>
              <GridContainer>
                <Grid item xs={12}>
                  <Card classes={{ root: classes.card }}>
                    <CardHeader
                      title="User Details"
                      classes={{
                        title: classes.cardHeaderTitle,
                      }}
                    />
                    <CardContent>
                      <GridContainer>
                        {/* <Grid item xs={12}>
                          <Typography variant="caption" display="block">
                            {userType === 'user' && 'Can login and work on your account'}
                            {userType === 'subcontractor' && 'Can not login, can take jobs and get messages'}
                          </Typography>
                        </Grid> */}
                        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                          <Grid item xs={12}>
                            {miscellaneousErrors.map((miscellaneousError, idx) => (
                              <Typography
                                variant="caption"
                                display="block"
                                color="error"
                                gutterBottom
                                key={`misc-error-${idx}`}>
                                {miscellaneousError}
                              </Typography>
                            ))}
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <AppSelectBox
                            fullWidth
                            name="user_type"
                            data={userTypes}
                            label="Type"
                            valueKey={USER_TYPE_KEYS.ID}
                            variant="outlined"
                            labelKey={USER_TYPE_KEYS.NAME}
                            value={userType}
                            onChange={userTypeChangeHandler}
                            helperText={userTypeError}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="email"
                            variant="outlined"
                            label="Email Address"
                            value={email}
                            onChange={e => {
                              setEmail(e.target.value);
                              setEmailError('');
                            }}
                            onBlur={emailBlurHandler}
                            helperText={emailError}
                          />
                          <Typography variant="caption" display="block">
                            Where should we send the invite
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" flexDirection="row" alignItems="baseline" m={0} p={0}>
                            <AppTextInput
                              type="text"
                              name="name"
                              variant="outlined"
                              label="Name"
                              value={name}
                              onChange={e => {
                                setName(e.target.value);
                                setNameError('');
                              }}
                              onBlur={nameBlurHandler}
                              helperText={nameError}
                            />
                            {/* <IconButton aria-label="pick color" onClick={() => setShowColorPicker(prevState => !prevState)}>
                              <BrushIcon />
                            </IconButton> */}
                          </Box>
                        </Grid>
                        {/* showColorPicker && (
                          <Grid item xs={12}>
                            <Typography variant="caption" display="block" gutterBottom className={classes.boldCaption}>
                              Schedule Color
                            </Typography>
                            {colorsList.length > 0 && (
                              <TwitterPicker
                                color={pickerColor ? pickerColor : ''}
                                colors={colorsList}
                                triangle="hide"
                                width="100"
                                onChangeComplete={handleColorChange}
                              />
                            )}
                            {colorError && (
                              <Typography variant="caption" display="block" color="error" gutterBottom>
                                {colorError}
                              </Typography>
                            )}
                          </Grid>
                        ) */}
                        <Grid item xs={12}>
                          <AppTextInput
                            fullWidth
                            type="text"
                            name="phone"
                            variant="outlined"
                            label="Phone"
                            value={phone}
                            onChange={e => {
                              setPhone(e.target.value);
                              setPhoneError('');
                            }}
                            onBlur={phoneBlurHandler}
                            helperText={phoneError}
                            InputProps={{
                              inputComponent: PhoneNumberFormat,
                            }}
                          />
                        </Grid>
                        {
                          /* userType === 'user' && ( */
                          <Grid item xs={12}>
                            <AppSelectBox
                              fullWidth
                              name="role"
                              data={roles}
                              label="Permission Level"
                              valueKey={ROLE_KEYS.ID}
                              variant="outlined"
                              labelKey={ROLE_KEYS.NAME}
                              value={role}
                              onChange={roleChangeHandler}
                              helperText={roleError}
                            />
                          </Grid>
                          /* ) */
                        }
                        {
                          /* userType === 'user' && ( */
                          <Grid item xs={12}>
                            <AppSelectBox
                              fullWidth
                              name="field_tech"
                              data={FIELD_TECH_OPTIONS}
                              label="Field Tech"
                              valueKey="slug"
                              variant="outlined"
                              labelKey="title"
                              value={fieldTech}
                              onChange={fieldTechChangeHandler}
                              helperText={fieldTechError}
                            />
                          </Grid>
                          /* ) */
                        }
                        {
                          /* userType === 'user' && ( */
                          <Grid item xs={12}>
                            <AppSelectBox
                              fullWidth
                              name="track_locatiom"
                              data={TRACK_LOCATION_OPTIONS}
                              label="Track Location"
                              valueKey="slug"
                              variant="outlined"
                              labelKey="title"
                              value={trackLocation}
                              onChange={trackLocationChangeHandler}
                              helperText={trackLocationError}
                            />
                          </Grid>
                          /* ) */
                        }
                        <Grid item xs={12}>
                          <Button type="submit" variant="contained" color="primary">
                            Invite User
                          </Button>
                        </Grid>
                      </GridContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </GridContainer>
            </form>
          </Grid>
        )}
      </GridContainer>
    </PageContainer>
  );
};

export default AddUserForm;

import { GET_INVOICE_SIGNATURES, ADD_INVOICE_SIGNATURE } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  invoiceSignatures: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE_SIGNATURES: {
      return {
        ...state,
        invoiceSignatures: action.payload,
      };
    }
    case ADD_INVOICE_SIGNATURE: {
      return {
        ...state,
        invoiceSignatures: [action.payload, ...state.invoiceSignatures],
      };
    }
    default:
      return state;
  }
};

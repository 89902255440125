export const getAddressString = parts => {
  let addressString = '';
  if (parts.addressOne) addressString = parts.addressOne;
  if (parts.addressTwo) addressString = `${addressString}${addressString ? ' ' : ''}${parts.addressTwo}`;
  if (parts.city) addressString = `${addressString}${addressString ? ', ' : ''}${parts.city}`;
  if (parts.state) addressString = `${addressString}${addressString ? ', ' : ''}${parts.state}`;
  if (parts.zipcode) addressString = `${addressString}${addressString ? ', ' : ''}${parts.zipcode}`;
  if (parts.country) addressString = `${addressString}${addressString ? ', ' : ''}${parts.country}`;
  return addressString;
};

export const getItemObjectNameFromId = (list, id, idKey, nameKey) => {
  let itemName = '';
  if (idKey && nameKey) {
    const itemData = list.find(listItem => listItem[idKey] === id);
    itemName = itemData ? itemData[nameKey] : '';
  }
  return itemName;
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
/* import Autocomplete from '@material-ui/lab/Autocomplete'; */
/* import AddIcon from '@material-ui/icons/Add'; */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CreateIcon from '@material-ui/icons/Create';
import PageviewIcon from '@material-ui/icons/Pageview';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
/* import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput'; */
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import { ESTIMATE_KEYS, ESTIMATE_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  smRightAlign: {
    [theme.breakpoints.up('sm')]: { textAlign: 'right' },
  },
  btnAddEstimate: {
    marginRight: theme.spacing(2),
  },
}));

const EstimateHeader = ({
  miscellaneousErrors,
  onStatusChange,
  onPreviewEstimate,
  onDownloadEstimate,
  onDeleteEstimate,
  onSignatureClick,
  onSendEstimate,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { currentEstimate } = useSelector(({ estimatesReducer }) => estimatesReducer);
  const { estimateStatuses } = useSelector(({ estimateStatusesReducer }) => estimateStatusesReducer);
  /* const { jobEstimates } = useSelector(({ jobsReducer }) => jobsReducer); */

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  /* const handleEstimateChange = (event, estimateId, reason) => history.push(`/estimate/${estimateId}/`); */

  const handleEstimateStatusChange = event => onStatusChange(event);

  const handleViewJob = () => {
    handleClose();
    history.push(`/job/${currentEstimate[ESTIMATE_KEYS.JOB_ID]}/`);
  };

  const handlePreviewEstimate = () => {
    handleClose();
    onPreviewEstimate();
  };

  const handleDownloadEstimate = () => {
    handleClose();
    onDownloadEstimate();
  };

  const handleSignature = () => {
    handleClose();
    onSignatureClick();
  };

  const handleSendEstimate = () => {
    handleClose();
    onSendEstimate();
  };

  const handleDeleteEstimate = () => {
    handleClose();
    onDeleteEstimate();
  };

  return (
    <GridContainer>
      {/* <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          disableClearable
          id="job_estimates"
          value={currentEstimate[ESTIMATE_KEYS.ID]}
          options={jobEstimates.map(jobEstimate => jobEstimate[ESTIMATE_KEYS.ID])}
          getOptionLabel={option => {
            if (option) {
              const selectedEstimate = jobEstimates.find(jobEstimate => jobEstimate[ESTIMATE_KEYS.ID] === option);
              if (selectedEstimate) {
                return selectedEstimate[ESTIMATE_KEYS.NAME];
              } else {
                return '';
              }
            } else {
              return '';
            }
          }}
          getOptionSelected={(option, value) => option === value || option === null}
          renderInput={params => (
            <AppTextInput {...params} type="text" name="job_estimate" label="Estimate" variant="outlined" margin="dense" />
          )}
          onChange={(e, value, reason) => handleEstimateChange(e, value, reason)}
          classes={{ root: classes.listAutocomplete }}
        />
      </Grid> */}
      <Grid item xs={12} sm={6} md={3}>
        <AppSelectBox
          fullWidth
          name="estimate_status"
          label="Status"
          variant="standard"
          size="small"
          data={estimateStatuses}
          value={currentEstimate[ESTIMATE_KEYS.STATUS]}
          valueKey={ESTIMATE_STATUS_KEYS.ID}
          labelKey={ESTIMATE_STATUS_KEYS.NAME}
          onChange={handleEstimateStatusChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={9} className={classes.smRightAlign}>
        {/* <Button type="button" variant="contained" color="primary" startIcon={<AddIcon />} className={classes.btnAddEstimate}>
          Add New Estimate
        </Button> */}
        <Button
          type="button"
          aria-controls="estimate-actions-menu"
          aria-haspopup="true"
          variant="contained"
          color="secondary"
          endIcon={<ExpandMoreIcon />}
          onClick={handleOpen}>
          Actions
        </Button>
        <Menu
          id="estimate-actions-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}>
          <MenuItem onClick={handleViewJob}>
            <ListItemIcon>
              <PageviewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Job" />
          </MenuItem>
          <MenuItem onClick={handlePreviewEstimate}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Preview" />
          </MenuItem>
          <MenuItem onClick={handleDownloadEstimate}>
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </MenuItem>
          <MenuItem onClick={handleSignature}>
            <ListItemIcon>
              <CreateIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sign" />
          </MenuItem>
          <MenuItem onClick={handleSendEstimate}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Send" />
          </MenuItem>
          <MenuItem onClick={handleDeleteEstimate}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        </Menu>
      </Grid>
      {miscellaneousErrors && miscellaneousErrors.length > 0 && (
        <Grid item xs={12}>
          {miscellaneousErrors.map((miscellaneousError, idx) => (
            <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
              {miscellaneousError}
            </Typography>
          ))}
        </Grid>
      )}
    </GridContainer>
  );
};

EstimateHeader.propTypes = {
  miscellaneousErrors: PropTypes.array.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onPreviewEstimate: PropTypes.func.isRequired,
  onDownloadEstimate: PropTypes.func.isRequired,
  onSignatureClick: PropTypes.func.isRequired,
  onSendEstimate: PropTypes.func.isRequired,
  onDeleteEstimate: PropTypes.func.isRequired,
};

export default EstimateHeader;

import React from 'react';
import { useSelector } from 'react-redux';

import { permitMenuAccess } from '../../utils/AccessCheck';

const RestrictredMenu = props => {
  const { authUser } = useSelector(({ auth }) => auth);

  if (!authUser || !props.menu) {
    return null;
  }

  if (!permitMenuAccess(props.menu, authUser)) {
    return null;
  }

  return props.children;
};

export default RestrictredMenu;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  ADD_CUSTOMER_PROPERTY,
  DELETE_CUSTOMER_PROPERTY,
  EDIT_CUSTOMER_PROPERTY,
  GET_CUSTOMER_PROPERTIES,
  SET_CUSTOMER_PROPERTY_DETAILS,
  SET_CUSTOMER_DETAILS,
  SET_JOB_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  GET_CUSTOMER_PROPERTIES_LINK,
  SEARCH_CUSTOMER_PROPERTIES_LINK,
  ADD_CUSTOMER_PROPERTY_LINK,
  ADD_CUSTOMER_PROPERTY_WITH_TYPE_LINK,
  UPDATE_CUSTOMER_PROPERTY_LINK,
  UPDATE_CUSTOMER_PROPERTY_WITH_TYPE_LINK,
  SAVE_JOB_PROPERTY_LINK,
  DELETE_CUSTOMER_PROPERTY_LINK,
} from '../../@jumbo/constants/APIConstants';
import { PROPERTY_KEYS } from '../../@jumbo/constants/AppDataConstants';

export const getCustomerProperties = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_PROPERTIES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.properties) {
              const customerProperties = data.properties;
              dispatch(fetchSuccess());
              dispatch({ type: GET_CUSTOMER_PROPERTIES, payload: customerProperties });
              if (callbackFun) callbackFun(customerProperties);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentCustomerProperty = customerProperty => {
  return dispatch => {
    dispatch({ type: SET_CUSTOMER_PROPERTY_DETAILS, payload: customerProperty });
  };
};

export const addNewCustomerProperty = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_CUSTOMER_PROPERTY_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.property) {
              dispatch(fetchSuccess('Customer Property was added successfully.'));
              dispatch({ type: ADD_CUSTOMER_PROPERTY, payload: data.property });
              if (callbackFun) callbackFun(data.property);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const addNewCustomerPropertyWithType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_CUSTOMER_PROPERTY_WITH_TYPE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.property) {
              dispatch(fetchSuccess('Customer Property was added successfully.'));
              dispatch({ type: ADD_CUSTOMER_PROPERTY, payload: data.property });
              if (data.client) dispatch({ type: SET_CUSTOMER_DETAILS, payload: data.client });
              if (callbackFun) callbackFun(data.property);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateCustomerProperty = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_CUSTOMER_PROPERTY_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.property) {
              dispatch(fetchSuccess('Selected property was updated successfully.'));
              dispatch({ type: EDIT_CUSTOMER_PROPERTY, payload: data.property });
              if (callbackFun) callbackFun(data.property);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateCustomerPropertyWithType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_CUSTOMER_PROPERTY_WITH_TYPE_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.property) {
              dispatch(fetchSuccess('Selected property was updated successfully.'));
              dispatch({ type: EDIT_CUSTOMER_PROPERTY, payload: data.property });
              if (data.client) dispatch({ type: SET_CUSTOMER_DETAILS, payload: data.client });
              if (callbackFun) callbackFun(data.property);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const saveJobProprty = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(SAVE_JOB_PROPERTY_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;
        let apiErrors = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.property) {
              dispatch(fetchSuccess('Property was saved successfully.'));
              const requestPropertyId = request.jobPropertyData[PROPERTY_KEYS.PROPERTY_ID];
              if (requestPropertyId === 'new') {
                dispatch({ type: ADD_CUSTOMER_PROPERTY, payload: data.property });
              } else {
                dispatch({ type: EDIT_CUSTOMER_PROPERTY, payload: data.property });
              }
              if (data.job) dispatch({ type: SET_JOB_DETAILS, payload: data.job });
              if (callbackFun) callbackFun(data.property);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = 'There was a problem.';
            apiErrors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteCustomerProperty = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.customerPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(DELETE_CUSTOMER_PROPERTY_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            dispatch(fetchSuccess('Selected property was deleted successfully.'));
            dispatch({
              type: DELETE_CUSTOMER_PROPERTY,
              payload: request.customerPropertyData[PROPERTY_KEYS.PROPERTY_ID],
            });
            if (callbackFun) callbackFun();
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was a problem';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          dispatch(fetchError(errorMsg));
          if (errorCallbackFun) errorCallbackFun();
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const searchPropertiesForJob = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerPropertyData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(SEARCH_CUSTOMER_PROPERTIES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.properties) {
              const customerProperties = data.properties;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customerProperties);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getCustomerPropertiesForJob = (request = {}, callbackFun) => {
  return dispatch => {
    const body = request.customerData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_CUSTOMER_PROPERTIES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.properties) {
              const customerProperties = data.properties;
              dispatch(fetchSuccess());
              if (callbackFun) callbackFun(customerProperties);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

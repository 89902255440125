import React /* , { useState } */ from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyles from '../index.style';

import TextToHtml from '../../../../@jumbo/components/Common/TextToHtml';
import { getFormattedDate } from '../../../../@jumbo/utils/dateHelper';
import { AUTOMATED_MESSAGE_SENDER_ID, MESSAGE_KEYS } from '../../../../@jumbo/constants/AppDataConstants';

/* import CmtImage from '../../../../@coremat/CmtImage'; */

/* import MediaViewer from '../MediaViewer'; */

const SentMessageCell = ({ message }) => {
  const classes = useStyles();

  /* const [position, setPosition] = useState(-1); */

  /* const handleClose = () => {
    setPosition(-1);
  }; */

  return (
    <Box className={clsx(classes.chatMsgItem, classes.sentMsgItem)}>
      <Box className={classes.chatMsgContent}>
        {message[MESSAGE_KEYS.SENDER_ID] !== AUTOMATED_MESSAGE_SENDER_ID && (
          <Box className={classes.chatSenderName}>{message[MESSAGE_KEYS.SENDER_NAME]}</Box>
        )}
        <Box className={classes.chatBubble}>
          {message[MESSAGE_KEYS.SENDER_ID] === AUTOMATED_MESSAGE_SENDER_ID && (
            <Typography variant="subtitle1" gutterBottom className={classes.chatMsgSubject}>
              {message[MESSAGE_KEYS.SUBJECT]}
            </Typography>
          )}
          <TextToHtml content={message[MESSAGE_KEYS.MESSAGE]} />
          {/* message[MESSAGE_KEYS.MEDIA] && message[MESSAGE_KEYS.MEDIA].length > 0 && (
            <Box className={classes.chatBubbleImg}>
              <Box className={classes.chatBubbleImgRow}>
                {message[MESSAGE_KEYS.MEDIA].map((data, index) => (
                  <Box key={index} className={classes.chatBubbleImgItem} onClick={() => setPosition(index)}>
                    <Box className={classes.chatBubbleImgItemInner}>
                      {data[MESSAGE_KEYS.MEDIA_TYPE].startsWith('image') ? (
                        <CmtImage
                          key={index}
                          src={data[MESSAGE_KEYS.MEDIA_LINK]}
                          alt={data[MESSAGE_KEYS.MEDIA_NAME]}
                          height={100}
                          width={100}
                        />
                      ) : (
                        <iframe
                          key={index}
                          src={data[MESSAGE_KEYS.MEDIA_LINK]}
                          title={data[MESSAGE_KEYS.MEDIA_NAME]}
                          height={100}
                          width={100}
                        />
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ) */}
        </Box>
        <Box className={classes.chatTime}>
          {`${getFormattedDate(message[MESSAGE_KEYS.SENT_AT], 'h:mm:ss a')} ${
            message[MESSAGE_KEYS.SENDER_ID] === AUTOMATED_MESSAGE_SENDER_ID ? ' | Automated' : ''
          } | ${message[MESSAGE_KEYS.METHOD]}`}
        </Box>
      </Box>
      {/* <MediaViewer position={position} medias={message[MESSAGE_KEYS.MEDIA]} handleClose={handleClose} /> */}
    </Box>
  );
};

export default SentMessageCell;

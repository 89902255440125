import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

import GridContainer from '../../@jumbo/components/GridContainer';
import ContentLoader from '../../@jumbo/components/ContentLoader';
import { AppName } from '../../@jumbo/constants/AppConstants';
import {
  INVOICE_KEYS,
  INVOICE_PAYMENT_COMPLETE,
  INVOICE_PAYMENT_NOT_CERTAIN,
  REQUEST_CUSTOMER_SIGNATURE,
} from '../../@jumbo/constants/AppDataConstants';

import { getInvoiceForCustomer, previewInvoiceForCustomer, downloadInvoiceForCustomer } from '../../redux/actions/Invoices';

import Header from './Header';
import InvoiceInfo from './InvoiceInfo';
import InvoicePayment from './InvoicePayment';
import JobInvoiceSignatureDialog from './JobInvoiceSignatureDialog';
import JobInvoicePreviewDialog from '../Invoices/JobInvoicePreviewDialog';
import SweetAlert from '../UI/Alert';

import useStyles from './index.style';

const CustomerPortalInvoice = () => {
  document.title = `${AppName} - Customer Portal`;

  const _isMounted = useRef(true);
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const invoiceId = params.invoiceId;

  const [invoiceFetching, setInvoiceFetching] = useState(true);
  const [invoiceFetched, setInvoiceFetched] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const [previewLink, setPreviewLink] = useState('');
  const [showSignatureBox, setShowSignatureBox] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    if (invoiceId && invoiceFetching && !invoiceFetched) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getInvoiceForCustomer(payload, invoiceData => {
              if (isActive && invoiceData) {
                setInvoice(invoiceData.invoice);
                setCustomer(invoiceData.customer);
                setProducts(invoiceData.products);
                setTotals(invoiceData.totals);
                setSignatures(invoiceData.signatures);
                setShowSignatureBox(invoiceData.requestSignature === REQUEST_CUSTOMER_SIGNATURE);
                setInvoiceFetched(true);
                setInvoiceFetching(false);
              }
            }),
          );
        };
      };

      const payload = { invoiceData: { [INVOICE_KEYS.INVOICE_ID]: invoiceId } };

      const promise = dispatch(fetchData(payload));
      promise.catch(error => {
        if (isActive) {
          setInvoiceFetched(false);
          setInvoiceFetching(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, invoiceId, invoiceFetching, invoiceFetched]);

  const handleSetInvoiceAfterPayment = () => {
    setInvoice(prevState => {
      return {
        ...prevState,
        [INVOICE_KEYS.PAYMENT_COMPLETE]: INVOICE_PAYMENT_COMPLETE,
      };
    });
  };

  const handleSetInvoiceAfterFailedPayment = () => {
    setInvoice(prevState => {
      return {
        ...prevState,
        [INVOICE_KEYS.PAYMENT_COMPLETE]: INVOICE_PAYMENT_NOT_CERTAIN,
      };
    });
  };

  const handlePreviewInvoice = () => {
    setPreviewLink('');

    const invoiceData = {
      [INVOICE_KEYS.INVOICE_ID]: invoice[INVOICE_KEYS.ID],
    };

    dispatch(
      previewInvoiceForCustomer({ invoiceData: invoiceData }, link => {
        if (_isMounted.current) {
          setPreviewLink(link);
          setOpenPreviewDialog(true);
        }
      }),
    );
  };

  const handleClosePreviewDialog = () => setOpenPreviewDialog(false);

  const handleDownloadInvoice = () => {
    const invoiceData = {
      [INVOICE_KEYS.INVOICE_ID]: invoice[INVOICE_KEYS.ID],
    };

    dispatch(
      downloadInvoiceForCustomer({ invoiceData: invoiceData }, link => {
        if (_isMounted.current) {
          SweetAlert({
            icon: 'success',
            title: 'Your download is ready!!',
            html: `Click <a href="${link}" target="_blank" download class="${classes.downloadLink}">here</a> to download.`,
          });
        }
      }),
    );
  };

  const handleSignatureClick = () => setOpenSignatureDialog(true);

  const handleCloseSignatureDialog = () => setOpenSignatureDialog(false);

  const handleSignatureSuccess = signature => {
    setShowSignatureBox(false);
    setSignatures(prevState => {
      return [...prevState, signature];
    });
  };

  const invoiceIsValid = invoice && invoice[INVOICE_KEYS.ID];

  return (
    <Paper className={classes.paper}>
      {!invoiceFetching && invoiceFetched && invoiceIsValid && (
        <React.Fragment>
          <GridContainer>
            <Grid item xs={12}>
              <Header
                classes={classes}
                invoice={invoice}
                onPreviewInvoice={handlePreviewInvoice}
                onDownloadInvoice={handleDownloadInvoice}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InvoiceInfo
                classes={classes}
                invoice={invoice}
                customer={customer}
                products={products}
                totals={totals}
                signatures={signatures}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InvoicePayment
                classes={classes}
                invoice={invoice}
                customer={customer}
                totals={totals}
                showSignatureBox={showSignatureBox}
                onSignatureClick={handleSignatureClick}
                onSetInvoiceAfterPayment={handleSetInvoiceAfterPayment}
                onSetInvoiceAfterFailedPayment={handleSetInvoiceAfterFailedPayment}
              />
            </Grid>
          </GridContainer>

          {openPreviewDialog && (
            <JobInvoicePreviewDialog
              invoice={invoice}
              link={previewLink}
              open={openPreviewDialog}
              onClose={handleClosePreviewDialog}
            />
          )}

          {openSignatureDialog && (
            <JobInvoiceSignatureDialog
              invoice={invoice}
              customer={customer}
              open={openSignatureDialog}
              onSignatureSuccess={handleSignatureSuccess}
              onClose={handleCloseSignatureDialog}
            />
          )}

          <ContentLoader />
        </React.Fragment>
      )}

      {!invoiceFetching && (!invoiceFetched || !invoiceIsValid) && (
        <GridContainer>
          <Grid item xs={12}>
            <Alert severity="error">Invoice data not found!</Alert>
          </Grid>
        </GridContainer>
      )}
    </Paper>
  );
};

export default CustomerPortalInvoice;

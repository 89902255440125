import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
/* import DoneIcon from '@material-ui/icons/Done'; */
/* import ListIcon from '@material-ui/icons/List'; */
/* import FileCopyIcon from '@material-ui/icons/FileCopy'; */
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import { JOB_KEYS, JOB_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  btnJobAction: {
    margin: theme.spacing(1),
  },
  btnJobDone: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      borderColor: theme.palette.success.main,
    },
  },
  btnJobDelete: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const EditJobHeader = ({
  miscellaneousErrors,
  onStatusChange,
  onCreateInvoice,
  /* onMarkJobDone, */
  /* onDuplicateJob, */
  onSendCustomerJobLink,
  onDeleteJob,
}) => {
  const classes = useStyles();

  const { currentJob } = useSelector(({ jobsReducer }) => jobsReducer);
  const { jobStatuses } = useSelector(({ jobStatusesReducer }) => jobStatusesReducer);

  return (
    <GridContainer>
      <Grid item xs={12} md={3}>
        <AppSelectBox
          fullWidth
          name="job_status"
          label="Status"
          variant="standard"
          size="small"
          data={jobStatuses}
          value={currentJob[JOB_KEYS.STATUS_ID]}
          valueKey={JOB_STATUS_KEYS.ID}
          labelKey={JOB_STATUS_KEYS.NAME}
          onChange={onStatusChange}
        />
      </Grid>
      {miscellaneousErrors && miscellaneousErrors.length > 0 && (
        <Grid item xs={12}>
          {miscellaneousErrors.map((miscellaneousError, idx) => (
            <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
              {miscellaneousError}
            </Typography>
          ))}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" flexWrap="wrap" justifyContent={{ md: /* 'space-between' */ 'flex-start' }} alignItems="center">
          {currentJob[JOB_KEYS.INVOICE_EXISTS] === '1' ? (
            <Button
              component={Link}
              to={`/jobInvoice/${currentJob[JOB_KEYS.INVOICE_ID]}/`}
              type="button"
              variant="outlined"
              color="primary"
              startIcon={<ReceiptIcon />}
              className={classes.btnJobAction}>
              View Invoice
            </Button>
          ) : (
            <Button
              type="button"
              variant="outlined"
              color="primary"
              startIcon={<ReceiptIcon />}
              className={classes.btnJobAction}
              onClick={onCreateInvoice}>
              Create Invoice
            </Button>
          )}
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<SendIcon />}
            className={classes.btnJobAction}
            onClick={onSendCustomerJobLink}>
            Send Customer Job Link
          </Button>
          {/*  <Button type="button" variant="outlined" color="primary" startIcon={<ListIcon />} className={classes.btnJobAction}>
            View Work Order
          </Button> */}
          {/* <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<FileCopyIcon />}
            className={classes.btnJobAction}
            onClick={onDuplicateJob}>
            Duplicate Job
          </Button> */}
          {/* <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<DoneIcon />}
            className={classes.btnJobAction}
            classes={{ outlinedPrimary: classes.btnJobDone }}
            onClick={onMarkJobDone}>
            Mark Job Done
          </Button> */}
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<DeleteIcon />}
            className={classes.btnJobAction}
            classes={{ outlinedPrimary: classes.btnJobDelete }}
            onClick={onDeleteJob}>
            Delete Job
          </Button>
        </Box>
      </Grid>
    </GridContainer>
  );
};

EditJobHeader.propTypes = {
  miscellaneousErrors: PropTypes.array.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  /* onMarkJobDone: PropTypes.func.isRequired, */
  onSendCustomerJobLink: PropTypes.func.isRequired,
  onDeleteJob: PropTypes.func.isRequired,
};

export default EditJobHeader;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  accordion: {
    width: '100%',
    padding: theme.spacing(2),
  },
  accordionHeading: {
    ...theme.overrides.MuiTypography.h3,
  },
  accordionDetails: {
    flexWrap: 'wrap',
  },
  container: {
    maxHeight: 415,
    flexBasis: '100%',
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  pagination: {
    width: '100%',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.black,
  },
}));

export default useStyles;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { ADD_JOB_TYPE, EDIT_JOB_TYPE, GET_JOB_TYPES, SET_JOB_TYPE_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { GET_JOB_TYPES_LINK, ADD_JOB_TYPE_LINK, UPDATE_JOB_TYPE_LINK } from '../../@jumbo/constants/APIConstants';

export const getJobTypes = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_JOB_TYPES_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobtypes) {
              const jobTypes = data.jobtypes;
              dispatch(fetchSuccess());
              dispatch({ type: GET_JOB_TYPES, payload: jobTypes });
              if (callbackFun) callbackFun(jobTypes);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const setCurrentJobType = jobType => {
  return dispatch => {
    dispatch({ type: SET_JOB_TYPE_DETAILS, payload: jobType });
  };
};

export const addNewJobType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(ADD_JOB_TYPE_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobtype) {
              dispatch(fetchSuccess('Job Type was added successfully.'));
              dispatch({ type: ADD_JOB_TYPE, payload: data.jobtype });
              if (callbackFun) callbackFun(data.jobtype);
            } else {
              dispatch(fetchError('Unable to get data'));
            }
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateJobType = (request = {}, callbackFun, errorCallbackFun) => {
  return dispatch => {
    const body = request.jobTypeData;
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());
    axios
      .post(UPDATE_JOB_TYPE_LINK, body, { headers })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.jobtype) {
              dispatch(fetchSuccess('Job Type was updated successfully.'));
              dispatch({ type: EDIT_JOB_TYPE, payload: data.jobtype });
              if (callbackFun) callbackFun(data.jobtype);
            } else {
              dispatch(fetchError('Unable to get data'));
            }
          } else {
            dispatch(fetchError('There was a problem.'));
            const errors = responseData.data && responseData.data.errors ? responseData.data.errors : null;
            if (errorCallbackFun) errorCallbackFun(errors);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

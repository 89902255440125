// Miscellaneous
export const COLOR_LOGO_LINK = '/images/aaarousse_logo.png';
export const TRANSPARENT_LOGO_LINK = '/images/aaarousse_transaprent_logo.png';
export const COLOR_LOGO_SYMBOL_LINK = '/images/aaarousse_symbol_logo.png';
export const TRANSPARENT_LOGO_SYMBOL_LINK = '/images/aaarousse_transparent_symbol_logo.png';
export const EXPORT_LINK_KEY = 'export_link';
export const AAA_ROUSE_PHONE_NO = '8004331094';

// Squad
export const ACTIVE_SQUAD_USER = '1';
export const INACTIVE_SQUAD_USER = '0';
export const INVITED_SQUAD_USER = '1';
export const UNINVITED_SQUAD_USER = '0';

export const SQUAD_USER_KEYS = {
  ID: 'id',
  USER_ID: 'user_id',
  NAME: 'full_name',
  EMAIL: 'email',
  PHONE: 'phone_number',
  ROLE: 'user_role_id',
  ROLE_NAME: 'role_name',
  FIELD_TECH: 'field_tech',
  TRACK_LOCATION: 'location_tracking',
  TYPE: 'user_type_id',
  TYPE_NAME: 'user_type_name',
  CREATED: 'created_at',
  SKILLS: 'user_skills',
  AREAS: 'metra_areas_access_ids',
  STATUS: 'status',
  INVITE_STATUS: 'invite_status',
  AUTH_CODE: 'authcode',
  AUTH_CODE_EXPIRE_DT: 'login_expire_at',
  AUTH_CODE_EXPIRE_TS: 'login_expire_timestamp',
  PERMISSIONS: 'permissions',
  COLOR: 'color_id',
  HOME_ADDRESS: 'home_address',
  NOTES: 'notes',
  ADDITIONAL_PHONE: 'add_phone_number',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  CONFIRM_PASSWORD_ALT: 'cpassword',
  INVITE_CODE: 'invite_code',
  RESET_PASSWORD_CODE: 'code',
};

export const FIELD_TECH_OPTIONS = [
  {
    title: 'Yes',
    slug: '1',
  },
  {
    title: 'No',
    slug: '0',
  },
];

export const TRACK_LOCATION_OPTIONS = [
  {
    title: 'Yes',
    slug: '1',
  },
  {
    title: 'No',
    slug: '0',
  },
];

// User Types
export const USER_TYPE_KEYS = {
  ID: 'id',
  NAME: 'user_type_name',
};

// COLORS
export const COLOR_KEYS = {
  ID: 'id',
  CODE: 'color_code',
  NAME: 'value',
};

// Roles
export const ADMIN_ROLE = '1';
export const DISPATCH_ROLE = '4';
export const EMPLOYEE_ROLE = '27';
export const SUBCONTRACTOR_ROLE = '28';
export const ROLE_KEYS = {
  ID: 'id',
  ROLE_ID: 'role_id',
  NAME: 'role_name',
  LOGIN: 'can_login',
  ACCESS_ALL_JOBS: 'view_all_jobs',
  ACCESS_ASSIGNED_JOBS: 'view_assigned_jobs',
  DASHBOARD: 'dashboard',
  ACCOUNT_SETTINGS: 'account_settings',
  USER_SETTINGS: 'user_settings',
  MESSAGING: 'messaging',
  CLIENTS: 'clients',
  JOB_SETTINGS: 'job_settings',
  NOTIFICATIONS: 'notifications',
  ADD_JOBS: 'add_jobs',
  MODIFY_DONE_JOBS: 'modify_done_jobs',
  OTHER_USER_JOBS: 'other_users_jobs',
  JOB_HISTORY: 'job_history',
  CHARGE_CREDIT_CARDS: 'charge_credit_cards',
  CLOSE_JOBS: 'close_jobs',
  CANCEL_JOBS: 'cancel_jobs',
  OVERRIDE_RATES: 'override_rates',
  UPDATE_JOB_COST: 'update_job_cost',
  SETTLE_JOBS: 'settle_jobs',
  CHANGE_JOB_TYPE: 'change_job_type',
  CALENDAR_SYNC: 'calendar_sync',
  LEADS: 'leads',
  LEAD_SETTINGS: 'lead_settings',
  VOICE: 'voice',
  VOICE_SETTINGS: 'voice_settings',
  FRANCHISES: 'franchises',
  INVENTORY: 'inventory',
  FINANCIAL_DATA: 'financial_data',
  CLIENT_MESSAGES: 'client_messages',
  FRANCHISE_SETTINGS: 'franchise_settings',
  JOB_REPORT: 'job_report',
  COMMISSIONS_REPORT: 'commissions_report',
  CALL_REPORTS: 'call_reports',
  ACTIVITY_REPORT: 'activity_report',
  PAYMENTS_REPORT: 'payments_report',
  ITEM_SERVICES_REPORT: 'items_services_report',
  INVOICE_REPORT: 'invoice_report',
  ESTIMATE_REPORT: 'estimate_report',
  BALANCE_REPORT: 'balance_report',
  HOURS_REPORT: 'hours_report',
  STATISTICS_REPORT: 'statistics_report',
  AD_STATISTICS: 'ad_statistics',
  TECH_STATISTICS: 'tech_statistics',
  AREA_STATISTICS: 'area_statistics',
  DISPATCH_STATISTICS: 'dispatch_statistics',
  VIEW_PROFIT: 'view_profit',
  LEAD_REPORT: 'lead_report',
  SALES_REPORT: 'sales_report',
  METRO_AREAS_ACCESS_IDS: 'metra_areas_access_ids',
  USERS_ACCESS_IDS: 'users_access_ids',
  STATUS_ACCESS_IDS: 'status_access_ids',
};

// Job Types
export const ACTIVE_JOB_TYPE = '1';
export const INACTIVE_JOB_TYPE = '0';
export const JOB_TYPE_KEYS = {
  ID: 'id',
  JOB_TYPE_ID: 'jobtype_id',
  JOB_ID: 'job_id',
  NAME: 'name',
  DISPLAY_ORDER: 'display_order',
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  DURATION: 'duration',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Taxes
export const ACTIVE_TAX = '1';
export const INACTIVE_TAX = '0';
export const TAX_KEYS = {
  ID: 'id',
  TAX_ID: 'tax_id',
  NAME: 'name',
  RATE: 'tax_rate',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Products
export const ACTIVE_PRODUCT = '1';
export const INACTIVE_PRODUCT = '0';
export const PRODUCT_KEYS = {
  ID: 'id',
  PRODUCT_ID: 'item_id',
  JOB_ID: 'job_id',
  TITLE: 'title',
  DESCRIPTION: 'description',
  PRICE: 'price',
  UNIT_COST: 'unit_cost',
  TYPE: 'type',
  SERIAL_NUMBER: 'serial',
  /* TAXABLE: 'taxable', */
  IMAGE: 'image',
  IMAGE_URL: 'image_link',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Metro Areas
export const METRO_AREA_KEYS = {
  ID: 'id',
  AREA_NAME: 'area_name',
  CENTER_ZIPCODE: 'center_zipcode',
  MILE_RADIUS: 'mile_radius',
  COLOR: 'color',
  DEFAULT_TAX_ID: 'default_tax_id',
  ADVANCE_AREA_SELECR: 'advance_area_select',
  ENABLED: 'enabled',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Ad Sources
export const AD_SOURCES_KEYS = {
  ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  DISPLAY_ORDER: 'display_order',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Notification
export const NOTIFICATION_KEYS = {};

// Customers
export const CUSTOMER_KEYS = {
  ID: 'id',
  CUSTOMER_ID: 'client_id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  COMPANY_NAME: 'client_company_name',
  PHONE_ONE: 'primary_phone',
  EXTENSION_ONE: 'ext_primary',
  PHONE_TWO: 'alternate_no',
  EXTENSION_TWO: 'ext_alt',
  EMAIL: 'email',
  AD_SOURCE: 'ad_source_id',
  ADDRESS_ONE: 'address' /* require for add customer form */,
  ADDRESS_TWO: 'unit' /* require for add customer form */,
  ZIP_CODE: 'zip' /* require for add customer form */,
  CITY: 'city' /* require for add customer form */,
  STATE: 'state_id' /* require for add customer form */,
  COUNTRY: 'country_id' /* require for add customer form */,
  MAIN_ADDRESS: 'main_property_id',
  BILL_ADDRESS: 'bill_property_id',
  STATUS: 'status',
  NAME: 'name' /* require for table header */,
  FORMATTED_ADDRESS: 'client_address' /* require for table header */,
  FORMATTED_CREATED: 'creation_date' /* require for table header */,
};

// Customer Tabs
export const CUSTOMER_INFO_TAB = 'customerInfoTab';
export const CUSTOMER_PROPERTIES_TAB = 'propertiesTab';

// Customer Address Types
export const CUSTOMER_MAIN_ADDRESS = 'main';
export const CUSTOMER_BILL_ADDRESS = 'bill';

// Customer Notes
export const CUSTOMER_NOTE_KEYS = {
  ID: 'id',
  NOTE_ID: 'note_id',
  CUSTOMER_ID: 'client_id',
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
  NOTE: 'note',
  STATUS: 'status',
  CREATION_DATE: 'creation_date',
};

// Customer Properties
export const PROPERTY_KEYS = {
  ID: 'id',
  PROPERTY_ID: 'property_id',
  CUSTOMER_ID: 'client_id',
  JOB_ID: 'job_id',
  NAME: 'location_name',
  ADDRESS_ONE: 'address',
  ADDRESS_TWO: 'unit',
  CITY: 'city',
  STATE: 'state_id',
  STATE_NAME: 'state_name',
  ZIP_CODE: 'zip',
  COUNTRY: 'country_id',
  COUNTRY_NAME: 'country_name',
  JOBS: 'jobs',
  TOTAL: 'total',
  DUE: 'due',
  PAST_DUE: 'past_due',
  TYPE: 'type',
  STATUS: 'status',
  CREATED: 'created',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Customer Dues
export const CUSTOMER_DUES_KEYS = {
  TOTAL_CLIENTS: 'total_clients',
  DUE_CLIENTS: 'due_clients',
  DUE_CLIENTS_COUNT: 'due_clients_count',
  PAST_DUE_CLIENTS: 'past_due_clients',
  PAST_DUE_CLIENTS_COUNT: 'past_due_clients_count',
  ESTIMATE_PENDING: 'estimate_pending',
  ESTIMATE_PENDING_COUNT: 'estimate_pending_count',
};

// Countries
export const COUNTRY_USA = '1';
export const COUNTRY_KEYS = {
  ID: 'id',
  COUNTRY_ID: 'country_id',
  CODE: 'country_key',
  NAME: 'name',
};

// USA States
export const USA_STATE_KEYS = {
  ID: 'id',
  USA_STATE_ID: 'usa_state_id',
  CODE: 'state_id',
  NAME: 'state_name',
  COUNTRY_ID: 'country_id',
};

// Jobs
export const JOB_SUBMITTED_STATUS = '1';
export const JOB_IN_PROGRESS_STATUS = '2';
export const JOB_PENDING_STATUS = '3';
export const JOB_DONE_PENDING_APP_STATUS = '4';
export const JOB_RESCHEDULE_STATUS = '5';
export const JOB_DONE_STATUS = '6';
export const JOB_CANCEL_STATUS = '7';
export const JOB_ASSIGNED_STATUS = '8';
export const JOB_SUBMITTED_STATUS_NAME = 'Not assigned';
export const JOB_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  USER_ID: 'user_id',
  CUSTOMER_ID: 'client_id',
  CUSTOMER_PROPERTY_ID: 'property_id',
  JOB_TYPE_ID: 'job_type_id',
  TAG_ID: 'tag_id',
  SERVICE_AREA_ID: 'service_area_id',
  AD_SOURCE_ID: 'ad_source_id',
  CUSTOMER_FNAME: 'first_name',
  CUSTOMER_LNAME: 'last_name',
  CUSTOMER_COMPANY: 'client_company_name',
  CUSTOMER_EMAIL: 'email',
  CUSTOMER_PHONE_ONE: 'primary_phone',
  CUSTOMER_EXTENSION_ONE: 'ext_primary',
  CUSTOMER_ADDRESS_ONE: 'address',
  CUSTOMER_ADDRESS_TWO: 'unit',
  CUSTOMER_CITY: 'city',
  CUSTOMER_STATE: 'state_id',
  CUSTOMER_ZIP_CODE: 'zip',
  CUSTOMER_COUNTRY: 'country_id',
  JOB_DESCRIPTION: 'job_description',
  START_DATE: 'scheduled_start_date',
  START_TIME: 'scheduled_start_time',
  END_DATE: 'scheduled_end_date',
  END_TIME: 'scheduled_end_time',
  TEAM_MEMBERS: 'assigned_team_members',
  FILE: 'file',
  TOTAL: 'total',
  BALANCE: 'balance',
  AMOUNT_DUE: 'due',
  PAST_DUE: 'past_due',
  TIME_IN_STATUS: 'time_in_status',
  STATUS_ID: 'job_status_id',
  INVOICE_EXISTS: 'invoice_exist',
  INVOICE_ID: 'invoice_id',
  CUSTOMER_MSG_SUBJECT: 'customer_msg_subject',
  CUSTOMER_MSG_CONTENT: 'customer_msg_content',
  CREATED_BY: 'created_by' /* require for table header */,
  CUSTOMER_STATE_NAME: 'state_name' /* require for table header */,
  STATUS: 'job_status' /* require for table header */,
  JOB_TYPE_NAME: 'job_type_name' /* require for table header */,
  SCHEDULED: 'scheduled' /* require for table header */,
  END: 'end' /* require for table header */,
  TECH: 'tech' /* require for table header */,
  SOURCE_NAME: 'source_name' /* require for table header */,
  NAME: 'name' /* require for table header */,
  FORMATTED_ADDRESS: 'client_address' /* require for table header */,
  JOB_DATE: 'job_date' /* require for table header */,
  CREATED_AT: 'created_at' /* require for table header */,
};

// Job Status
export const JOB_STATUS_KEYS = {
  ID: 'id',
  STATUS_ID: 'status_id',
  NAME: 'name',
  COLOR: 'color',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

// Job Products
export const JOB_PRODUCT_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  PRODUCT_ID: 'item_id',
  JOB_PRODUCT_ID: 'job_item_id',
  TITLE: 'itemname',
  DESCRIPTION: 'item_desc',
  QUANTITY: 'quantity',
  PRICE: 'price',
  UNIT_COST: 'cost',
  AMOUNT: 'amount',
};

// Job Products Totals
export const JOB_PRODUCTS_TOTALS_KEYS = {
  AMOUNT: 'amount',
  SUB_TOTAL: 'subtotal',
  TAX: 'tax',
  TAX_PERCENT: 'tax_per',
  TOTAL: 'total',
};

// Job 'Job Types'
export const JOB_JOB_TYPE_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  JOB_TYPE_ID: 'job_type_id',
  JOB_JOB_TYPE_ID: 'job_service_id',
  INVOICE_ID: 'invoice_id',
  ESTIMATE_ID: 'estimate_id',
  NAME: 'job_typename',
  DESCRIPTION: 'description',
  AMOUNT: 'amount',
};

// Job 'Job Types' Totals
export const JOB_JOB_TYPES_TOTALS_KEYS = {
  AMOUNT: 'amount',
  SUB_TOTAL: 'subtotal',
  TAX: 'tax',
  TAX_PERCENT: 'tax_per',
  TOTAL: 'total',
};

// Job Tags
export const JOB_TAG_KEYS = {
  ID: 'id',
  TAG_ID: 'tag_id',
  NAME: 'name',
};

// Job Payment
export const JOB_PAYMENT_KEYS = {
  ID: 'id',
  PAYMENT_ID: 'PAYMENT_id',
  JOB_ID: 'job_id',
};

// Job File
export const JOB_FILE_KEYS = {
  ID: 'id',
  FILE_ID: 'file_id',
  JOB_ID: 'job_id',
  FILE_NAME: 'file_name',
  FILE_DESCRIPTION: 'file_description',
  FILE: 'file',
  LINK: 'link',
  FORMATTED_CREATED: 'creation_date' /* require for table header */,
};

// Invoices
export const ALLOW_CUSTOMER_TO_PAY_INVOICE = '1';
export const DISALLOW_CUSTOMER_TO_PAY_INVOICE = '0';
export const INVOICE_PAYMENT_COMPLETE = '1';
export const INVOICE_PAYMENT_NOT_COMPLETE = '0';
export const INVOICE_PAYMENT_NOT_CERTAIN = '2';
export const REQUEST_CUSTOMER_SIGNATURE = '1';
export const NOT_REQUEST_CUSTOMER_SIGNATURE = '0';
export const INVOICE_KEYS = {
  ID: 'id',
  INVOICE_ID: 'invoice_id',
  CUSTOMER_ID: 'client_id',
  JOB_ID: 'job_id',
  NOTE: 'note',
  STATUS: 'status',
  STATUS_NAME: 'estimate_status',
  STATUS_ID: 'status_id',
  CUSTOMER_NAME: 'name',
  CUSTOMER_COMPANY: 'company',
  CUSTOMER_EMAIL: 'email',
  CUSTOMER_PHONE_ONE: 'phone',
  CUSTOMER_EXTENSION_ONE: 'ext_primary',
  CUSTOMER_SERVICE: 'service_address',
  CUSTOMER_BILL: 'biiling_address',
  CUSTOMER_ADDRESS_ONE: 'address',
  CUSTOMER_ADDRESS_TWO: 'unit',
  CUSTOMER_CITY: 'city',
  CUSTOMER_STATE: 'state',
  CUSTOMER_ZIP_CODE: 'zip',
  CUSTOMER_COUNTRY: 'country',
  SENT: 'sent',
  TOTAL: 'total',
  AMOUNT_DUE: 'due',
  PAST_DUE: 'past_due',
  DUE_BY: 'due_by',
  CREATED_AT: 'created_at',
  INVOICE_COUNT: 'invoice_count',
  SEND_TO_EMAIL: 'to',
  SEND_CC_EMAIL: 'cc_email',
  SEND_PHONE: 'primary_phone',
  SEND_SUBJECT: 'inv_subject',
  SEND_CONTENT: 'html',
  SEND_TYPE: 'send_type',
  ALLOW_PAYMENT: 'allow_payment',
  REQUEST_SIGNATURE: 'sign_request',
  PAYMENT_COMPLETE: 'payment',
  AMOUNT: 'amount' /* require for table header */,
  FORMATTED_ADDRESS: 'address' /* require for table header */,
  FORMATTED_CREATED: 'created' /* require for table header */,
  CUSTOMER_NAME_ALT: 'client_name',
  TRANSACTION_NO: 'transaction_id',
};

// Invoice Status
export const ALL_INVOICES_STATUS_ID = '0';
export const INVOICE_STATUS_KEYS = {
  ID: 'id',
  STATUS_ID: 'status_id',
  NAME: 'name',
};

// Invoice Products
export const INVOICE_PRODUCT_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  ESTIMATE_ID: 'estimate_id',
  INVOICE_ID: 'invoice_id',
  PRODUCT_ID: 'item_id',
  INVOICE_PRODUCT_ID: 'job_item_id',
  TITLE: 'itemname',
  DESCRIPTION: 'item_desc',
  QUANTITY: 'quantity',
  PRICE: 'price',
  UNIT_COST: 'cost',
  AMOUNT: 'amount',
};

// Invoice Products Totals
export const INVOICE_PRODUCTS_TOTALS_KEYS = {
  AMOUNT: 'amount',
  SUB_TOTAL: 'subtotal',
  TAX: 'tax',
  TAX_PERCENT: 'tax_per',
  TOTAL: 'total',
};

// Invoice Signatures
export const INVOICE_SIGNATURE_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  INVOICE_ID: 'invoice_id',
  SIGN_ID: 'sign_id',
  SIGN: 'sign',
  SIGN_LINK: 'path',
  SIGNER_NAME: 'signer_name',
  FORMATTED_CREATED: 'created_at',
};

// Invoice Payment
export const INVOICE_PAYMENT_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  INVOICE_ID: 'invoice_id',
  CARD_NUMBER: 'card_number',
  CARD_MONTH: 'card_month',
  CARD_YEAR: 'card_year',
  CARD_CVV: 'card_cvv',
  EMAIL: 'email',
  ZIP_CODE: 'zip_code',
};

// Estimates
export const ESTIMATE_KEYS = {
  ID: 'id',
  ESTIMATE_ID: 'estimate_id',
  CUSTOMER_ID: 'client_id',
  JOB_ID: 'job_id',
  NUMBER: 'number',
  NAME: 'name',
  DESCRIPTION: 'description',
  NOTE: 'note',
  STATUS: 'status',
  STATUS_NAME: 'estimate_status',
  STATUS_ID: 'status_id',
  CUSTOMER_COMPANY: 'company',
  CUSTOMER_EMAIL: 'email',
  CUSTOMER_PHONE_ONE: 'phone',
  CUSTOMER_EXTENSION_ONE: 'ext_primary',
  CUSTOMER_SERVICE: 'service_address',
  CUSTOMER_BILL: 'biiling_address',
  CUSTOMER_ADDRESS_ONE: 'address',
  CUSTOMER_ADDRESS_TWO: 'unit',
  CUSTOMER_CITY: 'city',
  CUSTOMER_STATE: 'state',
  CUSTOMER_ZIP_CODE: 'zip',
  CUSTOMER_COUNTRY: 'country',
  ESTIMATE_COUNT: 'estimate_count',
  SEND_TO_EMAIL: 'to',
  SEND_CC_EMAIL: 'cc_email',
  SEND_PHONE: 'primary_phone',
  SEND_SUBJECT: 'inv_subject',
  SEND_CONTENT: 'html',
  SEND_TYPE: 'send_type',
  CREATED_AT: 'created_at',
  SELECTED_ESTIMATES: 'selected_estimates' /* require for bulk delete and status update */,
  FORMATTED_ADDRESS: 'address' /* require for table header */,
  FORMATTED_ADDRESS_TWO: 'client_address' /* require for table data */,
  FORMATTED_CREATED: 'created' /* require for table header */,
  TOTAL: 'total' /* require for table header */,
  AMOUNT: 'amount' /* require for table header */,
  CUSTOMER_NAME: 'client_name' /* require for table header */,
};

// Estimate Status
export const ALL_ESTIMATES_STATUS_ID = '0';
export const APPROVE_ESTIMATES_STATUS_ID = '3';
export const DECLINE_ESTIMATES_STATUS_ID = '5';
export const ESTIMATE_STATUS_KEYS = {
  ID: 'id',
  STATUS_ID: 'status_id',
  NAME: 'name',
};

// Estimate Products
export const ESTIMATE_PRODUCT_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  ESTIMATE_ID: 'estimate_id',
  PRODUCT_ID: 'item_id',
  ESTIMATE_PRODUCT_ID: 'job_item_id',
  TITLE: 'itemname',
  DESCRIPTION: 'item_desc',
  QUANTITY: 'quantity',
  PRICE: 'price',
  UNIT_COST: 'cost',
  AMOUNT: 'amount',
};

// Estimate Products Totals
export const ESTIMATE_PRODUCTS_TOTALS_KEYS = {
  AMOUNT: 'amount',
  SUB_TOTAL: 'subtotal',
  TAX: 'tax',
  TAX_PERCENT: 'tax_per',
  TOTAL: 'total',
};

// Estimate Signatures
export const ESTIMATE_SIGNATURE_KEYS = {
  ID: 'id',
  JOB_ID: 'job_id',
  ESTIMATE_ID: 'estimate_id',
  SIGN_ID: 'sign_id',
  SIGN: 'sign',
  SIGN_LINK: 'path',
  SIGNER_NAME: 'signer_name',
  FORMATTED_CREATED: 'created_at',
};

// Messages
export const AUTOMATED_MESSAGE_SENDER_ID = '0';
export const MESSAGE_SEEN_TRUE = '1';
export const MESSAGE_SEEN_FALSE = '0';
export const MESSAGE_TYPE_SENT = 'sent';
export const MESSAGE_TYPE_RECEIVED = 'received';
export const MESSAGE_USER_TYPE_CUSTOMER = 'Customer';
export const MESSAGE_KEYS = {
  ID: 'id',
  MESSAGE_ID: 'message_id',
  MSG_ID: 'msg_id',
  JOB_ID: 'job_id',
  USER_ID: 'user_id',
  RECEIVER_ID: 'r_id',
  RECEIVER_ID_ALT: 'receiver_id',
  RECEIVER_TYPE: 'r_type',
  RECEIVER_TYPE_ALT: 'receiver_type',
  RECEIVER_NAME: 'name',
  RECEIVER_PHONE: 'phone',
  RECEIVER_PHOTO_LINK: 'receiver_photo_link',
  SENDER_ID: 's_id',
  SENDER_ID_ALT: 'sender_id',
  SENDER_TYPE: 's_type',
  SENDER_TYPE_ALT: 'sender_type',
  SENDER_NAME: 'sender_name',
  TYPE: 'type',
  SUBJECT: 'subject',
  MESSAGE: 'message',
  MESSAGE_PLAIN: 'message_plain',
  MEDIA: 'media',
  MEDIA_ID: 'media_id',
  MEDIA_LINK: 'media_link',
  MEDIA_NAME: 'media_name',
  MEDIA_TYPE: 'media_type',
  SEEN: 'read',
  METHOD: 'method',
  SENT_AT: 'sent',
  REPLY_LINK: 'reply_link',
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { JOB_JOB_TYPE_KEYS, JOB_TYPE_KEYS, ACTIVE_JOB_TYPE } from '../../../@jumbo/constants/AppDataConstants';
import { requiredMessage, amountNotValid, jobNotValid } from '../../../@jumbo/constants/ErrorMessages';

import { addJobJobType, updateJobJobType } from '../../../redux/actions/JobJobTypes';
import { isValidAmount } from '../../../utils/FormValidation';

import NumberFormatCustom from '../../UI/NumberFormatCustom';
import SweetAlert from '../../UI/Alert';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  listAutocomplete: {
    '& .MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  mdCenterAlign: {
    [theme.breakpoints.up('sm')]: { textAlign: 'center' },
  },
  mdRightAlign: {
    [theme.breakpoints.up('sm')]: { textAlign: 'right' },
  },
  mdZeroLeftPadding: {
    [theme.breakpoints.up('sm')]: { paddingLeft: '0 !important' },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  customAlert: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
}));

const JobTypesDialog = ({ currentJobId, open, onClose }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  /**
   * Job & jobId: Data and ID of current Job
   * Job Type & jobTypeId: Data and ID of job type selected from the Job Types list in Settings
   * Job 'Job Type' & jobJobTypeId: Data and ID of the job type ADDED to job. Also called Service.
   */

  const { authUser } = useSelector(({ auth }) => auth);
  const { currentJobJobType } = useSelector(({ jobJobTypesReducer }) => jobJobTypesReducer);
  const { jobTypes } = useSelector(({ jobTypesReducer }) => jobTypesReducer);

  const [activeJobTypes, setActiveJobTypes] = useState(null);
  const [jobId, setJobId] = useState('');
  const [jobType, setJobType] = useState(null);
  const [jobTypeId, setJobTypeId] = useState('');
  const [jobJobTypeId, setJobJobTypeId] = useState('new');
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  const jobIsValid = !isNaN(jobId) && !isNaN(parseInt(jobId)) && parseInt(jobId) > 0;

  let jobTypeIsValid = false;
  if (jobJobTypeId === 'new') {
    /**
     * If a new Job 'Job Type' is added, then check the 'jobType' state for its data
     */
    if (
      jobType &&
      jobTypeId &&
      !isNaN(jobTypeId) &&
      !isNaN(parseInt(jobTypeId)) &&
      parseInt(jobTypeId) > 0 &&
      jobType[JOB_TYPE_KEYS.ID] &&
      jobTypeId === jobType[JOB_TYPE_KEYS.ID]
    ) {
      jobTypeIsValid = true;
    }
  } else {
    /**
     * If an existing Job 'Job Type' is selected, then check the 'currentJobJobType' state for its data
     */
    if (
      currentJobJobType &&
      !isNaN(jobTypeId) &&
      !isNaN(parseInt(jobTypeId)) &&
      parseInt(jobTypeId) > 0 &&
      currentJobJobType[JOB_JOB_TYPE_KEYS.JOB_TYPE_ID] &&
      jobTypeId === currentJobJobType[JOB_JOB_TYPE_KEYS.JOB_TYPE_ID]
    ) {
      jobTypeIsValid = true;
    }
  }

  let jobJobTypeIsValid = false;
  if (jobJobTypeId) {
    if (jobJobTypeId === 'new') {
      jobJobTypeIsValid = jobIsValid && jobTypeIsValid;
    } else {
      if (
        currentJobJobType &&
        !isNaN(jobJobTypeId) &&
        !isNaN(parseInt(jobJobTypeId)) &&
        parseInt(jobJobTypeId) > 0 &&
        currentJobJobType[JOB_JOB_TYPE_KEYS.ID] &&
        jobJobTypeId === currentJobJobType[JOB_JOB_TYPE_KEYS.ID]
      ) {
        jobJobTypeIsValid = jobIsValid && jobTypeIsValid && true;
      }
    }
  }

  const jobTypeIsEdited = !!currentJobJobType;

  console.log({
    currentJobId,
    jobTypes,
    activeJobTypes,
    currentJobJobType,
    jobJobTypeId,
    jobTypeId,
    jobType,
    jobTypeIsValid,
    jobIsValid,
    jobJobTypeIsValid,
  });

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setActiveJobTypes(null);
      setJobId('');
      setJobJobTypeId('new');
      setJobType(null);
      setJobTypeId('');
      setAmount('');
      setAmountError('');
      setDescription('');
      setDescriptionError('');
      setErrorMessages([]);
      setMiscellaneousErrors([]);
    }
  }, [open]);

  useEffect(() => {
    const tempActiveJobTypes = jobTypes.filter(jobType => jobType[JOB_TYPE_KEYS.STATUS] === ACTIVE_JOB_TYPE);
    setActiveJobTypes(tempActiveJobTypes);
  }, [jobTypes]);

  /**
   * When an existing job 'job type'/service is edited
   */
  useEffect(() => {
    if (currentJobJobType) {
      setJobJobTypeId(currentJobJobType[JOB_JOB_TYPE_KEYS.ID]);
      setJobTypeId(currentJobJobType[JOB_JOB_TYPE_KEYS.JOB_TYPE_ID]);
      setJobId(currentJobJobType[JOB_JOB_TYPE_KEYS.JOB_ID]);
      setAmount(currentJobJobType[JOB_JOB_TYPE_KEYS.AMOUNT]);
      setDescription(
        currentJobJobType[JOB_JOB_TYPE_KEYS.DESCRIPTION] ? currentJobJobType[JOB_JOB_TYPE_KEYS.DESCRIPTION] : '',
      );
    }
  }, [currentJobJobType]);

  /**
   * When a new job 'job type'/service is added
   */
  useEffect(() => {
    if (currentJobId && jobType) {
      setJobId(currentJobId);
      setJobTypeId(jobType[JOB_TYPE_KEYS.ID]);
      setAmount(jobType[JOB_TYPE_KEYS.AMOUNT]);
      setDescription(jobType[JOB_TYPE_KEYS.DESCRIPTION] ? jobType[JOB_TYPE_KEYS.DESCRIPTION] : '');
    }
  }, [currentJobId, jobType]);

  useEffect(() => {
    for (const fieldName in errorMessages) {
      const msg = errorMessages[fieldName];
      switch (fieldName) {
        case JOB_JOB_TYPE_KEYS.DESCRIPTION:
          setDescriptionError(msg);
          break;

        case JOB_JOB_TYPE_KEYS.AMOUNT:
          setAmountError(msg);
          break;

        default:
          setMiscellaneousErrors(prevState => [...prevState, msg]);
          break;
      }
    }
  }, [errorMessages]);

  const handleResetJobType = () => {
    setJobJobTypeId('new');
    setJobType(null);
    setJobTypeId('');
    setJobId('');
    setAmount('');
    setDescription('');
    setErrorMessages([]);
    setMiscellaneousErrors([]);
  };

  const amountBlurHandler = event => {
    if (!event.target.value) {
      setAmountError(requiredMessage);
    } else if (!isValidAmount(event.target.value)) {
      setAmountError(amountNotValid);
    }
  };

  const handleFormSubmit = () => {
    let formIsValid = true;

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    if (!jobId) {
      formIsValid = false;
      setMiscellaneousErrors(prevState => [...prevState, jobNotValid]);
    }

    if (!amount) {
      formIsValid = false;
      setAmountError(requiredMessage);
    } else if (!isValidAmount(amount)) {
      formIsValid = false;
      setAmountError(amountNotValid);
    }

    if (formIsValid) {
      let jobJobTypeData = {
        authcode: authUser.authcode,
        [JOB_JOB_TYPE_KEYS.JOB_ID]: jobId,
        [JOB_JOB_TYPE_KEYS.JOB_TYPE_ID]: jobTypeId,
        [JOB_JOB_TYPE_KEYS.AMOUNT]: amount,
        [JOB_JOB_TYPE_KEYS.DESCRIPTION]: description,
      };

      if (jobJobTypeId === 'new') {
        dispatch(
          addJobJobType(
            { jobJobTypeData: jobJobTypeData },
            () => {
              if (_isMounted.current) onClose();
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      } else if (currentJobJobType && jobJobTypeId) {
        jobJobTypeData[JOB_JOB_TYPE_KEYS.JOB_JOB_TYPE_ID] = jobJobTypeId;

        dispatch(
          updateJobJobType(
            { jobJobTypeData: jobJobTypeData },
            () => {
              if (_isMounted.current) onClose();
            },
            messages => {
              if (_isMounted.current) setErrorMessages(messages);
            },
          ),
        );
      } else {
        SweetAlert({
          icon: 'error',
          title: 'Oops...',
          text: 'Service is not valid!',
          customClass: { container: classes.customAlert },
        });
      }
    }
  };

  let jobTypeName = 'NA';
  if (jobTypeIsEdited) {
    const currentJobTypeObj = jobTypes.find(
      jobTypeObj => jobTypeObj[JOB_TYPE_KEYS.ID] === currentJobJobType[JOB_JOB_TYPE_KEYS.JOB_TYPE_ID],
    );
    jobTypeName = currentJobTypeObj ? currentJobTypeObj[JOB_TYPE_KEYS.NAME] : 'NA';
  } else {
    jobTypeName = jobType ? jobType[JOB_TYPE_KEYS.NAME] : 'NA';
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} className={classes.dialogRoot} onClose={onClose}>
      <DialogTitle className={classes.dialogTitleRoot}>{jobTypeIsEdited ? 'Edit Service' : 'Add Service'}</DialogTitle>
      <DialogContent dividers>
        {!jobJobTypeIsValid && !jobTypeIsEdited && (
          <GridContainer>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                filterSelectedOptions
                clearOnBlur={false}
                popupIcon={null}
                id="jobType"
                value={jobType}
                options={activeJobTypes}
                getOptionLabel={option => {
                  return option && option[JOB_TYPE_KEYS.ID] ? `${option[JOB_TYPE_KEYS.NAME]}` : '';
                }}
                getOptionSelected={(option, value) => option[JOB_TYPE_KEYS.ID] === value[JOB_TYPE_KEYS.ID]}
                onChange={(event, newValue) => {
                  setJobType(newValue ? newValue : null);
                }}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    type="text"
                    name="jobType"
                    label="Select a service"
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                    }}
                  />
                )}
                renderOption={option => {
                  return (
                    <GridContainer>
                      <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary" className={classes.optionPrimaryText}>
                          {`${option[JOB_TYPE_KEYS.NAME]}`}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  );
                }}
                classes={{ root: classes.listAutocomplete }}
              />
            </Grid>
          </GridContainer>
        )}
        {jobJobTypeIsValid && (
          <GridContainer>
            <Grid item xs={12} md={jobTypeIsEdited ? 12 : 9}>
              <Typography variant="body1" gutterBottom>
                {jobTypeName}
              </Typography>
            </Grid>
            {!jobTypeIsEdited && (
              <Grid item xs={12} md={3} className={classes.mdRightAlign}>
                <Button type="button" variant="contained" size="small" color="secondary" onClick={handleResetJobType}>
                  Reset service
                </Button>
              </Grid>
            )}
            {miscellaneousErrors && miscellaneousErrors.length > 0 && (
              <Grid item xs={12}>
                {miscellaneousErrors.map((miscellaneousError, idx) => (
                  <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                    {miscellaneousError}
                  </Typography>
                ))}
              </Grid>
            )}
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                type="text"
                name="amount"
                variant="outlined"
                label="Amount"
                value={amount}
                onChange={e => {
                  setAmount(e.target.value);
                  setAmountError('');
                }}
                onBlur={amountBlurHandler}
                helperText={amountError}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                multiline
                minRows={4}
                name="description"
                variant="outlined"
                label="Description"
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                }}
                helperText={descriptionError}
              />
            </Grid>
          </GridContainer>
        )}
        <DialogActions className={classes.dialogActions}>
          {jobJobTypeIsValid && (
            <Button type="button" variant="contained" color="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          )}
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

JobTypesDialog.prototype = {
  currentJobId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JobTypesDialog;

import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { GET_INVOICE_STATUSES } from '../../@jumbo/constants/ActionTypes';
import { GET_INVOICE_STATUS_LIST_LINK } from '../../@jumbo/constants/APIConstants';

export const getInvoiceStatusList = (request = {}, callbackFun) => {
  return dispatch => {
    const body = {
      authcode: request.authcode,
    };
    const headers = { 'Content-Type': 'application/json' };
    dispatch(fetchStart());

    return axios
      .post(GET_INVOICE_STATUS_LIST_LINK, body, { headers })
      .then(response => {
        let isError = false;
        let errorMsg = null;

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.status && responseData.status === 'true') {
            const data = responseData.data;
            if (data.status) {
              const statuses = data.status;
              dispatch(fetchSuccess());
              dispatch({ type: GET_INVOICE_STATUSES, payload: statuses });
              if (callbackFun) callbackFun(statuses);
            } else {
              isError = true;
              errorMsg = 'Unable to get data';
            }
          } else {
            isError = true;
            errorMsg = responseData.message ? responseData.message : 'There was some issue in responding server.';
          }
        } else {
          isError = true;
          errorMsg = 'There was something issue in responding server.';
        }

        if (isError) {
          throw new Error(errorMsg);
        }
      })
      .catch(error => {
        dispatch({ type: GET_INVOICE_STATUSES, payload: [] });
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

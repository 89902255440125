import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';

import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';
import { MESSAGE_KEYS /* , MESSAGE_SEEN_FALSE */ } from '../../../@jumbo/constants/AppDataConstants';
import CmtAvatar from '../../../@coremat/CmtAvatar';

const useStyles = makeStyles(theme => ({
  chatCellItem: {
    padding: 16,
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover, &.active': {
      backgroundColor: alpha(theme.palette.common.dark, 0.04),
      '& $titleRoot': {
        color: theme.palette.primary.main,
      },
    },
  },
  chatCellInfo: {
    width: 'calc(100% - 40px)',
    paddingLeft: 16,
  },
  titleBox: {
    display: 'inline-flex',
    alignItems: 'baseline',
    justifyContent: 'space-around',
    width: 'calc(100% - 56px)',
  },
  titleRoot: {
    position: 'relative',
    width: '64%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  typeRoot: {
    fontSize: 12,
  },
  chatDesRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: theme.palette.text.secondary,
    paddingRight: 10,
    width: 'calc(100% - 24px)',
  },
  navUnread: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: 4,
    borderRadius: '10%',
    marginLeft: 'auto',
    textAlign: 'center',
    lineHeight: '19px',
    fontSize: 12,
  },
  chatAvatarRoot: {
    position: 'relative',
  },
}));

const UserCell = ({ data, currentUser, onContactSelect }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.chatCellItem, {
        active: currentUser && currentUser[MESSAGE_KEYS.RECEIVER_ID] === data[MESSAGE_KEYS.RECEIVER_ID],
      })}
      onClick={() => onContactSelect(data)}>
      <Box className={classes.chatAvatarRoot}>
        <CmtAvatar size={40} src="" alt={data[MESSAGE_KEYS.RECEIVER_NAME]} />
      </Box>
      <Box className={classes.chatCellInfo}>
        <Box display="flex" alignItems="center">
          <Box className={classes.titleBox}>
            <Typography component="span" variant="subtitle2" className={classes.titleRoot}>
              {data[MESSAGE_KEYS.RECEIVER_NAME]}
            </Typography>
            <Typography component="span" variant="subtitle2" className={classes.typeRoot}>{` (${
              data[MESSAGE_KEYS.RECEIVER_TYPE]
            })`}</Typography>
          </Box>
          <Box color="text.secondary" fontSize={12} ml="auto">
            {data[MESSAGE_KEYS.SENT_AT] ? getFormattedDate(data[MESSAGE_KEYS.SENT_AT], 'DD MMM') : ''}
          </Box>
        </Box>
        {data[MESSAGE_KEYS.MESSAGE] && (
          <Box display="flex" alignItems="center">
            <Typography className={classes.chatDesRoot}>{data[MESSAGE_KEYS.MESSAGE]}</Typography>
            {/* data[MESSAGE_KEYS.SEEN] === MESSAGE_SEEN_FALSE && (
              <Box component="p" className={classes.navUnread}>
                Unread
              </Box>
            ) */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

UserCell.prototype = {
  data: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onContactSelect: PropTypes.func.isRequired,
};

export default UserCell;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
/* import TablePagination from '@material-ui/core/TablePagination'; */
import TableRow from '@material-ui/core/TableRow';

import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
/* import { getComparator, stableSort } from '../../@jumbo/utils/tableHelper'; */
import { useDebounce } from '../../@jumbo/utils/commonHelper';
import { AppName } from '../../@jumbo/constants/AppConstants';
import { CUSTOMER_KEYS } from '../../@jumbo/constants/AppDataConstants';

import { getFilteredCustomers } from '../../redux/actions/Customers';
import CustomersHeader from './CustomersHeader';
import CustomersTableToolbar from './CustomersTableToolbar';
import CustomersTableHead from './CustomersTableHead';
import CustomerListRow from './CustomerListRow';
import NoRecordFound from '../NoRecordFound';
import Alert from '../UI/Alert';

import useStyles from './index.style';

const pageName = 'Customers';
const breadcrumbs = [{ label: pageName, isActive: true }];

const Customers = () => {
  document.title = `${AppName} - ${pageName}`;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { customers } = useSelector(({ customersReducer }) => customersReducer);
  const { authUser } = useSelector(({ auth }) => auth);

  /* const [filteredCustomers, setFilteredCustomers] = useState([]); */
  const [searchTerm, setSearchTerm] = useState('');
  /* const [orderBy, setOrderBy] = useState(CUSTOMER_KEYS.ID);
  const [order, setOrder] = useState('asc'); */
  const [sort, setSort] = useState({ orderBy: CUSTOMER_KEYS.ID, order: 'asc' });
  const [customersFetched, setCustomersFetched] = useState(false);
  const [customersExportFetched, setCustomersExportFetched] = useState(true);
  const [isFilterApplied, setFilterApplied] = useState(false);
  /* const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); */
  const [exportCSV, setExportCSV] = useState('0');
  const [fetchCriteria, setFetchCriteria] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isActive = true;

    if ((!customersFetched || !customersExportFetched) && fetchCriteria) {
      const fetchData = payload => {
        return (dispatch, getState) => {
          return dispatch(
            getFilteredCustomers(payload, (customersData, exportLink) => {
              if (isActive) {
                if (payload.customerData.export_list === '1' && exportLink) {
                  Alert({
                    icon: 'success',
                    title: 'Your export is ready!!',
                    html: `Click <a href="${exportLink}" target="_blank" download class="${classes.downloadLink}">here</a> to download your export`,
                  });
                }
                setCustomersFetched(true);
                setCustomersExportFetched(true);
                setFilterApplied(true);
                setExportCSV('0');
              }
            }),
          );
        };
      };

      const customerData = {
        authcode: authUser.authcode,
        search: fetchCriteria.search,
        order: fetchCriteria.order,
        order_by: fetchCriteria.orderBy,
        export_list: fetchCriteria.exportCSV,
      };
      const promise = dispatch(fetchData({ customerData: customerData }));
      promise.catch(error => {
        /* Setting to 'true' means API has been executed, not necessarily successfully */
        if (isActive) {
          setCustomersFetched(true);
          setCustomersExportFetched(true);
          setExportCSV('0');
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, authUser.authcode, customersFetched, customersExportFetched, fetchCriteria, classes.downloadLink]);

  useEffect(() => {
    setFetchCriteria({
      search: debouncedSearchTerm,
      order: sort.order,
      orderBy: sort.orderBy,
      exportCSV: '0',
    });
    setCustomersFetched(false);
  }, [debouncedSearchTerm, sort]);

  useEffect(() => {
    if (exportCSV === '1') {
      setFetchCriteria(prevState => {
        return {
          ...prevState,
          exportCSV: '1',
        };
      });
      setCustomersExportFetched(false);
    }
  }, [exportCSV]);

  /* useEffect(() => {
    let tempFilteredCustomers = customers;

    if (debouncedSearchTerm) {
      tempFilteredCustomers = tempFilteredCustomers.filter(customer => {
        return (
          (customer[CUSTOMER_KEYS.ID] &&
            customer[CUSTOMER_KEYS.ID].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.FIRST_NAME] &&
            customer[CUSTOMER_KEYS.FIRST_NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.LAST_NAME] &&
            customer[CUSTOMER_KEYS.LAST_NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.COMPANY_NAME] &&
            customer[CUSTOMER_KEYS.COMPANY_NAME].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.FORMATTED_ADDRESS] &&
            customer[CUSTOMER_KEYS.FORMATTED_ADDRESS].toLowerCase().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.PHONE_ONE] &&
            customer[CUSTOMER_KEYS.PHONE_ONE].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.EXTENSION_ONE] &&
            customer[CUSTOMER_KEYS.EXTENSION_ONE].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.PHONE_TWO] &&
            customer[CUSTOMER_KEYS.PHONE_TWO].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.EXTENSION_TWO] &&
            customer[CUSTOMER_KEYS.EXTENSION_TWO].toString().includes(debouncedSearchTerm.toLowerCase())) ||
          (customer[CUSTOMER_KEYS.FORMATTED_CREATED] &&
            customer[CUSTOMER_KEYS.FORMATTED_CREATED].toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
        );
      });
    }

    setFilteredCustomers(tempFilteredCustomers);
    setFilterApplied(!!debouncedSearchTerm);
  }, [customers, debouncedSearchTerm]); */

  const handleRequestSort = (event, property) => {
    /* const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc'); */
    const isAsc = sort.orderBy === property && sort.order === 'asc';
    setSort(prevState => {
      return { ...prevState, orderBy: property, order: isAsc ? 'desc' : 'asc' };
    });
  };

  /* const handlePageChange = (event, newPage) => {
    setPage(newPage);
  }; */

  /* const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }; */

  const handleCSVExport = () => setExportCSV('1');

  const handleRowClick = (event, id) => history.push(`/customer/${id}/`);

  const headCells = [
    { id: CUSTOMER_KEYS.ID, label: 'ID', sortColumn: true },
    { id: CUSTOMER_KEYS.NAME, label: 'Name', sortColumn: true },
    { id: CUSTOMER_KEYS.COMPANY_NAME, label: 'Company', sortColumn: true },
    { id: CUSTOMER_KEYS.FORMATTED_ADDRESS, label: 'Address', sortColumn: false },
    { id: CUSTOMER_KEYS.PHONE_ONE, label: 'Primary Phone', sortColumn: true },
    { id: CUSTOMER_KEYS.PHONE_TWO, label: 'Secondary Phone', sortColumn: true },
    { id: CUSTOMER_KEYS.FORMATTED_CREATED, label: 'Created', sortColumn: true },
  ];

  const colspan = headCells && headCells.length > 0 ? headCells.length : 1;

  return (
    <PageContainer heading={pageName} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CustomersHeader />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <CustomersTableToolbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleCSVExport={handleCSVExport} />
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="customersTableTitle"
                aria-label="Customers Table">
                <CustomersTableHead
                  headCells={headCells}
                  classes={classes}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {/* !!filteredCustomers.length */ !!customers.length ? (
                    /* stableSort(filteredCustomers, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => <CustomerListRow key={index} row={row} onRowClick={handleRowClick} />) */
                    customers.map((row, index) => <CustomerListRow key={index} row={row} onRowClick={handleRowClick} />)
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={colspan} rowSpan={10}>
                        {isFilterApplied ? (
                          <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                        ) : (
                          <NoRecordFound>
                            {customersFetched ? 'There are no records found.' : 'Loading customers...'}
                          </NoRecordFound>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={filteredCustomers.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            /> */}
          </Paper>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Customers;

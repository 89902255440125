import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCTS,
  SET_PRODUCT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  products: [],
  currentProduct: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case SET_PRODUCT_DETAILS: {
      return {
        ...state,
        currentProduct: action.payload,
      };
    }
    case ADD_PRODUCT: {
      return {
        ...state,
        products: [action.payload, ...state.products],
      };
    }
    case EDIT_PRODUCT: {
      return {
        ...state,
        products: state.products.map(product => (product.id === action.payload.id ? action.payload : product)),
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

import GridContainer from '../../../@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import AppDatePicker from '../../../@jumbo/components/Common/formElements/AppDatePicker';
import { JOB_KEYS } from '../../../@jumbo/constants/AppDataConstants';
import {
  addUnitsToDateTime,
  /* cloneDateTimeObj, */
  getDateinDesiredFormat,
  getDateObjinDesiredFormat,
  getMomentDateObj,
  getMomentUTCDateObj,
  getTimeList12HrFormat,
  isStartBeforeEnd,
  getCurrentDateinDesiredFormat,
} from '../../../@jumbo/utils/dateHelper';
import {
  customerNotValid,
  dateNotValid,
  jobNotValid,
  requiredMessage,
  timeNotValid,
} from '../../../@jumbo/constants/ErrorMessages';

import { updateJobForCustomer } from '../../../redux/actions/Jobs';

import useStyles from '../index.style';

const timeList = getTimeList12HrFormat();

const validateDate = (date, errorFunction, otherFunction) => {
  if (date) {
    if (!date.isValid()) {
      errorFunction(dateNotValid);
      if (otherFunction) otherFunction();
    }
  } else {
    errorFunction(requiredMessage);
    if (otherFunction) otherFunction();
  }
};

const isDateRangeValid = (startDate, startTime, endDate, endTime) => {
  const start = `${getDateinDesiredFormat(startDate, 'YYYY-MM-DD')} ${startTime}`;
  const end = `${getDateinDesiredFormat(endDate, 'YYYY-MM-DD')} ${endTime}`;
  if (isStartBeforeEnd(start, end)) {
    return true;
  } else {
    return false;
  }
};

const JobForm = ({ job, onJobStatusUpdate }) => {
  const _isMounted = useRef(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [jobId, setJobId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [startTimeError, setStartTimeError] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [endDateError, setEndDateError] = useState('');
  const [endTime, setEndTime] = useState(null);
  const [endTimeError, setEndTimeError] = useState('');
  /* const [updateEndWithStart, setUpdateEndWithStart] = useState(false); */
  const [updateEndDateWithStart, setUpdateEndDateWithStart] = useState(false);
  const [updateEndTimeWithStart, setUpdateEndTimeWithStart] = useState(false);
  const [dateTimeError, setDateTimeError] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (job) {
      setJobId(job[JOB_KEYS.ID]);
      setCustomerId(job[JOB_KEYS.CUSTOMER_ID]);
      setStartDate(job[JOB_KEYS.START_DATE] ? getMomentUTCDateObj(job[JOB_KEYS.START_DATE]) : null);
      setStartTime(job[JOB_KEYS.START_TIME] ? job[JOB_KEYS.START_TIME] : null);
      setEndDate(job[JOB_KEYS.END_DATE] ? getMomentUTCDateObj(job[JOB_KEYS.END_DATE]) : null);
      setEndTime(job[JOB_KEYS.END_TIME] ? job[JOB_KEYS.END_TIME] : null);
    }
  }, [job]);

  /* useEffect(() => {
    if (updateEndWithStart) {
      if (startDate && !startDateError) {
        if (!startTime || startTimeError) {
          setEndDate(startDate);
          setEndDateError('');
        } else {
          const startDateTime = `${getDateinDesiredFormat(startDate, 'YYYY-MM-DD')} ${startTime}`;
          const startDateTimeObj = getMomentDateObj(startDateTime);
          const endDateTimeObj = cloneDateTimeObj(startDateTimeObj);

          addUnitsToDateTime(endDateTimeObj, 2, 'hours');

          const newEndDate = getMomentDateObj(getDateObjinDesiredFormat(endDateTimeObj, 'YYYY-MM-DD'));
          const newEndTime = getDateObjinDesiredFormat(endDateTimeObj, 'HH:mm:ss');
          if (newEndDate && newEndTime) {
            setEndDate(newEndDate);
            setEndDateError('');
            setEndTime(newEndTime);
            setEndTimeError('');
          }
        }
      }
    }
  }, [updateEndWithStart, startDate, startDateError, startTime, startTimeError]); */

  useEffect(() => {
    if (updateEndDateWithStart) {
      if (startDate && !startDateError) {
        setEndDate(startDate);
        setEndDateError('');
      }
    }
  }, [updateEndDateWithStart, startDate, startDateError]);

  useEffect(() => {
    if (updateEndTimeWithStart) {
      if (startTime && !startTimeError) {
        const currentDate = getCurrentDateinDesiredFormat('YYYY-MM-DD');
        const dateTime = `${currentDate} ${startTime}`;
        const dateTimeObj = getMomentDateObj(dateTime);

        addUnitsToDateTime(dateTimeObj, 2, 'hours');

        const endTime = getDateObjinDesiredFormat(dateTimeObj, 'HH:mm:ss');
        if (endTime) {
          setEndTime(endTime);
          setEndTimeError('');
        }
      }
    }
  }, [updateEndTimeWithStart, startTime, startTimeError]);

  useEffect(() => {
    if (
      startDate &&
      !startDateError &&
      startTime &&
      !startTimeError &&
      endDate &&
      !endDateError &&
      endTime &&
      !endTimeError
    ) {
      if (!isDateRangeValid(startDate, startTime, endDate, endTime)) {
        setDateTimeError('Start time must be less than end time');
      } else if (dateTimeError) {
        setDateTimeError('');
      }
    } else if (dateTimeError) {
      setDateTimeError('');
    }
  }, [startDate, startDateError, startTime, startTimeError, endDate, endDateError, endTime, endTimeError, dateTimeError]);

  useEffect(() => {
    if (errorMessages) {
      for (const fieldName in errorMessages) {
        const msg = errorMessages[fieldName];
        switch (fieldName) {
          case JOB_KEYS.START_DATE:
            setStartDateError(msg);
            break;

          case JOB_KEYS.START_TIME:
            setStartTimeError(msg);
            break;

          case JOB_KEYS.END_DATE:
            setEndDateError(msg);
            break;

          case JOB_KEYS.END_TIME:
            setEndTimeError(msg);
            break;

          default:
            setMiscellaneousErrors(prevState => [...prevState, msg]);
            break;
        }
      }
    }
  }, [errorMessages]);

  const startDateChangeHandler = date => {
    setStartDate(date);
    setStartDateError('');
    validateDate(date, setStartDateError);
    if (/* !updateEndWithStart */ !updateEndDateWithStart) {
      /* setUpdateEndWithStart(true); */
      setUpdateEndDateWithStart(true);
    }
  };

  const endDateChangeHandler = date => {
    setEndDate(date);
    setEndDateError('');
    validateDate(date, setEndDateError);
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    setErrorMessages([]);
    setMiscellaneousErrors([]);

    let formIsValid = true;

    if (!jobId) {
      formIsValid = false;
      setMiscellaneousErrors(prevState => [...prevState, jobNotValid]);
    }

    if (!customerId) {
      formIsValid = false;
      setMiscellaneousErrors(prevState => [...prevState, customerNotValid]);
    }

    validateDate(startDate, setStartDateError, () => {
      formIsValid = false;
    });

    if (startTime) {
      if (!timeList.map(timeItem => timeItem.slug).includes(startTime)) {
        formIsValid = false;
        setStartTimeError(timeNotValid);
      }
    } else {
      formIsValid = false;
      setStartTimeError(requiredMessage);
    }

    validateDate(endDate, setEndDateError, () => {
      formIsValid = false;
    });

    if (endTime) {
      if (!timeList.map(timeItem => timeItem.slug).includes(endTime)) {
        formIsValid = false;
        setEndTimeError(timeNotValid);
      }
    } else {
      formIsValid = false;
      setEndTimeError(requiredMessage);
    }

    if (
      startDate &&
      !startDateError &&
      startTime &&
      !startTimeError &&
      endDate &&
      !endDateError &&
      endTime &&
      !endTimeError
    ) {
      if (!isDateRangeValid(startDate, startTime, endDate, endTime)) {
        formIsValid = false;
        setDateTimeError('Start time must be less than end time');
      }
    }

    if (formIsValid) {
      const jobData = {
        [JOB_KEYS.JOB_ID]: jobId,
        [JOB_KEYS.CUSTOMER_ID]: customerId,
        [JOB_KEYS.START_DATE]: getDateinDesiredFormat(startDate, 'YYYY-MM-DD'),
        [JOB_KEYS.START_TIME]: startTime,
        [JOB_KEYS.END_DATE]: getDateinDesiredFormat(endDate, 'YYYY-MM-DD'),
        [JOB_KEYS.END_TIME]: endTime,
      };

      dispatch(
        updateJobForCustomer(
          { jobData: jobData },
          updatedJobData => {
            onJobStatusUpdate(updatedJobData);
          },
          messages => {
            if (_isMounted.current) setErrorMessages(messages);
          },
        ),
      );
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer>
        {miscellaneousErrors && miscellaneousErrors.length > 0 && (
          <Grid item xs={12}>
            {miscellaneousErrors.map((miscellaneousError, idx) => (
              <Typography variant="caption" display="block" color="error" gutterBottom key={`misc-error-${idx}`}>
                {miscellaneousError}
              </Typography>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography component="h3" variant="h3">
            Customer Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.CUSTOMER_FNAME]} ${
            job[JOB_KEYS.CUSTOMER_LNAME]
          }`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Company
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.CUSTOMER_COMPANY]}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Phone
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${
            job[JOB_KEYS.CUSTOMER_EXTENSION_ONE] ? `(${job[JOB_KEYS.CUSTOMER_EXTENSION_ONE]}) ` : ''
          }${job[JOB_KEYS.CUSTOMER_PHONE_ONE]}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Email
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.CUSTOMER_EMAIL]}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Address
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.FORMATTED_ADDRESS]}`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component="h3" variant="h3">
            Job Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Job Type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.JOB_TYPE_NAME]}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Job Source
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.SOURCE_NAME]}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Job Description
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography variant="body1" className={classes.plainText}>{`${job[JOB_KEYS.JOB_DESCRIPTION]}`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component="h3" variant="h3">
            Schedule Details
          </Typography>
          {!!dateTimeError && (
            <Typography variant="caption" display="block" color="error" gutterBottom>
              {dateTimeError}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Start
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={5}>
          <AppDatePicker
            fullWidth
            disableToolbar
            name="start_date"
            inputVariant="outlined"
            format="ddd MMM Do YYYY"
            label="Starts"
            value={startDate}
            onChange={startDateChangeHandler}
            helperText={startDateError}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={5}>
          <Autocomplete
            fullWidth
            filterSelectedOptions
            id="start_time"
            value={startTime}
            options={timeList.map(timeItem => timeItem.slug)}
            getOptionLabel={option => {
              if (option) {
                const selectedTime = timeList.find(timeItem => timeItem.slug === option);
                if (selectedTime) {
                  return selectedTime.title;
                } else {
                  return '';
                }
              } else {
                return '';
              }
            }}
            getOptionSelected={(option, value) => option === value || option === null}
            renderInput={params => (
              <AppTextInput
                {...params}
                type="text"
                name="start_time"
                label="At"
                variant="outlined"
                margin="dense"
                helperText={startTimeError}
              />
            )}
            onChange={(e, value, reason) => {
              if (['select-option', 'remove-option'].includes(reason)) {
                const selectedTime = value ? value : null;
                setStartTime(selectedTime);

                if (selectedTime) {
                  if (!timeList.map(timeItem => timeItem.slug).includes(selectedTime)) {
                    setStartTimeError(timeNotValid);
                  } else {
                    setStartTimeError('');
                    if (/* !updateEndWithStart */ !updateEndTimeWithStart) {
                      /* setUpdateEndWithStart(true); */
                      setUpdateEndTimeWithStart(true);
                    }
                  }
                } else {
                  setStartTimeError(requiredMessage);
                }
              } else if (reason === 'clear') {
                setStartTime(null);
                setStartTimeError(requiredMessage);
              }
            }}
            classes={{ root: classes.listAutocomplete }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            End
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={5}>
          <AppDatePicker
            fullWidth
            disableToolbar
            name="end_date"
            inputVariant="outlined"
            format="ddd MMM Do YYYY"
            label="Ends"
            value={endDate}
            onChange={endDateChangeHandler}
            helperText={endDateError}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={5}>
          <Autocomplete
            fullWidth
            filterSelectedOptions
            id="end_time"
            value={endTime}
            options={timeList.map(timeItem => timeItem.slug)}
            getOptionLabel={option => {
              if (option) {
                const selectedTime = timeList.find(timeItem => timeItem.slug === option);
                if (selectedTime) {
                  return selectedTime.title;
                } else {
                  return '';
                }
              } else {
                return '';
              }
            }}
            getOptionSelected={(option, value) => option === value || option === null}
            renderInput={params => (
              <AppTextInput
                {...params}
                type="text"
                name="end_time"
                label="At"
                variant="outlined"
                margin="dense"
                helperText={endTimeError}
              />
            )}
            onChange={(e, value, reason) => {
              if (['select-option', 'remove-option'].includes(reason)) {
                const selectedTime = value ? value : null;
                setEndTime(selectedTime);

                if (selectedTime) {
                  if (!timeList.map(timeItem => timeItem.slug).includes(selectedTime)) {
                    setEndTimeError(timeNotValid);
                  } else {
                    setEndTimeError('');
                  }
                } else {
                  setEndTimeError(requiredMessage);
                }
              } else if (reason === 'clear') {
                setEndTime(null);
                setEndTimeError(requiredMessage);
              }
            }}
            classes={{ root: classes.listAutocomplete }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography component="h3" variant="h3">
            Team Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" className={clsx(classes.plainText, classes.boldPlainText)}>
            Team Members
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Box>
            <List style={{ padding: 0 }}>
              {job[JOB_KEYS.TECH].split(',').map((member, index) => (
                <ListItem key={index} style={{ padding: 0 }}>
                  <ListItemText
                    primary={member}
                    classes={{ primary: classes.plainText }}
                    style={{ margin: 0, marginBottom: 2 }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.textAlignRight}>
          <Button type="submit" variant="contained" color="primary">
            Update
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

JobForm.prototype = {
  job: PropTypes.object.isRequired,
  onJobStatusUpdate: PropTypes.func.isRequired,
};

export default JobForm;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';

import CmtImage from '../../../../@coremat/CmtImage';

import { AuhMethods } from '../../../../services/auth';
import IntlMessages from '../../../utils/IntlMessages';
import AuthWrapper from './AuthWrapper';
import ContentLoader from '../../ContentLoader';
import { AppName, CurrentAuthMethod } from '../../../constants/AppConstants';
import { COLOR_LOGO_LINK } from '../../../constants/AppDataConstants';
import { isEmpty } from '../../../../utils/FormValidation';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  document.title = `${AppName} - Login`;

  const [email, setEmail] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const emailBlurHandler = event => {
    if (isEmpty(event.target.value)) {
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
  };

  const passwordBlurHandler = event => {
    if (isEmpty(event.target.value)) {
      setPasswordIsInvalid(true);
    } else {
      setPasswordIsInvalid(false);
    }
  };

  const formSubmitHandler = event => {
    event.preventDefault();

    let formIsValid = true;

    if (isEmpty(email)) {
      formIsValid = false;
      setEmailIsInvalid(true);
    }

    if (isEmpty(password)) {
      formIsValid = false;
      setPasswordIsInvalid(true);
    }

    if (formIsValid) {
      dispatch(AuhMethods[method].onLogin({ email, password }));
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={COLOR_LOGO_LINK} width={240} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form onSubmit={formSubmitHandler}>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              onBlur={emailBlurHandler}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={emailIsInvalid}
              helperText={emailIsInvalid ? <IntlMessages id="appModule.emailIsInvalid" /> : ''}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              onBlur={passwordBlurHandler}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={passwordIsInvalid}
              helperText={passwordIsInvalid ? <IntlMessages id="appModule.passwordIsInvalid" /> : ''}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" mb={5}>
            {/* <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" />}
              label="Remember me"
            /> */}
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgotPassword">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button type="submit" variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>

            {/* <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signup">
                <IntlMessages id="signIn.signUp" />
              </NavLink>
            </Box> */}
          </Box>
        </form>

        {/* dispatch(AuhMethods[method].getSocialMediaIcons()) */}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;

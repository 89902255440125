import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const ColoredChip = props => {
  const StyledChip = withStyles(theme => {
    const chipColor = props.chipcolor ? props.chipcolor : theme.palette.primary.main;
    return {
      root: {
        color: ['default'].includes(chipColor) ? theme.palette.text.primary.main : 'white',
        backgroundColor: `${chipColor} !important`,
        '&:hover': {
          backgroundColor: chipColor,
          filter: 'brightness(120%)',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: chipColor,
          borderColor: chipColor,
        },
        borderRadius: 0,
      },
      outlined: {
        color: chipColor,
        border: `1px solid ${chipColor}`,
        backgroundColor: `transparent !important`,
      },
      icon: {
        color: props.variant === 'outlined' ? chipColor : 'white',
      },
      deleteIcon: {
        color: props.variant === 'outlined' ? chipColor : 'white',
      },
    };
  })(Chip);

  return <StyledChip {...props} />;
};

ColoredChip.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'outlined']),
};

export default ColoredChip;

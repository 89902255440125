import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GridContainer from '../../../@jumbo/components/GridContainer';
import { PROPERTY_KEYS, COUNTRY_KEYS, USA_STATE_KEYS } from '../../../@jumbo/constants/AppDataConstants';

import { getAddressString, getItemObjectNameFromId } from '../../../utils/Helpers';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewJobAddress = ({ open, propertiesList, onClose, onPropertySelection }) => {
  const classes = useStyles();

  const { countries } = useSelector(({ countriesReducer }) => countriesReducer);
  const { usaStates } = useSelector(({ usaStatesReducer }) => usaStatesReducer);

  let properties = [];
  if (propertiesList) {
    properties = propertiesList.map(customerProperty => {
      let stateName = getItemObjectNameFromId(
        usaStates,
        customerProperty[PROPERTY_KEYS.STATE],
        USA_STATE_KEYS.ID,
        USA_STATE_KEYS.NAME,
      );

      let countryName = getItemObjectNameFromId(
        countries,
        customerProperty[PROPERTY_KEYS.COUNTRY],
        COUNTRY_KEYS.ID,
        COUNTRY_KEYS.NAME,
      );

      const propertyLabel = getAddressString({
        addressOne: customerProperty[PROPERTY_KEYS.ADDRESS_ONE],
        addressTwo: customerProperty[PROPERTY_KEYS.ADDRESS_TWO],
        city: customerProperty[PROPERTY_KEYS.CITY],
        state: stateName,
        zipcode: customerProperty[PROPERTY_KEYS.ZIP_CODE],
        country: countryName,
      });

      return {
        slug: customerProperty[PROPERTY_KEYS.ID],
        label: propertyLabel,
        data: customerProperty,
      };
    });
  }
  properties.unshift({
    slug: 'new',
    label: 'Add New Property',
    data: null,
  });

  const handlePropertyClick = (id, property) => {
    onPropertySelection(id, property);
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
        className={classes.dialogRoot}
        TransitionComponent={Transition}>
        <DialogTitle className={classes.dialogTitleRoot}>Select a Property</DialogTitle>
        <DialogContent dividers>
          <GridContainer>
            <Grid item xs={12}>
              <List component="nav" aria-labelledby="properties-list">
                {properties.map(property => {
                  return (
                    <React.Fragment key={property.slug}>
                      <ListItem button onClick={() => handlePropertyClick(property.slug, property.data)}>
                        {property.slug === 'new' && (
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={property.label} />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </List>
            </Grid>
          </GridContainer>
          <DialogActions className={classes.dialogActions}>
            <Button type="button" variant="outlined" onClick={() => handlePropertyClick('new', null)}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

NewJobAddress.prototype = {
  open: PropTypes.bool.isRequired,
  propertiesList: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onPropertySelection: PropTypes.func.isRequired,
};

export default NewJobAddress;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
/* import Checkbox from '@material-ui/core/Checkbox'; */
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import { INVOICE_KEYS, INVOICE_STATUS_KEYS } from '../../../@jumbo/constants/AppDataConstants';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  titleRoot2: {
    letterSpacing: 0.25,
    color: theme.palette.text.disabled,
  },
  tableRow: {
    cursor: 'pointer',
  },
  columnCommon: {
    whiteSpace: 'nowrap',
  },
}));

const UserListRow = ({ row, /* isSelected, */ onRowClick /* , onCheckboxClick */ }) => {
  const classes = useStyles();

  const { invoiceStatuses } = useSelector(({ invoiceStatusesReducer }) => invoiceStatusesReducer);

  const invoiceId = row[INVOICE_KEYS.ID];
  const labelId = `invoice-checkbox-${invoiceId}`;
  /* const isItemSelected = isSelected(invoiceId); */

  const statusObj = invoiceStatuses.find(
    invoiceStatus => invoiceStatus[INVOICE_STATUS_KEYS.ID] === row[INVOICE_KEYS.STATUS],
  );
  const status = statusObj ? statusObj[INVOICE_STATUS_KEYS.NAME] : 'NA';

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, invoiceId)}
      role="checkbox"
      /* aria-checked={isItemSelected} */
      tabIndex={-1}
      key={invoiceId}
      /* selected={isItemSelected} */
      classes={{
        hover: classes.tableRow,
      }}>
      {/* <TableCell padding="checkbox" onClick={event => onCheckboxClick(event, invoiceId)}>
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
      <TableCell component="th" id={labelId} scope="row" className={classes.columnCommon}>
        {invoiceId}
      </TableCell>
      <TableCell className={classes.columnCommon}>
        <Box display="flex" flexDirection="column">
          <Typography className={classes.titleRoot} component="div" variant="h4">
            {`${row[INVOICE_KEYS.CUSTOMER_NAME_ALT]}`}
          </Typography>
          <Typography className={classes.titleRoot2} component="div" variant="h5">
            {row[INVOICE_KEYS.CUSTOMER_EMAIL]}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.columnCommon}>
        <Typography>{row[INVOICE_KEYS.FORMATTED_CREATED]}</Typography>
      </TableCell>
      <TableCell className={classes.columnCommon}>{row[INVOICE_KEYS.AMOUNT]}</TableCell>
      <TableCell className={classes.columnCommon}>{status}</TableCell>
      <TableCell onClick={event => event.stopPropagation()} className={classes.columnCommon}>
        <Link component={RouterLink} to={`/job/${row[INVOICE_KEYS.JOB_ID]}/`}>
          {row[INVOICE_KEYS.JOB_ID]}
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);

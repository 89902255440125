export const requiredMessage = 'This field is required!';
export const emailNotValid = 'Email is not valid!';
export const passwordMisMatch = 'Password did not match!';
export const passwordRulesMisMatch = 'Password does not follow under written rules!';
export const phoneOnlyNumericals = 'Phone no. is invalid!';
export const noSpaceMessage = 'No spaces allowed';
export const positiveOnlyIntegers = 'Value must be a non-zero positive number';
export const phoneNotValid = 'Phone is not valid!';
export const dateNotValid = 'Date is not valid!';
export const percentageNotValid = 'Percentage is not valid';
export const amountNotValid = 'Amount is not valid';
export const stateNotValid = 'State is not valid!';
export const countryNotValid = 'Country is not valid!';
export const propertyNotValid = 'Property is not valid!';
export const statusNotValid = 'Status is not valid!';
export const userNotValid = 'User is not valid!';
export const customerNotValid = 'Customer is not valid!';
export const jobTypeNotValid = 'Job Type is not valid!';
export const jobSourceNotValid = 'Job Source is not valid!';
export const timeNotValid = 'Time is not valid!';
export const jobNotValid = 'Job is not valid!';
export const fileNotValid = 'File is not valid!';
export const quantityNotValid = 'Quantity is not valid!';
